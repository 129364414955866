import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import * as Hammer from "hammerjs";
import { FliperElement } from "src/app/core/interface/types";
import lodash from "lodash";

@Component({
  selector: "app-fliper-pages",
  templateUrl: "./fliper-pages.component.html",
  styleUrls: ["./fliper-pages.component.scss"]
})
export class FliperPagesComponent implements OnInit {
  @Input() styles: any = {};
  @Input() f_elem: any;
  @Input() b_elem: any;
  @Input() swipable: boolean = false;
  @Output() onPageFlipped: EventEmitter<string> = new EventEmitter<string>();

  activeSide: "front" | "back" = "front";
  constructor() {}

  /**
   * Flip the page (to Front or back depending on current position)
   */
  isFlipping = true;
  flip(
    direction: "clockwise" | "anti-clockwise" = "clockwise",
    animationTimeInSec: number = 0.6
  ) {
    this.isFlipping = true;

    // --- flip side logic
    let flipper = $(".flipper");
    let transformDeg = this.findTransformDeg(direction);
    flipper.css("transition", `${animationTimeInSec}s`);
    flipper.css("transform", `rotateY(${transformDeg}deg)`);

    // --- delay half time of the animation time to keep front page visible
    setTimeout(() => {
      // --- adjust side stylings
      this.adjustFrontBackDisplayStyles();

      // --- trigger page flipped event
      this.onPageFlipped.next(this.activeSide);

      this.isFlipping = false;
    }, 150);
  }

  // --- adjust front and back side view styling
  adjustFrontBackDisplayStyles() {
    let frontElem = $(".front");
    let backElem = $(".back");
    if (backElem.css("display") == "none") {
      frontElem.css("display", "none");
      backElem.css("display", "block");
      this.activeSide = "back";
    } else {
      frontElem.css("display", "block");
      backElem.css("display", "none");
      this.activeSide = "front";
    }
  }

  /**
   * get transform degree value for clockwise/anti-clockwise rotation based on user's swipe direction
   */
  prevTransformDeg = 0;
  findTransformDeg(direction: "clockwise" | "anti-clockwise" = "clockwise") {
    if (direction == "clockwise") this.prevTransformDeg += 180;
    else if (direction == "anti-clockwise") this.prevTransformDeg -= 180;
    return this.prevTransformDeg;
  }

  /**
   * set front and back pages swipe listeners
   */
  swipeListenersSetted = false;
  setSwipeListeners() {
    if (this.swipeListenersSetted) return;
    this.swipeListenersSetted = true;
    let self = this;

    let frontElem = $(".front")[0];
    let backElem = $(".back")[0];

    const onSwipe = (swipeDirection: "left" | "right") => {
      if (!this.swipable) return;

      self.flip(swipeDirection == "left" ? "anti-clockwise" : "clockwise");
    };

    const setFrontElementListener = () => {
      if (!frontElem) return;

      // Create a manager to manager the element
      var manager = new Hammer.Manager(frontElem);

      // Create a recognizer
      var Swipe = new Hammer.Swipe({ direction: Hammer.DIRECTION_HORIZONTAL });

      // Add the recognizer to the manager
      manager.add(Swipe);

      // Subscribe to a desired event
      manager.on("swipeleft", () => onSwipe("left"));
      manager.on("swiperight", () => onSwipe("right"));
    };

    const setBackElementListener = () => {
      if (!backElem) return;

      // Create a manager to manager the element
      var manager = new Hammer.Manager(backElem);

      // Create a recognizer
      var Swipe = new Hammer.Swipe({ direction: Hammer.DIRECTION_HORIZONTAL });

      // Add the recognizer to the manager
      manager.add(Swipe);

      // Subscribe to a desired event
      manager.on("swipeleft", () => onSwipe("left"));
      manager.on("swiperight", () => onSwipe("right"));
    };

    setFrontElementListener();
    setBackElementListener();
  }

  ngOnInit(): void {
    this.adjustFrontBackDisplayStyles();
  }

  ngOnChanges() {
    if (this.swipable) this.setSwipeListeners();
  }
}
