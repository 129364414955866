import { Component, Input, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import UserAuth from "src/app/core/auth/userAuth.class";
import { Role } from "src/app/core/enums/user.enums";
import { PreferencesService } from "src/app/core/preferences/preferences.service";
import { SettingsService } from "src/app/core/settings/settings.service";
import { getQueryParams } from "src/app/core/utils/queryParams.utils";
import lodash from "lodash";
import { CommonServiceService } from "src/app/core/common/common-service.service";
import { AlertDialogService } from "src/app/features/alert-dialog/alert-dialog.service";
import { IndividualApiService } from "src/app/core/individual/individual.api.service";

@Component({
  selector: "app-change-password-dialog",
  templateUrl: "./change-password-dialog.component.html",
  styleUrls: ["./change-password-dialog.component.scss"]
})
export class ChangePasswordDialogComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;

  password: string;
  confirmPassword: string;
  userAuth: UserAuth;
  indData: any;
  passwordMinimumLength: any;
  passwordMaximumLength: any;
  passwordComplexityType: any;
  signUpForm: FormGroup;

  constructor(
    private activeModal: NgbActiveModal,
    private settingsService: SettingsService,
    private commonService: CommonServiceService,
    private preferencesService: PreferencesService,
    private alertDialogService: AlertDialogService,
    private individualApi: IndividualApiService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.userAuth = getQueryParams();

    this.signUpForm = this.fb.group({
      password: [
        null,
        [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(1),
          this.confirmValidator
        ]
      ],
      confirmPassword: [null, [this.confirmValidator]]
    });
  }

  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.signUpForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  // --- Close dialog
  public dismiss() {
    this.activeModal.close({ success: false });
  }

  // ---- check blank, undefined or null value
  isBlank(val: string): boolean {
    return !(val != "" && val != undefined && val != null);
  }

  errorMsg = "";
  async validateConfirmPassword() {
    let errorMsg = "";
    let password = this.signUpForm.get("password").value;
    let atLeastOneSpecialChar: RegExp = new RegExp(
      /^(?=.*[0-9])(?=.*[!@#$%^&*()_+,.\\\/;':"-]).{5,}$/
    );
    let atLeastOneNo: RegExp = new RegExp(/^(?=(.*[\d]){1,})/);
    let oneNoOneChar: RegExp = new RegExp(
      /^(?=.*[!@#$%^&*()_+,.\\\/;':"-]).{5,}$/
    );

    let readDataPromises = [];

    readDataPromises.push(
      this.preferencesService.getPreferenceByInheritance(
        Role.ORG,
        this.userAuth.orgID,
        "nonAdminPasswordPolicyMaximumPasswordComplexityRequirements",
        null,
        true,
        "value"
      )
    );

    readDataPromises.push(
      this.preferencesService.getPreferenceByInheritance(
        Role.ORG,
        this.userAuth.orgID,
        "nonAdminPasswordPolicyMinimumPasswordLength",
        null,
        true,
        "value"
      )
    );

    readDataPromises.push(
      this.preferencesService.getPreferenceByInheritance(
        Role.ORG,
        this.userAuth.orgID,
        "nonAdminPasswordPolicyMaximumPasswordLength",
        null,
        true,
        "value"
      )
    );

    let readDataPromisesRes = await Promise.all(readDataPromises);

    let pwComplexity = lodash.nth(readDataPromisesRes, 0);
    let pwMinLen: any = lodash.nth(readDataPromisesRes, 1);
    let pwMaxLen: any = lodash.nth(readDataPromisesRes, 2);
    let minimumLength =
      pwMinLen == 1
        ? this.indData?.hasOwnProperty("isSubUserExist")
          ? 6
          : 1
        : parseInt(pwMinLen);
    let maximumLength = pwMaxLen == 1 ? 20 : parseInt(pwMaxLen);
    if (pwComplexity != "none") {
      setTimeout(async () => {
        this.signUpForm.controls["password"].setValidators(
          Validators.maxLength(maximumLength)
        );
        this.signUpForm.controls["password"].setValidators(
          Validators.minLength(minimumLength)
        );
        if (pwComplexity == "atLeastOneSpecialChar") {
          this.signUpForm.controls["password"].setValidators(
            Validators.pattern(atLeastOneSpecialChar)
          );
        } else if (pwComplexity == "atLeastOneNo") {
          this.signUpForm.controls["password"].setValidators(
            Validators.pattern(atLeastOneNo)
          );
        } else if (pwComplexity == "oneNoOneChar") {
          this.signUpForm.controls["password"].setValidators(
            Validators.pattern(oneNoOneChar)
          );
        }
        this.signUpForm.controls["password"].updateValueAndValidity();

        if (this.isBlank(password)) {
          errorMsg = "That password won't work because:";
          let msg = this.settingsService.adminPasswordValidaion(
            pwMinLen,
            pwMaxLen,
            pwComplexity,
            password
          );
          errorMsg = errorMsg + msg;
        } else if (this.isBlank(password)) {
          errorMsg = "Please enter password";
        } else if (!this.isBlank(password)) {
          errorMsg = "That password won't work because:";
          let msg = this.settingsService.adminPasswordValidaion(
            pwMinLen,
            pwMaxLen,
            pwComplexity,
            password
          );
          errorMsg = msg ? errorMsg + msg : "";
        }
        this.errorMsg = errorMsg;
      }, 100);
    
    } else {
      setTimeout(() => {
        this.signUpForm.controls["password"].setValidators(
          Validators.maxLength(maximumLength)
        );
        this.signUpForm.controls["password"].setValidators(
          Validators.minLength(minimumLength)
        );
        this.signUpForm.controls["password"].updateValueAndValidity();
        if (this.isBlank(password)) {
          errorMsg = "Please enter password";
        }
        this.errorMsg = errorMsg;
      }, 100);
    }
  }

  isPwdEmpty: boolean = false;
  checkPwdIsEmpty() {
    this.isPwdEmpty = this.isBlank(this.signUpForm.get("password").value);
  }

  async checkPassswordValidation() {
    let passwdVal = this.signUpForm.get("password").value;
    if (passwdVal === null || passwdVal === undefined) {
      passwdVal = "";
    }
    // ========== Default check password field =====
    let defaults = {
      minimumLength: this.passwordMinimumLength,
      maximumLength: this.passwordMaximumLength,
      useUppercase: false,
      useNumbers: false,
      useSpecial: false,
      infoMessage: "",
      style: "dark", // Style Options light or dark
      fadeTime: 50 // FadeIn / FadeOut in milliseconds
    };

    let passwordComplexity = Object.create(defaults);
    passwordComplexity = {
      complexityType: this.passwordComplexityType
    };
    let passwd_Complex = passwordComplexity.complexityType;
    // Set password complexity
    if (passwd_Complex === "atLeastOneSpecialChar") {
      $(".pr-useSpecial").show();
      defaults.useSpecial = true;
    } else {
      $(".pr-useSpecial").hide();
      defaults.useSpecial = false;
    }
    if (passwd_Complex === "atLeastOneNo") {
      $(".pr-useNumbers").show();
      defaults.useNumbers = true;
    } else {
      $(".pr-useNumbers").hide();
      defaults.useNumbers = false;
    }
    if (passwd_Complex === "oneNoOneChar") {
      $(".pr-useSpecial, .pr-useNumbers").show();
      defaults.useSpecial = true;
      defaults.useNumbers = true;
    }

    // Set completion variables
    let minimumLengthDone = true,
      maximumLengthDone = true,
      useUppercaseDone = true,
      useNumbersDone = true,
      useSpecialDone = true;
    // Show Tooltip resuable function
    let showTolltip = () => {
      if (
        minimumLengthDone === false ||
        maximumLengthDone === false ||
        useUppercaseDone === false ||
        useNumbersDone === false ||
        useSpecialDone === false
      ) {
        $(".pr-password").each(function() {
          // Find the position of element
          var posH = $(this).offset().top,
            itemH = $(this).innerHeight(),
            totalH = (posH + itemH) / 1.8,
            itemL = $(this).offset().left - $(this).innerWidth() / 2;
          // Append info box tho the body
          $("#pr-box")
            .addClass(defaults.style)
            .fadeIn(defaults.fadeTime);
        });
      }
    };

    // Hide tooltip resuable function
    let hideTooltip = () => {
      var targetMessage = $("#pr-box");
      targetMessage.fadeOut(defaults.fadeTime, function() {
        $(this).hide();
      });
    };

    // Show / Delete Message when completed requirements function
    let checkCompleted = () => {
      if (
        minimumLengthDone === true &&
        maximumLengthDone === true &&
        useUppercaseDone === true &&
        useNumbersDone === true &&
        useSpecialDone === true
      ) {
        hideTooltip();
      } else {
        showTolltip();
      }
    };

    // Show or Hide password hint based on user's event
    // Set variables
    let upperCase = new RegExp("[A-Z]"),
      numbers = new RegExp(/^(?=(.*[\d]){1,})/),
      specialCharacter = new RegExp("[!,%,&,@,#,$,^,*,?,_,~]");
    // ======= Conditions to check
    // minimum length meet requirements
    if (
      passwdVal.length >= defaults.minimumLength &&
      passwdVal.length <= defaults.maximumLength
    ) {
      $(".pr-minimumcharacters span").addClass("pr-ok");
      minimumLengthDone = true;
    } else {
      $(".pr-minimumcharacters span").removeClass("pr-ok");
      minimumLengthDone = false;
    }
    // maximum length meet requirements
    if (passwdVal.length > defaults.maximumLength) {
      $(".pr-maximumcharecters span").addClass("pr-ok");
      maximumLengthDone = false;
    } else {
      $(".pr-maximumcharecters span").removeClass("pr-ok");
      maximumLengthDone = true;
    }
    // upperCase meet requirements
    if (defaults.useUppercase === true) {
      if (passwdVal.match(upperCase)) {
        useUppercaseDone = true;
      } else {
        useUppercaseDone = false;
      }
    }
    // Special character
    if (defaults.useSpecial === true) {
      if (passwdVal.match(specialCharacter)) {
        $(".pr-useSpecial span").addClass("pr-ok");
        useSpecialDone = true;
      } else {
        $(".pr-useSpecial span").removeClass("pr-ok");
        useSpecialDone = false;
      }
    }
    // Number meet requirements
    if (defaults.useNumbers === true) {
      if (passwdVal.match(numbers)) {
        $(".pr-useNumbers span").addClass("pr-ok");
        useNumbersDone = true;
      } else {
        $(".pr-useNumbers span").removeClass("pr-ok");
        useNumbersDone = false;
      }
    }
    checkCompleted();
  }

  // ============ Password Validation show lables tooltip ====================
  async showLabels() {
    let readDataPromises = [];

    readDataPromises.push(
      this.preferencesService.getPreferenceByInheritance(
        Role.ORG,
        this.userAuth.orgID,
        "nonAdminPasswordPolicyMaximumPasswordLength",
        null,
        true,
        "value"
      )
    );

    readDataPromises.push(
      this.preferencesService.getPreferenceByInheritance(
        Role.ORG,
        this.userAuth.orgID,
        "nonAdminPasswordPolicyMinimumPasswordLength",
        null,
        true,
        "value"
      )
    );

    readDataPromises.push(
      this.preferencesService.getPreferenceByInheritance(
        Role.ORG,
        this.userAuth.orgID,
        "nonAdminPasswordPolicyMaximumPasswordComplexityRequirements",
        null,
        true,
        "value"
      )
    );

    let readDataPromisesRes = await Promise.all(readDataPromises);
    this.passwordMaximumLength = lodash.nth(readDataPromisesRes, 0);
    this.passwordMinimumLength = lodash.nth(readDataPromisesRes, 1);
    this.passwordComplexityType = lodash.nth(readDataPromisesRes, 2);

    this.checkPassswordValidation();
  }

  hideLabels() {
    var targetMessage = $("#pr-box");
    targetMessage.fadeOut(300, function() {
      $(this).hide();
    });
  }

  async signUp() {
    // --- check if signup form is valid
    if (!this.signUpForm.valid) {
      
      return;
    }

    // --- check password validation criteria
    let isValid = await this.checkPasswordBeforeSubmit();
    if (isValid) {      
      return;
    }

    // --- set individual password
    let password = this.signUpForm.get("password").value;
    let [
      setIndPWResult,
      setIndPWError
    ] = await this.commonService.executePromise(
      this.individualApi.setIndPW(
        this.userAuth.orgID,
        this.userAuth.indID,
        password,
        this.userAuth.payload
      )
    );

    // --- error handling
    if (setIndPWError) {
      this.commonService.handleError(setIndPWError);
      return false;
    }

    this.activeModal.close({ success: true });
  }

  async checkPasswordBeforeSubmit() {
    return new Promise(async (resolve, reject) => {
      try {
        let errorMsg = "";
        let password = this.signUpForm.get("password").value;
        let confirmPassword = this.signUpForm.get("confirmPassword").value;

        let readDataPromises = [];

        readDataPromises.push(
          this.preferencesService.getPreferenceByInheritance(
            Role.ORG,
            this.userAuth.orgID,
            "nonAdminPasswordPolicyMaximumPasswordComplexityRequirements",
            null,
            true,
            "value"
          )
        );

        readDataPromises.push(
          this.preferencesService.getPreferenceByInheritance(
            Role.ORG,
            this.userAuth.orgID,
            "nonAdminPasswordPolicyMinimumPasswordLength",
            null,
            true,
            "value"
          )
        );

        readDataPromises.push(
          this.preferencesService.getPreferenceByInheritance(
            Role.ORG,
            this.userAuth.orgID,
            "nonAdminPasswordPolicyMaximumPasswordLength",
            null,
            true,
            "value"
          )
        );

        let readDataPromisesRes = await Promise.all(readDataPromises);

        let pwComplexity = lodash.nth(readDataPromisesRes, 0);
        let pwMinLen: any = lodash.nth(readDataPromisesRes, 1);
        let pwMaxLen: any = lodash.nth(readDataPromisesRes, 2);

        if (pwComplexity != "none") {
          if (this.isBlank(password)) {
            errorMsg = "That password won't work because:";
            let msg = this.settingsService.adminPasswordValidaion(
              pwMinLen,
              pwMaxLen,
              pwComplexity,
              password
            );
            errorMsg = errorMsg + msg;
          } else if (this.isBlank(password)) {
            errorMsg = "Please enter password";
          } else if (!this.isBlank(password)) {
            errorMsg = "That password won't work because:";
            let msg = this.settingsService.adminPasswordValidaion(
              pwMinLen,
              pwMaxLen,
              pwComplexity,
              password
            );
            if (msg) {
              errorMsg = errorMsg + msg;
            } else {
              errorMsg = "";
            }
          }
          if (errorMsg) {
            this.alertDialogService
              .confirm("Error", errorMsg, null, null)
              .then(res => {
                resolve(true);
              })
              .catch(e => {
                resolve(true);
              });
          }
        } else {
          if (this.isBlank(password)) {
            errorMsg = "Please enter password";
          }
          if (errorMsg) {
            this.alertDialogService
              .confirm("Error", errorMsg, null, null)
              .then(res => {
                resolve(true);
              })
              .catch(e => {
                resolve(true);
              });
          }
        }

        if (!errorMsg && password != confirmPassword) {
          this.alertDialogService
            .confirm("Error", "Password is inconsistent!", null, null)
            .then(res => {
              resolve(true);
            })
            .catch(e => {
              resolve(true);
            });
        } else {
          if (password.length < pwMinLen || password.length > pwMaxLen) {
            errorMsg =
              "Password length between " + pwMinLen + " and " + pwMaxLen;
            this.alertDialogService
              .confirm("Error", errorMsg, null, null)
              .then(res => {
                resolve(true);
              })
              .catch(e => {
                resolve(true);
              });
          } else {
            resolve(false);
          }
        }
      } catch (e) {
        resolve(true);
      }
    });
  }
}
