import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import lodash from "lodash";
import moment from "moment";
import { CommonServiceService } from "../common/common-service.service";
import { Role } from "../enums/user.enums";

@Injectable({
  providedIn: "root"
})
export class LicenseService {
  constructor(
    private db: AngularFireDatabase,
    private commonService: CommonServiceService
  ) {}

  // --- helper map variable for free and paid license ids
  licenseIDMap = {
    BehaviourTracking: {
      free: ["1184595810766927", "1524407436285466"],
      paid: ["1271243152799481", "1532666995854258"]
    },
    StudentTracking: {
      free: ["ST_F_sssss"],
      paid: ["ST_P_sssss"]
    }
  };

  async getOrgLicensesArr(orgID: string) {
    let snapshot = await this.db
      .list(`/licenses/instances/${orgID}`)
      .query.once("value");
    let orgLicensesObj = snapshot.val();
    let orgLicenses = [];
    lodash.each(orgLicensesObj, (value, key) => {
      value["key"] = key;
      orgLicenses.push(value);
    });
    return orgLicenses;
  }

  private isLicenseValid(licenseId, orgLicensesArr): boolean {
    for (let k = 0; k < orgLicensesArr.length; k++) {
      const orgLicense = orgLicensesArr[k];
      let keys = Object.keys(orgLicense);
      for (let o = 0; o < keys.length; o++) {
        const key = keys[o];
        if (key == "key") {
          continue;
        }
        if (orgLicense[key]["id"] == licenseId) {
          if (
            orgLicense[key]["type"] == "expiring" &&
            orgLicense[key]["expire_on"] &&
            orgLicense[key]["expire_on"] > moment().format("x")
          ) {
            return true;
          } else if (
            orgLicense[key]["type"] == "count_prepaid" &&
            orgLicense[key]["defaultCount"] &&
            orgLicense[key]["defaultCount"] > 0
          ) {
            return true;
          }
        }
      }
    }
    return false;
  }

  // ---
  isAnyLicenseValid(licensesArr, orgLicensesArr) {
    let isLicenseValid = false;
    for (let l = 0; l < licensesArr.length; l++) {
      const licenseId = licensesArr[l];
      if (this.isLicenseValid(licenseId, orgLicensesArr)) {
        isLicenseValid = true;
        break;
      }
    }
    return isLicenseValid;
  }

  /**
   * get license definitions of given pool
   * @param pool pool name
   * @returns list of license definitions of given pool
   */
  async getLicenseDefinitionsOfPool(pool: string) {
    if (!pool) return [];

    let [snapshot, err] = await this.commonService.executePromise(
      this.db
        .list(`licenses/definitions`)
        .query.orderByChild("pool")
        .equalTo(pool)
        .once("value")
    );
    if (err) return [];

    return lodash.map(snapshot.val(), (licenseDefData: any, key) => {
      return { ...licenseDefData, key };
    });
  }

  /**
   * filter messages licenses from valid license data
   * @param orgValidLicenses org valid licenses object
   * @returns list of org messages license
   */
  async getMessagesLicenses(validLicenses) {
    let msgLicenses = [];

    // --- read license definitions of message pool
    let msgsLicenseDefs = await this.getLicenseDefinitionsOfPool("Message");

    lodash.each(validLicenses, licenseObj => {
      let licenseFirstObjKey = lodash
        .chain(licenseObj)
        .keys()
        .first()
        .value();
      if (!licenseFirstObjKey) return;

      if (
        licenseObj[licenseFirstObjKey].pool == "Message" ||
        lodash.some(
          msgsLicenseDefs,
          def => def.id == licenseObj[licenseFirstObjKey].id
        )
      )
        msgLicenses.push(licenseObj);
    });

    return msgLicenses;
  }

  /**
   * get licenses list to update, if messages get sent successfull
   * @param role user role
   * @param userID user id (org id or studio id)
   * @param sendMsgsCreditsUsageCount credits count required to send messages
   * @returns list of licenses to update
   */
  async getLicensesToUpdateAfterMsgsSent(
    role: string,
    userID: string,
    sendMsgsCreditsUsageCount: number
  ) {
    if (!role || !userID || (role != Role.ORG && role != Role.STUDIO))
      return [];

    // --- read user licenses
    let userLicenses = await this.getUserLicense(role, userID);

    // --- remove replaced licenses data
    userLicenses = lodash.map(userLicenses, singleLicenseData => {
      return lodash.omitBy(
        singleLicenseData,
        (data, timestamp) => data.isReplaced
      );
    });

    // --- filter messages licenses
    let msgLicenses = await this.getMessagesLicenses(userLicenses);

    // --- sort array by default count
    msgLicenses = lodash.orderBy(msgLicenses, singleLicenseData => {
      let licenseFirstObjKey = lodash
        .chain(singleLicenseData)
        .keys()
        .first()
        .value();

      let defCount = lodash
        .chain(singleLicenseData)
        .get(`${licenseFirstObjKey}.defaultCount`, 0)
        .toNumber()
        .value();
      return defCount;
    });

    // --- prepare array by reducing credits
    let licensesToUpdate = [];
    lodash.each(msgLicenses, singleLicenseData => {
      let updatedSingleLicenseData = lodash.mapValues(
        singleLicenseData,
        (data, timestamp) => {
          let defCount = lodash
            .chain(data)
            .get("defaultCount", "0")
            .toNumber()
            .value();
          let modifiedLicenseData = lodash.cloneDeep(data);
          if (defCount >= sendMsgsCreditsUsageCount) {
            modifiedLicenseData.defaultCount = lodash.toString(
              defCount - sendMsgsCreditsUsageCount
            );
            sendMsgsCreditsUsageCount = 0;
          } else if (defCount != 0) {
            modifiedLicenseData.defaultCount = "0";
            sendMsgsCreditsUsageCount -= defCount;
          }

          return modifiedLicenseData;
        }
      );

      // --- if updated object of license is not equal to previous object,
      // add it to to-update licenses list
      if (!lodash.isEqual(singleLicenseData, updatedSingleLicenseData)) {
        licensesToUpdate.push(updatedSingleLicenseData);
      }

      // --- if credits already debited, break loop
      if (sendMsgsCreditsUsageCount == 0) return false;
    });

    return licensesToUpdate;
  }

  async getTotalSmsCount(role, userID) {
    // --- read user licenses
    let userLicenses = await this.getUserLicense(role, userID);

    // --- remove replaced licenses data
    userLicenses = lodash.map(userLicenses, singleLicenseData => {
      return lodash.omitBy(
        singleLicenseData,
        (data, timestamp) => data.isReplaced
      );
    });

    // --- filter messages licenses
    let msgLicenses = await this.getMessagesLicenses(userLicenses);

    // --- calculate total remaining message balance
    let totalMsgsCount = lodash.reduce(
      msgLicenses,
      (ac, singleLicenseData) => {
        lodash.each(singleLicenseData, (data, timestamp) => {
          ac += lodash
            .chain(data)
            .get("defaultCount", 0)
            .toNumber()
            .value();
        });
        return ac;
      },
      0
    );

    return totalMsgsCount;
  }

  getBundleList(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      let listSub = this.db
        .list(`/licenses/Bundles`)
        .snapshotChanges()
        .subscribe(
          list => {
            listSub.unsubscribe();
            let bundleList = [];
            if (list.length > 0) {
              lodash.each(list, entry => {
                let value: any = entry.payload.val();
                value["key"] = entry.payload.key;
                bundleList.push(value);
              });
              resolve(bundleList);
            } else {
              resolve([]);
            }
          },
          err => {
            console.log("err: ", err);
            resolve([]);
          }
        );
    });
  }

  setOrgBundleHistory(orgId: string, recordID: string, data: any) {
    return this.db
      .object(`/licenses/orgBundleHistory/${orgId}/${recordID}`)
      .set(data);
  }

  /**
   * get user license list
   * @param role user role
   * @param userID user id (orgID or studioID)
   * @returns promise of list of user licenses
   */
  async getUserLicense(
    role: string,
    userID: string,
    responseType: "list" | "object" = "list"
  ) {
    if (!role || !userID || (role != Role.ORG && role != Role.STUDIO))
      return [];

    // --- prepare db path
    let dbPath =
      role == Role.ORG
        ? `/licenses/instances/${userID}`
        : `/licenses/studios/instances/${userID}`;

    let [snapshot, err]: any = await this.commonService.executePromise(
      this.db.object(dbPath).query.once("value")
    );

    if (err) {
      console.log("err", err);
      return [];
    }

    let resObj = snapshot.val();
    return responseType == "list" ? lodash.values(resObj) : resObj;
  }

  // --- update studio licenses
  async updateStudioLicenses(
    studioID: string,
    licensesToUpdate: any[]
  ): Promise<any> {
    let studioLicenses = await this.getUserLicense(
      Role.STUDIO,
      studioID,
      "object"
    );
    let updateLicensePromises: Promise<void>[] = [];

    lodash.each(licensesToUpdate, lData => {
      let currentLicenseKey;
      let millisKeyToMatch = lodash
        .chain(lData)
        .keys()
        .first()
        .value();
      lodash.each(studioLicenses, (orgLData: any, lKey) => {
        let isMillisKeyPresent = lodash.some(
          orgLData,
          (_, orgLMillis) => orgLMillis == millisKeyToMatch
        );
        if (isMillisKeyPresent) {
          currentLicenseKey = lKey;
          return false;
        }
        return true;
      });

      if (currentLicenseKey)
        updateLicensePromises.push(
          this.db
            .object(
              `/licenses/studios/instances/${studioID}/${currentLicenseKey}`
            )
            .update(lData)
        );
    });

    return await Promise.all(updateLicensePromises);
  }

  // --- update org licenses
  async updateOrgLicenses(
    orgID: string,
    licensesToUpdate: any[]
  ): Promise<any> {
    let orgLicenses = await this.getUserLicense(Role.ORG, orgID, "object");
    let updateLicensePromises: Promise<void>[] = [];

    lodash.each(licensesToUpdate, lData => {
      let currentLicenseKey;
      let millisKeyToMatch = lodash
        .chain(lData)
        .keys()
        .first()
        .value();
      lodash.each(orgLicenses, (orgLData: any, lKey) => {
        let isMillisKeyPresent = lodash.some(
          orgLData,
          (_, orgLMillis) => orgLMillis == millisKeyToMatch
        );
        if (isMillisKeyPresent) {
          currentLicenseKey = lKey;
          return false;
        }
        return true;
      });

      if (currentLicenseKey)
        updateLicensePromises.push(
          this.db
            .object(`/licenses/instances/${orgID}/${currentLicenseKey}`)
            .update(lData)
        );
    });

    return await Promise.all(updateLicensePromises);
  }
}
