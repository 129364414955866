import lodash from "lodash";
import { CommonServiceService } from "../common/common-service.service";
import { ExpandSupportingChars } from "../enums/general.enums";

export const tagDelimiter = "~^";
export const tagModifiers = lodash.map(ExpandSupportingChars, "value");

export const copyFieldsInstructions = [
  // --- org
  {
    source: ["org.orgName"],
    target: ["org.displayName"]
  },
  {
    source: ["org.key", "org._key", "org.orgID"],
    target: ["org.orgID"]
  },
  {
    source: ["org.logoURL"],
    target: ["org.logo"]
  },
  {
    source: ["org.settings.yearbook-setup.deadline_YearbookPortraits"],
    target: ["org.Deadline_YearbookPortraits"]
  },
  {
    source: ["org.settings.yearbook-setup.yearbookAdvisor_Name"],
    target: ["org.YearbookAdvisor_Name"]
  },
  {
    source: ["org.settings.yearbook-setup.YearbookAdvisor_Phone"],
    target: ["org.YearbookAdvisor_Phone"]
  },
  {
    source: ["org.settings.yearbook-setup.yearbookAdvisor_Location"],
    target: ["org.YearbookAdvisor_Location"]
  },
  {
    source: ["org.settings.visuals.uRL_OrganizationWebSite"],
    target: ["org.URL_OrganizationWebSite"]
  },
  {
    source: ["org.settings.visuals.uRL_OrgLink_SIS"],
    target: ["org.URL_OrgLink_SIS"]
  },
  {
    source: ["org.settings.visuals.uRL_OrgLink_Teammate"],
    target: ["org.URL_OrgLink_Teammate"]
  },
  {
    source: ["org.settings.visuals.uRL_OrgLink_Additional"],
    target: ["org.URL_OrgLink_Additional"]
  },
  {
    source: ["org.shippingAddress"],
    target: ["org.Shipping_Address_1", "org.StreetAddress1"],
    defVal: ""
  },
  {
    source: ["org.shippingAddress2"],
    target: ["org.Shipping_Address_2", "org.StreetAddress2"],
    defVal: ""
  },
  {
    source: ["org.shippingCity"],
    target: ["org.Shipping_City", "org.City"],
    defVal: ""
  },
  {
    source: ["org.shippingState"],
    target: ["org.Shipping_StateProv", "org.State"],
    defVal: ""
  },
  {
    source: ["org.shippingPostCode"],
    target: ["org.Shipping_ZipPostCode", "org.Zip", "org.zipCode"],
    defVal: ""
  },
  {
    source: ["org.shippingCountry"],
    target: ["org.Shipping_Country", "org.Country"],
    defVal: ""
  },
  {
    source: ["org.billingAddress"],
    target: ["org.Billing_Address_1"],
    defVal: ""
  },
  {
    source: ["org.billingAddress2"],
    target: ["org.Billing_Address_2"],
    defVal: ""
  },
  {
    source: ["org.billingCity"],
    target: ["org.Billing_City"],
    defVal: ""
  },
  {
    source: ["org.shippingState"],
    target: ["org.Shipping_StateProv"],
    defVal: ""
  },
  {
    source: ["org.billingState"],
    target: ["org.Billing_StateProv"],
    defVal: ""
  },
  {
    source: ["org.billingPostCode"],
    target: ["org.Billing_ZipPostCode"],
    defVal: ""
  },
  {
    source: ["org.billingCountry"],
    target: ["org.Billing_Country"],
    defVal: ""
  },
  {
    source: ["org.orgSmsShortCode"],
    target: ["org.shortID"],
    defVal: ""
  },
  {
    source: ["org.primaryColor"],
    target: ["org.logoColorPrimary"],
    defVal: CommonServiceService.DEFAULT_PRIMARY_COLOR
  },
  {
    source: ["org.secondaryColor"],
    target: ["org.logoColorSecondary"],
    defVal: CommonServiceService.DEFAULT_SECONDARY_COLOR
  },
  {
    source: ["org.settings.Legal.Contracts.PrivacyPolicy"],
    target: ["org.LegalContractsPrivacyPolicy"]
  },
  {
    source: ["org.settings.Legal.Contracts.SecurityPolicy"],
    target: ["org.LegalContractsSecurityPolicy"]
  },
  {
    source: ["org.settings.Legal.Contracts.TermsOfUse"],
    target: ["org.LegalContractsTermsOfUse"]
  },
  {
    source: ["org.settings.Legal.Contracts.userInfo"],
    target: ["org.LegalContractsuserInfo"]
  },
  {
    source: ["org.COD1", "org.primaryColor"],
    target: ["org.COD1"],
    defVal: CommonServiceService.DEFAULT_PRIMARY_COLOR
  },
  {
    source: ["org.COD2", "org.secondaryColor"],
    target: ["org.COD2"],
    defVal: CommonServiceService.DEFAULT_SECONDARY_COLOR
  },
  {
    source: ["org"],
    target: ["recipient"]
  },
  // --- teammate/org manager
  {
    source: ["orgMgr"],
    target: ["org.orgMngr", "teammate"]
  },
  // --- ind
  {
    source: ["ind.key", "ind._key"],
    target: ["ind.indID", "ind.DBKey", "ind.key"]
  },
  // --- crossing
  {
    source: ["crossing.timestampEntryApproved"],
    target: ["crossing.timestamp_entry"]
  },
  {
    source: ["crossing.visitorType"],
    target: ["crossing.type"]
  },
  {
    source: ["crossing.startLocation"],
    target: ["crossing.location"] // to support legacy tag crossing.location
  },
  {
    source: ["crossing.destLocation"],
    target: ["crossing.destinationLocation"]
  },
  // --- pass
  {
    source: ["pass.passType.name"],
    target: ["pass.displayName"]
  },
  {
    source: ["pass.destinationLocation"],
    target: ["pass.endLocation"]
  },
  // --- variation
  {
    source: ["variation.results.bgOverhang", "variation.bgOverhang"],
    target: ["variation.bgOverhang"],
    defVal: 0
  },
  // --- incident
  {
    source: ["incident.name"],
    target: ["incident.displayName"]
  }
];

export const gradeFormats = {
  grade_a: {
    prek: "Pre-kindergarten",
    kindergarten: "Kindergarten",
    k: "Kindergarten",
    1: "Grade 1",
    2: "Grade 2",
    3: "Grade 3",
    4: "Grade 4",
    5: "Grade 5",
    6: "Grade 6",
    7: "Grade 7",
    8: "Grade 8",
    9: "Grade 9",
    10: "Grade 10",
    11: "Grade 11",
    12: "Grade 12",
    13: "Grade 13"
  },
  grade_b: {
    prek: "Pre-kindergarten",
    kindergarten: "Kindergarten",
    k: "Kindergarten",
    1: "First Grade",
    2: "Second Grade",
    3: "Third Grade",
    4: "Fourth Grade",
    5: "Fifth Grade",
    6: "Sixth Grade",
    7: "Seventh Grade",
    8: "Eighth Grade",
    9: "Ninth Grade",
    10: "Tenth Grade",
    11: "Eleventh Grade",
    12: "Twelfth Grade",
    13: "Thirteenth Grade"
  },
  grade_c: {
    prek: "Pre-kindergarten",
    kindergarten: "Kindergarten",
    k: "Kindergarten",
    1: "1st Grade",
    2: "2nd  Grade",
    3: "3rd Grade",
    4: "4th Grade",
    5: "5th Grade",
    6: "6th Grade",
    7: "7th Grade",
    8: "8th Grade",
    9: "9th Grade",
    10: "10th Grade",
    11: "11th Grade",
    12: "12th Grade",
    13: "13th Grade"
  },
  grade_d: {
    prek: "Pre-kindergarten",
    kindergarten: "Kindergarten",
    k: "Kindergarten",
    1: "Freshman Year",
    2: "Sophomore Year",
    3: "Junior Year",
    4: "Senior Year",
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: "Freshman Year",
    10: "Sophomore Year",
    11: "Junior Year",
    12: "Senior Year",
    13: 13
  },
  grade_e: {
    prek: "Pre-kindergarten",
    kindergarten: "Kindergarten",
    k: "Kindergarten",
    1: "Freshman",
    2: "Sophomore",
    3: "Junior",
    4: "Senior",
    5: 5,
    6: 6,
    7: 7,
    8: 8,
    9: "Freshman",
    10: "Sophomore",
    11: "Junior",
    12: "Senior",
    13: 13
  }
};

/* eslint-disable no-useless-escape */
export const tagContexts = [
  {
    ctx: /~\^(sys|message)\.(?!(registrationConfirmation|CaptureConfirmation|DIYCapture_CopyrightRelease))[^~\^\.]+~\^/gi,
    requires: ["org", "ind"]
  },
  {
    ctx: /~\^(message\.(registrationConfirmation|CaptureConfirmation|DIYCapture_CopyrightRelease))~\^/gi,
    requires: ["org"]
  },
  {
    ctx: /~\^(message\.(Onramp_SMS|Onramp_SMS_NoPhoto))~\^/gi,
    requires: ["org", "ind", "output"]
  },
  { ctx: /~\^(org|recipient)\.[^~\^\.]+~\^/gi, requires: ["org"] },
  {
    ctx: /~\^(teammate|orgMgr|org\.orgMngr)\.[^~\^\.]+~\^/gi,
    requires: ["orgMgr"]
  },
  { ctx: /~\^(pass)\.[^~\^\.]+~\^/gi, requires: ["pass"] },
  { ctx: /~\^(pass.assigner)\.[^~\^\.]+~\^/gi, requires: ["passAssigner"] },
  { ctx: /~\^(crossing)\.[^~\^\.]+~\^/gi, requires: ["crossing"] },
  { ctx: /~\^(variation)\.[^~\^\.]+~\^/gi, requires: ["variation"] },
  { ctx: /~\^(output)\.[^~\^\.]+~\^/gi, requires: ["output"] },
  { ctx: /~\^(ind)\.[^~\^\.]+~\^/gi, requires: ["ind"] },
  { ctx: /~\^(ind\.cert)(\.[^~\^\.]+)+~\^/gi, requires: ["indCerts"] },
  { ctx: /~\^(ind\.photourl)~\^/gi, requires: ["indPhoto"] },
  { ctx: /~\^(ind\.division)~\^/gi, requires: ["indDivision"] },
  { ctx: /~\^(org\.cycle)~\^/gi, requires: ["orgCycle"] },
  { ctx: /~\^(org\.(logoURL|logo))~\^/gi, requires: ["orgLogo"] },
  {
    ctx: /~\^(ind\.guardian\[.+\])\.[^~\^\.]+~\^/gi,
    requires: ["indGuardians"]
  },
  { ctx: /~\^(incident)\.[^~\^\.]+~\^/gi, requires: ["incident"] }
];
/* eslint-enable no-useless-escape */
