<div style="padding: 10px;width: 100%;height: 100%; padding: var(--sat) var(--sar) var(--sab) var(--sal);">
    <button nz-button nzType="primary" style="margin-bottom: 20px;"
        class="back-button d-flex align-items-center justify-content-center" (click)="onBack()">
        <i nz-icon nzType="left"></i>
        Back
    </button>

    <div class="qr-code" (click)="onBack()">
        <div style="width: 100%;text-align: center;">
            <img *ngIf="qrCode" [src]="qrCode" style="height: auto;max-width: 100%;" />
        </div>
        <div style="width: 100%;text-align: center;">
            <img *ngIf="barcode" [src]="barcode" style="height: auto;max-width: 500px;width: 100%;">
        </div>
    </div>

</div>