import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./core/auth/auth.guard";
import { CachedIDComponent } from "./features/app-common/cached-id/cached-id.component";
import { CenterLayoutComponent } from "./layout/center-layout/center-layout.component";
import { HeaderLayoutComponent } from "./layout/header-layout/header-layout.component";

const routes: Routes = [
  // --- routes for authorized users
  // -- with header
  {
    path: "",
    component: HeaderLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "game-tracker",
        loadChildren: () =>
          import("./features/game-tracker/game-tracker.module").then(
            m => m.GameTrackerModule
          )
      },
      {
        path: "events",
        loadChildren: () =>
          import("./features/events/events.module").then(m => m.EventsModule)
      },
      {
        path: "save-digital-id",
        loadChildren: () =>
          import("./features/save-digital-id/save-digital-id.module").then(m => m.SaveDigitalIDModule)
      }
    ]
  },
  // -- without header
  {
    path: "",
    canActivate: [AuthGuard],
    children: [
      {
        path: "landingPage",
        loadChildren: () =>
          import("./features/landing-page/landing-page.module").then(
            m => m.LandingPageModule
          )
      },
      {
        path: "scanner",
        loadChildren: () =>
          import("./features/scanner/scanner.module").then(m => m.ScannerModule)
      },
      {
        path: "photo",
        loadChildren: () =>
          import("./features/photo/photo.module").then(m => m.PhotoModule)
      },
      {
        path: "game-tracker-nh",
        loadChildren: () =>
          import(
            "./features/game-tracker-no-header/game-tracker-no-header.module"
          ).then(m => m.GameTrackerNoHeaderModule)
      },
      {
        path: "take-attendance",
        loadChildren: () =>
          import("./features/take-attendance/take-attendance.module").then(
            m => m.TakeAttendanceModule
          )
      },
      {
        path: "send-digital-id",
        loadChildren: () =>
          import("./features/send-digital-id/send-digital-id.module").then(
            m => m.SendDigitalIDModule
          )
      },
      {
        path: "generic-scanner",
        loadChildren: () =>
          import("./features/generic-scanner/generic-scanner.module").then(
            m => m.GenericScannerModule
          )
      },
      {
        path: "game-check-in",
        loadChildren: () =>
          import("./features/game-check-in/game-check-in.module").then(
            m => m.GameCheckInModule
          )
      },
      {
        path: "common-scanner",
        loadChildren: () =>
          import("./features/common-scanner/common-scanner.module").then(
            m => m.CommonScannerModule
          )
      },
      {
        path: "events-list",
        loadChildren: () =>
          import("./features/events/events.module").then(m => m.EventsModule)
      },
      {
        path: "passes",
        loadChildren: () =>
          import("./features/passes/passes.module").then(m => m.PassesModule)
      },
      {
        path: "behaviour-tracking",
        loadChildren: () =>
          import(
            "./features/behaviour-tracking/behaviour-tracking.module"
          ).then(m => m.BehaviourTrackingModule)
      },
      {
        path: "order",
        loadChildren: () =>
          import("./features/order/order.module").then(m => m.OrderModule)
      },
      {
        path: "buy-replacement-card",
        loadChildren: () =>
          import(
            "./features/buy-replacement-id/buy-replacement-id.module"
          ).then(m => m.BuyReplacementIDModule)
      },
      {
        path: "incident-task",
        loadChildren: () =>
          import("./features/incidents-task/incidents-task.module").then(
            m => m.IncidentsTaskModule
          )
      },
      {
        path: "spac",
        loadChildren: () =>
          import("./features/spac/spac.module").then(m => m.SpacModule)
      },
      {
        path: "registration-conflicts",
        loadChildren: () =>
          import(
            "./features/registration-conflicts/registration-conflicts.module"
          ).then(m => m.RegistrationConflictsModule)
      },
      {
        path: "transactions",
        loadChildren: () =>
          import("./features/transactions/transactions.module").then(
            m => m.TransactionsModule
          )
      },
      {
        path: "aux-id",
        loadChildren: () =>
          import("./features/aux-id/aux-id.module").then(m => m.AuxIdModule)
      },
      {
        path: "rfid",
        loadChildren: () =>
          import("./features/rfid/rfid.module").then(m => m.RfidModule)
      },
      {
        path: "certificate",
        loadChildren: () =>
          import("./features/certificate/certificate.module").then(
            m => m.CertificateModule
          )
      },
      {
        path: "watchMeGrow",
        loadChildren: () =>
          import("./features/watch-me-grow/watch-me-grow.module").then(
            m => m.WatchMeGrowModule
          )
      },
      {
        path: "attended-event",
        loadChildren: () =>
          import("./features/attended-event/attended-event.module").then(
            m => m.AttendedEventModule
          )
      }
    ]
  },
  // --- routes for unauthorized users
  // -- centered layouts
  {
    path: "",
    // component: CenterLayoutComponent,
    children: [
      {
        path: "auth",
        loadChildren: () =>
          import("./features/auth/auth.module").then(m => m.AuthModule)
      }
    ]
  },
  // -- no parent layout
  {
    path: "",
    children: [
      {
        path: "demo",
        loadChildren: () =>
          import("./features/demo/demo.module").then(m => m.DemoModule)
      },
      {
        path: "kiosk",
        loadChildren: () =>
          import("./features/kiosk/kiosk.module").then(m => m.KioskModule)
      },
      {
        path: "help",
        loadChildren: () =>
          import("./features/help/help.module").then(m => m.HelpModule)
      },
      {
        path: "generic",
        loadChildren: () =>
          import("./features/generic/generic.module").then(m => m.GenericModule)
      },
      {
        path: "Signup_Org",
        loadChildren: () =>
          import("./features/signup-org/signup-org.module").then(
            m => m.SignupOrgModule
          )
      },
      {
        path: "Signup_OrgMgr",
        loadChildren: () =>
          import(
            "./features/signup-org-manager/signup-org-manager.module"
          ).then(m => m.SignupOrgManagerModule)
      },
      {
        path: "photo-done",
        loadChildren: () =>
          import("./features/photo-done/photo-done.module").then(
            m => m.PhotoDoneModule
          )
      },
      {
        path: "camera-permission-video",
        loadChildren: () =>
          import(
            "./features/camera-permission-video/camera-permission-video.module"
          ).then(m => m.AddToHomeScreenVideoModule)
      },
      {
        path: "add-to-homescreen-video",
        loadChildren: () =>
          import(
            "./features/add-to-homescreen-video/add-to-homescreen-video.module"
          ).then(m => m.AddToHomeScreenVideoModule)
      },
      {
        path: "confirm-detail",
        loadChildren: () =>
          import("./features/confirm-detail/confirm-detail.module").then(
            m => m.ConfirmDetailModule
          )
      },
      {
        path: "organization",
        loadChildren: () =>
          import("./features/organization/organization.module").then(
            m => m.OrganizationModule
          )
      },
      {
        path: "visitor",
        loadChildren: () =>
          import("./features/touchless-visitor/touchless-visitor.module").then(
            m => m.TouchlessVisitorModule
          )
      },
      {
        path: "self-registration",
        loadChildren: () =>
          import("./features/self-registration/self-registration.module").then(
            m => m.SelfRegistrationModule
          )
      },
      {
        path: "survey",
        loadChildren: () =>
          import("./features/survey/survey.module").then(m => m.SurveyModule)
      },
      {
        path: "pwa",
        loadChildren: () =>
          import("./features/pwa/pwa.module").then(m => m.PwaModule)
      },
      {
        path: "sales-tools",
        loadChildren: () =>
          import("./features/sales-tool/sales-tool.module").then(
            m => m.SalesToolModule
          )
      },
      {
        path: "grdn-onboarding",
        loadChildren: () =>
          import(
            "./features/guardian-onboarding/guardian-onboarding.module"
          ).then(m => m.GuardianOnboardingModule)
      },
      {
        path: "grdn-dependent",
        loadChildren: () =>
          import(
            "./features/guardian-dependent/guardian-dependent.module"
          ).then(m => m.GuardianDependentModule)
      },
      {
        path: "changelog",
        loadChildren: () =>
          import("./features/blog-feed/blog-feed.module").then(
            m => m.BlogFeedModule
          )
      },
      {
        path: "offline",
        component: CachedIDComponent
      }
    ]
  },
  // --- redirection routes
  {
    path: "**",
    pathMatch: "full",
    redirectTo: "landingPage"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
