export enum FeaturesEnums {
  DigitalID = "digitalID",
  PanicButton = "panicButton",
  CustomURL = "customURL",
  StripePaymentLink = "Stripe Payment Link",
  Subpage = "subpage",
  BusRoutes = "busRoutes",
  EntranceScanner = "Entrance Scanner",
  GiveAttendance = "Give Attendance",
  TakeAttendance = "take-attendance",
  AboutHigh5ID = "About High5.ID",
  SignInAsAVisitor = "Sign in as a visitor",
  ShowVisitorBadge = "Show Visitor Badge",
  StudentTracking = "Student Tracking",
  Scanner = "Scanner",
  GameTracker = "game-tracker",
  GameRefControl = "Game Ref Control",
  GameDayReport = "Game Day Report",
  Survey = "Survey",
  GameCheckIn = "Check In",
  Events = "Events",
  ContactInd = "contactInd",
  SendPersonalPageLinkBySms= 'Send Personal Page Link By Sms',
  SendPersonalPageLinkByEmail= 'Send Personal Page Link By Email',
  EntriesExits = 'Entries/Exits',
  Passes = 'Passes',
  VisitNow = "Visit Now",
  AssignPass = 'AssignPass',
  RequestPass = 'RequestPass',
  BuyReplacementCard ="Buy Replacement Card",
  BehaviouralIncident = "Behavioural Incident",
  RedeemPoints = "Redeem Points",
  Relation = "Relations",
  Task = "Task",
  IndividualLookUp = "Individual lookup",
  ContactGuardian = "Contact Guardian",
  OtherID = "other_id",
  ApplePass = "Apple Pass",
  ExportReport = "Export Data",
  Html = "Html",
  WatchMeGrow = "watch_me_grow",
  Certificate = "Certificate",
  AttendedEvent = "Attended Event",
  VideoUrl = "Video Player",
  SaveDigitalID = "Save Digital ID",
  BuyPhotos = "Buy Photos",
  PassReport = "Pass Report",
}
