import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ForgotPasswordDialogComponent } from "./forgot-password-dialog.component";

@Injectable()
export class ForgotPasswordDialogService {
  constructor(private modalService: NgbModal) {}

  public confirm(
    title: string,
    dialogSize: "sm" | "lg" = "sm"
  ): Promise<boolean> {
    const modalRef = this.modalService.open(ForgotPasswordDialogComponent, {
      size: dialogSize
    });
    modalRef.componentInstance.title = title;
    return modalRef.result;
  }
}
