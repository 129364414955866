import { Injectable } from "@angular/core";
import { Role } from "../enums/user.enums";
import { AngularFireDatabase } from "@angular/fire/database";
import lodash from "lodash";
import { CommonServiceService } from "../common/common-service.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ApiService } from "../api/api.service";
import moment from "moment";
@Injectable({
  providedIn: "root"
})
export class MessagesService {
  private basePath: string = "inbox-messages";
  private basePathMsgCounter: string = `${this.basePath}/counters`;

  constructor(
    private db: AngularFireDatabase,
    private commonService: CommonServiceService,
    public http: HttpClient, public api: ApiService
  ) { }

  /**
   * Broadcasts a message to everyone, orgs, orgmgrs, specific orgtype, specific orgmgr, or to mix ids (superadmin, some studio, some orgs)
   * @param broadcastTo 'everyone' | 'orgs' | 'orgmgr'
   * @param type 'school' | 'conference' | 'studio' | 'district'
   * @param message Message Object
   * @param specificIds Ids of orgs/studios or of whoever to whom you want to broadcast message
   */
  private async broadcastMessage(
    broadcastTo: string,
    type: string,
    message: Message,
    specificIds?: string[]
  ) {
    let randomId = this.commonService.generateId(20);
    let objectToUpdate = {};
    let counterObjToUpdate = {};
    let promises = [];

    // --- get all organizations
    const getAllOrgs = async () => {
      console.error("TODO: Method not implemented!");
      return [];
    };

    // --- get all organization managers
    const getAllOrgMgrs = async () => {
      let allOrgMgrs = (
        await this.db.list("/studios/").query.once("value")
      ).val();
      let orgMgrs = [];
      lodash.each(allOrgMgrs, (orgMgrData, orgMgrKey) => {
        let value = { value: orgMgrData, key: orgMgrKey };
        orgMgrs.push(value);
      });
      return orgMgrs;
    };

    // --- get all org manager types
    const getOrgManagerTyps = async () => {
      let orgMgrTypesObj = (
        await this.db.list("/org-manager-types").query.once("value")
      ).val();
      let orgMgrTypes = [];
      if (orgMgrTypesObj) {
        lodash.each(orgMgrTypesObj, (data, key) => {
          let value = { ...data, key };
          orgMgrTypes.push(value);
        });
      }
      return orgMgrTypes;
    };

    if (broadcastTo == "everyone") {
      // --- prepare message for superadmin
      objectToUpdate[`superadmin/${randomId}`] = message;

      // --- prepare message for all organizations
      let orgs = await getAllOrgs();

      orgs.forEach(org => {
        if (org.key && org.key != "undefined") {
          objectToUpdate[`${org.key}/${randomId}`] = message;
        }
      });

      // --- prepare message for all org managers
      let orgMgrs = await getAllOrgMgrs();

      orgMgrs.forEach(orgmgr => {
        if (orgmgr.key && orgmgr.key != "undefined") {
          objectToUpdate[`${orgmgr.key}/${randomId}`] = message;
        }
      });

      // --- update counters
      let counters = (
        await this.db.list(`${this.basePathMsgCounter}`).query.once("value")
      ).val();

      // --- prepare superadmin counter
      counterObjToUpdate[`superadmin/ua_counter`] =
        counters && counters["superadmin"]
          ? counters["superadmin"]["ua_counter"] + 1
          : 1;

      // --- prepare organizations counter
      orgs.forEach(org => {
        if (org.key && org.key != "undefined") {
          counterObjToUpdate[`${org.key}/ua_counter`] =
            counters && counters[org.key]
              ? counters[org.key]["ua_counter"] + 1
              : 1;
        }
      });

      // --- prepare org managers counter
      orgMgrs.forEach(orgmgr => {
        if (orgmgr.key && orgmgr.key != "undefined") {
          counterObjToUpdate[`${orgmgr.key}/ua_counter`] =
            counters && counters[orgmgr.key]
              ? counters[orgmgr.key]["ua_counter"] + 1
              : 1;
        }
      });
    } else if (broadcastTo == "orgs") {
      // --- prepare message for organizations
      let orgs = await getAllOrgs();

      // --- filter orgs by type if required
      if (type) {
        orgs = orgs.filter(org => {
          if (org.value && org.value.type)
            return org.value.type.toLowerCase() == type.toLowerCase();
          return false;
        });
      }

      // --- prepare object to update
      orgs.forEach(org => {
        if (org.key && org.key != "undefined") {
          objectToUpdate[`${org.key}/${randomId}`] = message;
        }
      });

      // --- update counter
      let counters = (
        await this.db.list(`${this.basePathMsgCounter}`).query.once("value")
      ).val();

      // --- prepare organizations counter
      orgs.forEach(org => {
        if (org.key && org.key != "undefined") {
          counterObjToUpdate[`${org.key}/ua_counter`] =
            counters && counters[org.key]
              ? counters[org.key]["ua_counter"] + 1
              : 1;
        }
      });
    } else if (broadcastTo == "orgmgr") {
      // --- prepare message for  org managers
      let orgMgrs = await getAllOrgMgrs();

      // --- filter orgs by type if required
      if (type) {
        let orgMgrTypes = await getOrgManagerTyps();

        orgMgrs = orgMgrs.filter(orgmgr => {
          if (orgmgr.value && orgmgr.value.orgManagerType) {
            if (
              orgmgr.value.orgManagerType.toLowerCase() == type.toLowerCase()
            ) {
              return true;
            } else {
              let orgMgrType = orgMgrTypes.find(
                type => type.key == orgmgr.value.orgManagerType
              );
              return (
                orgMgrType &&
                orgMgrType.name.toLowerCase() == type.toLowerCase()
              );
            }
          } else if (type == "teammate") return true; // if orgManagerType is not present in db for given teammate, consider that user as teammate.
          return false;
        });
      }

      // --- prepare object to update
      orgMgrs.forEach(orgmgr => {
        if (orgmgr.key && orgmgr.key != "undefined") {
          objectToUpdate[`${orgmgr.key}/${randomId}`] = message;
        }
      });

      // --- update counter
      let counters = (
        await this.db.list(`${this.basePathMsgCounter}`).query.once("value")
      ).val();

      // --- prepare org managers counter
      orgMgrs.forEach(orgmgr => {
        if (orgmgr.key && orgmgr.key != "undefined") {
          counterObjToUpdate[`${orgmgr.key}/ua_counter`] =
            counters && counters[orgmgr.key]
              ? counters[orgmgr.key]["ua_counter"] + 1
              : 1;
        }
      });
    } else if (broadcastTo == "specificIds" && specificIds && specificIds.length > 0) {
      // --- prepare message
      specificIds.forEach(id => {
        objectToUpdate[`${id}/${randomId}`] = message;
      });

      // --- update counters
      let counters = (
        await this.db.list(`${this.basePathMsgCounter}`).query.once("value")
      ).val();

      // --- prepare organizations counter
      specificIds.forEach(id => {
        counterObjToUpdate[`${id}/ua_counter`] =
          counters && counters[id]
            ? counters[id]["ua_counter"] + 1
            : 1;
      });
    } else {
      throw Error("invalid broadcastTo!");
    }

    if (!lodash.isEmpty(objectToUpdate)) {
      promises.push(this.db.object(`${this.basePath}`).update(objectToUpdate));
      promises.push(
        this.db.object(`${this.basePathMsgCounter}`).update(counterObjToUpdate)
      );
    }

    await Promise.all(promises);
  }

  /**
   * insert or update message. If message object contains key, it updates or else it will insert new
   * @param role Role of the user
   * @param message Message Object to Insert or Update
   * @param userId Organization/Studio Id to whom you want to send message
   * @param messagesCounterNew New Messages Counter Object with new counter values (incremented or decremented relative count)
   * @param broadcastTo 'everyone' | 'orgs' | 'orgmgr'
   * @param type 'school' | 'conference' | 'studio' | 'district'
   * @param specificIds Ids of orgs/studios or of whoever to whom you want to broadcast message
   */
  async setMessage(
    role: string,
    message: Message,
    userId?: string,
    messagesCounterNew?: MessagesCounter,
    broadcastTo?: string,
    type?: string,
    specificIds?: string[]
  ) {
    // --- function argument validations
    if (!message) throw Error("Message Required");

    if (broadcastTo) {
      // --- broadcast message
      await this.broadcastMessage(broadcastTo, type, message, specificIds);
    } else {
      // --- create message for single user
      if (role != Role.SUPERADMIN && !userId) throw Error("OrgId Required");

      // --- remove unnecessary keys before creating entry on firebase
      let path = message.key
        ? `${this.basePath}/${userId ? userId : Role.SUPERADMIN}/${message.key}`
        : `${this.basePath}/${userId ? userId : Role.SUPERADMIN}`;
      let isUpdateOperation = message.key ? true : false;
      delete message.key;
      delete message.fromName;

      let promises = [];
      if (!isUpdateOperation) {
        // --- insert message
        promises.push(this.db.list(path).push(message));
      } else {
        // --- update message
        promises.push(this.db.object(path).update(message));
      }

      if (!messagesCounterNew) {
        let currentCounter = (
          await this.getMessagesCounterValue(role, userId)
        ).val();
        // --- if its insert operation, increase un-archived counter by 1.
        if (!isUpdateOperation) {
          currentCounter.ua_counter++;
        } else {
          currentCounter = { ua_counter: 0 };
        }
        messagesCounterNew = currentCounter;
      }

      // --- update counter
      promises.push(
        this.setMessageCounterValue(
          role,
          messagesCounterNew,
          userId ? userId : Role.SUPERADMIN
        )
      );

      await Promise.all(promises);
    }
  }

  /**
   * Get value of messages counter
   * @param role Role of the user
   * @param userId Organization/Studio Id
   */
  getMessagesCounterValue(role: string, userId?: string): Promise<any> {
    // --- function argument validations
    if (role != Role.SUPERADMIN && !userId) throw Error("userId Required");

    return this.db
      .object(`${this.basePathMsgCounter}/${userId ? userId : Role.SUPERADMIN}`)
      .query.once("value");
  }

  /**
   * set messages counter object
   * @param role Role of the user
   * @param counter messages counter object
   * @param userId organization/Studio id
   */
  setMessageCounterValue(
    role: string,
    counter: MessagesCounter,
    userId: string
  ) {
    // --- function argument validations
    if (role != Role.SUPERADMIN && !userId) throw Error("OrgId Required");

    return this.db
      .object(`${this.basePathMsgCounter}/${userId ? userId : Role.SUPERADMIN}`)
      .set(counter);
  }

  // get truedialog remaining credits
  getTruedialogCredit() {
    return new Promise<any>((resolve, reject) => {
      this.http.get(`${environment.newApiBaseUrl}/check-truedialog-credit.php`).subscribe(res => {
        resolve(res)
      }, err => {
        reject(err);
      })
    })
  }

  // send email to high5 admin
  async sendEmailToHigh5Admin(orgName: string, smsCount: number, truedialogCredit: number) {
    let msg = `This org "${orgName}" try to send ${smsCount} SMS. but truedialog remaining balance is ${truedialogCredit}`;
    let message: Message = {
      timestamp: new Date().getTime(),
      details: msg,
      type: "Attention",
      area: MessageArea.ATTENTION
    };
    this.setMessage(Role.SUPERADMIN, message);

    // --- prepare mailgun object
    const mailgunObj = {
      from: environment.mailFromAddress,
      to: [environment.superAdminEmail],
      text: msg,
      subject: `Truedialog Balance Reminder`,
      recipient_variables: {}
    }

    await this.api.post(environment.mailgunObj.endPoint, { data: [mailgunObj], type: "single", emailType: "email" }).toPromise()
  }

  // send Email 
  sendEmail(obj: any) {
    return new Promise((resolve, reject) => {
      this.api.post(environment.mailgunObj.endPoint, obj).subscribe(async (result: any) => {
        console.log('result: ', result);
        if (result.status == 'ok') {
          resolve({ success: true })
        } else {
          resolve({ success: false, message: result.message ? result.message : 'Something went wrong. please try again later!' })
        }
      }, (err) => {
        reject({ success: false, message: err.message })
      })
    })
  }

  // send sms
  sendSMS(postData: any) {
    return new Promise((resolve, reject) => {
      this.api.post(environment.smsObj.endPoint, postData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }, responseType: 'text'
      }).subscribe(
       async (result: any) => {
        resolve({ success: true });
      }, (err) => {
        resolve({ success: false, message: err.message })
      })
    })
  }
}

export class MessagesCounter {
  ua_counter: number; // un-archived messages counter
}

export class Message {
  timestamp: number;
  type: string;
  area: string;
  details: string;
  fromID?: string;
  fromRole?: Role;
  fromName?: string;
  archived?: boolean = false;
  key?: string;
  action?: MessageTypes;
  alertTitle?: string;
  alertHTML?: string;
  priority?: number;
  internalPageName?: string;
}

export enum MessageTypes {
  ALERT = "alert",
  VIDEO = "video",
  CUSTOM_ALERT = "custom_alert",
  INTERNAL = 'internal'
}

export enum MessageArea {
  PLIC = "plic",
  SVG = "svg",
  LICENSING = "licensing",
  PROBLEM_REPORT = "problem report",
  BROADCAST = "broadcast",
  COMPOSE = "compose",
  VECTA = "vecta",
  REGISTRATION = "registration",
  PERSONAL_HOME_PAGE = "personal home page",
  TVT = "tvt",
  ATTENTION = "Attention",
  OFFLINE = "offline",
  OTHER_ID = "Other ID"
}