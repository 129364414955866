import { Injectable } from "@angular/core";
import { Role } from "../enums/user.enums";
import {
  InheritanceService,
  ReplacementTags
} from "../inheritance/inheritance.service";
import lodash from "lodash";

@Injectable({
  providedIn: "root"
})
export class VariationsService {
  readonly userDBNodes = {
    [Role.ORG]: "organizations",
    [Role.STUDIO]: "studios",
    [Role.SUPERADMIN]: null
  };
  readonly dbPath = `${ReplacementTags.USER_DB_NODE}/${ReplacementTags.USER_ID}/designs/customizeDesigns`;
  readonly variationsOrderDbpath = `${ReplacementTags.USER_DB_NODE}/${ReplacementTags.USER_ID}/designs/customizeDesignsOrder`;

  constructor(private inheritanceProvider: InheritanceService) {}

  // --- get variation by inheritance
  async getVariationByInheritance(
    role: string,
    userID: string,
    designTypeID: string,
    variationID: string,
    usersData?: any,
    useCache: boolean = false
  ) {
    // --- params validation
    if (
      !role ||
      (role != Role.SUPERADMIN && !userID) ||
      !designTypeID ||
      !variationID
    )
      throw new Error(
        "Params missing in getVariationByInheritance method call"
      );

    // --- fetch variations data by inheritance
    let variationInheritedData = await this.inheritanceProvider.getByInheritance(
      role,
      userID,
      `${this.dbPath}/${designTypeID}/variations/${variationID}`,
      "object",
      usersData,
      this.userDBNodes,
      null,
      useCache
    );

    let variationData = lodash.find(
      variationInheritedData,
      vData => vData.data != null
    );

    if (!variationData || lodash.toLower(variationData.override) == "hide")
      return null;

    return {
      ...variationData.data,
      key: variationID,
      ownerID: variationData.ownerID
    };
  }

  // --- get variations of given design type
  async getVariationsOfDTByInheritance(
    role: string,
    userID: string,
    designTypeID: string,
    usersData?: any,
    useCache: boolean = false
  ) {
    // --- params validation
    if (!role || (role != Role.SUPERADMIN && !userID) || !designTypeID)
      throw new Error(
        "Params missing in getVariationsOfDTByInheritance method call"
      );

    // --- fetch variations data by inheritance
    let variationsInheritedData = await this.inheritanceProvider.getByInheritance(
      role,
      userID,
      `${this.dbPath}/${designTypeID}`,
      "object",
      usersData,
      this.userDBNodes,
      null,
      useCache
    );

    let variationsObj: any = {};

    // --- merge variations data based on inheritance
    lodash.each(variationsInheritedData, variationData => {
      if (!variationData) return;

      let ownerID = variationData.ownerID;
      let data: any = variationData.data;

      if (!data || !data.fields || !data.variations) return;

      // --- merge fields
      variationsObj.fields = lodash.union(variationsObj.fields, data.fields);

      // --- merge variations
      lodash.each(data.variations, (data, key) => {
        if (!lodash.has(variationsObj, `variations.${key}`))
          lodash.set(variationsObj, `variations.${key}`, {
            ...data,
            ownerID
          });
      });
      if (data.fieldoverride == true) return false;
    });

    // --- insert missing field in every variations objects with value "Any"
    lodash.each(variationsObj.fields, field => {
      lodash.each(variationsObj.variations, (variation, variationKey) => {
        if (!lodash.has(variation, `conditions.${field}`))
          lodash.set(
            variationsObj,
            `variations.${variationKey}.conditions.${field}`,
            "Any"
          );
      });
    });

    return variationsObj;
  }

  // --- get variations order (by inheritance)
  async getVariationsOrderByInheritance(
    role: string,
    userID: string,
    usersData?: any,
    useCache: boolean = false
  ) {
    // --- params validation
    if (!role || (role != Role.SUPERADMIN && !userID))
      throw new Error(
        "Params missing in getVariationsOrderByInheritance method call"
      );

    // --- fetch variations data by inheritance
    let variationsOrderInheritedData = await this.inheritanceProvider.getByInheritance(
      role,
      userID,
      `${this.variationsOrderDbpath}`,
      "object",
      usersData,
      this.userDBNodes,
      null,
      useCache
    );

    // --- merge variations order by inheritance and return results
    let variationsOrders = {};
    lodash.each(variationsOrderInheritedData, data => {
      let orderData = data.data;
      lodash.each(orderData, (variationsOrder, designTypeID) => {
        if (!variationsOrders[designTypeID])
          variationsOrders[designTypeID] = variationsOrder;
        else
          variationsOrders[designTypeID] = lodash.union(
            variationsOrders[designTypeID],
            variationsOrder
          );
      });
    });

    return variationsOrders;
  }

  // --- sort any design type's variations by order
  sortDesignTypeVariationsByOrder(variationsData, variationOrders) {
    // --- show variations base on customize designs order
    let resultObj = lodash.cloneDeep(variationsData);

    if (variationsData && variationsData.variations) {
      let variations = variationsData.variations;
      resultObj["variations"] = {};
      lodash.each(variationOrders, variationID => {
        if (lodash.has(variations, variationID))
          resultObj["variations"][variationID] = variations[variationID];
      });
      Object.keys(variations).forEach(variationKey => {
        if (!lodash.has(resultObj, `variations.${variationKey}`))
          resultObj["variations"][variationKey] = variations[variationKey];
      });
    }

    return resultObj;
  }
}
