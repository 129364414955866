import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShowQrCodeComponent } from './show-qr-code.component';

@Injectable()
export class ShowQrCodeService {

  constructor(private modalService: NgbModal) { }

  public confirm(
    title: string,
    hash: any,
    studentId: any): Promise<boolean> {
    const modalRef = this.modalService.open(ShowQrCodeComponent, { size: 'lg', windowClass: "show-qr-code" });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.indhash = hash;
    modalRef.componentInstance.studentID = studentId;
    return modalRef.result;
  }

}
