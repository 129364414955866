import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { DigitalIDService } from "../../../core/digital-id/digital-id.service";
import { Subject, Subscription } from "rxjs";
import { AuthApiService } from "../../../core/auth/auth.api.service";
import { debounceTime, skip, takeUntil, timeout } from "rxjs/operators";
import { Router } from "@angular/router";
import {
  getQueryParams,
  setQueryParams
} from "src/app/core/utils/queryParams.utils";
import { Location } from "@angular/common";
import { AngularFireDatabase } from "@angular/fire/database";
import { SettingsService } from "src/app/core/settings/settings.service";
import { AlertDialogService } from "../../../features/alert-dialog/alert-dialog.service";
import { environment } from "../../../../environments/environment";
import { Role } from "src/app/core/enums/user.enums";
import {
  MessageArea,
  MessagesService
} from "src/app/core/messages/messages.service";
import { UrlService } from "src/app/core/digital-id/url.service";
import { CommonServiceService } from "src/app/core/common/common-service.service";
import { ShowQrCodeService } from "../../confirm-dialog/pages/show-qr-code/show-qr-code.service";
import { GetIndPhotoUsingPrefSettings } from "src/app/core/pipes/getIndPhotoUsingPrefSettings/get-ind-photo-using-pref-settings.pipe";
import { IndividualApiService } from "src/app/core/individual/individual.api.service";
import lodash from "lodash";
import { LocalStorageService } from "src/app/core/storage/local-storage.service";
import { High5AppService } from "src/app/core/high5-app/high5-app.service";
import UserAuth from "src/app/core/auth/userAuth.class";
import { Patches, PatchesService, PatchID } from "src/app/core/patches.service";
import { PreferencesService } from "src/app/core/preferences/preferences.service";
import { LicenseService } from "src/app/core/license/license.service";
import { FeaturesEnums } from "src/app/core/enums/features.enums";
import { IndexedDBKeys } from "src/app/core/enums/general.enums";
import moment from "moment";
import { EncryptionService } from "src/app/core/encryption/encryption.service";
import { RendererService } from "src/app/core/renderer/renderer.service";
import { FliperElement } from "src/app/core/interface/types";
import { DesignService } from "src/app/core/design/design.service";
import { IndexedDBService } from "src/app/core/IndexedDB/indexed-db.service";
import { FliperPagesComponent } from "../fliper-pages/fliper-pages.component";
import { OrganizationsService } from "src/app/core/organizations/organizations.service";

@Component({
  selector: "app-digital-id",
  templateUrl: "./digital-id.component.html",
  styleUrls: ["./digital-id.component.scss"]
})
export class DigitalIDComponent implements OnInit, FliperElement {
  loadingSvg: boolean;
  @ViewChild("canvasTarget") canvasTarget;
  subscriptions: Subscription[] = [];
  errorMsg: string;
  isInfo = false;
  params: UserAuth;
  @Input() expiryMillis;
  @Input() flipperPagesHomePlusID: FliperPagesComponent;
  @Input() idType;
  @Input() lifecycleMode: "flipperBased" | "normal" = "normal"; // this component can be used in flipper (flip front/back) as well as as normal componet. So lifecycle depends on mode
  @Output() onBackPressed: EventEmitter<any> = new EventEmitter();
  @Output() onError: EventEmitter<any> = new EventEmitter();
  lastRenderWidth: number = 0;
  lastRenderHeight: number = 0;

  pageDestroy$: Subject<void> = new Subject();
  preIdValid: boolean = false;

  constructor(
    private digitalIDService: DigitalIDService,
    private router: Router,
    private _location: Location,
    private db: AngularFireDatabase,
    public authApi: AuthApiService,
    public settingsService: SettingsService,
    public alertDialogService: AlertDialogService,
    private messagesService: MessagesService,
    private urlService: UrlService,
    private commonFun: CommonServiceService,
    public showQrCodeService: ShowQrCodeService,
    public getIndPhotoUsingPrefSettings: GetIndPhotoUsingPrefSettings,
    private individualApiService: IndividualApiService,
    private localStorageService: LocalStorageService,
    private high5AppService: High5AppService,
    private patchesService: PatchesService,
    private preferencesService: PreferencesService,
    private licenseService: LicenseService,
    private encryptionService: EncryptionService,
    private rendererService: RendererService,
    private designService: DesignService,
    private indexedDBService: IndexedDBService,
    private orgService: OrganizationsService
  ) {}

  onBack() {
    if (this.flipperPagesHomePlusID) this.flipperPagesHomePlusID.flip();
    this.onBackPressed.next();
  }

  resetVariables() {
    this.errorMsg = undefined;
    this.isInfo = false;
    this.loadingSvg = undefined;
    this.isSvgBeingRendered = false;
  }

  /**
   * Read Organization Data
   */
  orgData;
  async readOrgData() {
    let params = getQueryParams();
    let orgData = await this.orgService.getOrgData(params.orgID);
    if (!orgData) {
      throw Error(
        `No Organization found with the given org id! OrgID: ${params.orgID} (${
          environment.production ? "prod" : "stage"
        })`
      );
    }
    orgData["key"] = params.orgID;
    orgData["_key"] = params.orgID;
    orgData["orgID"] = params.orgID;
    this.orgData = orgData;

    // --- set org data in user object on settings and url services
    this.digitalIDService.setOrgData(orgData);
  }

  // --- triggers when user chooses err action (eg. take selfie or deny)
  onErrActionBtnClicked(action: "takeSelfie" | "denyTakingSelfie" | "goBack") {
    if (action == "takeSelfie") {
      this.errorMsg = null;
      this.takeSelfie();
    } else if (action == "denyTakingSelfie") {
      if (this.idAccessNoPhotoPrefValue == 1) {
        this.errorMsg = null;
        setTimeout(() => {
          this.showCachedID();
          this.reloadDigitalID();
        }, 10);
      } else {
        this.flipBack();
      }
    } else if (action == "goBack") this.flipBack();
  }

  // --- flip back-side
  flipBack() {
    // --- if currently viewing pass, go back to pass list
    if (this.idType == "pass") {
      let allowedQPForPage = ["orgID", "indID"];
      let userAuth = getQueryParams();
      // --- remove unnecessary query params
      userAuth = this.commonFun.cleanUpObj(userAuth, allowedQPForPage);

      // --- proceed with navigation
      this.router.navigate(["/passes"], setQueryParams(userAuth));
      return;
    }

    // --- flip to front side of flipper
    if (this.flipperPagesHomePlusID) this.flipperPagesHomePlusID.flip();
  }

  // --- open take selfie page
  async takeSelfie() {
    this.loadingSvg = true;
    let link = await this.commonFun.prepareMessage(
      "~^Sys.DIY_PURL_OrgID~^",
      { urlService: this.urlService, licenseService: this.licenseService },
      { orgID: this.params.orgID, indID: this.params.indID },
      { orgData: this.orgData, indData: this.indData },
      undefined,
      true
    );
    this.loadingSvg = false;

    if (link && this.commonFun.isURLValid(link)) {
      window.open(link, "_self");
    } else {
      let userAuth = getQueryParams();
      userAuth.designTypeId = "-M4svx1Ssr7jk7r4dJLN";
      delete userAuth.designOrVariationId;
      delete userAuth.isDesignOrVariation;
      delete userAuth.printDesignID;
      delete userAuth.isPrintDesignOrVariation;
      await this.router.navigate(["/photo"], setQueryParams(userAuth));
    }
  }

  // --- get foreground color based on bg color brightness
  getForegroundColor(bgColor: string) {
    if (!bgColor) return "#000000";

    // understand brightness of bg color and set text color accordingly
    let r = parseInt(bgColor.substring(1, 3), 16);
    let g = parseInt(bgColor.substring(3, 5), 16);
    let b = parseInt(bgColor.substring(5, 7), 16);
    let a = parseInt(bgColor.substring(7, 9), 16);
    let brightnessLevel = r * 0.299 + g * 0.587 + b * 0.114;
    return a < 180 || brightnessLevel > 150 ? "#000000" : "#ffffff";
  }

  // --- prepare list of patches to display
  prepareIndPatchesToDisplay(indData) {
    let patches = [];
    lodash.each(this.patchesConfigsList, patchData => {
      if (!patchData || !patchData.patchID) return;
      let value;
      let patchToDisplay;

      switch (patchData.patchID) {
        // --- boolean patches handling
        case PatchID.ASB:
        case PatchID.YEARBOOK:
        case PatchID.LUNCH_PRIVILAGE:
        case PatchID.CAN_LEAVE_CAMPUS:
          value = lodash.get(indData, patchData.patchID, false);
          if (value == true) {
            patchToDisplay = {
              label: Patches.getDisplayName(patchData.patchID),
              color: patchData.color,
              key: patchData.key
            };
          }
          break;

        case PatchID.FEES_BALANCE:
          // value = lodash.chain(indData).get(patchData.patchID, 0).value()
          value = lodash.get(indData, patchData.patchID);
          if (!lodash.isNil(value) && value.trim())
            patchToDisplay = {
              // label: Patches.getDisplayName(patchData.patchID),
              label: value,
              color: patchData.color,
              key: patchData.key
            };
          break;

        case PatchID.PARKING_LOT:
          value = lodash.get(indData, patchData.patchID);
          if (!lodash.isNil(value) && value.trim()) {
            patchToDisplay = {
              label: lodash.truncate(value, { length: 3, omission: "" }),
              color: patchData.color,
              key: patchData.key
            };
          }
          break;
      }

      if (patchToDisplay) {
        patchToDisplay.textColor = this.getForegroundColor(
          patchToDisplay.color
        );
        patches.push(patchToDisplay);
      }
    });

    this.patchesToDisplay = lodash.orderBy(patches, patch => patch.key);
  }

  offerTakePhotoPrefValue;
  idAccessNoPhotoPrefValue;
  async onIndDataUpdate() {
    this.preIdValid = this.individualApiService.isIndActive(this.indData);
    this.prepareIndPatchesToDisplay(this.indData);

    let indPhotoData = this.getIndPhotoUsingPrefSettings.transform(
      this.indData,
      this.photoUsedInIDPrefValue,
      this.currentCycle
    );

    if (lodash.isNil(this.indData)) {
      this.errorMsg = "individual data not found";
      return;
    }

    if (!indPhotoData.isPhotoPresent && this.offerTakePhotoPrefValue == 1) {
      // --- if no photo present
      this.isInfo = true;
      this.errorMsg = `We do not have an image for your ID.<br/>Would you like to capture one now?`;
      this.showErrBtns = true;
      return;
    }

    if (
      !indPhotoData.isPhotoPresent &&
      this.offerTakePhotoPrefValue == 2 &&
      this.idAccessNoPhotoPrefValue == 2
    ) {
      this.isInfo = true;
      this.errorMsg = `You are not allow to view ID without your photo.`;
      this.showErrBtns = true;
      return;
    }

    // --- handle in-active ind
    if (!this.individualApiService.isIndActive(this.indData)) {
      this.errorMsg = `${this.indData.firstName} ${this.indData.lastName} does not have valid ID for ${this.orgData.orgName}`;
      return;
    }

    // --- set qr code data
    this.qrCodeData = {
      hash: this.indData.hash,
      studentID: this.indData.studentID
    };

    if (
      this.orgPrefPhotosMustBeApproved == true &&
      indPhotoData.photoStatus != 1
    ) {
      this.errorMsg = `Your photo is not approved yet. You will not be able to see your id until your photo gets approved by admin.`;
      this.onError.next(this.errorMsg);
      return;
    }
    this.reloadDigitalID();
  }

  async onOrgDataUpdate() {
    if (lodash.isNil(this.orgData)) {
      this.onFatalError("organization data not found");
      return;
    }

    this.reloadDigitalID();
  }

  // --- clear canvas to render new SVG
  clearCanvas() {
    if (this.canvasTarget && this.canvasTarget.nativeElement) {
      this.canvasTarget.nativeElement.innerHTML = "";
      this.canvasTarget.nativeElement.style.height = "0px";
    }
  }

  // --- decide whether to show svg loader or not
  shouldShowSvgLoading() {
    let svgData = lodash.get(this.canvasTarget, "nativeElement.innerHTML");
    return !svgData;
  }

  // --- show cached ID
  async showCachedID() {
    if (this.idType != FeaturesEnums.DigitalID) return;

    let cachedID = await this.commonFun.getCachedID();
    let idB64 = lodash.get(cachedID, "b64");
    if (!idB64) return;

    // --- clear canvas
    this.clearCanvas();

    // --- show cached digital ID in container
    if (this.canvasTarget && this.canvasTarget.nativeElement) {
      this.canvasTarget.nativeElement.innerHTML = idB64;
    }

    // --- start cached ID timestamp ticking
    this.rendererService.startTimestampRefresh(cachedID.timestampFormat);

    // --- trigger resize event to adjust SVG sizing
    this.commonFun.svgSizing(this.canvasTarget, 66);

    // --- set qr code data
    this.qrCodeData = {
      hash: lodash.get(cachedID, "indHash"),
      studentID: lodash.get(cachedID, "studentID")
    };
  }

  indData;
  showErrBtns = false;
  currentCycle;
  photoUsedInIDPrefValue: string = "";
  orgPrefPhotosMustBeApproved;
  formFactorToRenderIDWith;
  screenOrientation;
  patchesConfigsList = [];
  patchesToDisplay = [];
  patchesData = Patches.getAll();
  async init() {
    console.log('init call');
    // --- wait for authorization
    let authorized = await this.commonFun.waitForAuthorization(this.authApi);
    console.log('authorized: 395', authorized);
    if (!authorized) return;

    this.params = getQueryParams();

    // --- check for invalid url
    if (!this.settingsService.isUrlValid()) {
      this.errorMsg = environment.urlCrashMsgContent;
      this.settingsService.isEmailSend("inValidURL").then(res => {
        if (res != false) this.settingsService.sendErrorEmail(res);
      });
      return;
    }

    let [, getOrgDatErr] = await this.commonFun.executePromise(this.readOrgData())

    // --- error handling
    if (getOrgDatErr) {
      let is0Err = getOrgDatErr?.status === 0; // happens when internet turns of when request is in progress
      let is504Error = getOrgDatErr?.status === 504; // 504 is timeout error which can occur when service worker is handling network request (in offline mode)
      let isSpecialErr = is504Error || is0Err;

      this.errorMsg = this.commonFun.prepareErrorMessage(
        getOrgDatErr
      );
      if (!isSpecialErr)
        this.setMessageLog(
          getOrgDatErr,
          "init",
          "required data load failed"
        );
      return;
    }

    // --- read required data
    let readRequiredDataPromises = [];

    // --- read org data
    // readRequiredDataPromises.push(this.readOrgData());
    // --- read org cycle
    readRequiredDataPromises.push(
      this.commonFun.getOrgCurrentCycle(this.params.orgID)
    );

    // --- read photo used in ID preference
    readRequiredDataPromises.push(
      this.preferencesService.getPreferenceByInheritance(
        Role.ORG,
        this.params.orgID,
        "photoUsedInID",
        null,
        true,
        "value"
      )
    );

    // --- read photo must be approved preference
    readRequiredDataPromises.push(
      this.preferencesService.getPreferenceByInheritance(
        Role.ORG,
        this.params.orgID,
        "photoMustBeApproved",
        null,
        true,
        "value"
      )
    );

    // --- read individual data
    // readRequiredDataPromises.push(
    //   this.individualApiService.getIndData(
    //     this.params.orgID,
    //     this.params.indID,
    //     undefined,
    //     true
    //   )
    // );
    


    // --- read patches configurations
    readRequiredDataPromises.push(
      this.patchesService.getPatches(Role.ORG, this.params.orgID, null, true)
    );

    // --- read OfferPhotoTakingNoPhotoPresent preference
    readRequiredDataPromises.push(
      this.preferencesService.getPreferenceByInheritance(
        Role.ORG,
        this.params.orgID,
        "OfferPhotoTakingNoPhotoPresent",
        null,
        true,
        "value"
      )
    );

    // --- read IdAccessibilityNoPhotoPresent preference
    readRequiredDataPromises.push(
      this.preferencesService.getPreferenceByInheritance(
        Role.ORG,
        this.params.orgID,
        "IdAccessibilityNoPhotoPresent",
        null,
        true,
        "value"
      )
    );

    // --- resolve readRequiredDataPromises
    let [
      readRequiredDataPromisesRes,
      readRequiredDataPromisesErr
    ] = await this.commonFun.executePromise(
      Promise.all(readRequiredDataPromises)
    );

    // --- error handling
    if (readRequiredDataPromisesErr) {
      let is0Err = readRequiredDataPromisesErr?.status === 0; // happens when internet turns of when request is in progress
      let is504Error = readRequiredDataPromisesErr?.status === 504; // 504 is timeout error which can occur when service worker is handling network request (in offline mode)
      let isSpecialErr = is504Error || is0Err;

      this.errorMsg = this.commonFun.prepareErrorMessage(
        readRequiredDataPromisesErr
      );
      if (!isSpecialErr)
        this.setMessageLog(
          readRequiredDataPromisesErr,
          "init",
          "required data load failed"
        );
      return;
    }

    this.currentCycle = readRequiredDataPromisesRes[0];
    this.photoUsedInIDPrefValue = readRequiredDataPromisesRes[1];
    this.orgPrefPhotosMustBeApproved = readRequiredDataPromisesRes[2];
    // this.indData = readRequiredDataPromisesRes[4];
    this.patchesConfigsList = readRequiredDataPromisesRes[3];
    this.offerTakePhotoPrefValue = readRequiredDataPromisesRes[4];
    this.idAccessNoPhotoPrefValue = readRequiredDataPromisesRes[5];

    let [getIndDatRes, getIndDatErr] = await this.commonFun.executePromise(this.individualApiService.getIndData(
      this.params.orgID,
      this.params.indID,
      undefined,
      true
    ))

    // --- error handling
    if (getIndDatErr) {
      let is0Err = getIndDatErr?.status === 0; // happens when internet turns of when request is in progress
      let is504Error = getIndDatErr?.status === 504; // 504 is timeout error which can occur when service worker is handling network request (in offline mode)
      let isSpecialErr = is504Error || is0Err;

      this.errorMsg = this.commonFun.prepareErrorMessage(
        getIndDatErr
      );
      if (!isSpecialErr)
        this.setMessageLog(
          getIndDatErr,
          "init",
          "required data load failed"
        );
      return;
    }
    this.indData = getIndDatRes;
    this.onIndDataUpdate();

    // --- set individual data change listener
    
    let indSub = this.individualApiService
      .observeIndData(this.params.orgID, this.params.indID)
      .pipe(takeUntil(this.pageDestroy$), skip(1))
      .subscribe(res => {
        console.log('res: ', res);
        this.indData = res;
        this.indData.key = this.params.indID;
        this.indData._key = this.params.indID;
        this.onIndDataUpdate();
      });
    this.subscriptions.push(indSub);

    // --- set org data change listener
    let orgSub = this.orgService
      .monitorOrgData(this.params.orgID)
      .pipe(takeUntil(this.pageDestroy$), debounceTime(500))
      .pipe(skip(1))
      .subscribe(res => {
        if (!res) this.orgData = null;
        else {
          res["key"] = this.params.orgID;
          res["_key"] = this.params.orgID;
          res["orgID"] = this.params.orgID;
          this.orgData = res;

          // --- set org data in user object on settings and url services
          this.digitalIDService.setOrgData(this.orgData);
        }

        this.onOrgDataUpdate();
      });
    this.subscriptions.push(orgSub);
  }

  orgSub: Subscription;
  studioSub: Subscription;
  superAdminSub: Subscription;
  // --- listen for variations data change (to refresh ID on change)
  listenToVariationsDataChange() {
    if (!this.variationKeyDesignTypeIdObj.designTypeId) return;

    // --- remove old subscriptions
    this.commonFun.unSub(this.orgSub);
    this.commonFun.unSub(this.studioSub);
    this.commonFun.unSub(this.superAdminSub);

    let params = getQueryParams();
    let endPoint = this.variationKeyDesignTypeIdObj.variationKey
      ? `/variations/${this.variationKeyDesignTypeIdObj.variationKey}`
      : `/`;

    const subscribeToOrgVariationChange = () => {
      // --- org variation subscription
      this.orgSub = this.db
        .object(
          `/organizations/${params.orgID}/designs/customizeDesigns/${this.variationKeyDesignTypeIdObj.designTypeId}${endPoint}`
        )
        .valueChanges()
        .pipe(takeUntil(this.pageDestroy$), skip(1), debounceTime(1000))
        .subscribe((orgVariation: any) => {
          // --- if org has variation, its fine. If no, subscribe to studio variation
          if (orgVariation) this.reloadDigitalID();
          else if (this.orgData.studioID) subscribeToStudioVariationChange();
          else subscribeToSAVariationChange();
        });
    };

    const subscribeToStudioVariationChange = () => {
      // --- studio variation subscription
      this.studioSub = this.db
        .object(
          `/studios/${this.orgData.studioID}/designs/customizeDesigns/${this.variationKeyDesignTypeIdObj.designTypeId}${endPoint}`
        )
        .valueChanges()
        .pipe(takeUntil(this.pageDestroy$), skip(1), debounceTime(1000))
        .subscribe(studioVariation => {
          // --- if studio has variation, its fine. If no, subscribe to superadmin variation
          if (studioVariation) this.reloadDigitalID();
          else subscribeToSAVariationChange();
        });
    };

    const subscribeToSAVariationChange = () => {
      // --- super admin variation subscription
      this.superAdminSub = this.db
        .object(
          `/designs/customizeDesigns/${this.variationKeyDesignTypeIdObj.designTypeId}${endPoint}`
        )
        .valueChanges()
        .pipe(takeUntil(this.pageDestroy$), skip(1), debounceTime(1000))
        .subscribe(SAVariation => {
          if (SAVariation) this.reloadDigitalID();
        });
    };

    subscribeToOrgVariationChange();
  }

  // --- prepare design type ID
  async prepareDesignTypeID() {
    if (!this.params.designTypeId) {
      let designTypeID = await this.designService.getDefaultDesignTypeIDInheritance(
        Role.ORG,
        this.params.orgID
      );

      if (!designTypeID) {
        await this.onFatalError("Design Type Id Missing in URL");
        return false;
      }

      this.localStorageService.setItem("designTypeId", designTypeID);
    }

    return true;
  }

  // --- check necessary params
  async checkNecessaryParams() {
    const showErrorMessage = async msg => {
      await this.onFatalError(msg);
      return false;
    };

    let params = getQueryParams();

    // --- check for org ID
    if (!params.orgID) {
      return await showErrorMessage("Organization Id Missing in URL");
    }

    // --- check for ind ID
    if (!params.indID) {
      return await showErrorMessage("Individual Id Missing in URL");
    }

    return true;
  }

  // --- re-load/refresh digital ID
  async reloadDigitalID() {
    console.log('reloadDigitalID: ');
    let isParamsValid = await this.checkNecessaryParams();
    if (!isParamsValid) return;

    let shouldContinue = await this.prepareDesignTypeID();
    if (!shouldContinue) return;

    this.loadDigitalId();
  }

  openFullscreen() {
    if (!this.commonFun.mobileAndTabletCheck()) return;

    if (this.high5AppService.isRunningInAppShell) {
      this.high5AppService.callNativeMethod("openFullScreen");
    } else this.commonFun.openFullscreen();
  }

  closeFullscreen() {
    if (this.high5AppService.isRunningInAppShell) {
      this.high5AppService.callNativeMethod("closeFullScreen");
    } else this.commonFun.closeFullscreen();
  }

  // --- open fallback page on fatal error
  async onFatalError(err: any) {
    this.errorMsg = this.commonFun.prepareErrorMessage(err);
  }

  variationKeyDesignTypeIdObj = { variationKey: "", designTypeId: "" };
  delayedDigitalIDLoadCount = 0;
  isSvgBeingRendered = false;
  loadDigitalId() {
    // --- if load is already running and second request received for loading ID again,
    // then delay the load request until current one completes
    if (this.isSvgBeingRendered == true) {
      this.delayedDigitalIDLoadCount++;
      return;
    }

    this.isSvgBeingRendered = true;
    if (this.shouldShowSvgLoading()) this.loadingSvg = true;

    // --- prepare screen sized form factor
    this.prepareFormFactor();

    // --- render digital ID
    let loadIDSub = this.digitalIDService
      .renderDigitalID(
        this.params.orgID,
        this.params.indID,
        true,
        true,
        false,
        null,
        null,
        this.formFactorToRenderIDWith,
        null,
        this.screenOrientation
      )
      .pipe(timeout(30 * 1000))
      .subscribe(
        async ({ base64 }) => {
          this.isSvgBeingRendered = false;
          this.loadingSvg = false;

          // --- if any delayed digital id load req available,
          // then process that
          if (this.delayedDigitalIDLoadCount > 0) {
            this.delayedDigitalIDLoadCount = 0;
            this.loadDigitalId();
            return;
          }

          // --- cache digital ID for offline mode
          if (this.idType == FeaturesEnums.DigitalID) {
            let rendererTimestampFormat = await this.digitalIDService.getRendererTimestampFormat();
            let cachedID: any = {
              b64: base64,
              t: moment().valueOf(),
              indHash: this.indData.hash,
              studentID: this.indData.studentID,
              timestampFormat: rendererTimestampFormat
            };
            cachedID = this.encryptionService.encrypt(
              JSON.stringify(cachedID),
              environment.edk
            );
            this.localStorageService.removeItem(IndexedDBKeys.CachedID); // --- remove legacy cached ID
            this.indexedDBService
              .setItem(IndexedDBKeys.CachedID, cachedID)
              .catch(err => console.log("Cache:: Error caching ID", err));
          }

          this.lastRenderWidth = window.innerWidth;
          this.lastRenderHeight = window.innerHeight;

          if (this.canvasTarget && this.canvasTarget.nativeElement)
            this.canvasTarget.nativeElement.innerHTML = base64;

          // --- stop cached ID timestamp refreshing
          this.rendererService.stopTimestampRefresh();

          // --- start timestamp ticking
          this.commonFun.startSVGTimestampTick();

          // --- start fresh ID timestamp refreshing
          if (this.expiryMillis) this.commonFun.startCountDown(this.expiryMillis);

          // --- get variationKey and designTypeId from digital id service
          this.variationKeyDesignTypeIdObj = this.digitalIDService.getVariationKeyDesignTypeId();
          this.listenToVariationsDataChange();

          this.commonFun.svgSizing(this.canvasTarget, 66);
        },
        async e => {
          if (loadIDSub) loadIDSub.unsubscribe();

          let isTimeoutError = e && e.name == "TimeoutError";
          let is0Error = e && e.status === 0; // happens when internet gets turned off while request is in progress
          let is504Error = e && e.status === 504; // 504 is timeout error which can occur when service worker is handling network request (in offline mode)
          let isSpecialErr = isTimeoutError || is504Error || is0Error;

          // --- show cached ID (if applicable & available)
          let shouldUseCachedID = this.idType == FeaturesEnums.DigitalID;
          let shouldShowCachedID = false;
          if (shouldUseCachedID) {
            shouldShowCachedID = isSpecialErr;
            let cachedID = this.commonFun.getCachedID();
            if (cachedID) {
              if (!shouldShowCachedID) {
                shouldShowCachedID = await this.commonFun.presentAlert(
                  `An issue was detected while preparing your digital ID.\nError: ${this.commonFun.prepareErrorMessage(
                    e
                  )}\nWe are aware of the issue and will work to fix it.\nYour last-loaded ID will be shown now.`,
                  "Notice",
                  "Ok",
                  undefined,
                  { dismissOnOutsideClick: false }
                );
              }
            }
          }

          // --- report error to superadmin (except timeout errors for cached ID)
          if (
            this.commonFun
              .prepareErrorMessage(e, null, true)
              .includes("Error loading renderer")
          ) {
            this.isSvgBeingRendered = false;
            this.loadDigitalId();
            return;
          } else {
            if (!shouldUseCachedID || !isSpecialErr) this.setMessageLog(e);
          }
          this.isSvgBeingRendered = false;
          this.loadingSvg = false; // hide loader

          // --- show cached ID or handle errors
          if (shouldShowCachedID) await this.router.navigate(["/offline"]);
          else {
            // --- pass down error triggers
            if (this.onError.observers.length > 0) {
              this.onError.next(e);
            } else {
              // --- show error
              await this.commonFun.presentAlert(
                `Error: ${this.commonFun.prepareErrorMessage(e)}`
              );

              // --- flip to front (or go back)
              if (
                this.flipperPagesHomePlusID &&
                this.flipperPagesHomePlusID.activeSide == "back"
              )
                this.flipperPagesHomePlusID.flip();
              else this._location.back();
            }
          }
        }
      );

    this.subscriptions.push(loadIDSub);
  }

  async setMessageLog(err: any, fnName?, error?) {
    if (!err) return;
    let currentUser = null;
    try {
      let userData = await this.authApi.getCurrentUserData()
      currentUser = JSON.stringify(userData);
    } catch(e) {
      currentUser = `Error getting current user data: ${this.commonFun.prepareErrorMessage(e)}`
    }

    try {
      let userAuth = getQueryParams();
      let message = {
        timestamp: new Date().getTime(),
        details: `Page: Digital ID
          <br/>Fun: ${fnName || "getSVGFromRenderer"}
          <br/>orgId: ${userAuth.orgID}
          <br/><br/>Error: ${error || "render failed"}
          <br/><br/>ErrorDetails: ${this.commonFun.prepareErrorMessage(
            err,
            null,
            true
          )}
          <br/><br/>User: ${currentUser}
          <br/><br/>URL: ${window.location.href}`,
        type: "error",
        area: MessageArea.SVG
      };
      await this.messagesService.setMessage(Role.SUPERADMIN, message);
    } catch (error) {}
  }

  // --- disable right click to prevent for save digital id
  @HostListener("contextmenu", ["$event"])
  onRightClick(event) {
    event.preventDefault();
  }

  // --- prepare form-factor based on screen size
  prepareFormFactor() {
    let width = window.innerWidth;
    let height = window.innerHeight - 66;
    this.screenOrientation = width > height ? "landscape" : "portrait";
    this.formFactorToRenderIDWith = this.commonFun.generateFormFactor(
      width,
      height,
      this.screenOrientation
    );
  }

  // --- triggers when window gets resized
  @HostListener("window:resize", ["$event"])
  onWindowResize = lodash.debounce(() => {
    console.log("onWindowResize");

    // -- prevent ID reload if in flipper mode, component isn't visible
    if (
      this.lifecycleMode == "flipperBased" &&
      this.flipperPagesHomePlusID.activeSide != "back"
    )
      return;

    // -- prevent ID reload if design type id not present
    if (!this.params.designTypeId) return;

    // -- prevent ID reload if window size is the same as previous load
    if (
      this.lastRenderHeight == window.innerHeight &&
      this.lastRenderWidth == window.innerWidth
    )
      return;

    // --- reload ID with new form factor (based on screen size)
    this.reloadDigitalID();
  }, 100);

  qrCodeData: any;
  showQrCode() {
    if (this.flipperPagesHomePlusID && this.flipperPagesHomePlusID.isFlipping)
      return;

    if (!lodash.has(this.qrCodeData, "hash")) return;

    this.showQrCodeService.confirm(
      "Qr Code",
      { indhash: this.qrCodeData.hash },
      this.qrCodeData.studentID || ""
    );
  }

  // --- triggers when component instance becomes visible first time
  onBecameVisibleFirstTime() {
    // --- show cached ID for instant view (it will eventually refresh when new ID will be generated via internet data)
    this.showCachedID();
  }

  // --- triggers when component becomes visible (eg. displayed on UI by swiping)
  becameVisibleFirstTime = true;
  onBecameVisible() {
    if (this.becameVisibleFirstTime) {
      this.becameVisibleFirstTime = false;
      this.onBecameVisibleFirstTime();
    }
  }

  ngOnInit() {
    // --- in case of normal lifecycle, trigger visibile event externally to start loading ID
    if (this.lifecycleMode == "normal") {
      this.onBecameVisible();
      this.init();
    }
  }

  ngOnDestroy() {
    this.commonFun.stopSVGTimestampTick();
    lodash.each(this.subscriptions, sub => {
      if (sub && !sub.closed) sub.unsubscribe();
    });
    this.pageDestroy$.next();
    this.pageDestroy$.complete();
  }
}
