import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetDate, TimestampPipe } from './timestamp/timestamp.pipe';
import { FormatTimeInMin, FormatTimerPipe, SafeHtml, TimeAgoPipe } from './format-timer/format-timer.pipe';
import { FindAndGetPropOfObjPipe } from './findAndGetPropOfObj/find-and-get-prop-of-obj.pipe';
import { IsShowPhotoPipe } from './isShowPhoto/is-show-photo.pipe';
import { FindAndGetPropOfObj } from './findAndGetPropOfObj';
import { GetIndPhotoUsingPrefSettings } from './getIndPhotoUsingPrefSettings/get-ind-photo-using-pref-settings.pipe';
import { GetIndPropPipe } from './getIndProp/get-ind-prop.pipe';
import { ViewAnonPipe } from './viewAnon/view-anon.pipe';
import { GetIndNameUsingHashPipe } from './get-ind-name-using-hash/get-ind-name-using-hash.pipe';
import { SafeAccessPropPipe } from './safe-access-prop/safe-access-prop.pipe';
import { CountAgeFromDOB } from './countAgeFromDOB/count-age-from-dob';
import { GetPassStatusPipe } from './passStatus/get-pass-status.pipe';
import { GetPassDurationPipe } from './get-pass-duration.pipe';

@NgModule({
  declarations: [TimestampPipe, FormatTimerPipe, FormatTimeInMin,SafeHtml, FindAndGetPropOfObjPipe, IsShowPhotoPipe, FindAndGetPropOfObj, GetIndPhotoUsingPrefSettings, GetIndPropPipe, ViewAnonPipe, GetIndNameUsingHashPipe, SafeAccessPropPipe,
    GetDate,
    CountAgeFromDOB,
    GetPassStatusPipe,
    TimeAgoPipe,
    GetPassDurationPipe],
  imports: [
    CommonModule
  ],
  exports: [TimestampPipe, FormatTimerPipe, FormatTimeInMin,SafeHtml, TimeAgoPipe, FindAndGetPropOfObjPipe, IsShowPhotoPipe, FindAndGetPropOfObj, GetIndPhotoUsingPrefSettings, GetIndPropPipe, GetIndNameUsingHashPipe, SafeAccessPropPipe,
    GetDate, CountAgeFromDOB, GetPassStatusPipe, GetPassDurationPipe]
})
export class PipesModule { }
