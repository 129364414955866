<div>
    <!-- <div class="modal-header">
        <p class="modal-title vertical_centr">Select Game</p>
        <button type="button" class="vertical_centrC close-icon" aria-label="Close" (click)="dismiss()">
            <span nz-icon nzType="close" nzTheme="outline"></span>
        </button>
    </div> -->

    <div class="modal-footer" style="padding: 0px;border-radius: 5px;border-top: 0px !important;">
        <div style="margin: 0px; width: 100%;border-radius: 5px;">
            <ul class="scroll-div-new" style="border-radius: 5px !important;" *ngIf="gameList && gameList.length">
                <li *ngFor="let game of gameList" class="game-name" (click)="selectGame(game)">
                    <span>{{game.oppositeTeamName}} - {{game.divisionName}}</span>
                    <img src="assets/check_mark.png" *ngIf="selectedGame.key == game.key" class="img-tick">
                </li>
            </ul>
        </div>
    </div>
</div>
