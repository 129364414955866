import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../src/environments/environment";
import { IndividualApiService } from "../individual/individual.api.service";
import lodash from "lodash";
import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { EncryptionService } from "../encryption/encryption.service";
import { CommonServiceService } from "../common/common-service.service";
import { ElasticEndPoint } from "../enums/general.enums";

@Injectable()
export class ElasticService {
  constructor(
    public http: HttpClient,
    public individualApi: IndividualApiService,
    public db: AngularFireDatabase,
    public encryptionService: EncryptionService,
    private commonService: CommonServiceService
  ) {}

  allIndsList: any[] = [];

  getIndListByRole(orgId: string, role: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let body = {
        hashCode: ElasticEndPoint.IND_ROLE,
        orgID: orgId,
        txt: role
      };
      let res: any = await this.http
        .post(
          `${environment.newApiBaseUrl}${environment.elasticSearch.endPoint}`,
          this.commonService.prepareElasticReqBodyNew(body),
          { responseType: "text" }
        )
        .toPromise();
      let elasticResponse = JSON.parse(
        this.encryptionService.decrypt(res, environment.edk)
      );
      let tempIndList = [];
      if(elasticResponse.response)
        elasticResponse.response.forEach(ind => {
          let value: any = ind._source;
          value["key"] = ind._id;
          tempIndList.push(value);
        });
      resolve({ success: elasticResponse.success, indList: tempIndList });
    });
  }

  // --- get total ind using orgId
  getIndList(orgID: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let body = { hashCode: ElasticEndPoint.IND_LIST, orgID };
      let res: any = await this.http
        .post(
          `${environment.newApiBaseUrl}${environment.elasticSearch.endPoint}`,
          this.commonService.prepareElasticReqBodyNew(body),
          { responseType: "text" }
        )
        .toPromise();
      let elasticResponse = JSON.parse(
        this.encryptionService.decrypt(res, environment.edk)
      );
      if (elasticResponse.success && elasticResponse.response.length > 0) {
        let tempIndList = [];
        elasticResponse.response.forEach(ind => {
          let value: any = ind._source;
          value["key"] = ind._id;
          tempIndList.push(value);
        });
        resolve({ success: true, indList: tempIndList });
      } else {
        resolve({ success: false, message: "Individual data not found" });
      }
    });
  }
}

export class Condition {
  query: string;
  fields: string[];
}

export class SortItem {
  field: string;
  order: "asc" | "desc";
}
