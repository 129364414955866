import { Injectable, Inject, forwardRef, OnDestroy } from "@angular/core";
import { IndividualFull } from "../individual/individual-full.class";
import { getQueryParams } from "../utils/queryParams.utils";
import UserAuth from "../auth/userAuth.class";
import { AngularFireDatabase } from "@angular/fire/database";
import { AngularFireList } from "@angular/fire/database";
import lodash from "lodash";
import { CommonServiceService } from "../common/common-service.service";
import moment from "moment";
import { from, Subscription } from "rxjs";
import { map } from "rxjs/operators";
export class Visit {
  public static StartLocation = "Mobile Device";

  public $key: string;
  public _onSite: boolean;
  public _individual: IndividualFull;
  public _pickedIndividual: IndividualFull;
  public disabled: boolean;
  public direction: string = "entry";

  public individualKey: string;
  public kioskKey: string;
  public kioskType: string;
  public logKey: string;
  public passRefKey: string;

  public timestamp: number;
  public timestampChange: number;
  public timestampEntryRequested: number;
  public timestampEntryApproved: number;
  public timestampExitRequested: number;
  public timestampExitApproved: number;

  public completed: boolean;
  public entryApproved: number;
  public exitApproved: number;

  public visitorType: string;
  public visitType: string;
  public reason: string;
  public startLocation: string;
  public destLocation: string;
  public startLocationKey: string;
  public destLocationKey: string;

  public studentVisitType: string;
  public studentVisitReason: string;
  public studentOtherDetails: string;

  public pickedIndKey: string;

  public eventKey: string;
  public visitKey: string;

  public isOnCampus: boolean;
  public manuallyMarkedArrivedAt: any;
  public monthDatePath: string;

  constructor(kioskKey?, indKey?) {
    if (indKey) {
      this.individualKey = indKey;
    }
    if (kioskKey) {
      this.kioskKey = kioskKey;
    }
    this.timestamp = new Date().getTime();
    this.timestampChange = this.timestamp;
    this.completed = false;
    this.entryApproved = 0;
  }

  copyInto(obj: any, key?: string, kiosksList?) {
    if (key) this.$key = key;
    this.individualKey = obj.individualKey;
    this.kioskType = obj.kioskType;
    this.logKey = obj.logKey;
    this.disabled = obj.disabled;
    this.passRefKey = obj.passRefKey || "";

    this.timestamp = obj.timestamp;
    this.timestampChange = obj.timestampChange;
    this.timestampEntryRequested = obj.timestampEntryRequested;
    this.timestampEntryApproved = obj.timestampEntryApproved;
    this.timestampExitRequested = obj.timestampExitRequested;
    this.timestampExitApproved = obj.timestampExitApproved;

    this.completed = obj.completed;
    this.entryApproved = obj.entryApproved;
    this.exitApproved = obj.exitApproved;
    this.visitorType = obj.visitorType;
    this.visitType = obj.visitType;
    this.reason = obj.reason;

    this.kioskKey = obj.kioskKey;
    this.startLocation = obj.startLocation;
    this.destLocation = obj.destLocation;
    this.startLocationKey = obj.startLocationKey;
    this.destLocationKey = obj.destLocationKey;

    this.studentVisitType = obj.studentVisitType;
    this.studentVisitReason = obj.studentVisitReason;
    this.studentOtherDetails = obj.studentOtherDetails;

    this.pickedIndKey = obj.pickedIndKey;

    this.eventKey = obj.eventKey;
    this.visitKey = obj.$key;
    this.isOnCampus = obj.isOnCampus;
    this.manuallyMarkedArrivedAt = obj.manuallyMarkedArrivedAt || null;
  }

  clearInternal() {
    this._individual = null;
    this._pickedIndividual = null;

    lodash.forOwn(this, (value, key) => {
      if (this[key] === undefined) {
        this[key] = null;
      }
    });
  }
}

@Injectable({
  providedIn: "root"
})
export class Visits implements OnDestroy {
  public list: AngularFireList<{}>;
  userAuth: UserAuth;
  orgCurrentCycle;

  constructor(
    public db: AngularFireDatabase,
    private commonService: CommonServiceService
  ) {
    this.userAuth = getQueryParams();
    this.init();
  }

  subscriptions: Subscription[] = [];
  ngOnDestroy() {
    // --- remove subscriptions
    lodash.each(this.subscriptions, sub => {
      if (sub && !sub.closed) sub.unsubscribe();
    });

    console.log("Visits service destroyed");
  }

  async getOrgCurrentCycle() {
    if (!this.orgCurrentCycle && this.userAuth && this.userAuth.orgID) {
      let [cycle] = await this.commonService.executePromise(
        this.commonService.getOrgCurrentCycle(this.userAuth.orgID)
      );
      if (cycle) this.orgCurrentCycle = cycle;
    }
  }

  orgKiosks = [];
  monitorKiosks() {
    if (!this.userAuth.orgID) return;

    let kiosksSub = this.db
      .object(`organizations/${this.userAuth.orgID}/settings/kiosks`)
      .valueChanges()
      .subscribe(kiosksData => {
        this.orgKiosks = lodash.map(kiosksData, (displayName, key) => {
          return { displayName, key };
        });
      });

    // --- add subscription to subscriptions list
    this.subscriptions.push(kiosksSub);
  }

  async init() {
    this.monitorKiosks();
    await this.getOrgCurrentCycle();
  }

  async monitorVisit(key: string) {
    await this.getOrgCurrentCycle();

    return from(
      this.db
        .object(
          `/Transactions/${this.userAuth.orgID}/${
            this.orgCurrentCycle
          }/visits/${this.commonService.getMonthDatePath()}/${key}`
        )
        .valueChanges()
    ).pipe(
      map(value => {
        let visit: any = new Visit();
        visit.copyInto(value, key, this.orgKiosks);
        return visit;
      })
    );
  }

  getVisiByKey(key: string, callback?: Function, listenForChanges?: boolean) {
    return new Promise(async resolve => {
      await this.getOrgCurrentCycle();

      // --- look for visits in current day,
      // if not found, look for visits in past 7 days,
      // if not found, look for past 15 days,
      // if not found, look for past 1 month
      // This way, incrementally look for visit until full year and still not found, that means visit does not exists
      let incrementalDaysToLookFor = [0, 7, 15, 30, 90, 180, 365];
      let visits: any = [];
      let matchingVisitFound = false;
      for (let day of incrementalDaysToLookFor) {
        let startMillis = moment()
          .subtract(day, "days")
          .startOf("day")
          .valueOf();
        let endMillis = moment()
          .endOf("day")
          .valueOf();
        let duration = {
          start: startMillis,
          end: endMillis
        };
        visits = await this.commonService.promisify(
          this.monitorFiltered,
          1,
          this
        )(duration, null, null, null, null, true);
        let matchingVisit = lodash.find(visits, visit => visit.$key == key);
        if (matchingVisit && matchingVisit.dbPath) {
          matchingVisitFound = true;
          if (listenForChanges) {
            let matchingVisitPath = `${matchingVisit.dbPath}/${matchingVisit.$key}`;
            let visitListener = this.db
              .object(matchingVisitPath)
              .valueChanges()
              .subscribe(obj => {
                if (obj) {
                  const visit = new Visit();
                  visit.copyInto(obj, key, this.orgKiosks);
                  if (callback) callback(visit, visitListener);
                  return resolve(visit);
                } else {
                  if (callback) callback("", visitListener);
                  return resolve("");
                }
              });
          } else {
            const visit = new Visit();
            visit.copyInto(matchingVisit, key, this.orgKiosks);
            if (callback) callback(visit);
            return resolve(visit);
          }
          break;
        }
      }

      if (!matchingVisitFound) {
        // --- code reaches at this point meaning no matching visit found, so return null data
        console.log("NO MATCHING VISIT FOUND!");
        if (callback) callback("");
        return resolve("");
      }
    });
  }

  async checkVisitExists(
    individual: IndividualFull,
    newVisit: boolean,
    callback: Function
  ) {
    if (newVisit === true || !individual.lastVisitKey) {
      callback(undefined, undefined);
      return false;
    }

    await this.getOrgCurrentCycle();

    let subscription = this.db
      .object(
        `/Transactions/${this.userAuth.orgID}/${
          this.orgCurrentCycle
        }/visits/${this.commonService.getMonthDatePath()}/${
          individual.lastVisitKey
        }`
      )
      .snapshotChanges()
      .subscribe((entry: any) => {
        if (subscription) {
          subscription.unsubscribe();
        }

        let found: any;
        let foundKey: string;
        let value: any = entry.payload.val();

        if (value && value.disabled !== true) {
          // check if visit found is from the same date
          let previousTimestamp = new Date(value.timestamp).setHours(
            0,
            0,
            0,
            0
          );
          let currentTimestamp = new Date().setHours(0, 0, 0, 0);

          if (
            currentTimestamp === previousTimestamp &&
            value.completed === false &&
            value.eventKey === undefined &&
            (value.entryApproved === 0 ||
              value.entryApproved === 1 ||
              value.entryApproved === 3)
          ) {
            found = new Visit();
            found.copyInto(value, null, this.orgKiosks);
            foundKey = entry.key;
          }
        }

        callback(found, foundKey);
      });
  }

  async addVisit(visit: Visit, callback: Function) {
    await this.getOrgCurrentCycle();

    visit.kioskType = "touchless";
    visit.clearInternal();
    this.db
      .list(
        `/Transactions/${this.userAuth.orgID}/${
          this.orgCurrentCycle
        }/visits/${this.commonService.getMonthDatePath()}`
      )
      .push(visit)
      .then(
        (resolve: any) => {
          let key = resolve.getKey();
          this.db
            .object(
              `/Transactions/${this.userAuth.orgID}/${this.orgCurrentCycle}/visits_new/${key}`
            )
            .set(visit);
          callback(key);
        },
        reject => {
          console.log(reject);
          callback();
        }
      );
  }

  async addPrintVisitQueue(visit: Visit, callback: Function) {
    await this.getOrgCurrentCycle();

    // remove utility properties
    visit.kioskType = "touchless";
    let key = visit.$key;
    delete visit.$key;
    visit.visitKey = key;
    visit.clearInternal && visit.clearInternal();
    try {
      await this.db
        .object(
          `/Transactions/${this.userAuth.orgID}/${
            this.orgCurrentCycle
          }/printQueue/${this.commonService.getMonthDatePath()}/${key}`
        )
        .set(visit);
      callback(key);
    } catch (e) {
      console.error("Error while adding visit to queue: ", e);
      callback();
    }
  }

  // --- update visit
  async updateVisit(key: string, updateObj: any) {
    if (!key || !updateObj) throw new Error("Params missing!");

    await this.getOrgCurrentCycle();

    // --- add additional props in visit data (eg. timestamp entry/exit requested/approved, timestamp of changes)
    let additionalUpdates: any = {};
    let currentTimestamp = moment().valueOf();
    let propToSet;
    let entryApproved = lodash.get(updateObj, "entryApproved");
    let exitApproved = lodash.get(updateObj, "exitApproved");
    if (!lodash.isNil(entryApproved)) {
      propToSet =
        entryApproved === 0
          ? "timestampEntryRequested"
          : "timestampEntryApproved";
      lodash.set(additionalUpdates, propToSet, currentTimestamp);
    }
    if (!lodash.isNil(exitApproved)) {
      propToSet =
        exitApproved === 0 ? "timestampExitRequested" : "timestampExitApproved";
      lodash.set(additionalUpdates, propToSet, currentTimestamp);
    }
    additionalUpdates.timestampChange = currentTimestamp;

    // --- merge update data with additional props
    updateObj = lodash.cloneDeep(updateObj);
    updateObj = { ...updateObj, ...additionalUpdates };

    await this.db
      .object(
        `/Transactions/${this.userAuth.orgID}/${
          this.orgCurrentCycle
        }/visits/${this.commonService.getMonthDatePath()}/${key}`
      )
      .update(updateObj);

    return true;
  }

  setVisit(
    key: string,
    propertyName: string,
    propertyValue: any,
    callback?: Function,
    bypassTimers?: boolean
  ) {
    return new Promise(async resolve => {
      await this.getOrgCurrentCycle();

      if (bypassTimers !== true) {
        // keep track of timestamps
        switch (propertyName) {
          case "entryApproved":
            if (propertyValue === 0) {
              await this.db
                .object(
                  `/Transactions/${this.userAuth.orgID}/${
                    this.orgCurrentCycle
                  }/visits/${this.commonService.getMonthDatePath()}/${key}/timestampEntryRequested`
                )
                .set(new Date().getTime());
            } else {
              await this.db
                .object(
                  `/Transactions/${this.userAuth.orgID}/${
                    this.orgCurrentCycle
                  }/visits/${this.commonService.getMonthDatePath()}/${key}/timestampEntryApproved`
                )
                .set(new Date().getTime());
            }

            break;
          case "exitApproved":
            if (propertyValue === 0) {
              await this.db
                .object(
                  `/Transactions/${this.userAuth.orgID}/${
                    this.orgCurrentCycle
                  }/visits/${this.commonService.getMonthDatePath()}/${key}/timestampExitRequested`
                )
                .set(new Date().getTime());
            } else {
              await this.db
                .object(
                  `/Transactions/${this.userAuth.orgID}/${
                    this.orgCurrentCycle
                  }/visits/${this.commonService.getMonthDatePath()}/${key}/timestampExitApproved`
                )
                .set(new Date().getTime());
            }
            break;
        }

        await this.db
          .object(
            `/Transactions/${this.userAuth.orgID}/${
              this.orgCurrentCycle
            }/visits/${this.commonService.getMonthDatePath()}/${key}/timestampChange`
          )
          .set(new Date().getTime());
      }

      await this.db
        .object(
          `/Transactions/${this.userAuth.orgID}/${
            this.orgCurrentCycle
          }/visits/${this.commonService.getMonthDatePath()}/${key}/${propertyName}`
        )
        .set(propertyValue);

      if (lodash.isFunction(callback) === true) {
        callback();
      }

      resolve("");
    });
  }

  async getVisitsFromIndividual(
    individual: IndividualFull,
    callback?: Function,
    limitToLast?: number,
    monthDatePath?: string
  ) {
    return new Promise(async resolve => {
      await this.getOrgCurrentCycle();

      let _MDPath = monthDatePath
        ? monthDatePath
        : this.commonService.getMonthDatePath();
      const subscription = this.db
        .list(
          `/Transactions/${this.userAuth.orgID}/${this.orgCurrentCycle}/visits/${_MDPath}`,
          ref =>
            limitToLast
              ? ref
                  .orderByChild("individualKey")
                  .equalTo(individual._key)
                  .limitToLast(limitToLast)
              : ref.orderByChild("individualKey").equalTo(individual._key)
        )
        .snapshotChanges()
        .subscribe(obj => {
          subscription.unsubscribe();

          const list = [];

          lodash.each(obj, entry => {
            const value: any = entry.payload.val();

            if (value && value.disabled !== true) {
              const found = new Visit();
              found.copyInto(value, null, this.orgKiosks);
              found.$key = entry.key;
              found.monthDatePath = _MDPath;

              list.push(found);
            }
          });

          let result = limitToLast == 1 ? list[0] : list;
          if (callback) callback(result);
          return resolve(result);
        });
    });
  }
  // get completed visit
  async getCompletedVisitsFromIndividual(
    individual: IndividualFull,
    callback: Function,
    limitToLast?: number
  ) {
    await this.getOrgCurrentCycle();
    const subscription = this.db
      .list(
        `/Transactions/${this.userAuth.orgID}/${
          this.orgCurrentCycle
        }/visits/${this.commonService.getMonthDatePath()}`,
        ref =>
          limitToLast
            ? ref
                .orderByChild("individualKey")
                .equalTo(individual._key)
                .limitToLast(limitToLast)
            : ref.orderByChild("individualKey").equalTo(individual._key)
      )
      .snapshotChanges()
      .subscribe(obj => {
        subscription.unsubscribe();

        const list = [];

        lodash.each(obj, entry => {
          const value: any = entry.payload.val();
          if (value && value.disabled !== true && value.completed) {
            const found = new Visit();
            found.copyInto(value, null, this.orgKiosks);
            found.$key = entry.key;

            list.push(found);
          }
        });

        callback(limitToLast == 1 ? list[0] : list);
      });
  }

  async monitorFiltered(
    duration,
    callback: (list, subscription?) => void,
    filterByIndID?: string,
    filterByCrossingTypeID?: string,
    reportingYear?: string,
    dbPathRequired: boolean = false
  ) {
    if (!reportingYear) await this.getOrgCurrentCycle();

    let promises = [];

    let startMillis = Number(duration.start);
    let endMillis = Number(duration.end);

    let isSingleDayRequest = moment(startMillis, "x").isSame(
      moment(endMillis, "x"),
      "day"
    );
    let daysDiff = Math.abs(
      moment(startMillis, "x").diff(moment(endMillis, "x"), "days")
    );

    if (isSingleDayRequest) {
      let monthDatePath = this.commonService.getMonthDatePath(
        moment(startMillis, "x")
      );
      promises.push(
        this.db
          .list(
            `/Transactions/${this.userAuth.orgID}/${
              reportingYear ? reportingYear : this.orgCurrentCycle
            }/visits/${monthDatePath}`
          )
          .query.once("value")
      );
    } else if (daysDiff <= 10) {
      for (let i = 0; i <= daysDiff; i++) {
        let monthDatePath = this.commonService.getMonthDatePath(
          moment(startMillis, "x").add(i, "day")
        );
        promises.push(
          this.db
            .list(
              `/Transactions/${this.userAuth.orgID}/${
                reportingYear ? reportingYear : this.orgCurrentCycle
              }/visits/${monthDatePath}`
            )
            .query.once("value")
        );
      }
    } else {
      let monthsDiff = Math.abs(
        moment(endMillis).diff(moment(startMillis), "month")
      );
      let startMonth = Number(moment(startMillis).format("MM"));
      let monthDataPathsArr: string[] = [
        moment(startMonth, "MM").format("MMMM")
      ];
      for (let i = 0; i < monthsDiff + 1; i++) {
        let nextMonthStart = moment(startMillis)
          .add(i + 1, "months")
          .startOf("month");
        if (nextMonthStart.isSameOrBefore(moment(endMillis))) {
          let monthName = nextMonthStart.format("MMMM");
          if (monthDataPathsArr.findIndex(a => a == monthName) == -1)
            monthDataPathsArr.push(monthName);
        }
      }

      for (let i = 0; i < monthDataPathsArr.length; i++) {
        promises.push(
          this.db
            .list(
              `/Transactions/${this.userAuth.orgID}/${
                reportingYear ? reportingYear : this.orgCurrentCycle
              }/visits/${monthDataPathsArr[i]}`
            )
            .query.once("value")
        );
      }
    }

    let promiseResults = [];
    try {
      promiseResults = await Promise.all(promises);
      promiseResults = lodash.map(promiseResults, snapshot => {
        let visitData = snapshot.val();
        if (!visitData) return null;

        // --- prepare visit db path
        const fullPath = snapshot.ref.toString();
        const basePath = this.db.database.ref().toString();
        const pathWithoutBase = fullPath.replace(basePath, "");

        return {
          ...visitData,
          dbPath: pathWithoutBase
        };
      });
    } catch (e) {
      callback([]);
      return;
    }

    let list = [];

    const parseSingleDayVisits = visitsObj => {
      // --- store dbPath in seperate variable and delete it from original object
      let dayVisitsDbPath;
      if (visitsObj && lodash.isObject(visitsObj)) {
        dayVisitsDbPath = lodash.get(visitsObj, "dbPath");
        delete visitsObj.dbPath;
      }

      // --- loop through all visits and filter by duration, add db path if required and add visit to final list
      lodash.each(visitsObj, (value, key) => {
        if (!value || value.disabled === true) return;
        if (
          value.timestampChange &&
          value.timestampChange >= startMillis &&
          value.timestampChange <= endMillis
        ) {
          value.$key = key;

          // --- add dbPath in visits object if required
          if (dbPathRequired && dayVisitsDbPath) {
            value.dbPath = dayVisitsDbPath;
          }

          list.push(value);
        }
      });
    };

    const parseSingleMonthVisit = monthVisitsObj => {
      // --- store dbPath in seperate variable and delete it from original object
      let monthVisitsDbPath;
      if (monthVisitsObj && lodash.isObject(monthVisitsObj)) {
        monthVisitsDbPath = lodash.get(monthVisitsObj, "dbPath");
        delete monthVisitsObj.dbPath;
      }

      // --- loop through monthly visits, add dbPath in daily visits data and parse visits
      lodash.each(monthVisitsObj, (val, dateKey) => {
        if (!val) return;

        // --- add dbPath with date appended at the end
        if (monthVisitsDbPath) val.dbPath = `${monthVisitsDbPath}/${dateKey}`;

        parseSingleDayVisits(val);
      });
    };

    const parseMultipleMonthVisits = multipleMonthsVisitsObj => {
      lodash.each(multipleMonthsVisitsObj, res => {
        if (!res) return;
        parseSingleMonthVisit(res);
      });
    };

    if (isSingleDayRequest || daysDiff <= 10) {
      lodash.each(promiseResults, singleDayVisits => {
        parseSingleDayVisits(singleDayVisits);
      });
    } else {
      parseMultipleMonthVisits(promiseResults);
    }

    // --- individual id filter
    if (filterByIndID) {
      list = lodash.filter(list, visit => {
        return (
          (visit.individualKey && visit.individualKey == filterByIndID) ||
          (visit.pickedIndKey && visit.pickedIndKey == filterByIndID)
        );
      });
    }

    // --- crossing type id filter
    if (filterByCrossingTypeID) {
      list = lodash.filter(
        list,
        visit =>
          visit.visitorType == filterByCrossingTypeID ||
          visit.studentVisitType == filterByCrossingTypeID
      );
    }

    callback(list);
  }

  /**
   * get start/destination locations from visit object
   * @param visit visit object
   * @param getLocation async function to retrieve location data with given ID (configured in High5 admin panel)
   * @param getKiosk async function to retrieve kiosk data with given ID (configured in High5 admin panel)
   * @param startLocation bool showing if you need start location in result or not
   * @param destLocation bool showing if you need destination location in result or not
   * @returns object containing required locations
   */
  // TODO: remove this method
  async getLocationsDetail(
    visit: any,
    getLocation: (key) => any,
    getKiosk: (key) => any,
    startLocation: boolean = true,
    destLocation: boolean = true,
    startLocationKey: boolean = true,
    destLocationKey: boolean = true
  ) {
    let result: any = {};
    if (!visit) return result;

    // concept used below for legacy data handling
    // SL = start location, DL = destination location
    // SL = kioskKey == "Touchless_Visitor_Tracking" || kioskKey == "demo" ? "Mobile Device" : kioskName
    // DL = location != "Mobile Device" ? location name : ""

    // --- start location
    if (startLocation) {
      // as per new structure
      if (visit.startLocation) result.startLocation = visit.startLocation;
      // handling legacy data
      else if (
        lodash.some(
          ["touchless_visitor_tracking", "demo"],
          i => i == lodash.toLower(visit.kioskKey)
        )
      )
        result.startLocation = "Mobile Device";
      else if (visit.kioskKey) {
        let [kioskData, err] = await this.commonService.executePromise(
          getKiosk(visit.kioskKey)
        );
        result.startLocation = lodash.get(kioskData, "name");
      }
    }

    // --- destination location
    if (destLocation) {
      // as per new structure
      if (visit.destLocation) result.destLocation = visit.destLocation;
      // handling legacy data
      else if (
        visit.location &&
        lodash.some(["mobile device"], i => i != visit.location)
      ) {
        let [locationData, err] = await this.commonService.executePromise(
          getLocation(visit.location)
        );
        visit.destLocation = lodash.get(locationData, "name");
      }
    }

    // --- start location key
    if (startLocationKey) result.startLocationKey = visit.kioskKey;

    // --- end location key
    if (destLocationKey && lodash.toLower(visit.location) != "mobile device")
      result.destLocationKey = visit.location;

    return result;
  }
}

export enum VisitStatus {
  WAITING_FOR_ENTRY_APPROVAL = 0,
  ENTRY_APPROVED_MANUAL = 1,
  ENTRY_REJECTED_MANUAL = 2,
  ENTRY_APPROVED_AUTO = 3, // --- used when visitor picks up a dependent

  WAITING_FOR_EXIT_APPROVAL = 0,
  EXIT_APPROVED_MANUAL = 1,
  EXIT_REJECTED_MANUAL = 2,
  EXIT_APPROVED_AUTO = 3
}
