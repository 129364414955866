import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from "src/environments/environment";

const config: AuthConfig = {
  issuer: "https://login.microsoftonline.com/common/v2.0",
  clientId: "618bbb33-190c-4e5a-8a36-23842d094a5f",
  redirectUri: window.location.origin + "/auth/sso-login",
  postLogoutRedirectUri: window.location.origin + "/auth/logout",
  responseType: "code",
  scope: "email openid profile offline_access",
  showDebugInformation: !environment.production,
  skipIssuerCheck: true,
  strictDiscoveryDocumentValidation: false
};

export default config;
