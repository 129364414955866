import { Injectable } from "@angular/core";
import { CommonServiceService } from "../common/common-service.service";
import { AngularFireDatabase } from "@angular/fire/database";
import lodash from "lodash";
import { BehaviorSubject, Observable, from } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import moment from "moment";

@Injectable({
  providedIn: "root"
})
export class PassesService {
  private basePath: string = "Transactions";

  constructor(
    private commonService: CommonServiceService,
    public db: AngularFireDatabase
  ) {}

  /**
   * helper method to verify pass object data
   * @param pass pass object
   */
  validatePass(pass: Pass) {
    if (!pass) throw new Error("Pass param required!");
    if (!pass.passTypeID) throw new Error("Pass must contain passTypeID!");
    if (!pass.createdTimestamp)
      throw new Error("Pass must contain createdTimestamp!");
    if (!pass.assignedBy) throw new Error("Pass must contain assignedBy");
  }

  /**
   * get organization current cycle
   * @param orgID organization id
   */
  orgCurrentCycleMap = {};
  async getOrgCurrentCycle(orgID: string) {
    if (!this.orgCurrentCycleMap[orgID]) {
      this.orgCurrentCycleMap[
        orgID
      ] = await this.commonService.getOrgCurrentCycle(orgID);
    }
  }

  /**
   * helper method to clean pass object by removing unnecessary props
   * @param pass pass object
   * @returns cleaned pass object which can be pushed to db
   */
  cleanupPass(pass: Pass) {
    let clone = lodash.cloneDeep(pass);
    delete clone.key;
    return clone;
  }

  /**
   * get path for passes
   * @param orgID organization id
   * @param passID pass id (Optional)
   * @returns relevant db path for passes
   */
  async getPath(
    orgID: string,
    passID?: string,
    cycle?: string
  ): Promise<string> {
    await this.getOrgCurrentCycle(orgID);
    // return `${this.basePath}/${orgID}/${cycle || this.orgCurrentCycleMap[orgID]}/passes/${indID}/${passID ? passID : ""}`;
    return `${this.basePath}/${orgID}/${cycle || this.orgCurrentCycleMap[orgID]}/passes_new/${passID ? passID : ""}`;
  }

  /**
   * create a new pass
   * @param indID individual id
   * @param orgID organization id
   * @param pass pass object
   */
  async addPass(indID: string, orgID: string, pass: Pass) {
    // --- params validation
    if (!indID || !orgID || !pass)
      throw new Error("addPass method params invalid!");
    this.validatePass(pass);

    // --- get db path
    let path = await this.getPath(orgID);

    // --- push pass to db
    let cleanedPass = this.cleanupPass(pass);
    let theneableRef = this.db.list(path).push(cleanedPass);
    await theneableRef;
    return theneableRef.key;
  }

  /**
   * update a pass
   * @param indID individual id
   * @param orgID organization id
   * @param pass pass object
   */
  async updatePass(indID: string, orgID: string, pass: Pass) {
    // --- params validation
    if (!indID || !orgID || !pass || !pass.key)
      throw new Error("updatePass method params invalid!");
    this.validatePass(pass);

    // --- get db path
    let path = await this.getPath(orgID, pass.key);

    // --- push pass to db
    let cleanedPass = this.cleanupPass(pass);
    await this.db.object(path).update(cleanedPass);
  }

  passesListSubject$ = new BehaviorSubject<any[]>([]);
  /**
   * get list of passes
   * @param indID individual id
   * @param orgID organization id
   */
  async getPasses(indID: string, orgID: string) {
    // --- params validation
    if (!indID || !orgID) throw new Error("getPasses method params invalid!");

    // --- get db path
    let path = await this.getPath(orgID);

    // --- read passes from db
    let passes = [];
    this.db.list(path, (ref) => ref.orderByChild('assignedTo').equalTo(indID)).snapshotChanges().subscribe((val) => {
      let passList = {};
      val.forEach((passSub: any) => {
        passList[passSub.key] = passSub.payload.val();
      });

      const startOfDay = moment().startOf('day');
      passes = [];
      if (!passList) return passes;
      lodash.forEach(passList, (pass, key) => {
        if (moment(pass.createdTimestamp).isAfter(startOfDay)) {
          passes.push({ ...pass, key });
        }
      });
      this.passesListSubject$.next(passes);
    })
  }

  staffPassList$ = new BehaviorSubject<any[]>([]);
   /**
   * get list of passes
   * @param indID individual id
   * @param orgID organization id
   */
    async getCurrentDayPasses(indID: string, orgID: string) {
      // --- params validation
      if (!indID || !orgID) throw new Error("getPasses method params invalid!");

      // --- get db path
      let path = await this.getPath(orgID);

      const startOfDay = moment().startOf('day').valueOf();
      // --- read passes from db
      let passesList: any[] = [];
      this.db.list(path, (ref) => ref.orderByChild('createdTimestamp').startAfter(startOfDay))
      .snapshotChanges()
      .subscribe((value) => {
        let passData = {};
        if (!passData) return [];
        passesList = value.map(pass => {
          let obj = pass.payload.val();
          obj['key'] = pass.key;
          return obj;
        })
        this.staffPassList$.next(passesList);
      })
    }

  // --- get pass data
  async getPass(orgID: string, indID: string, passID: string, cycle?: string) {
    // --- params validation
    if (!indID || !orgID) throw new Error("getPass method params invalid!");

    if (!cycle) await this.getOrgCurrentCycle(orgID);

    // --- get db path
    let path = await this.getPath(orgID, passID, cycle);

    // --- read passes from db
    let snapshot = await this.db.object(path).query.once("value");
    let passData = snapshot.val();
    if (!passData) return null;

    return { ...passData, key: passID };
  }

  // --- get pass requests db path
  async getPassRequestsPath(
    type: "pending" | "history",
    orgID: string,
    cycle?: string
  ): Promise<string> {
    if (!cycle) await this.getOrgCurrentCycle(orgID);
    return `${this.basePath}/${orgID}/${cycle ||
      this.orgCurrentCycleMap[orgID]}/passes-requests/${type}`;
  }

  /**
   * helper method to clean passRequest object by removing unnecessary props
   * @returns cleaned passRequest object which can be pushed to db
   */
  cleanupPassRequest(passRequest: PassRequest) {
    let clone = lodash.cloneDeep(passRequest);
    delete clone.key;
    return clone;
  }

  // --- request pass
  async requestPass(orgID: string, passRequest: PassRequest) {
    // --- params validation
    if (!orgID || !passRequest)
      throw new Error("requestPass method params invalid!");

    // --- get db path
    let path = await this.getPassRequestsPath("pending", orgID);

    // --- push pass to db
    let cleanedPassRequest = this.cleanupPassRequest(passRequest);
    let theneableRef = this.db.list(path).push(cleanedPassRequest);
    await theneableRef;
    return theneableRef.key;
  }

  async autoAprovePass(passRequest, indID, orgID) {

    if(
      !passRequest ||
      !passRequest.passTypeID ||
      !passRequest.startLocation ||
      !passRequest.startLocationKey ||
      passRequest.isRequiresApproval ||
      !passRequest.destLocation ||
      !passRequest.destLocationKey ||
      !passRequest.duration ||
      !passRequest.key
       ) return;
    // --- create pass
    let pass: Pass = {
      passTypeID: passRequest.passTypeID,
      assignedBy: indID,
      startLocation: passRequest.startLocation,
      startLocationKey: passRequest.startLocationKey,
      isRequiresApproval: passRequest.isRequiresApproval,
      destinationLocation: passRequest.destLocation,
      destinationLocationKey: passRequest.destLocationKey,
      createdTimestamp: moment().valueOf(),
      duration: passRequest.duration,
      requestID: passRequest.key,
      assignedToName: passRequest.assignedToName,
      assignedToPhoto: passRequest.assignedToPhoto,
      assignedTo: passRequest.requestedBy,
      assignedByName: passRequest.assignedByName
    };

    // --- assign pass to requester
    let [passID, errAssigningPass] = await this.commonService.executePromise(
      this.addPass(passRequest.requestedBy, orgID, pass)
    );

    // --- error handling
    if (errAssigningPass) {
      this.commonService.presentAlert(
        this.commonService.prepareErrorMessage(errAssigningPass),
        "Error"
      );
      console.log("errAssigningPass", errAssigningPass);
      return;
    }

    // --- move pass request to history
    passRequest.passID = passID;
    let [, errMovingPassReqToHistory] = await this.commonService.executePromise(
      this.movePassReqToHistory(orgID, passRequest)
    );
    if (errMovingPassReqToHistory) {
      this.commonService.presentAlert(
        this.commonService.prepareErrorMessage(errMovingPassReqToHistory),
        "Error"
      );
      console.log("errMovingPassReqToHistory", errMovingPassReqToHistory);
      return;
    }
  }

  // --- move pass request to history
  async movePassReqToHistory(orgID: string, passRequest: PassRequest) {
    if (!orgID || !passRequest || !passRequest.key)
      throw new Error("moveToHistory method params invalid!");

    // --- get db path
    let pendingPath = await this.getPassRequestsPath("pending", orgID);
    let historyPath = await this.getPassRequestsPath("history", orgID);

    // --- move to history
    let passReqKey = passRequest.key;
    let cleanedPassRequest = this.cleanupPassRequest(passRequest);
    await this.db
      .object(`${historyPath}/${passReqKey}`)
      .set(cleanedPassRequest);

    // --- delete from pending
    await this.db.object(`${pendingPath}/${passReqKey}`).remove();
  }

  // --- monitor pending pass requests
  async monitorPendingPassRequests(orgID: string, indID: string) {
    // --- params validation
    if (!orgID || !indID)
      throw new Error("monitorPendingPassRequests method params invalid!");

    let dbPath = await this.getPassRequestsPath("pending", orgID);

    return new Observable(observer => {
      let query = this.db
        .list(dbPath)
        .query.orderByChild("requestedFrom")
        .equalTo(indID);

      const listener = query.on("value", snapshot => {
        const data = snapshot.val();
        observer.next(data);
      });

      return () => {
        query.off("value", listener);
      };
    });
  }

  // --- monitor history pass request
  async monitorHistoryPassRequest(orgID: string, passReqKey: string) {
    // --- params validation
    if (!orgID || !passReqKey)
      throw new Error("monitorHistoryPassRequests method params invalid!");

    let dbPath = await this.getPassRequestsPath("history", orgID);

    return from(this.db.object(`${dbPath}/${passReqKey}`).valueChanges()).pipe(
      debounceTime(100)
    );
  }

  /**
   * modify pass object as per the page requirement
   * @param pass pass object
   * @returns modified pass object
   */
  updatePassObj(pass, passTypes) {
    if (!pass || !pass.passTypeID) return null;

    // // --- reset pass expireTime
    // pass.expireTime = null;
    pass.isExpired = false;

    // --- find passtype and attach it with pass object
    let passType = lodash.find(
      passTypes,
      (pt) => pt?.key == pass?.passTypeID
    );
    if (!passType) return null;
    pass.passType = passType;

    // --- check if pass is expired manually
    if (pass.manuallyMarkedExpiredBy) {
      pass.isExpired = true;
      return pass;
    }

    let time = moment(pass.createdTimestamp).add(pass.duration, 'minutes').valueOf();
    pass.isExpiredInTwoMinutes = this.compareTime(time);

    // --- check if pass is expired by time
    let passCreatedTimeMoment = moment(pass.createdTimestamp);
    let duration = pass.duration || passType.duration;
    let minAllowedTime = moment().subtract(duration, "minutes");
    let isValid = passCreatedTimeMoment.isSameOrAfter(minAllowedTime);
    if (!isValid) {
      pass.isExpired = true;
      pass.isExpiredInTwoMinutes = true;
      // return pass;
    }
    pass.isExpiredInTwoMinutes = false;
    // --- count expiring time and attach it with pass object
    pass.expireTime = null
    pass.expireTime = moment
      .duration(
        passCreatedTimeMoment.diff(minAllowedTime, "milliseconds"),
        "milliseconds"
      )
      .humanize(false);

    return pass;
  }


  compareTime(givenTimestamp: number): boolean {
    const currentTime = moment();
    const timeToCompare = moment(givenTimestamp);
    const difference = currentTime.diff(timeToCompare, 'minutes');
    return Math.abs(difference) < 2;
  }

  async approveRequest(passRequest: any, indID, orgID) {
    try {
      // --- create pass
      let pass: Pass = {
        passTypeID: passRequest.passTypeID,
        assignedBy: indID,
        startLocation: passRequest.startLocation,
        startLocationKey: passRequest.startLocationKey,
        destinationLocation: passRequest.destLocation,
        destinationLocationKey: passRequest.destLocationKey,
        isRequiresApproval: passRequest.hasOwnProperty('isRequiresApproval') ? passRequest.isRequiresApproval : true,
        createdTimestamp: moment().valueOf(),
        duration: passRequest.duration,
        requestID: passRequest.key,
        assignedTo: passRequest.requestedBy,
        assignedToName: passRequest.assignedToName,
        assignedToPhoto: passRequest.assignedToPhoto,
        assignedByName: passRequest.assignedByName,
      };

     // --- assign pass to requester
     let [passID, errAssigningPass] = await this.commonService.executePromise(
       this.addPass(passRequest.requestedBy, orgID, pass)
     );

     // --- error handling
     if (errAssigningPass) {
       this.commonService.presentAlert(
         this.commonService.prepareErrorMessage(errAssigningPass),
         "Error"
       );
       console.log("errAssigningPass", errAssigningPass);
       return;
      }

     // --- move pass request to history
     passRequest.passID = passID;
     let [, errMovingPassReqToHistory] = await this.commonService.executePromise(
       this.movePassReqToHistory(orgID, passRequest)
     );
     if (errMovingPassReqToHistory) {
       this.commonService.presentAlert(
         this.commonService.prepareErrorMessage(errMovingPassReqToHistory),
         "Error"
       );
       console.log("errMovingPassReqToHistory", errMovingPassReqToHistory);
       return;
     }
    } catch (error) {
     throw error
    }
   }
}

export class Pass {
  key?: string;
  passTypeID: string;
  createdTimestamp: number;
  assignedBy?: string;
  manuallyMarkedExpiredBy?: string;
  startLocation?: string;
  startLocationKey?: string;
  destinationLocation?: string;
  destinationLocationKey?: string;
  duration?: number;
  requestID?: string;
  isRequiresApproval?: boolean;
  expireTime?: number;
  assignedToPhoto?: string;
  assignedToName?: string;
  assignedByName?: string;
  assignedTo?: string;
}

export class PassRequest {
  key?: string;
  isRequiresApproval?: boolean;
  passTypeID: string;
  requestedBy: string;
  requestedFrom?: string;
  startLocation: string;
  startLocationKey: string;
  destLocation: string;
  destLocationKey: string;
  duration: number;
  requestedAt: number;
  passID?: string;
  assignedToPhoto?: string;
  assignedToName?: string;
  assignedByName?: string;
}
