import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthApiService } from "src/app/core/auth/auth.api.service";
import { getQueryParams } from "src/app/core/utils/queryParams.utils";
import lodash from "lodash";
import { CommonServiceService } from "src/app/core/common/common-service.service";
@Component({
  selector: "app-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
  styleUrls: ["./confirmation-dialog.component.scss"]
})
export class ConfirmationDialogComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;

  copyUrl: string = '';

  constructor(
    private activeModal: NgbActiveModal,
    private authApi: AuthApiService,
    private commonService: CommonServiceService
  ) {}

  ngOnInit(): void {
    if (this.btnOkText == "Copy Link") this.prepareCopyLink();
  }

  async prepareCopyLink() {
    let queryParams = getQueryParams();
    let orgID = queryParams.orgID;
    let indID = queryParams.indID;

    // --- prepare custom token
    let customToken, timestamp;
    if (orgID && indID) {
      let [
        customTokenRes,
        customTokenErr
      ] = await this.commonService.executePromise(
        this.authApi.getUserCustomToken(orgID, indID)
      );
      if (customTokenErr) {
        console.warn("Error generating custom token", customTokenErr);
      } else {
        customToken = lodash.get(customTokenRes, "token");
        timestamp = lodash.get(customTokenRes, "timestamp");
      }
    }

    // --- prepare query params
    if (customToken) queryParams.payload = customToken;
    if (timestamp) queryParams.timestamp = timestamp;
    queryParams.redir = "photo";

    // --- prepare URL to copy
    let queryString = $.param(queryParams);
    let urlToCopy = this.commonService.getCurrentURLRootPath();
    this.copyUrl = `${urlToCopy}/auth/tokenBasedSignIn?${queryString}`;
  }

  public decline() {
    if (this.btnCancelText == "Site Permission") {
      this.activeModal.close("Site Permission");
    } else {
      this.activeModal.close(false);
    }
  }

  // --- for copy to clipboard
  copyToClipboard(textToCopy) {
    this.btnOkText = "Copied!";
    var textArea;

    function isOS() {
      //can use a better detection logic here
      return navigator.userAgent.match(/ipad|iphone/i);
    }

    function createTextArea(text) {
      textArea = document.createElement("textArea");
      textArea.readOnly = true;
      textArea.contentEditable = true;
      textArea.value = text;
      document.body.appendChild(textArea);
    }

    function selectText() {
      var range, selection;

      if (isOS()) {
        range = document.createRange();
        range.selectNodeContents(textArea);
        selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 999999);
      } else {
        textArea.select();
      }
    }

    function copyTo() {
      document.execCommand("copy");
      textArea.blur()
      // document.body.removeChild(textArea);
    }

    createTextArea(textToCopy);
    selectText();
    copyTo();
    setTimeout(() => {
      this.btnOkText = "Copy Link"
    },2000)
  }

  public async accept() {
    // this.activeModal.close(true);
    if (this.btnCancelText == "Not Now") {
      this.activeModal.close(true);
    } else if (this.btnOkText == "App Permission") {
      this.activeModal.close("App Permission");
    } else if (this.btnCancelText == "Show Me") {
      this.activeModal.close("App Permission");
    } else if (this.btnOkText == "Send Now") {
      this.activeModal.close("Confirmed");
    } else if (this.btnOkText == "Yes") {
      this.activeModal.close("Confirmed");
    } else {
      this.btnOkText = "Copied!";
      setTimeout(() => {
        this.btnOkText = "Copy Link"
      },2000)
    }
  }

  public dismiss() {
    this.activeModal.close(false);
  }
}
