import { HttpClient } from "@angular/common/http";
import { forwardRef, Inject, Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import * as $ from "jquery";
import lodash from "lodash";
import moment from "moment";
import { take } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { ApiService } from "../api/api.service";
import { CommonServiceService } from "../common/common-service.service";
import {
  ElasticEndPoint,
  ExpandSupportingChar,
  ExpandSupportingChars
} from "../enums/general.enums";
import { Role } from "../enums/user.enums";
import { IndividualApiService } from "../individual/individual.api.service";
import {
  InheritanceService,
  ReplacementTags
} from "../inheritance/inheritance.service";
import { MessagesService } from "../messages/messages.service";
import { OrganizationsService } from "../organizations/organizations.service";
import { CountAgeFromDOB } from "../pipes/countAgeFromDOB/count-age-from-dob";
import { GetIndPhotoUsingPrefSettings } from "../pipes/getIndPhotoUsingPrefSettings/get-ind-photo-using-pref-settings.pipe";
import {
  ToDoManagerClass,
  ToDoManagerr,
  ToDoService
} from "../to-do/to-do.service";
import {
  copyFieldsInstructions,
  gradeFormats,
  tagContexts
} from "../utils/tagEvaluation.utils";
import firebase from "firebase/app";
import { ApiHelperService, CloudFnNames } from "../api-helper.service";

@Injectable({
  providedIn: "root"
})
export class UrlService {
  readonly msgSymbol: string = "~^";

  constructor(
    public db: AngularFireDatabase,
    public messagesService: MessagesService,
    private orgService: OrganizationsService,
    private commonService: CommonServiceService,
    public http: HttpClient,
    @Inject(forwardRef(() => ApiService)) public api: ApiService,
    @Inject(forwardRef(() => IndividualApiService))
    public individualApiService: IndividualApiService,
    public getIndPhotoUsingPrefSettings: GetIndPhotoUsingPrefSettings,
    public countAgeFromDOB: CountAgeFromDOB,
    private todoService: ToDoService,
    private inheritanceProvider: InheritanceService,
    private apiHelperService: ApiHelperService
  ) {}

  // --- get context requirements for tag evaluation
  // NOTE: this does not returns perfect requirements. It estimates requirements at its best. so use appropriately.
  getContextRqrmntsForTagEvaluation(str: string) {
    let strL = lodash.toLower(str);

    let rqrmnts = {};
    lodash.each(tagContexts, item => {
      let requires = item.requires;
      let regExp = item.ctx as RegExp;
      regExp.lastIndex = 0;
      let matches = regExp.exec(strL);
      if (matches != null && lodash.size(matches) > 0) {
        lodash.each(requires, r => lodash.set(rqrmnts, r, true));
      }
    });

    return rqrmnts;
  }

  // --- pre-process fields tags (eg. incoming SMS number should be used based on shipping country)
  preProcessFieldTag(tag: string, ctx: any) {
    let tagL = lodash.toLower(tag);

    if (tagL == "sys.incomingsms") {
      let shippingCountry = lodash.get(ctx, "org.shippingCountry");
      let isUSA = lodash.some(
        this.commonService.usaSynonymList,
        usaSynonym =>
          lodash.toLower(usaSynonym) == lodash.toLower(shippingCountry)
      );
      if (!shippingCountry || isUSA) tag = "sys.incomingSMSUSA";
    }

    return tag;
  }

  // --- pre-process tag evaluation context
  preProcessTagEvaluationContext(ctx: any) {
    if (!ctx) return;

    const img1px = environment.sampleImage;

    // --- for sportsteam, show blank ind photo for Participant instead of placeholder
    let orgType = lodash.get(ctx, "org.type");
    let indRole = lodash.get(ctx, "ind.role");
    let indPhotoURL = lodash.get(ctx, "ind.photoUrl");
    if (
      orgType == "sportsteam" &&
      indRole == "Participant" &&
      indPhotoURL == environment.placeholderImageUrl
    )
      ctx.ind.photoUrl = img1px;

    // --- do not allow cert stamp/sign if ind does not have proper certification status
    if (orgType == "sportsteam" && ctx.ind && ctx.org) {
      let indCertStatus = lodash.get(ctx, "ind.certificateStatus");

      // --- adjust org cert sign/stamp
      let orgCertStamp = lodash.get(ctx, "org.certificationStamp", img1px);
      let orgCertSign = lodash.get(ctx, "org.certificationSignature", img1px);
      let orgCertOfcrSign = lodash.get(
        ctx,
        "org.certificationOfficerSignature",
        img1px
      );
      if (indCertStatus < 100) {
        orgCertStamp = orgCertSign = orgCertOfcrSign = img1px;
      }
      lodash.set(ctx, "org.certificationStamp", orgCertStamp);
      lodash.set(ctx, "org.certificationSignature", orgCertSign);
      lodash.set(ctx, "org.certificationOfficerSignature", orgCertOfcrSign);

      // --- adjust partner org cert sign/stamp
      let partnerOrgCertStamp = lodash.get(
        ctx,
        "org.partnerOrg.certificationStamp",
        img1px
      );
      let partnerOrgCertSign = lodash.get(
        ctx,
        "org.partnerOrg.certificationSignature",
        img1px
      );
      if (indCertStatus < 100) {
        partnerOrgCertStamp = partnerOrgCertSign = img1px;
      }
      lodash.set(ctx, "org.partnerOrg.certificationStamp", partnerOrgCertStamp);
      lodash.set(
        ctx,
        "org.partnerOrg.certificationSignature",
        partnerOrgCertSign
      );

      // --- adjust org manager cert sign/stamp
      let orgMgrCertStamp = lodash.get(
        ctx,
        "orgMgr.certificationStamp",
        img1px
      );
      let orgMgrCertSign = lodash.get(
        ctx,
        "orgMgr.certificationSignature",
        img1px
      );
      let orgMgrCrtOfcrSign = lodash.get(
        ctx,
        "orgMgr.certificationOfficerSignature",
        img1px
      );
      if (indCertStatus < 100) {
        orgMgrCertStamp = orgMgrCertSign = orgMgrCrtOfcrSign = img1px;
      }
      lodash.set(ctx, "orgMgr.certificationStamp", orgMgrCertStamp);
      lodash.set(ctx, "orgMgr.certificationSignature", orgMgrCertSign);
      lodash.set(
        ctx,
        "orgMgr.certificationOfficerSignature",
        orgMgrCrtOfcrSign
      );
    }

    // --- copy values from one key to another
    lodash.each(copyFieldsInstructions, instruction => {
      let sources = instruction.source;
      let targets = instruction.target;

      // --- determine value from source
      let value;
      lodash.each(sources, source => {
        value = lodash.get(ctx, source);
        if (!lodash.isNil(value)) return false;
      });
      if (lodash.isNil(value) && lodash.has(instruction, "defVal"))
        value = instruction.defVal;

      // --- set value to target
      if (!lodash.isNil(value))
        lodash.each(targets, target => {
          lodash.setWith(ctx, target, value, Object);
        });
    });

    return ctx;
  }

  readonly tagDelimiter = "~^";
  readonly tagModifiers = lodash.map(ExpandSupportingChars, "value");

  // --- get tag finder regular expression
  private getTagFinderRegex() {
    let escapedTagDelimiter = lodash.escapeRegExp(this.tagDelimiter);
    let regexStr = `${escapedTagDelimiter}([^${escapedTagDelimiter}]*)${escapedTagDelimiter}`;
    return new RegExp(regexStr, "g");
  }

  // --- get regex modifiers regular expression
  private getTagModifiersRegex() {
    let tagModifiersStr = lodash
      .chain(this.tagModifiers)
      .map(lodash.escapeRegExp)
      .join("|")
      .value();
    let regexStr = `.+(${tagModifiersStr}).+`;
    return new RegExp(regexStr, "g");
  }

  // --- extract list of tags from given string
  // TODO: make this method private and remove usages
  extractTags(str: string) {
    let regex = this.getTagFinderRegex();
    let tags = [];
    let match;
    while ((match = regex.exec(str))) {
      tags.push(lodash.nth(match, 1));
    }
    return tags;
  }

  // --- replace tag evaluations in the source string
  private replaceTagsWithEvaluations(srcStr: string, evaluations: any[]) {
    let regex = this.getTagFinderRegex();
    return lodash.replace(srcStr, regex, () => evaluations.shift());
  }

  // --- replace field tags (eg. Sys.DIY_PURL_OrgID) with contents recursively
  private async evaluateFieldTags(
    str: string,
    fieldsByLTags: any,
    ctx: any,
    defValue: any
  ) {
    if (lodash.isNil(str)) return str;

    // --- extract tags from string
    let tags = this.extractTags(str);

    // --- evaluate fields tags
    let evaluateFieldTagsPromises = lodash.map(
      tags,
      tag =>
        new Promise(async resolve => {
          // --- pre-process field's tags
          tag = this.preProcessFieldTag(tag, ctx);

          let tagWODelimiter = tag;
          let tagWODelimiterL = lodash.toLower(tag);
          let tagWithDelimiter = `${this.tagDelimiter}${tagWODelimiter}${this.tagDelimiter}`;
          let tagWithDelimiterL = lodash.toLower(tagWithDelimiter);

          let tagFieldData = lodash.get(fieldsByLTags, tagWithDelimiterL);
          let tagValue;
          if (tagFieldData) {
            let tagFieldContent = tagFieldData.contents;
            tagValue = await this.evaluateFieldTags(
              tagFieldContent,
              fieldsByLTags,
              ctx,
              defValue
            );
          } else tagValue = tagWithDelimiter;

          // --- evaluate value tags
          tagValue = this.evaluateValueTags(tagValue, ctx, defValue);

          // --- minify URLs
          let shouldMinifyURL = lodash.get(tagFieldData, "isMinifiedUrl", true);
          if (ctx.preventUrlShortening) shouldMinifyURL = false;
          if (shouldMinifyURL) {
            tagValue = await this.linkify(tagValue);
          }

          // --- special actions on value tagwise (after evaluation)
          if (tagWODelimiterL == "background") {
            tagValue += `&quot;,&quot;H5_Tag&quot;:&quot;${tag}`;
          }
          if (tagWODelimiterL == "sys.indhashjson") {
            tagValue = lodash.replace(tagValue, /"/g, "&apos;");
            tagValue = lodash.replace(tagValue, /&/g, "&amp;");
          }

          return resolve(tagValue);
        })
    );
    let evaluatedFieldTags = await Promise.all(evaluateFieldTagsPromises);

    return this.replaceTagsWithEvaluations(str, evaluatedFieldTags);
  }

  // --- replace value tags (eg. ind.firstName) with contents
  private evaluateValueTags(str: string, context: any, defValue: any) {
    if (lodash.isNil(str)) return str;

    // --- extract tags from string
    let tags = this.extractTags(str);

    // --- lower case context keys
    let contextL = this.commonService.mapNestedKeys(context, (key: string) =>
      lodash.toLower(key)
    );

    let evaluatedValueTags = lodash.map(tags, tag => {
      let tagWODelimiter = tag;
      let tagWODelimiterL = lodash.toLower(tagWODelimiter);

      let tagModifierRegex = this.getTagModifiersRegex();
      let doesContainModifier = tagModifierRegex.test(tagWODelimiter);

      // --- extract value from context
      let value;
      let modifier;
      let modifierSymbol;
      let tagWOModifierL;
      if (!doesContainModifier) value = lodash.get(contextL, tagWODelimiterL);
      else {
        modifierSymbol = ExpandSupportingChar.StringMethod;
        let splits = lodash.split(tagWODelimiter, modifierSymbol);
        if (splits.length < 2) {
          modifierSymbol = ExpandSupportingChar.TimeFormat;
          splits = lodash.split(tagWODelimiter, "@@");
        }

        tagWOModifierL = lodash
          .chain(splits)
          .nth(0)
          .toLower()
          .value();
        modifier = lodash.nth(splits, 1);
        value = lodash.get(contextL, tagWOModifierL);
      }

      // --- replace double quotes with single quote
      value = lodash.replace(value, /"/g, "'");

      // --- replace & with &amp;
      value = lodash.replace(value, /&/g, "&amp;");

      // --- apply value modifiers
      if (modifierSymbol == ExpandSupportingChar.StringMethod && modifier) {
        switch (lodash.toLower(modifier)) {
          case "upper":
            value = lodash.toUpper(value);
            break;

          case "lower":
            value = lodash.toLower(value);
            break;

          case "firstletter":
            value = lodash
              .chain(value)
              .split("")
              .nth(0)
              .toUpper()
              .value();
            break;

          case "firstletterperiod":
            value =
              lodash
                .chain(value)
                .split("")
                .nth(0)
                .toUpper()
                .value() + ".";
            break;

          case "grade_a":
          case "grade_b":
          case "grade_c":
          case "grade_d":
          case "grade_e":
            value = lodash.get(
              gradeFormats,
              `${lodash.toLower(modifier)}.${lodash.toLower(value)}`
            );
            break;
        }
      } else if (
        modifierSymbol == ExpandSupportingChar.TimeFormat &&
        modifier &&
        value != ""
      ) {
        let sourceFormat = "x";
        if (tagWOModifierL == "ind.birth") sourceFormat = "YYYY/MM/DD";
        value = moment(value, sourceFormat).format(modifier);
      }

      if (lodash.isNil(value) || value == "") value = defValue;
      return value;
    });

    return this.replaceTagsWithEvaluations(str, evaluatedValueTags);
  }

  // --- evaluate string with tags
  async evaluateStrWithTags(
    str: string,
    fieldsList: any[],
    context: any,
    defValue: any = " "
  ) {
    if (!str) throw new Error("Params missing in evaluateStrWithTags method!");

    // --- if no tags present in string, return string
    if (!lodash.includes(str, this.tagDelimiter)) return str;

    // --- pre-process context (eg. copy values from one key to another)
    context = this.preProcessTagEvaluationContext(context);

    // --- evaluate fields tags from string (eg. Sys.DIY_PURL_OrgID)
    let fieldsByLTags = lodash.keyBy(fieldsList, fieldData =>
      lodash.toLower(fieldData.tag)
    );
    let fieldsEvaluatedStr = await this.evaluateFieldTags(
      str,
      fieldsByLTags,
      context,
      defValue
    );

    // --- evaluate value tags from string (eg. ind.firstName)
    let valuesEvaluatedStr = this.evaluateValueTags(
      fieldsEvaluatedStr,
      context,
      defValue
    );

    return valuesEvaluatedStr;
  }

  async minifyURL(short, url) {
    if (!short) return url;
    return await this.shortenUrl(url);
  }

  async shortenUrl(longUrl: string) {
    // --- prevent re-shortening short URL
    let shortURLRegex = /^https:\/\/high5.id\/p\/.{5}$/g;
    if (shortURLRegex.test(longUrl)) return longUrl;

    let url = longUrl;
    // url = url.replace(/%/g, "%25");
    // url = url.replace(/&/g, "%26");

    let shortUrlRes: any = await this.apiHelperService.postToCloudFn(
      CloudFnNames.getShortenURL,
      { url: url }
    );

    if (!shortUrlRes) {
      console.log("Error shortening URL");
      return longUrl;
    }

    return lodash.get(shortUrlRes, "result.data.shortUrl", longUrl);
  }

  // --- minify all URLs in given string
  async linkify(str: any) {
    let urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    let match;
    let minifyURLsPromises = [];
    while ((match = urlRegex.exec(str))) {
      minifyURLsPromises.push(this.minifyURL(true, match[0]));
    }
    let minifiedURLs = await Promise.all(minifyURLsPromises);

    return lodash.replace(str, urlRegex, () => minifiedURLs.shift());
  }

  readonly userDBNodes = {
    [Role.ORG]: "organizations",
    [Role.STUDIO]: "studios",
    [Role.SUPERADMIN]: null
  };
  readonly dbPathFields = `${ReplacementTags.USER_DB_NODE}/${ReplacementTags.USER_ID}/URLs`;

  // --- get list of fields by inheritance
  async getFieldsByInheritance(
    role: any,
    userID: string,
    usersData?: any,
    useCache: boolean = false
  ) {
    // --- params validation
    if (!role || (role != Role.SUPERADMIN && !userID))
      throw new Error("invalid params passed to getFieldsByInheritance call");

    // --- fetch fields data by inheritance
    let fieldsInheritedData = await this.inheritanceProvider.getByInheritance(
      role,
      userID,
      this.dbPathFields,
      "list",
      usersData,
      this.userDBNodes,
      null,
      useCache
    );

    // --- merge fields data based on inheritance
    let fieldsList = [];
    lodash.each(fieldsInheritedData, fieldData => {
      let ownerID = fieldData.ownerID;
      let userFieldsList = lodash.map(fieldData.data, (data: any, key) => {
        return { ...data, key, ownerID };
      });
      fieldsList = this.commonService.mergeTwoArrUsingKeyMatch(
        fieldsList,
        userFieldsList
      );
    });

    // --- remove fields with Override hide
    lodash.remove(
      fieldsList,
      fieldData => !fieldData || lodash.toLower(fieldData.Override) == "hide"
    );

    return fieldsList;
  }

  /**
   * get field data based on inheritance
   */
  async getFieldByInheritance(
    role: any,
    userID: string,
    tag: string,
    usersData?: any,
    useCache: boolean = false
  ) {
    // --- params validation
    if (!role || (role != Role.SUPERADMIN && !userID) || !tag)
      throw new Error("invalid params passed to getField call");

    // --- prepare query interceptor
    const queryInterceptor = {
      uniqueIdentifier: tag,
      execute: (dbQuery: firebase.database.Query) => {
        return dbQuery.orderByChild("tag").equalTo(tag);
      }
    };

    // --- fetch field data by inheritance
    let fieldInheritedData = await this.inheritanceProvider.getByInheritance(
      role,
      userID,
      this.dbPathFields,
      "object",
      usersData,
      this.userDBNodes,
      null,
      useCache,
      queryInterceptor
    );

    // --- find highest priority non-null data from the inherited data
    let fieldData: any = lodash.find(
      fieldInheritedData,
      userFieldData => userFieldData.data != null
    );

    if (!fieldData) return null;
    // --- process results
    let ownerID = fieldData.ownerID;
    let fieldDataa = lodash
      .chain(fieldData.data)
      .map((data: any, key) => {
        return { ...data, key, ownerID };
      })
      .first()
      .value();

    if (
      lodash
        .chain(fieldDataa)
        .get("Override")
        .toLower()
        .value() == "hide"
    )
      return null;

    return fieldDataa;
  }

  // --- generate fields list from custom json
  generateFieldsFromJSONStr(jsonStr: string) {
    let fields = [];
    try {
      let jsonObj = JSON.parse(jsonStr);
      fields = lodash.map(jsonObj, (value, key) => {
        return { tag: `~^${key}~^`, contents: value };
      });
    } catch (e) {}
    return fields;
  }

  doesHaveUrl(userInput) {
    let res = userInput.match(
      /(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=. ]+/gm
    );
    if (res == null) return false;
    else return true;
  }

  // --- get total messages count
  getTotalMsgCount(messages) {
    let smsCount = 0;
    messages.forEach(msg => {
      smsCount = smsCount + Math.ceil(msg.length / 160);
    });
    return smsCount;
  }

  updateSMSCountInLicense(orgID, licenseList): Promise<any> {
    return new Promise(resolve => {
      let sub = this.db
        .object(`/licenses/instances/${orgID}`)
        .valueChanges()
        .subscribe((res: {}) => {
          sub.unsubscribe();
          lodash.each(licenseList, (ele, index) => {
            let currentLicenseKey;
            lodash.each(res, (e, index) => {
              if (Object.keys(e).length == 1) {
                if (Object.keys(ele)[0] == Object.keys(res[index])[0])
                  currentLicenseKey = index;
              } else {
                let innerObj = res[index];
                lodash.each(innerObj, (e1, index1) => {
                  if (Object.keys(ele)[0] == index1) currentLicenseKey = index;
                });
              }
            });
            this.db
              .object(`/licenses/instances/${orgID}/${currentLicenseKey}`)
              .update(ele);
          });
          resolve("");
        });
    });
  }

  tagPrefixStr = "%recipient.";
  async sendLowBalanceSMSEmail(orgID: string) {
    // #1 read org email, phone AND all parents email, phone
    // # 1A - read org data
    let readOrgDataPromises = [];
    readOrgDataPromises.push(this.orgService.getOrgSecureData(orgID, false));
    readOrgDataPromises.push(
      this.orgService.getOrgData(orgID, ["parentList", "orgName"], true)
    );
    let [
      orgDataPromisesResults,
      orgDataPromisesErr
    ] = await this.commonService.executePromise(
      Promise.all(readOrgDataPromises)
    );
    if (orgDataPromisesErr) {
      return;
    }
    let orgSecData = orgDataPromisesResults[0];
    let orgData = orgDataPromisesResults[1];
    let orgAdminEmail;
    let orgParentsEmails = [],
      orgParentsPhones = [],
      orgParentsPhonesCountryCodes = [];
    orgAdminEmail = orgSecData ? orgSecData.email : null;

    let orgParents = lodash.get(orgData, "parentList", null);
    let orgName = lodash.get(orgData, "orgName", null);

    // # 1B - read parents data
    if (orgParents) {
      let parentIDs = lodash.map(orgParents, (value, parentID) => parentID);

      let fetchParentsDataPromises = [];
      const getStudioField = async (studioID, fieldName) => {
        let value = (
          await this.db
            .object(`studios/${studioID}/${fieldName}`)
            .query.once("value")
        ).val();
        return { studioID, [fieldName]: value };
      };
      lodash.each(parentIDs, parentID => {
        fetchParentsDataPromises.push(getStudioField(parentID, "email"));
        fetchParentsDataPromises.push(getStudioField(parentID, "adminPhone"));
      });
      let [
        fetchParentsDataPromisesRes,
        fetchParentsDataPromisesErr
      ] = await this.commonService.executePromise(
        Promise.all(fetchParentsDataPromises)
      );

      if (fetchParentsDataPromisesRes) {
        lodash.each(fetchParentsDataPromisesRes, parentData => {
          let email = lodash.get(parentData, "email");
          let phone = lodash.get(parentData, "adminPhone");
          if (email && this.commonService.emailRegx.test(email))
            orgParentsEmails.push(email);
          if (phone) {
            let wellFormattedNumber = this.commonService.getWellFormattedPhone(
              phone
            );
            let phoneNumber = this.commonService.getCountryCode(
              wellFormattedNumber
            );
            orgParentsPhonesCountryCodes.push(phoneNumber.countryCode);
            orgParentsPhones.push(phoneNumber.number);
          }
        });
      }
    }

    // #2 prepare & send messages to org
    let messagesForStudios = {
      sms: `IMPORTANT: The organization ${orgName} is about to run out of message credits. They will shortly be unable to send SMS/Email.`,
      email: `<h3>IMPORTANT</h3><br/><p>The organization ${orgName} is about to run out of message credits. They are sending email or SMS messages but will shortly be unable to continue.</p>`
    };
    let messagesForOrgAdmins = {
      sms: `IMPORTANT: Your message balance is less than 10% of the individual count for your org. Shortly you will be out of credits and won't able to send messages.`,
      email: `<h3>IMPORTANT</h3><br/><p>Your message balance is less than 10% of the individual count for your org. Shortly you will be out of credits and won't able to send messages. You can buy more credits from admin panel.</p>`
    };
    let phoneNumbers = orgParentsPhones;
    let countryCodes = orgParentsPhonesCountryCodes;
    let smsMessages = lodash.map(
      phoneNumbers,
      (number, index) => messagesForStudios.sms
    );

    const smsApiReqBody = {
      type: "sms",
      service: "twilio",
      emailSubject: [],
      emails: [],
      numbers: phoneNumbers,
      messages: smsMessages,
      countryCodes: countryCodes,
      orgName: orgName,
      timestamp: Number(moment().format("x"))
    };

    let emails = orgAdminEmail ? [orgAdminEmail] : [];
    emails = lodash.concat(emails, orgParentsEmails);
    let recipientVariables = {};
    lodash.each(emails, (email, index) => {
      if (orgAdminEmail && email == orgAdminEmail)
        recipientVariables[orgAdminEmail] = {
          emailContent: messagesForOrgAdmins.email
        };
      else
        recipientVariables[email] = { emailContent: messagesForStudios.email };
    });

    const emailApiReqBody = {
      from: environment.mailFromAddress,
      to: emails,
      subject: `Low message credits - ${orgName}`,
      text: `${this.tagPrefixStr}emailContent%`,
      recipient_variables: recipientVariables,
      ["v:org_id"]: orgID,
      ["v:is_from_test"]: !environment.production
    };

    let sendEmailsSMSsPromises = [];
    if (smsApiReqBody && smsApiReqBody.numbers.length > 0) {
      sendEmailsSMSsPromises.push(
        this.api
          .post(environment.smsObj.endPoint, smsApiReqBody, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
            },
            responseType: "text"
          })
          .pipe(take(1))
          .toPromise()
      );
    }
    if (emailApiReqBody && emailApiReqBody.to.length > 0) {
      sendEmailsSMSsPromises.push(
        this.api
          .post(environment.mailgunObj.endPoint, {
            data: [emailApiReqBody],
            type: "single",
            emailType: "emailHtml"
          })
          .pipe(take(1))
          .toPromise()
      );
    }
    if (sendEmailsSMSsPromises.length == 0) {
      console.log(
        "No SMS or Emails data found to send warning about low credit."
      );
      return;
    }
    let [
      sendEmailsSMSsPromisesRes,
      sendEmailsSMSsPromisesErr
    ] = await this.commonService.executePromise(
      Promise.all(sendEmailsSMSsPromises)
    );
    if (sendEmailsSMSsPromisesErr) {
      return;
    }
  }

  // --- send low balance reminder to org
  async sendLowBalanceReminderToOrg(orgID: string) {
    if (!orgID) throw new Error("orgID is required");
    // --- send email & SMS to admin & all parents
    this.sendLowBalanceSMSEmail(orgID);

    // --- fetch current cycle of org
    let currentCycle = await this.commonService.getOrgCurrentCycle(
      orgID,
      Role.ORG,
      "value"
    );

    // --- prepare to-do task object
    let lowCreditsToDoTask = {
      manager: ToDoManagerr.LICENSE,
      class: ToDoManagerClass.LICENSE.LOW_CREDIT_BALANCE,
      title: "Low Credit Balance",
      desc:
        "Your message balance is less than 10% of the individual count for your organization. Click the above button to refill.",
      severity: 15,
      privateDetails: {
        orgID: orgID,
        pageToOpen: "AddConsumablesPage"
      }
    };

    await this.commonService.executePromise(
      this.todoService.setToDoTask(
        orgID,
        Role.ORG,
        currentCycle,
        lowCreditsToDoTask,
        true
      )
    );
  }

  // --- get total ind count
  getTotalIndCount(orgId: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      // let body = { query: { bool: { filter: { term: { orgId: `${orgId}` } } } } }
      let body = { hashCode: ElasticEndPoint.TOTAL_IND_COUNT, orgID: orgId };
      let res: any = await this.http
        .post(
          `${environment.newApiBaseUrl}${environment.elasticSearch.endPoint}`,
          this.commonService.prepareElasticReqBodyNew(body),
          { responseType: "text" }
        )
        .toPromise();
      let elasticResponse = this.commonService.decryptResponse(res);

      if (elasticResponse.success && elasticResponse.response) {
        resolve({
          success: true,
          indCount: elasticResponse.response.count || 0
        });
      } else {
        resolve({ success: false, message: "Individual data not found" });
      }
    });
  }
}
