import { HttpErrorResponse, HttpRequest } from "@angular/common/http";
import lodash from "lodash";

export class CustomError extends Error {
  message: string;
  extraDetails?: string;

  constructor(message, extraDetails?) {
    super(message);

    // restore prototype chain
    const actualProto = new.target.prototype;

    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, actualProto);
    } else {
      CustomError.prototype = actualProto;
    }

    this.message = message;
    this.extraDetails = extraDetails;
  }
}

export class CloudFnV2Error {
  httpRequest: HttpRequest<any>;
  httpError: HttpErrorResponse;

  constructor(httpRequest: HttpRequest<any>, httpError: HttpErrorResponse) {
    this.httpRequest = httpRequest;
    this.httpError = httpError;
  }

  getErrorMessage() {
    let errMsg = lodash.get(this.httpError, "error.error.message", null);
    if (!errMsg) errMsg = this.httpError.message;
    return errMsg;
  }

  getReqBody(): string {
    try {
      return JSON.stringify(this.httpRequest.body);
    } catch (e) {
      return null;
    }
  }

  getAuthHeader(): string {
    try {
      return JSON.stringify(this.httpRequest.headers.get("Authorization"));
    } catch (e) {
      return null;
    }
  }

  getResBody(): string {
    try {
      return JSON.stringify(this.httpError.error);
    } catch (e) {
      return null;
    }
  }

  getFailedRulesIDs(): string[] {
    return (
      lodash.get(
        this.httpError,
        "error.error.details.ruleCheckerResult.error.failedRules",
        []
      ) || []
    );
  }

  toString(): string {
    return `${this.getErrorMessage()} :: ${this.getReqBody()} :: ${this.getAuthHeader()} :: ${this.getResBody()}`;
  }
}
