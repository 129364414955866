import { Pipe, PipeTransform } from '@angular/core';
import lodash from "lodash";

@Pipe({
  name: 'viewAnon'
})
export class ViewAnonPipe implements PipeTransform {

  transform(data: any, first: number, last: number): string {
    let value = String(data);
    if(value.length > 2) {
        var firstChar = value.substring(0, first);
        var lastChar = value.substring(value.length - last);

        var mask = value.substring(first, value.length - last).replace(/./g,"*");
        value = lodash.cloneDeep(`${firstChar}${mask}${lastChar}`); 
    } 
    return value;
}

}
