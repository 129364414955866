import { Pipe, PipeTransform } from '@angular/core';
import lodash from "lodash";

@Pipe({
  name: 'isShowPhoto'
})
export class IsShowPhotoPipe implements PipeTransform {

  transform(currentInd: any, indList: any[]): boolean {
    let name = String(`${currentInd.firstName} ${currentInd.lastName}`).trim().toLowerCase();
    indList = lodash.cloneDeep(indList.filter(ind => {return ind.key != currentInd.key}))
    let count = 0;
    for (let i = 0; i < indList.length; i++) {
      const ind = indList[i];
      if(String(`${ind.firstName} ${ind.lastName}`).trim().toLowerCase() == name) {
        count = 1;
      }
    }
    if(count == 0) {
      return false 
    } else {
      return true;
    }

  }

}
