import { Component, Input, OnInit } from "@angular/core";
import { CommonServiceService } from "src/app/core/common/common-service.service";
import { Language, LocalStorageKeys } from "src/app/core/enums/general.enums";
import { LocalStorageService } from "src/app/core/storage/local-storage.service";

@Component({
  selector: "app-language-chooser",
  templateUrl: "./language-chooser.component.html",
  styleUrls: ["./language-chooser.component.scss"]
})
export class LanguageChooserComponent implements OnInit {
  @Input() styles: any = {};

  constructor(
    private commonService: CommonServiceService,
    private localStorageService: LocalStorageService
  ) {}

  selectedLang: any;
  ngOnInit(): void {
    this.selectedLang =
      this.localStorageService.getItem(LocalStorageKeys.lang) || Language.EN;
  }

  /**
   * triggers when user makes dropdown item selection
   * @param position position of selected item
   */
  onSelection(position) {
    this.selectedLang = position == 0 ? Language.EN : Language.ES;
    this.localStorageService.setItem(LocalStorageKeys.lang, this.selectedLang);
    this.commonService.setAppLanguage(this.selectedLang);
  }
}
