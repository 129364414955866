import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'getPassStatus'
})
export class GetPassStatusPipe implements PipeTransform {

  transform(pass: any) {
    if (pass && (!pass.createdTimestamp || pass.manuallyMarkedExpiredBy || pass.duration == 'choose')) return "Expired";

    let createdTimestampMoment = moment(pass.createdTimestamp);
    let duration = pass.duration;
    let minAllowedTime = moment().subtract(duration, "minutes");
    pass.expiringInMillis = createdTimestampMoment.diff(
      minAllowedTime,
      "milliseconds"
    );
    if (pass.expiringInMillis <= 0) {
      return "Expired";
    }

    return "Valid";
  }

}
