import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import UserAuth from "./userAuth.class";
import { setQueryParams, getQueryParams } from "../utils/queryParams.utils";
import {
  AccessRole,
  IndexedDBKeys,
  LocalStorageKeys
} from "../enums/general.enums";
import { LocalStorageService } from "../storage/local-storage.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment.js";
import { AngularFireAuth } from "@angular/fire/auth";
import { CommonServiceService } from "../common/common-service.service";
import { take } from "rxjs/operators";
import { EventsService } from "../events.service";
import { ApiHelperService, CloudFnNames } from "../api-helper.service";
import { AngularFireDatabase } from "@angular/fire/database";
import lodash from "lodash";
import { EncryptionService } from "../encryption/encryption.service";
import { IndividualApiService } from "../individual/individual.api.service";
import { IndexedDBService } from "../IndexedDB/indexed-db.service";
import { AuthDependency } from "../interface/types";
import { SSOService } from "../sso/sso.service";
@Injectable({
  providedIn: "root"
})
export class AuthApiService {
  isAuthStateReady = false;

  user$: Observable<UserAuth>;
  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private afAuth: AngularFireAuth,
    private ssoService: SSOService,
    private commonService: CommonServiceService,
    private eventsService: EventsService,
    private apiHelperService: ApiHelperService,
    private db: AngularFireDatabase,
    private encryptionService: EncryptionService,
    private indexedDBService: IndexedDBService
  ) {
    // TODO::remove this user$
    this.user$ = new Observable<UserAuth>(observer => {
      let userAuth = getQueryParams();
      if (userAuth.orgID && userAuth.indID) {
        observer.next(userAuth);
        observer.complete();
      } else {
        observer.next(null);
        observer.complete();
      }
    });
  }

  // --- check if authenticated user is logged in currently
  private async isUserLoggedIn() {
    let authState = await this.getAuthState();
    if (!authState || authState.isAnonymous) return false;
    return true;
  }

  // --- check if given claims proves that user is teacher
  isTeacher(claimsAR: string) {
    let accessRoles = lodash.split(claimsAR, ",");
    return lodash.includes(accessRoles, AccessRole.TRUSTED_ADULT_TE);
  }

  // --- check if given claims proves that user is guardian
  isGuardian(claimsAR: string) {
    let accessRoles = lodash.split(claimsAR, ",");
    return lodash.includes(accessRoles, AccessRole.TRUSTED_ADULT_GU);
  }

  // --- check if user is guardian (based on their claims)
  async isUserGuardian() {
    let isLoggedIn = await this.isUserLoggedIn();
    if (!isLoggedIn) return false;

    let claims = await this.getUserCustomClaim();
    let claimsAR = lodash.get(claims, "AR");
    let claimsIID = lodash.get(claims, "IID");
    return claimsIID && this.isGuardian(claimsAR);
  }

  // --- check if the given user is logged in currently
  async isGivenUserLoggedIn(orgID: string, indID: string) {
    if (!orgID || !indID) return false;

    let isLoggedIn = await this.isUserLoggedIn();
    if (!isLoggedIn) return false;

    let claims = await this.getUserCustomClaim();
    let claimsOID = lodash.get(claims, "OID");
    let claimsIID = lodash.get(claims, "IID");
    return orgID == claimsOID && indID == claimsIID;
  }

  // --- get current user data (eg. orgID, indID)
  async getCurrentUserData() {
    let [claims, _] = await this.commonService.executePromise(
      this.getUserCustomClaim()
    );
    let orgID = lodash.get(claims, "OID");
    let indID = lodash.get(claims, "IID");
    return { orgID, indID };
  }

  // --- triggers when user gets logged in
  onUserLoggedIn(orgID: string, indID: string) {
    // --- increase activity count for dashboard
    if (orgID) {
      this.http
        .post(
          `${environment.firebaseFunctionsBaseUrl}/incrementActivityCount`,
          {
            orgID: orgID,
            type: "ppLogin"
          }
        )
        .toPromise();
    }
  }

  /**
   * Prepare logout redirection URL 
   * if redirectUrl is provided, it will be used
   * otherwise prepare from redirectEndpoint and redirectQueryParams
   * @param redirectUrl redirection full URL
   * @param redirectEndpoint @default /auth redirection endpoint
   * @param redirectQueryParams redirection query params (default is current query params)
   */
  private prepareLogoutRedirectionUrl(
    redirectUrl?: string,
    redirectEndpoint?: string,
    redirectQueryParams?: any
  ) {
    if (redirectUrl) return redirectUrl;

    // --- use default redirect endpoint if not provided
    if (!redirectEndpoint) redirectEndpoint = "/auth";

    // --- use current query params if not provided
    if (redirectQueryParams === undefined)
      redirectQueryParams = setQueryParams(getQueryParams()).queryParams;

    return this.commonService.generateUrl(redirectEndpoint, redirectQueryParams);
  }

  /**
   * logout user
   * @param redirectEndpoint redirection endpoint
   * @param redirectQueryParams redirection query params
   * @param redirectUrl redirection full URL
   */
  async logout(
    redirectEndpoint?: string,
    redirectQueryParams?: any,
    redirectUrl?: string,
  ) {
    this.localStorageService.removeItem(
      LocalStorageKeys.isPWAInstallationDetectedBefore
    ); // clear PWA installation data
    this.localStorageService.removeItem("isShowVisitorMsg")
    this.commonService.executePromise(
      this.indexedDBService.deleteItem(IndexedDBKeys.CachedID)
    ); // clear cached ID
    this.localStorageService.removeItem(LocalStorageKeys.H5Trace); // clear high5 trace
    //  clear pass report filter data
    this.localStorageService.removeItem('startLocation')
    await this.afAuth.signOut();

    // --- prepare redirection URL
    let redirUrl = this.prepareLogoutRedirectionUrl(
      redirectUrl,
      redirectEndpoint,
      redirectQueryParams
    );

    // --- SSO sign out
    if (this.ssoService.idToken) {
      await this.ssoService.ssoLogout(redirUrl);
      return;
    }

    window.location.href = redirUrl
  }

  // --- sign anonymous user
  signInAnonymously() {
    return this.afAuth.signInAnonymously();
  }

  // --- get current user auth state
  getAuthState() {
    return this.afAuth.authState.pipe(take(1)).toPromise();
  }

  // --- force refresh ID token of current user
  async refreshIDToken() {
    let currentUser = await this.afAuth.currentUser;
    return currentUser.getIdToken(true);
  }

  // get current user custom claim
  async getUserCustomClaim() {
    let claims = null;

    let currentUser = await this.afAuth.currentUser;
    if (currentUser && currentUser.getIdTokenResult()) {
      claims = await currentUser.getIdTokenResult();
      claims = lodash.get(claims, "claims");
    }

    return claims;
  }

  // --- get user custom token
  /**
   * get user custom token
   * @param orgID organization ID
   * @param indID individual ID
   */
  async getUserCustomToken(
    orgID: string,
    indID: string,
    authDependencies?: AuthDependency[],
    ssoIdToken?: string
  ) {
    if (!orgID || !indID) throw new Error("Params missing!");

    // --- get ind email to sign in
    let reqBody = { orgID, indID, authDependencies, ssoIdToken };
    let response = await this.apiHelperService.postToCloudFn(
      CloudFnNames.getCustomToken,
      reqBody
    );
    return lodash.get(response, "result.data");
  }

  // --- get user ID token
  async getUserIDToken(
    orgID: string,
    indID: string,
    authDependencies?: AuthDependency[]
  ) {
    if (!orgID || !indID) throw new Error("Params missing!");

    // --- get ind email to sign in
    let reqBody = { orgID, indID, authDependencies };
    let response = await this.apiHelperService.postToCloudFn(
      CloudFnNames.getIdTokenUsingId,
      reqBody
    );
    return lodash.get(response, "result.data");
  }

  isValidCustomToken(token: string) {
    return lodash.split(token, ".").length == 3;
  }

  // -- wait for post process of ind creation to be completed (eg. firebase auth user) login process
  waitForIndCreationPostPrcs(orgID: string, indID: string) {
    let waitForPostPrcsPromise = new Promise(async resolve => {
      let hash = this.encryptionService.sha256CryptoJs(`${orgID}${indID}`);
      let sub = this.db
        .object(`postIndProcessDone/${hash}/postIndPrcsDoneAt`)
        .valueChanges()
        .subscribe(timestamp => {
          if (timestamp) {
            if (sub && !sub.closed) sub.unsubscribe();
            return resolve(true);
          }
        });
    });
    let timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => {
        return reject(new Error("Post login process timed out!"));
      }, 15000);
    });
    return Promise.race([waitForPostPrcsPromise, timeoutPromise]);
  }

  async isUserAllowedToSetPW(orgID: string, indID: string): Promise<boolean> {
    let claimData: any = await this.getUserCustomClaim();

    if (
      !claimData ||
      !claimData.GLC ||
      claimData.GLC.OID != orgID ||
      claimData.GLC.IID != indID ||
      !lodash.has(claimData.GLC, "QC")
    )
      return false;

    let questionCodes = lodash
      .chain(claimData.GLC.QC)
      .split(",")
      .filter(qc => !lodash.isNil(qc) && qc != "")
      .value();
    return lodash.every(
      questionCodes,
      qc => lodash.get(claimData.GLC, qc) == true
    );
  }

  // --- helper method to sign in user (with pre/post login processes)
  async signIn(
    method: "emailPass" | "customToken",
    services: { individualApi: IndividualApiService },
    inputs: {
      pwOrToken: string;
      isAutoLogin?: boolean;
      orgID?: string;
      indID?: string;
    }
  ) {
    if (!inputs.isAutoLogin) inputs.isAutoLogin = false;
    // --- read currently logged in anonymous user id (useful to delete later on)
    let beforeSignInAuthState = await this.getAuthState();
    let anonymousUid = "";
    if (beforeSignInAuthState && beforeSignInAuthState.isAnonymous)
      anonymousUid = beforeSignInAuthState.uid;

    // --- input validation
    if (method == "emailPass" && (!inputs.orgID || !inputs.indID))
      throw new Error("Params missing to signin!");

    if (method == "emailPass") {
      // --- prepare credentials to login
      let email = await services.individualApi.getIndEmail(
        inputs.orgID,
        inputs.indID,
        inputs.pwOrToken,
        inputs.isAutoLogin
      );
      let credentials = {
        email: email,
        password: inputs.pwOrToken
      };

      // --- sign in with email/password
      await this.signInWithEmailAndPassword(
        credentials.email,
        credentials.password
      );
    } else if (method == "customToken") {
      await this.signInWithCustomToken(inputs.pwOrToken);
    }

    // --- publish event with new auth state
    let aftrSignInAuthState = await this.getAuthState();
    this.eventsService.publish("user:authStateReady", aftrSignInAuthState);

    // --- trigger post-logged in process
    this.onUserLoggedIn(inputs.orgID, inputs.indID);

    // --- remove anonymous user
    if (anonymousUid)
      await this.commonService.executePromise(this.removeAnnUser(anonymousUid));

    return true;
  }

  // Sign in with email and password
  private signInWithEmailAndPassword(email: string, password: string) {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  private signInWithCustomToken(customToken: string) {
    return this.afAuth.signInWithCustomToken(customToken);
  }

  // --- remove annonymous user
  private async removeAnnUser(uid: string) {
    let [, removeUserError] = await this.commonService.executePromise(
      this.apiHelperService.postToCloudFn(CloudFnNames.removeAnnUser, {
        uid
      })
    );
    return removeUserError ? false : true;
  }

  // --- wait for auth state to get ready
  async waitForAuthStateToGetReady() {
    let random = Math.floor(Math.random() * 1000);
    this.commonService.appendLog(
      `Waiting for auth state to get ready! ${random}`
    );
    let waitForAuthStatePromise = new Promise(resolve => {
      if (this.isAuthStateReady) return resolve(true);

      this.eventsService.subscribe("user:authStateReady", () => {
        this.commonService.appendLog(`Auth state ready! ${random}`);
        return resolve(true);
      });
    });
    let timeoutJob;
    let timeoutPromise = new Promise((_, reject) => {
      timeoutJob = setTimeout(() => {
        return reject(new Error("Auth state timed out!"));
      }, 30 * 1000);
    });

    let [res, err] = await this.commonService.executePromise(
      Promise.race([waitForAuthStatePromise, timeoutPromise])
    );
    if(timeoutJob) clearTimeout(timeoutJob)
    
    if (err) {
      this.commonService.appendLog(
        `${this.commonService.prepareErrorMessage(err)} ${random}`
      );
      this.commonService.reportLogs();
      throw err;
    }
    return res;
  }

  // --- get challenges for selected ind
  async getChallenges(orgID: string, indID: string) {
    let challengesData = await this.apiHelperService.postToCloudFn(
      CloudFnNames.getChallengesForInd,
      {
        orgID,
        indID
      }
    );
    return lodash.get(challengesData, "result.data");
  }
}
