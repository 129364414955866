import { Injectable } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { JwksValidationHandler } from "angular-oauth2-oidc-jwks";
import googleConfig from "config/sso/google/config";
import microsoftConfig from "config/sso/microsoft/config";
import { filter, take } from "rxjs/operators";
import { CommonServiceService } from "../common/common-service.service";
import { SSOProvider } from "../enums/general.enums";
import { LocalStorageService } from "../storage/local-storage.service";

@Injectable({
  providedIn: "root"
})
export class SSOService {
  constructor(
    private localStorageService: LocalStorageService,
    private oAuthService: OAuthService,
    private commonService: CommonServiceService
  ) {}

  get provider() {
    return this.localStorageService.getItem("ssoProvider") as SSOProvider;
  }

  set provider(provider: SSOProvider) {
    this.localStorageService.setItem("ssoProvider", provider);
  }

  // --- get id token for currently logged in sso user
  get idToken() {
    return this.oAuthService.getIdToken();
  }

  // --- get id token claims for currently logged in sso user
  get idClaims() {
    return this.oAuthService.getIdentityClaims() as any;
  }

  // --- get sso user email
  get ssoUserEmail() {
    let idClaims = this.idClaims;
    if (!idClaims) return null;
    if (idClaims.email) return idClaims.email;
    if (
      idClaims.preferred_username &&
      this.commonService.isEmailValid(idClaims.preferred_username)
    )
      return idClaims.preferred_username;
    return null;
  }

  // --- get afterSSOLoginUrl to redirect to after SSO login
  get afterSSOLoginUrl() {
    return this.localStorageService.getItem("afterSSOLoginURL");
  }

  // --- set afterSSOLoginUrl to redirect to after SSO login
  set afterSSOLoginUrl(url: string) {
    if (url) this.localStorageService.setItem("afterSSOLoginURL", url);
    else this.localStorageService.removeItem("afterSSOLoginURL");
  }

  // --- get afterSSOLogoutUrl to redirect to after SSO logout
  get afterSSOLogoutUrl() {
    return this.localStorageService.getItem("afterSSOLogoutURL");
  }

  // --- set afterSSOLogoutUrl to redirect to after SSO logout
  set afterSSOLogoutUrl(url: string) {
    if (url) this.localStorageService.setItem("afterSSOLogoutURL", url);
    else this.localStorageService.removeItem("afterSSOLogoutURL");
  }

  // --- wait for discovery document
  async waitForDiscoveryDocument() {
    await this.oAuthService.events
      .pipe(
        filter(e => e.type === "discovery_document_loaded"),
        take(1)
      )
      .toPromise();
    return;
  }

  // --- in OIDC, the SSO authcode/token is received in the URL query params
  // this function tries to extract the info & login the user (after sso redirects user back to the app)
  async trySSOLogin() {
    return await this.oAuthService.tryLogin();
  }

  // --- configure SSO service
  async configureSSO() {
    // --- determine SSO provider configs
    let config =
      this.provider === SSOProvider.MICROSOFT ? microsoftConfig : googleConfig;

    let isAlreadyConfigured = this.oAuthService.issuer === config.issuer;

    // --- if its already configured, meaning document discovery load is in progress
    // --- we're not providing jwks externally, so if its not yet set, it means we need to wait until document gets loaded
    if (isAlreadyConfigured && !this.oAuthService.jwks)
      await this.waitForDiscoveryDocument();

    // --- prevent configuring for the same issuer again
    if (isAlreadyConfigured) return;

    // --- configure SSO service
    this.oAuthService.configure(config);
    this.oAuthService.tokenValidationHandler = new JwksValidationHandler();
    await this.oAuthService.loadDiscoveryDocument();

    // --- set logout url (if not already set from discovery document)
    // note: google is not providing logout url in discovery document
    if (!this.oAuthService.logoutUrl)
      this.oAuthService.logoutUrl = window.location.origin + "/auth/logout";
  }

  // --- initiate SSO login (this redirects to SSO provider login page)
  async initiateSSOSignin() {
    // store current url, so that after SSO login, the user is redirected back to this url
    this.afterSSOLoginUrl = window.location.href;
    await this.oAuthService.initLoginFlow();
  }

  // --- perform SSO logout
  async ssoLogout(redirUrl) {
    this.afterSSOLogoutUrl = redirUrl; // store the url to redirect to after SSO logout
    try {
      // --- add logout_hint for microsoft logout
      let logoutHint = this.idClaims?.login_hint;
      let logoutUrl = this.oAuthService.logoutUrl;
      if (logoutUrl && logoutHint && this.provider === SSOProvider.MICROSOFT) {
        this.oAuthService.logoutUrl =
          logoutUrl +
          (logoutUrl.includes("?") ? "&" : "?") +
          `logout_hint=${logoutHint}`;
      }

      if (this.oAuthService.revocationEndpoint)
        await this.oAuthService.revokeTokenAndLogout();
      else await this.oAuthService.logOut();
    } catch (e) {
      window.location.href = window.location.origin + "/auth/logout";
    }
  }
}
