import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { CommonServiceService } from "src/app/core/common/common-service.service";
import { Role } from "src/app/core/enums/user.enums";
import { PassTypesService } from "src/app/core/pass-types/pass-types.service";
import {
  PassRequest,
  PassesService
} from "src/app/core/passes/passes.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-mpass-request-modal",
  templateUrl: "./pass-request-modal.component.html",
  styleUrls: ["./pass-request-modal.component.scss"]
})
export class PassRequestModalComponent implements OnInit {
  isLoading = false;
  @Input() orgID: string;
  @Input() indID: string;
  @Input() passRequest: PassRequest;
  indInfoModalRef: NgbModalRef;

  constructor(
    private activeModal: NgbActiveModal,
    private passTypesService: PassTypesService,
    private passesService: PassesService,
    private commonService: CommonServiceService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  // --- decline pass request
  async declineRequest() {
    this.isLoading = true;

    // --- move pass request to history
    let [, errMovingPassReqToHistory] = await this.commonService.executePromise(
      this.passesService.movePassReqToHistory(this.orgID, this.passRequest)
    );
    if (errMovingPassReqToHistory) {
      this.isLoading = false;
      this.commonService.presentAlert(
        this.commonService.prepareErrorMessage(errMovingPassReqToHistory),
        "Error"
      );
      console.log("errMovingPassReqToHistory", errMovingPassReqToHistory);
      return;
    }

    this.isLoading = false;
    this.closeModal();
  }

  // --- approve pass request
  async approveRequest() {
    this.isLoading = true;
    try {
      await this.passesService.approveRequest(
        this.passRequest,
        this.indID,
        this.orgID
      );
    this.isLoading = false;
    this.closeModal();

    } catch (error) {
      this.commonService.presentAlert(
        this.commonService.prepareErrorMessage(error),
        "Error"
      );
    }
  }

  // --- initialization
  passTypeData: any;
  passDurations: any[] = [];
  async init() {
    if (!this.orgID || !this.indID || !this.passRequest) this.closeModal();

    this.isLoading = true;
    let readDataPromises = [];

    // --- read pass type data
    readDataPromises.push(
      this.passTypesService.getPassTypes(
        Role.ORG,
        this.orgID,
        undefined,
        true,
        this.passRequest.passTypeID
      )
    );

    // --- resolve readDataPromises
    let [results, err] = await this.commonService.executePromise(
      Promise.all(readDataPromises)
    );
    if (err) {
      this.commonService.presentAlert(
        this.commonService.prepareErrorMessage(err),
        "Error"
      );
      this.closeModal();
      return;
    }

    this.passTypeData = results[0];
    this.passDurations = this.passTypesService.durations;
    this.isLoading = false;
  }

  ngOnInit(): void {
    this.init();
  }

  closeModal() {
    this.activeModal.close();
  }

  moreDetails(){
    this.closeModal();
    this.router.navigate(["passes/assign-pass"], {
      queryParams: {
        passData: JSON.stringify(this.passRequest)
      },
      queryParamsHandling: "merge"
    });
  }
}
