import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-multi-match-modal",
  templateUrl: "./multi-match-modal.component.html",
  styleUrls: ["./multi-match-modal.component.scss"]
})
export class MultiMatchModalComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() matchingInds: any[] = [];
  @Input() btnCancelText: string;

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  // --- triggers when user clicks button (login as any ind or cancel)
  onBtnClicked(indData) {
    this.activeModal.close(indData);
  }
}
