import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { combineLatest, Observable, Subject, Subscription } from "rxjs";
import { map, switchMap, takeUntil } from "rxjs/operators";
import { AuthApiService } from "src/app/core/auth/auth.api.service";
import UserAuth from "src/app/core/auth/userAuth.class";
import { CommunicationService } from "src/app/core/communication/communication.service";
import { IndividualFull } from "src/app/core/individual/individual-full.class";
import { IndividualApiService } from "src/app/core/individual/individual.api.service";
import { Organization } from "src/app/core/organization/organization.class";
import {
  getQueryParams,
  QueryParamsIDs,
  setQueryParams
} from "src/app/core/utils/queryParams.utils";
import { AlertDialogService } from "../../alert-dialog/alert-dialog.service";
import { ConfirmationDialogService } from "../../confirm-dialog/pages/confirmation-dialog/confirmation-dialog.service";
import { getDeviceInfo } from "../../../../assets/js/deviceInfo.js";
import moment from "moment";
import { UrlService } from "src/app/core/digital-id/url.service";
import { CommonServiceService } from "src/app/core/common/common-service.service";
import { Role } from "src/app/core/enums/user.enums";
import { MediaService } from "src/app/core/media/media.service";
import lodash from "lodash";
import { GetIndPhotoUsingPrefSettings } from "src/app/core/pipes/getIndPhotoUsingPrefSettings/get-ind-photo-using-pref-settings.pipe";
import { SettingsService } from "src/app/core/settings/settings.service";
import { PreferencesService } from "src/app/core/preferences/preferences.service";
import { environment } from "src/environments/environment";
import { LocalStorageService } from "src/app/core/storage/local-storage.service";
import { OrganizationsService } from "src/app/core/organizations/organizations.service";
import {
  DiyCapturePolicyPhotoUsedInIDMap,
  IndexedDBKeys
} from "src/app/core/enums/general.enums";
import { ChangePasswordDialogService } from "../../confirm-dialog/pages/change-password-dialog/change-password-dialog.service";
import { LicenseService } from "src/app/core/license/license.service";
import { EventsService } from "src/app/core/events.service";
import { AppComponent } from "src/app/app.component";
import { IndexedDBService } from "src/app/core/IndexedDB/indexed-db.service";

@Component({
  selector: "app-general-common-contents",
  templateUrl: "./general-common-contents.component.html",
  styleUrls: ["./general-common-contents.component.scss"]
})
export class GeneralCommonContentsComponent implements OnInit, OnDestroy {
  @Input() pageContent;
  @Input() showSidebar = true;
  @Input() showHeader = true;
  @Input() showFooter = true;
  messageSend = false;
  indData: any;
  orgData: any;
  readonly app: any = AppComponent.app;

  orgAndInd$: Observable<{
    individual: IndividualFull;
    organization: Organization;
  }>;
  userAuth: UserAuth;

  deviceInfo = getDeviceInfo(); //this.deviceService.getDeviceInfo();

  orgIndSub: Subscription;
  shouldOfferTakeSelfie: boolean = false;
  pageDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private authApi: AuthApiService,
    private individualApi: IndividualApiService,
    private confirmationDialogService: ConfirmationDialogService,
    private communicationService: CommunicationService,
    private alertDialogService: AlertDialogService,
    private router: Router,
    private urlService: UrlService,
    public commonService: CommonServiceService,
    private mediaService: MediaService,
    private getIndPhotoUsingPrefSettings: GetIndPhotoUsingPrefSettings,
    private settingsService: SettingsService,
    private preferencesService: PreferencesService,
    private localStorageService: LocalStorageService,
    private organizationsService: OrganizationsService,
    private changePasswordDialogService: ChangePasswordDialogService,
    private licenseService: LicenseService,
    private eventsService: EventsService,
    private indexedDBService: IndexedDBService,
    private orgService: OrganizationsService
  ) {
    this.userAuth = getQueryParams();
  }

  /**
   * Reset Password of the current user
   */
  async changePassword() {
    if (this.userAuth.orgID && this.userAuth.indID) {
      let changePasswordDialogRes: any = await this.changePasswordDialogService.confirm(
        "Change Password",
        "Are you sure you want to change your password?",
        "Yes",
        "No"
      );

      if (changePasswordDialogRes.success) {
        // --- remove reset password params from query string
        // let queryParams = getQueryParams();
        // delete queryParams.payload;
        // delete queryParams.forgotPassword;
        // await this.router.navigate([], { queryParams });

        // await this.individualApi.setIndPW(
        //   this.userAuth.orgID,
        //   this.userAuth.indID,
        //   changePasswordDialogRes.password
        // );
      }
    }
  }

  /**
   * Open Contact Details (Account Settings) Page
   */
  openAccountSettings() {
    let queryParams: UserAuth = {
      id: QueryParamsIDs.CONTACT_DETAILS_FROM_SIDEBAR_OPTION,
      orgID: this.userAuth.orgID,
      indID: this.userAuth.indID
    };

    this.localStorageService.setItem("indType", "student");
    this.router.navigate(["/confirm-detail"], { queryParams });
  }

  /**
   * Take a selfie for your digital id
   */
  async takeASelfie() {
    let link = await this.commonService.prepareMessage(
      "~^Sys.DIY_PURL_OrgID~^",
      { urlService: this.urlService, licenseService: this.licenseService },
      { orgID: this.userAuth.orgID, indID: this.userAuth.indID },
      { orgData: this.orgData, indData: this.indData },
      { customCtx: { preventUrlShortening: true } },
      true
    );
    if (link && this.commonService.isURLValid(link)) {
      if (!link.includes("id=103")) link = `${link}&id=103`;
      window.open(link, "_self");
    } else {
      this.userAuth.designTypeId = "-M4svx1Ssr7jk7r4dJLN";
      delete this.userAuth.designOrVariationId;
      delete this.userAuth.isDesignOrVariation;
      delete this.userAuth.printDesignID;
      delete this.userAuth.isPrintDesignOrVariation;
      this.userAuth.id = QueryParamsIDs.SELFIE_FROM_SIDEBAR_OPTION;
      this.router.navigate(["/photo"], setQueryParams(this.userAuth));
    }
  }

  sendDigitalIdConfirmation(mobileNumber) {
    this.confirmationDialogService
      .confirm(
        "Please confirm",
        "You want to send your digital ID link to " + mobileNumber,
        "Send Now",
        "Cancel"
      )
      .then(res => {
        if (res) {
          this.sendMessage().then(() => {
            this.alertDialogService.confirm(
              "Success",
              "Message sent successfully.\n Please check your phone!",
              null,
              null
            );
          });
        } else {
          console.log("Cancelled");
        }
      })
      .catch(error => {
        console.log("Error");
      });
  }

  sendMessage = async () => {
    if (this.indData.hasOwnProperty("optin") && this.indData.optin == false) {
      alert("individual has unsubscribed for sms.");
      this.messageSend = false;
    } else {
      this.messageSend = true;

      let message = "~^Sys.Landing_SMS_Message~^";
      message = await this.commonService.prepareMessage(
        message,
        { urlService: this.urlService, licenseService: this.licenseService },
        { orgID: this.userAuth.orgID, indID: this.userAuth.indID },
        { orgData: this.orgData, indData: this.indData },
        undefined,
        true
      );

      if (this.indData.mobilePhone)
        await this.communicationService.send(
          this.orgService,
          "sms",
          this.indData.mobilePhone,
          this.orgData,
          this.indData,
          message
        );
      this.messageSend = false;
    }
  };

  async logout() {
    await this.authApi.logout('/organization', null);
  }

  // --- monitor individual data
  monitorIndividual(orgID, indID) {
    return new Observable(observer => {
      if (!orgID || !indID) {
        observer.next(null);
        observer.complete();
        return;
      }

      let indSub = this.individualApi
        .observeIndData(orgID, indID, 500)
        .subscribe(individual => {
          if (!individual) {
            observer.next(null);
            observer.complete();
            return;
          }

          let exists: any = new IndividualFull();
          exists._new = false;
          if (individual) {
            exists.copyInto(individual, indID, this.orgData?.type);
          }
          observer.next(exists);
        });

      return () => {
        if (indSub && !indSub.closed) indSub.unsubscribe();
      };
    });
  }

  indPhoto: string = "";
  currentCycle;
  photoUsedInIDPrefValue;
  diyCapturePolicyPredValue;
  orgLogoUrl: string = "";
  defferedPWAEvent;
  async ngOnInit() {
    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    // console.time("getOneFull ======= 215")
    this.individualApi
      .getIndData(this.userAuth.orgID, this.userAuth.indID, undefined, true)
      .then(async indData => {
        // console.timeEnd("getOneFull ======= 215")
        // --- get ind photo
        const getIndPhoto = async () => {
          let readRequiredDataPromises = [];
          readRequiredDataPromises.push(
            this.commonService.getOrgCurrentCycle(this.userAuth.orgID)
          );
          readRequiredDataPromises.push(
            this.preferencesService.getPreferenceByInheritance(
              Role.ORG,
              this.userAuth.orgID,
              "photoUsedInID",
              undefined,
              true,
              "value"
            )
          );
          readRequiredDataPromises.push(
            this.preferencesService.getPreferenceByInheritance(
              Role.ORG,
              this.userAuth.orgID,
              "PhotoDefBG",
              null,
              true,
              "value"
            )
          );
          readRequiredDataPromises.push(
            this.preferencesService.getPreferenceByInheritance(
              Role.ORG,
              this.userAuth.orgID,
              "DIYCapturePolicy",
              null,
              true,
              "value"
            )
          );
          let readRequiredDataPromisesRes = await Promise.all(
            readRequiredDataPromises
          );
          this.currentCycle = readRequiredDataPromisesRes[0];
          this.photoUsedInIDPrefValue = readRequiredDataPromisesRes[1];
          let mediaID = readRequiredDataPromisesRes[2];
          this.diyCapturePolicyPredValue = readRequiredDataPromisesRes[3];
          let photoDefBGPref;
          if (mediaID == "none") photoDefBGPref = mediaID;
          else if (mediaID) {
            let mediaData = await this.mediaService.getMediaByInheritance(
              Role.ORG,
              this.userAuth.orgID,
              mediaID
            );
            photoDefBGPref = lodash.get(mediaData, "source.url");
          }
          let photoData: any = await this.getIndPhotoUsingPrefSettings.transform(
            indData,
            this.photoUsedInIDPrefValue,
            this.currentCycle,
            "",
            this.userAuth.orgID,
            null,
            photoDefBGPref,
            true,
            true,
            "sanitizedObjectURL"
          );
          this.indPhoto = photoData.finalImgSrc;
        };
        getIndPhoto();

        this.authApi.user$.subscribe(userAuth => {
          if (userAuth == null) {
            this.authApi.logout();
          }
        });

        this.orgAndInd$ = this.authApi.user$.pipe(
          switchMap(userAuth => {
            if (userAuth == null) {
              this.authApi.logout();
              return;
            } else {
              return combineLatest([
                this.monitorIndividual(userAuth.orgID, userAuth.indID),
                this.organizationsService.monitorOrgData(userAuth.orgID)
              ]).pipe(
                takeUntil(this.pageDestroy$),
                map(res => {
                  return {
                    individual: lodash.nth(res, 0),
                    organization: lodash.nth(res, 1)
                  };
                })
              );
            }
          })
        );

        this.orgIndSub = this.orgAndInd$.subscribe(async res => {
          if (!res || !res.organization) {
            await this.alertDialogService.confirm(
              "Alert",
              'It seems like that your organization is deleted OR if you are using PWA, you have switched between "STAGE" and "PRODUCTION" environment inappropriately.',
              "Ok",
              null
            );
            this.logout();
            return;
          }

          if (!res.individual) {
            this.logout();
            return;
          }

          this.orgData = res.organization;
          this.orgData.orgID = this.userAuth.orgID;
          this.orgData._key = this.userAuth.orgID;
          this.orgData.key = this.userAuth.orgID;

          if (
            this.orgData &&
            this.orgData.settings &&
            this.orgData.settings.visuals &&
            this.orgData.settings.visuals.logo
          ) {
            if (
              this.orgData.settings.visuals.logo.indexOf("http") > -1 ||
              this.orgData.settings.visuals.logo.indexOf("firebasestorage") > -1
            ) {
              this.orgLogoUrl = this.orgData.settings.visuals.logo;
            } else {
              this.orgLogoUrl = `${environment.firebaseImgUrl}${environment.firebaseConfig.storageBucket}/o/photos%2Forganisations%2F${this.orgData._key}%2ForgLogo?alt=media`;
            }
          }

          if (this.orgData) {
            let _COD1 =
              lodash.get(this.orgData, "COD1") ||
              lodash.get(
                this.orgData,
                "primaryColor",
                CommonServiceService.DEFAULT_PRIMARY_COLOR
              );
            let _COD2 =
              lodash.get(this.orgData, "COD2") ||
              lodash.get(
                this.orgData,
                "secondaryColor",
                CommonServiceService.DEFAULT_SECONDARY_COLOR
              );
            setTimeout(() => {
              $(".div-colors-of-the-day > div").css(
                "background",
                `linear-gradient(to right, ${_COD1}, ${_COD2})`
              );
            }, 100);
          }

          this.indData = res.individual;

          // --- read selfie option preference
          this.preferencesService
            .getPreferenceByInheritance(
              Role.ORG,
              this.userAuth.orgID,
              "OfferPhotoTakingNoPhotoPresent",
              null,
              true,
              "value"
            )
            .then(value => {
              this.shouldOfferTakeSelfie = value != 2;
            });
        });

        // --- show PWA installation popup once a day
        var currentDay = moment()
          .startOf("day")
          .format("x");
        let triedToday =
          this.localStorageService.getItem("PwaPopupDay") == currentDay;
        if (!triedToday) {
          this.installPWA("custom");
          this.localStorageService.setItem("PwaPopupDay", currentDay);
        }
      });
  }

  // --- clear offline ID cache
  async clearIDCache() {
    try {
      // --- unregister cache service workers
      let registrations: any = await navigator.serviceWorker.getRegistrations();
      registrations = lodash.filter(
        registrations,
        reg =>
          reg &&
          reg.active &&
          lodash.includes(reg.active.scriptURL, "/ngsw-worker.js")
      );
      const unregisterPromises = registrations.map(registration =>
        registration.unregister()
      );

      // --- clear all caches
      const allCaches = await caches.keys();
      const cacheDeletionPromises = allCaches.map(cache =>
        caches.delete(cache)
      );

      // --- remove locally cached ID
      let deleteCachedIDPromise = this.indexedDBService.deleteItem(
        IndexedDBKeys.CachedID
      );

      let promises = lodash.concat(
        unregisterPromises,
        cacheDeletionPromises,
        deleteCachedIDPromise
      );
      await Promise.all(promises.map(p => p.catch(e => e)));

      window.location.reload();
    } catch (e) {
      this.commonService.presentAlert(
        this.commonService.prepareErrorMessage(e),
        "Error",
        "Ok"
      );
    }
  }

  // --- trigger PWA installation command
  installPWA(interfacePref: "custom" | "default") {
    this.eventsService.publish("installPWA", { interfacePref });
  }

  shareUrl() {
    this.urlService.minifyURL(true, window.location.href).then(sortUrl => {
      console.log("sortUrl: ", sortUrl);
      let newVariable: any;
      newVariable = window.navigator;
      if (newVariable && newVariable.share) {
        newVariable
          .share({
            title: "Personal Page",
            text: sortUrl
          })
          .then(
            res => {
              console.log("res: ", res);
            },
            err => {
              console.log("err: ", err);
            }
          )
          .catch(e => {
            console.log("e: ", e);
          });
      } else {
      }
    });
  }

  ngOnDestroy(): void {
    this.pageDestroy$.next();
    this.pageDestroy$.complete();
    if (this.orgIndSub && !this.orgIndSub.closed) this.orgIndSub.unsubscribe();
  }

  public get diyCapturePolicyPhotoUsedInIDMap(): typeof DiyCapturePolicyPhotoUsedInIDMap {
    return DiyCapturePolicyPhotoUsedInIDMap;
  }
}
