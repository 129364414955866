import { Injectable } from "@angular/core";

import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";

import { AlertDialogComponent } from "./alert-dialog.component";

@Injectable()
export class AlertDialogService {
  constructor(private modalService: NgbModal) {}

  public confirm(
    title: string,
    message: string,
    btnOkText: string,
    btnCancelText: string,
    dialogSize: "sm" | "lg" = "sm",
    hideCloseButton: boolean = false,
    options?: NgbModalOptions
  ): Promise<boolean> {
    let modalOptions: NgbModalOptions = {};
    if (options) modalOptions = options;
    modalOptions.size = dialogSize;
    const modalRef = this.modalService.open(AlertDialogComponent, modalOptions);
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText ? btnOkText : "OK";
    modalRef.componentInstance.btnCancelText = btnCancelText;
    modalRef.componentInstance.hideCloseButton = hideCloseButton;

    return modalRef.result;
  }
}
