import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import lodash from "lodash";
import { Role } from "../enums/user.enums";
import {
  InheritanceService,
  ReplacementTags
} from "../inheritance/inheritance.service";

@Injectable({
  providedIn: "root"
})
export class PassTypesService {
  private basePath: string = "pass-types";
  durations: any[] = [
    { name: "5 mins", value: 5 },
    { name: "10 mins", value: 10 },
    { name: "15 mins", value: 15 },
    { name: "60 mins", value: 60 },
    { name: "2 hours", value: 120 },
    { name: "12 hours", value: 720 },
    { name: "24 hours", value: 1440 },
    { name: "48 hours", value: 2880 }
  ];

  constructor(
    public db: AngularFireDatabase,
    private inheritanceProvider: InheritanceService
  ) {}

  // --- helper method to validate pass type object before doing any CRUD operations
  validatePassType(passType: PassType, keyRequired: boolean = false) {
    if (lodash.isNil(passType.name))
      throw new Error("Pass type must contain name");
    if (lodash.isNil(passType.duration))
      throw new Error("Pass type must contain duration");
    if (lodash.isNaN(passType.duration))
      throw new Error("Pass type duration must be a number");
    if (lodash.isNil(passType.designTypeID))
      throw new Error("Pass type must contain design type");
    if (
      passType.designTypeID != "None" &&
      lodash.isNil(passType.designOrVariationID)
    )
      throw new Error("Pass type must contain design");
    if (
      passType.designOrVariationID &&
      passType.designOrVariationID != "default" &&
      lodash.isNil(passType.isDesignOrVariation)
    )
      throw new Error("Pass type must contain isDesignOrVariation");
    if (keyRequired && lodash.isNil(passType.key))
      throw new Error("Pass type must contain key for update operation");
  }

  // --- helper method to clean up pass type object before doing CRUD on db
  cleanUpPassType(passType: PassType) {
    let clone = lodash.cloneDeep(passType);
    delete clone.ownerID;
    delete clone.edited;
    delete clone.key;
    return clone;
  }

  // --- helper method to get db path
  getPath(userID: string, passTypeID?: string) {
    let path = this.basePath;
    path = `${path}/${userID ? userID : "superadmin"}`;
    if (passTypeID) path = `${path}/${passTypeID}`;
    return path;
  }

  // --- add new pass type in db
  async setPassType(
    passType: PassType,
    role: any,
    userID: string
  ): Promise<any> {
    if (!role || !passType || (role != Role.SUPERADMIN && !userID))
      throw new Error("Invalid params passed!");

    // --- validate pass-type data
    this.validatePassType(passType);
    let cleanedObj = this.cleanUpPassType(passType);

    // --- prepare db path
    let path = this.getPath(userID);

    let thenableRef = this.db.list(`${path}`).push(cleanedObj);
    await thenableRef;
    return thenableRef.key;
  }

  readonly dbPath = `pass-types/${ReplacementTags.USER_ID}`;

  /**
   * read pass type(s) based on inheritance
   * @param role role of the user (org, studio, etc)
   * @param userID user if (org or studio id)
   * @param passTypeID pass-type id if want to read a specific pass only
   * @param shouldRemoveHidden if true, function will remove ovverride=hide pass-types from results before returning, othewise it won't
   * @returns passTypes Array or passType object if passTypeID available
   */
  // --- read pass type(s) based on inheritance
  async getPassTypes(
    role: any,
    userID: string,
    usersData?: any,
    useCache: boolean = false,
    passTypeID?: string,
    shouldRemoveHidden: boolean = true
  ): Promise<PassType[] | PassType> {
    // --- params verification
    if (!role || !userID)
      throw new Error("Params missing in getPassTypes method call");

    // --- read pass types data by inheritance
    let passTypesInheritedData = await this.inheritanceProvider.getByInheritance(
      role,
      userID,
      `${this.dbPath}/${passTypeID || ""}`,
      "list",
      usersData,
      undefined,
      "superadmin",
      useCache
    );

    // ---  merge passtypes by inheritance
    let passTypes = [];
    lodash.each(passTypesInheritedData, passTypesData => {
      let ownerID = passTypesData.ownerID;

      if (passTypeID && passTypesData.data) {
        passTypesData.data = {
          [passTypeID]: passTypesData.data
        };
      }

      let userPassTypes = lodash.map(passTypesData.data, (data: any, key) => {
        return { ...data, key, ownerID };
      });
      passTypes = lodash.unionBy(passTypes, userPassTypes, "key");
    });

    // --- remove pass types with override = hide if needed
    if (shouldRemoveHidden)
      passTypes = lodash.filter(passTypes, pt => pt.override != "hide");

    // --- sort by name in ascending order
    passTypes = lodash.sortBy(passTypes, passType =>
      lodash.toLower(passType.name)
    );

    return passTypeID ? lodash.first(passTypes) : passTypes;
  }

  // --- update pass type
  async updatePassType(
    passType: PassType,
    role: any,
    userID: string
  ): Promise<any> {
    if (!role || !passType || (role != Role.SUPERADMIN && !userID))
      throw new Error("Invalid params passed!");

    // --- validate pass-type data
    this.validatePassType(passType, true);

    // --- prepare db path
    let path = this.getPath(userID, passType.key);

    // --- add override data
    if (userID && passType.ownerID != userID) {
      passType.override = "edit";
      passType.ownerID = userID;
    }

    let cleanedObj = this.cleanUpPassType(passType);
    await this.db.object(`${path}`).update(cleanedObj);
    return passType;
  }

  // --- remove pass type respecting inheritance
  async removePassType(
    passType: PassType,
    role: any,
    userID: string
  ): Promise<any> {
    if (!role || !passType || (role != Role.SUPERADMIN && !userID))
      throw new Error("Invalid params passed!");

    // --- validate pass-type data
    this.validatePassType(passType, true);

    // --- prepare db path
    let path = this.getPath(userID, passType.key);

    // --- if superadmin, actually remove the record
    if (role == Role.SUPERADMIN) return this.db.object(`${path}`).remove();

    // --- if owner same then remove, othewise add override data
    if (passType.ownerID == userID && !passType.override) {
      return this.db.object(`${path}`).remove();
    } else {
      passType.override = "hide";
      let cleanedObj = this.cleanUpPassType(passType);
      await this.db.object(`${path}`).update(cleanedObj);
      return passType;
    }
  }

  // --- remove all local edited pass types copies
  async resetPassTypes(role: any, userID: string) {
    if (!role || (role != Role.SUPERADMIN && !userID))
      throw new Error("Invalid params passed!");

    // --- prepare db path
    let path = this.getPath(userID);

    return this.db.object(`${path}`).remove();
  }
}

export class PassType {
  name: string = null;
  duration: number = null;
  designTypeID: string = "None";
  designOrVariationID: string = null;
  isDesignOrVariation: "design" | "variation";
  ownerID?: string;
  key?: string;
  override?: "edit" | "hide";
  edited?: boolean;
  location?: string = "1";
}
