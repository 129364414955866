export const Background = [
    'linear-gradient(96.5deg, rgb(39, 103, 187) 10.4%, rgb(16, 72, 144) 87.7%)',
    'linear-gradient(135deg, rgb(139, 198, 236) 10%, rgb(149, 153, 226) 100%)',
    'linear-gradient(135deg, rgb(151, 171, 255) 10%, rgb(18, 53, 151) 100%)',
    'linear-gradient(to right, rgb(157, 80, 187), rgb(110, 72, 170))',
    'linear-gradient(135deg, rgb(33, 212, 253) 10%, rgb(183, 33, 255) 100%)',
    'linear-gradient(135deg, rgb(171,220,255) 10%, rgb(3,150,255) 100%)',
    'linear-gradient(135deg, rgb(151,171,255) 10%, rgb(18,53,151) 100%)',
    'linear-gradient(135deg, rgb(114,237,242) 10%, rgb(81,81,229) 100%)',
    'linear-gradient(135deg, rgb(24,90,157) 10%, rgb(67,206,162) 100%)',
    'linear-gradient(135deg, rgb(69,104,220) 10%, rgb(176,106,179) 100%)',
    'linear-gradient(135deg, rgb(91,134,229) 10%, rgb(54,209,220) 100%)',
    'linear-gradient(135deg, rgb(43,88,118) 10%, rgb(78,67,118) 100%)',
    'linear-gradient(135deg, rgb(102,126,234) 10%, rgb(118,75,162) 100%)'
]