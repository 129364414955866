import { Injectable } from "@angular/core";
import lodash from "lodash";
import moment from "moment";
import { Observable } from "rxjs";
import { CommonServiceService } from "../common/common-service.service";

declare var High5App;
declare var window;

@Injectable({
  providedIn: "root"
})
export class High5AppService {
  isRunningInAppShell: boolean = "High5App" in window;
  isIos: boolean = false;

  constructor(private commonService: CommonServiceService) {
    // --- expose callJSMethod to window so that it can be called by native code
    window.callJSMethod = this.callJSMethod.bind(this);
  }

  // ===== START register/unregister back btn click handler
  backHandler: () => Boolean = null;
  registerBackHandler(fn: () => Boolean) {
    this.backHandler = fn;
  }

  unregisterBackHandler() {
    this.backHandler = null;
  }
  // ===== END register/unregister back btn click handler

  requestResultFnMap = {};

  // --- generate unique invocation ID
  private getUniqueInvocationID() {
    return `${moment().valueOf()}_${this.commonService.randomIntFromInterval(
      1,
      9999
    )}`;
  }

  /**
   * Method which listens to native code requests to call js method or to respond to any request
   * @param invocationIDOrMethodName unique invocation ID (If responding to any request) or method name (if want to call any specific method)
   * @param params params to pass while calling js method
   */
  private callJSMethod(invocationIDOrMethodName: string, payload?: any) {
    let eventPayload = {
      invocationIDOrMethodName,
      payload: payload ? JSON.parse(payload) : null
    };

    return this.processHigh5Event(eventPayload);
  }

  /**
   * Process High5 Event
   * @param event event data
   */
  private processHigh5Event(event) {
    let invocationID = lodash.get(event, "invocationIDOrMethodName");
    if (!invocationID) {
      console.warn("High5 Event received without sufficient information!");
      return;
    }

    // --- if requestResultFnMap contains given key, it's respond to request, else its new method call
    let isRespondToReq = lodash.has(this.requestResultFnMap, invocationID);

    // --- process respond of request
    if (isRespondToReq) {
      this.processRespondToReqEvent(invocationID, event);
      return;
    }

    // --- process new method calls
    return this.processJSMethodCallEvent(event);
  }

  /**
   * Process respond to requests event
   * @param invocationID invocation id of request for which the response is
   * @param event High5 event data
   */
  private processRespondToReqEvent(invocationID, event) {
    let isSuccess = lodash.get(event, "payload.success");
    let data = lodash.get(event, "payload.data");

    let respondFns = lodash.get(this.requestResultFnMap, invocationID);
    if (!respondFns) return;

    // --- handle observer type response
    if (respondFns.observer) {
      // --- throw next input to observer
      respondFns.observer.next(data);

      // --- check if its completed and no further input will get received, then complete the observer
      let isCompleted = lodash.get(event, "payload.isCompleted");
      if (isCompleted) {
        respondFns.observer.complete();

        // --- delete completed observer Fns from map
        delete this.requestResultFnMap[invocationID];
      }
    }

    // --- handle promise type response
    if (respondFns.resolve) {
      // --- resolve/reject based on response
      if (isSuccess) respondFns.resolve(data);
      else respondFns.reject(data);

      // --- delete resolved/rejected Fns from map
      delete this.requestResultFnMap[invocationID];
    }
  }

  /**
   * Process JS method call event
   * @param event High5 event data
   */
  private processJSMethodCallEvent(event) {
    let methodName = lodash.get(event, "invocationIDOrMethodName");

    switch (methodName) {
      case "onBackPress":
        if (this.backHandler) return this.backHandler();
        else return false;
    }

    return false;
  }

  /**
   * Call any observable native method
   * @param methodName native method name
   * @param params params to pass while calling
   * @returns Observable of response data
   */
  callObservableNativeMethod(methodName: string, ...params) {
    return new Observable(observer => {
      // --- check if native function exists
      if (!this.isRunningInAppShell)
        return observer.error(
          `Observable native method ${methodName} does not exists on native side!`
        );

      let invocationID = this.getUniqueInvocationID(); // --- generate unique invocation ID
      this.requestResultFnMap[invocationID] = {
        observer
      }; // --- set observer function to map

      // --- call native function
      let finalParams = lodash.union([invocationID], params);
      let callRes = High5App[methodName].apply(High5App, finalParams);

      // --- if native function does not respond, reject the request
      if (callRes !== true)
        return observer.error(
          `Observable native method ${methodName} call didn't responded back!`
        );
    });
  }

  /**
   * Call any asynchronous native method
   * @param methodName native method name
   * @param params params to pass while calling
   * @returns Promise of response data
   */
  async callAsyncNativeMethod(methodName: string, ...params) {
    return new Promise((resolve, reject) => {
      // --- check if native function exists
      if (!this.isRunningInAppShell)
        return reject(
          `Async native method ${methodName} does not exists on native side!`
        );

      let invocationID = this.getUniqueInvocationID(); // --- generate unique invocation ID
      this.requestResultFnMap[invocationID] = {
        resolve,
        reject
      }; // --- set resolvable/rejectable function to map

      // --- call native function
      let finalParams = lodash.union([invocationID], params);
      let callRes = High5App[methodName].apply(High5App, finalParams);

      // --- if native function does not respond, reject the request
      if (callRes !== true)
        return reject(
          `Async native method ${methodName} call didn't responded back!`
        );
    });
  }

  /**
   * Call any synchronous native method
   * @param methodName native method name
   * @param params params to pass while calling
   * @returns response received from native side
   */
  callNativeMethod(methodName: string, ...params) {
    // --- check if native function exists
    // if (!lodash.has(High5App, methodName))
    if (!this.isRunningInAppShell)
      throw new Error(
        `Native method ${methodName} does not exists on native side!`
      );

    return High5App[methodName].apply(High5App, params);
  }

  // ===========================================================================
  // ================== FOR IOS NATIVE  ========================================
  // ===========================================================================

  /**
  * Call any observable native method
  * @param methodName native method name
  * @param params params to pass while calling
  * @returns Observable of response data
  */
  callObservableIosNativeMethodWatchUserLocation() {
    return new Observable(observer => {
      // --- check if native function exists

      let invocationID = this.getUniqueInvocationID(); // --- generate unique invocation ID
      this.requestResultFnMap[invocationID] = {
        observer
      }; // --- set observer function to map

      // --- call native function
      let finalParams = lodash.union([invocationID]);
      let callRes = (window as any).webkit.messageHandlers.watchUserLocation.postMessage(finalParams);

      // --- if native function does not respond, reject the request
      // if (callRes !== true)
      //   return observer.error(
      //     `Observable native method ${methodName} call didn't responded back!`
      //   );
    });
  }

  /**
   * Call any asynchronous native method
   * @param methodName native method name
   * @param params params to pass while calling
   * @returns Promise of response data
   */
  async callAsyncIosNativeMethodGetCurrentLocation() {
    return new Promise((resolve, reject) => {
      // --- check if native function exists

      let invocationID = this.getUniqueInvocationID(); // --- generate unique invocation ID
      this.requestResultFnMap[invocationID] = {
        resolve,
        reject
      }; // --- set resolvable/rejectable function to map

      // --- call native function
      let finalParams = lodash.union([invocationID]);
      let callRes = (window as any).webkit.messageHandlers.getCurrentLocation.postMessage(finalParams);


      // --- if native function does not respond, reject the request
      // if (callRes !== true)
      //   return reject(
      //     `Async native method ${methodName} call didn't responded back!`
      //   );
    });
  }

  callIosNativeMethodStopMonitoringLocation() {
    return (window as any).webkit.messageHandlers.stopMonitoringUserLocation.postMessage();
  }

  isIosDevice() {
    const value = `; ${document.cookie}`;
    const device = value.split(`; DEVICE=`);
    if (device.length === 2) {
      this.isIos = device.pop().split(';').shift() == 'ios'
    }
  }
}
