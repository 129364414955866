import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'timestamp'
})
export class TimestampPipe implements PipeTransform {

  transform(timeInMillis: number, format?: string, fromFormat?: string): string {
    return moment(timeInMillis, fromFormat || "x").format((format ? format : 'MMMM DD, YYYY'))
  }

}


@Pipe({
  name: 'getDate'
})
export class GetDate implements PipeTransform {

  transform(timeInMillis: any): string {
    if(typeof timeInMillis === 'number') return moment(timeInMillis, 'x').format('MMM DD, YYYY')
    return moment(timeInMillis, 'YYYY-MM-DD h:mm:ss a').format('MMM DD, YYYY')
  }

}
