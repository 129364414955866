import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { CommunicationService } from "../communication/communication.service";
import { UserApiService } from "../user/user.api.service";
import { CommonServiceService } from "../common/common-service.service";
import {
  Message,
  MessageArea,
  MessagesService,
  MessageTypes
} from "../messages/messages.service";
import { Role } from "../enums/user.enums";
import moment from "moment";
import { EncryptionService } from "../encryption/encryption.service";
import { ApiHelperService, CloudFnNames } from "../api-helper.service";
import lodash from "lodash";
import { CacheKeys, CacheService } from "../cache/cache.service";
import { MsgMedium } from "../enums/general.enums";

@Injectable({
  providedIn: "root"
})
export class OrgManagerService {
  constructor(
    private httpClient: HttpClient,
    private communicationService: CommunicationService,
    private userService: UserApiService,
    private commonService: CommonServiceService,
    private messagesService: MessagesService,
    private encryptionService: EncryptionService,
    private apiHelperService: ApiHelperService,
    private cacheService: CacheService
  ) {}

  orgSecDataBaseURL = environment.firebaseFunctionsBaseUrl;
  readonly secKey = environment.edk;
  tagPrefixStr = "%recipient.";

  async getStudioKey() {
    return await this.httpClient
      .post(`${this.orgSecDataBaseURL}/createOrgManagerkey`, {})
      .toPromise();
  }

  // --- read org manager data
  async getOrgMgrData(
    studioID: string,
    propsToReturn: string[] = ["all"],
    useCache: boolean = false
  ) {
    if (!studioID || !propsToReturn) throw new Error("Params missing!");

    // --- prepare cache path
    let cachePath = `${studioID}/`;
    cachePath += this.commonService.getCachePathForProps(propsToReturn)

    let studioData: any;

    // --- read from cache
    if (useCache) {
      let cachedData = this.cacheService.get(CacheKeys.StudioData, cachePath);
      if (cachedData) studioData = cachedData.value;
    }

    // --- read org data from database
    if(!studioData) {
      let reqBody = { studioID, propsToReturn };
      let orgMgrDataRes = await this.apiHelperService.postToCloudFn(
        CloudFnNames.getOrgMgrData,
        reqBody
      );
      studioData = lodash.get(orgMgrDataRes, "result.data");

      // --- set cache
      this.cacheService.set(CacheKeys.StudioData, cachePath, studioData);
    }

    return studioData;
  }


  // --- remove  org manager data data
  async removeOrgManagerData(ID: string) {
    if (!ID) return null;

    let requestBody = JSON.stringify({ ID: ID });
    let encryptedBody = this.encryptionService.encrypt(
      requestBody,
      this.secKey
    );
    return await this.httpClient
      .post(`${this.orgSecDataBaseURL}/removeOrgManagerData`, encryptedBody)
      .toPromise();
  }

  // --- delete studio
  deleteStudio(id: string, firebaseUID?: string) {
    if (!id && !firebaseUID) throw Error("params missing!");
    let deletePromises = [];
    if (firebaseUID)
      deletePromises.push(this.userService.removeUser(firebaseUID));
    deletePromises.push(this.removeTeammateSecData(id));
    deletePromises.push(this.removeOrgManagerData(id));
    return Promise.all(deletePromises);
  }
  async removeTeammateSecData(ID: string) {
    if (!ID) return null;

    let requestBody = JSON.stringify({ ID: ID });
    let encryptedBody = this.encryptionService.encrypt(
      requestBody,
      this.secKey
    );

    return await this.httpClient
      .post(`${this.orgSecDataBaseURL}/removeOrgManagerSecData`, encryptedBody)
      .toPromise();
  }
  async createStudio(ID: string, orgManagerData) {
    if (!ID || !orgManagerData) return null;

    let requestBody = JSON.stringify({
      ID: ID,
      orgManagerData: orgManagerData
    });
    let encryptedBody = this.encryptionService.encrypt(
      requestBody,
      this.secKey
    );

    return await this.httpClient
      .post(`${this.orgSecDataBaseURL}/createOrgManagerData`, encryptedBody)
      .toPromise();
  }

  async updateOrgManagerLogo(ID: string, studioLogoRef) {
    if (!ID || !studioLogoRef) return null;

    let requestBody = JSON.stringify({ ID: ID, studioLogo: studioLogoRef });
    let encryptedBody = this.encryptionService.encrypt(
      requestBody,
      this.secKey
    );

    return await this.httpClient
      .post(`${this.orgSecDataBaseURL}/updateOrgManagerLogo`, encryptedBody)
      .toPromise();
  }

  /**
   * update org manager data
   * @param id studio db key
   * @param updateObj update object containing properties to update
   */
  async updateOrgMgrData(id: string, updateObj: any) {
    if (!id || !updateObj)
      throw new Error("params missing in updateOrgMgrData method call.");

    let requestBody = JSON.stringify({ id, updateObj });
    let encryptedBody = this.encryptionService.encrypt(
      requestBody,
      environment.edk
    );
    return await this.httpClient
      .post(
        `${environment.firebaseFunctionsBaseUrl}/updateOrgMgrData`,
        encryptedBody
      )
      .toPromise();
  }

  /**
   * send org manager applied email to high5 admin
   * @param orgMgrData org manager data object
   * @returns true if success, false otherwise
   */
  async sendOrgMgrAppliedEmailToHigh5Admin(orgMgrData, sourceOfApplication?) {
    console.log(
      `sending email to high5 admin about org manager applied with email ${orgMgrData.email}`
    );

    // --- prepare email message
    let _SAEmailMessage = `An Org Manager just applied through ${
      sourceOfApplication ? sourceOfApplication : "public sign up form"
    }.\n\nDisplay Name: ${orgMgrData.name}\nAdmin email ID: ${
      orgMgrData.email
    }\nAdmin Name: ${orgMgrData.adminName}\nAdmin Phone: ${
      orgMgrData.adminPhone
    }\n\nPlease review their information and set their status to 'approved'.`;

    // --- send email
    let [, sendMailErr] = await this.commonService.executePromise(
      this.communicationService.sendSingleEmail(
        [environment.onrampSetupAdminEmail],
        `Org Manager "${orgMgrData.name}" Applied`,
        _SAEmailMessage,
        MsgMedium.EMAIL
      )
    ) 

    // --- error check
    if (sendMailErr) {
      console.log(
        `Error while sending org manager applied email to high5 admin.`,
        sendMailErr
      );
      return null;
    }

    console.log("Org manager applied Email sent successfully to high5 admin!");
    return true;
  }

  // --- send org manager applied notification (in messages section) to high5 admin
  async sendOrgMgrAppliedMessageToHigh5Admin(orgMgrData, sourceOfApplication?) {
    console.log(
      `sending notification message to high5 admin about org manager applied with email ${orgMgrData.email}`
    );

    // --- prepare email message for high5 admin
    let randomNumber = this.commonService.randomIntFromInterval(1, 99999);
    let message: Message = {
      type: "notification",
      area: MessageArea.ATTENTION,
      details: `An Org Manager just applied through ${
        sourceOfApplication ? sourceOfApplication : "public sign up form"
      }.<br/><br/>Display Name: ${orgMgrData.name}<br/>Admin email ID: ${
        orgMgrData.email
      }<br/>Admin Name: ${orgMgrData.adminName}<br/>Admin Phone: ${
        orgMgrData.adminPhone
      }<br/><br/><a id='internal${randomNumber}'>Manage Org Managers</a>`,
      priority: randomNumber,
      action: MessageTypes.INTERNAL,
      internalPageName: "SuperAdminTeammatesPage",
      timestamp: moment().valueOf()
    };

    // --- send message
    let [, err] = await this.commonService.executePromise(
      this.messagesService.setMessage(Role.SUPERADMIN, message)
    );

    // --- error check
    if (err) {
      console.log(
        `Error while sending notification message to high5 admin about org manager applied with email ${orgMgrData.email}.`,
        err
      );
      return null;
    }

    console.log(
      `Org manager registered with email ${orgMgrData.email} notification sent successfully to high5 admin!`
    );
    return true;
  }
}
