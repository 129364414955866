import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import lodash from "lodash";

@Component({
  selector: "app-game-list-dialog",
  templateUrl: "./game-list-dialog.component.html",
  styleUrls: ["./game-list-dialog.component.scss"]
})
export class GameListDialogComponent implements OnInit {
  @Input() gameList;
  @Input() selectedGame;
  @Input() orgName;
  constructor(private activeModal: NgbActiveModal) {}
  ngOnInit(): void {
    this.gameList = lodash.map(this.gameList, gameData => {
      gameData.oppositeTeamName =
        gameData.assn1Name != this.orgName
          ? gameData.assn1Name
          : gameData.assn2Name;
      return gameData;
    });
  }

  selectGame(game) {
    this.selectedGame = game;
    this.activeModal.close(game.key);
  }

  public dismiss() {
    this.activeModal.close();
  }
}
