import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import lodash from "lodash";
import { AuthDependency } from "./interface/types";
import { ApiHelperService, CloudFnNames } from "./api-helper.service";

@Injectable({
  providedIn: "root"
})
export class RelationsService {
  constructor(
    private db: AngularFireDatabase,
    private apiHelperService: ApiHelperService
  ) {}

  // --- add new relation
  async addRelation(
    orgID: string,
    relation: any,
    authDependencies?: AuthDependency[]
  ) {
    // --- params validation
    if (!orgID || !relation)
      throw new Error("Params missing in addRelation method call!");

    let relationID;
    if (authDependencies && lodash.size(authDependencies) > 0) {
      let indMediaRes = await this.apiHelperService.postToCloudFn(
        CloudFnNames.addRelation,
        { orgID, relation, authDependencies }
      );
      relationID = lodash.get(indMediaRes, "result.data.relationID");
    } else {
      let thenableRef = this.db.list(`/relations/${orgID}/`).push(relation);
      await thenableRef;
      relationID = thenableRef.key;
    }

    return relationID;
  }

  // --- get all relations of given individual
  async getAllRelations(orgID: any, indID: any) {
    let readRelationsPromises = [];
    readRelationsPromises.push(
      this.db
        .list(`relations/${orgID}`)
        .query.orderByChild("indId")
        .equalTo(indID)
        .once("value")
    );
    readRelationsPromises.push(
      this.db
        .list(`relations/${orgID}`)
        .query.orderByChild("relativeIndId")
        .equalTo(indID)
        .once("value")
    );

    try {
      let readRelationsPromisesRes = await Promise.all(readRelationsPromises);
      readRelationsPromisesRes = lodash.map(
        readRelationsPromisesRes,
        relationsSnapshot => relationsSnapshot.val()
      ); // --- convert snapshots to values
      let relationsIndId = readRelationsPromisesRes[0];
      let relationsRelativeIndId = readRelationsPromisesRes[1];
      relationsIndId = lodash.map(
        relationsIndId,
        (relationData: any, relationKey) => {
          return { ...relationData, relationKey };
        }
      );
      relationsRelativeIndId = lodash.map(
        relationsRelativeIndId,
        (relationData: any, relationKey) => {
          return { ...relationData, relationKey };
        }
      );
      let mergedRelations = lodash.union(
        relationsIndId,
        relationsRelativeIndId
      );
      return mergedRelations;
    } catch (e) {
      console.log(
        `Error while fetching relations. orgID: ${orgID} indID: ${indID}`
      );
      return null;
    }
  }

  // --- get dependent & guardian ID from relation
  getDepGrdnID(relation: any) {
    let result = { dependentID: null, guardianID: null };
    if (relation && relation.relation == "dependents") {
      result.dependentID = relation.relativeIndId;
      result.guardianID = relation.indId;
    } else if (relation && relation.relation == "parents") {
      result.dependentID = relation.indId;
      result.guardianID = relation.relativeIndId;
    }
    return result;
  }

  // --- check if indID1 is guardian of indID2
  isGuardianOf(indID1: string, indID2: string, relations: any[]) {
    return lodash.some(relations, relation => {
      let { dependentID, guardianID } = this.getDepGrdnID(relation);
      return guardianID == indID1 && dependentID == indID2;
    });
  }

  // --- check if indID1 is depenedent of indID2
  isDependentOf(indID1: string, indID2: string, relations: any[]) {
    return lodash.some(relations, relation => {
      let { dependentID, guardianID } = this.getDepGrdnID(relation);
      return dependentID == indID1 && guardianID == indID2;
    });
  }
}
