import { Injectable } from "@angular/core";
import { CommonServiceService } from "../common/common-service.service";
import { Role } from "../enums/user.enums";
import {
  InheritanceService,
  ReplacementTags
} from "../inheritance/inheritance.service";
import lodash from "lodash";

@Injectable({
  providedIn: "root"
})
export class MediaService {
  readonly userDBNodes = {
    [Role.ORG]: "organizations",
    [Role.STUDIO]: "studios",
    [Role.SUPERADMIN]: null
  };

  readonly dbPath = `${ReplacementTags.USER_DB_NODE}/${ReplacementTags.USER_ID}/designs/Backgrounds/`;

  constructor(
    private inheritanceProvider: InheritanceService,
    private commonFun: CommonServiceService
  ) {}

  // --- get media list by inheritance
  async getMediaListByInheritance(
    role: string,
    userID: string,
    usersData?: any,
    useCache: boolean = false,
    filterFn?: (item) => boolean
  ) {
    // --- params verification
    if (!role || (role != Role.SUPERADMIN && !userID))
      throw new Error(
        "Params missing in getMediaListByInheritance method call"
      );

    // --- fetch media list by inheritance
    let mediaInheritedData = await this.inheritanceProvider.getByInheritance(
      role,
      userID,
      this.dbPath,
      "list",
      usersData,
      this.userDBNodes,
      null,
      useCache
    );

    let mediaList = [];

    // --- merge media data based on inheritance
    lodash.each(mediaInheritedData, mediaData => {
      let ownerID = mediaData.ownerID;
      let userMediaList = lodash.map(mediaData.data, (data: any, key) => {
        return { ...data, key, ownerID };
      });
      mediaList = this.commonFun.mergeTwoArrUsingKeyMatch(
        mediaList,
        userMediaList
      );
    });

    // --- remove media with Override hide
    lodash.remove(
      mediaList,
      mediaData => lodash.toLower(mediaData.Override) == "hide"
    );

    // --- sort media list by name
    mediaList = lodash.orderBy(mediaList, mediaData =>
      lodash.toLower(mediaData.name)
    );

    // --- filter media list by filter function if available
    if (filterFn) {
      mediaList = lodash.filter(mediaList, filterFn);
    }

    return mediaList;
  }

  // --- get media data by inheritance
  async getMediaByInheritance(
    role: string,
    userID: string,
    mediaID: string,
    usersData?: any,
    useCache: boolean = false
  ) {
    // --- params verification
    if (!role || (role != Role.SUPERADMIN && !userID) || !mediaID)
      throw new Error(
        "Params missing in getMediaListByInheritance method call"
      );

    // --- fetch media data by inheritance
    let mediaInheritedData = await this.inheritanceProvider.getByInheritance(
      role,
      userID,
      `${this.dbPath}/${mediaID}`,
      "object",
      usersData,
      this.userDBNodes,
      null,
      useCache
    );

    // --- find highest priority non-null data from the inherited data
    let mediaData: any = lodash.find(
      mediaInheritedData,
      mediaData => mediaData.data != null
    );

    if (!mediaData) return null;
    if (mediaData.data.Override == "hide") return null;

    return { ...mediaData.data, key: mediaID, ownerID: mediaData.ownerID };
  }
}
