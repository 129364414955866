import { Injectable } from "@angular/core";
import CryptoJS from "crypto-js";
import lodash from "lodash";
import { environment } from "src/environments/environment";
import * as jsrsasign from "jsrsasign";

@Injectable({
  providedIn: "root"
})
export class EncryptionService {
  readonly encryptMethod = "AES-256-CBC";
  readonly encryptMethodLength = lodash
    .chain(this.encryptMethod.match(/\d+/))
    .nth(0)
    .floor()
    .value();
  readonly iterations = 10;

  constructor() {}

  // --- generate hash key of the given key (to make it more secure)
  generateHashKey(key: string, salt: CryptoJS.lib.WordArray) {
    return CryptoJS.PBKDF2(key, salt, {
      hasher: CryptoJS.algo.SHA512,
      keySize: this.encryptMethodLength / 32,
      iterations: this.iterations
    });
  }

  /**
   * Decrypt string.
   *
   * @link https://stackoverflow.com/questions/41222162/encrypt-in-php-openssl-and-decrypt-in-javascript-cryptojs Reference.
   * @link https://stackoverflow.com/questions/25492179/decode-a-base64-string-using-cryptojs Crypto JS base64 encode/decode reference.
   * @param string encryptedString The encrypted string to be decrypt.
   * @param string key The key.
   * @return string Return decrypted string.
   */
  decrypt(encryptedString, key) {
    var json = JSON.parse(
      CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(encryptedString))
    );

    var salt = CryptoJS.enc.Hex.parse(json.salt);
    var iv = CryptoJS.enc.Hex.parse(json.iv);
    var hashKey = this.generateHashKey(key, salt);

    var encrypted = json.ciphertext; // no need to base64 decode.
    var decrypted = CryptoJS.AES.decrypt(encrypted, hashKey, {
      mode: CryptoJS.mode.CBC,
      iv: iv
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  /**
   * Encrypt string.
   *
   * @link https://stackoverflow.com/questions/41222162/encrypt-in-php-openssl-and-decrypt-in-javascript-cryptojs Reference.
   * @link https://stackoverflow.com/questions/25492179/decode-a-base64-string-using-cryptojs Crypto JS base64 encode/decode reference.
   * @param string string The original string to be encrypt.
   * @param string key The key.
   * @return string Return encrypted string.
   */
  encrypt(data: any, key) {
    if (typeof data !== "string") data = JSON.stringify(data);

    var iv = CryptoJS.lib.WordArray.random(16); // AES 128, 192 and 256 uses 16 length initial vector
    var salt = CryptoJS.lib.WordArray.random(256);
    var hashKey = this.generateHashKey(key, salt);

    var encrypted = CryptoJS.AES.encrypt(data, hashKey, {
      mode: CryptoJS.mode.CBC,
      iv: iv
    });
    var encryptedString = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);

    var output = {
      ciphertext: encryptedString,
      iv: CryptoJS.enc.Hex.stringify(iv),
      salt: CryptoJS.enc.Hex.stringify(salt)
    };

    return CryptoJS.enc.Base64.stringify(
      CryptoJS.enc.Utf8.parse(JSON.stringify(output))
    );
  }

  // ---- create hash using sha256
  sha256CryptoJs = (data: string) => {
    let hash = CryptoJS.SHA256(`${data}`).toString(CryptoJS.enc.Base64);
    return String(hash).replace(/[^a-zA-Z ]/g, "")
  }
}
