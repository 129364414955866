import { Injectable } from "@angular/core";
import { NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { ConfirmationDialogComponent } from "./confirmation-dialog.component";

@Injectable()
export class ConfirmationDialogService {
  constructor(private modalService: NgbModal) {}

  public confirm(
    title: string,
    message: string,
    btnOkText: string,
    btnCancelText: string,
    dialogSize: "sm" | "lg" = "sm",
    options?: NgbModalOptions
  ): Promise<boolean> {
    let modalOptions: NgbModalOptions = {};
    if (options) modalOptions = options;
    modalOptions.size = dialogSize;
    const modalRef = this.modalService.open(ConfirmationDialogComponent, modalOptions);
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText ? btnOkText : "Copy Link";
    modalRef.componentInstance.btnCancelText = btnCancelText
      ? btnCancelText
      : "Cancel";

    return modalRef.result;
  }
}
