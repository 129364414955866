import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-camera-confirmation-dialog',
  templateUrl: './camera-confirmation-dialog.component.html',
  styleUrls: ['./camera-confirmation-dialog.component.scss']
})
export class CameraConfirmationDialogComponent implements OnInit {

  @Input() title: string;
  @Input() message: string;
  @Input() btnOkText: string;
  @Input() btnTryAgainText: string;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  // 0 = false (Cancel)
  // 1 = true (Use Another Camera)
  // 2 = try again

  public accept() {
    this.activeModal.close(0);
  }


  public tryAgain() {
    this.activeModal.close(2)
  }

  public dismiss() {
    this.activeModal.close();
  }
}
