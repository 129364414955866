import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from "src/environments/environment";

const config: AuthConfig = {
  issuer: "https://accounts.google.com",
  clientId:
    "155372021514-9psu9vlajm81c7kbrr993eae3296feie.apps.googleusercontent.com",
  redirectUri: window.location.origin + "/auth/sso-login",
  postLogoutRedirectUri: window.location.origin + "/auth/logout",
  responseType: "token id_token", // can not use 'code' type because google doesn't have support for code + PKCE for client side secure login as of 2024 Jul 25
  scope: "email",
  showDebugInformation: !environment.production,
  strictDiscoveryDocumentValidation: false
};

export default config;
