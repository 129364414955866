<div class="modal-header">
    <h4 class="modal-title">Pass requested</h4>
</div>

<div class="modal-body">
    <div *ngIf="!isLoading">
        <p class="text-capitalize"><span class="text-secondary">Requested by: </span>{{ passRequest.assignedToName }} </p>
        <p><span class="text-secondary">Pass Type: </span>{{ passTypeData.name }}</p>
        <p><span class="text-secondary">From: </span>{{ passRequest.startLocation }}</p>
        <p><span class="text-secondary">To: </span>{{ passRequest.destLocation }}</p>
        <p><span class="text-secondary">Duration: </span>{{ passDurations | findAndGetPropOfObj : 'value' : passRequest.duration : 'name' }}</p>
    </div>

    <!-- Loader -->
    <div class="text-center" *ngIf="isLoading">
        <nz-spin nzSimple [nzSize]="'large'" style="text-align: center;"></nz-spin>
        <span>Loading...</span>
    </div>
</div>

<div class="modal-footer" *ngIf="!isLoading">
    <div class="btn-container">
        <button type="button" class="btn btn-success btn-positive" (click)="approveRequest()">Approve</button>
        <button type="button" class="btn btn-danger btn-negative" (click)="declineRequest()">Decline</button>
    </div>
    <button type="button" class="btn btn-info btn-positive w100" (click)="moreDetails()">Get More Details</button>
</div>
