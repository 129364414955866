import { Pipe, PipeTransform } from '@angular/core';
import { PassTypesService } from "src/app/core/pass-types/pass-types.service";
import * as lodash from 'lodash';

@Pipe({
  name: 'getPassDuration'
})
export class GetPassDurationPipe implements PipeTransform {

  durationList: any[] = [];
  constructor(private passTypeService: PassTypesService) {
    this.durationList = this.passTypeService.durations;
  }

  transform(duration: any): string {
    if (!duration || duration == 'choose') return "-";

    return lodash.find(this.durationList, du => du.value == duration).name;
  }

}
