import { Pipe, PipeTransform } from "@angular/core";
import lodash from "lodash";

@Pipe({
  name: "findAndGetPropOfObj"
})
export class FindAndGetPropOfObjPipe implements PipeTransform {
  /**
   * @param array Array List of Items
   * @param searchProp key name of item to be compared
   * @param valueToCompare value to be compared with the given item key
   * @param returnProp required return property key name
   */
  transform(
    array: any[],
    searchProp: string,
    valueToCompare: any,
    returnProp: string,
    concatProps?: string[]
  ) {
    if(!array) {
      console.warn("You are supplying undefined to pipe findAndGetPropOfObj.");
      array = [];
    }
    let val = array.find(x => x[searchProp] == valueToCompare);
    if (!val) return "";
    else if (concatProps) {
      let propValues = [];
      lodash.each(concatProps, prop => {
        propValues.push(val[prop]);
      });
      return propValues.join(" ");
    } else return val[returnProp];
  }
}
