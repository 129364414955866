import { ErrorHandler } from "@angular/core";
import moment from "moment";
import { LocalStorageKeys } from "../enums/general.enums";

export class GlobalErrorHandler extends ErrorHandler {
  // --- clean all caches (usually before page reloads to avoid using cache in reload)
  async clearAllCaches() {
    try {
      let cacheKeys = await caches.keys();
      let removeCachesPromises = cacheKeys.map(cacheKey =>
        caches.delete(cacheKey)
      );
      await removeCachesPromises;
    } catch (e) {}
  }

  // --- handle "loading chunk failed error"
  async onChunkLoadFailed() {
    let thresholdInSec = 20 * 1000;

    // --- if reloaded in past n seconds, clear cache and then reload
    try {
      let chunkReloadAt: any = localStorage.getItem(
        LocalStorageKeys.chunkReloadAt
      );

      if (
        chunkReloadAt &&
        chunkReloadAt >= moment().valueOf() - thresholdInSec
      ) {
        // --- if cache cleared in past n seconds, do not reload again as clearing cache also didn't worked
        let cacheCleanedAt: any = localStorage.getItem(
          LocalStorageKeys.cacheCleanedAt
        );
        if (
          cacheCleanedAt &&
          cacheCleanedAt >= moment().valueOf() - thresholdInSec
        ) {
          console.log("Preventing reload loop for chunk failed error!");
          return;
        }

        // --- store cache clean timestamp & clean cache
        await this.clearAllCaches();
        localStorage.setItem(
          LocalStorageKeys.cacheCleanedAt,
          `${moment().valueOf()}`
        );
      }
    } catch (e) {}

    // --- store reload timestamp
    try {
      localStorage.setItem(
        LocalStorageKeys.chunkReloadAt,
        `${moment().valueOf()}`
      );
    } catch (e) {}

    // --- reload page
    window.location.reload();
  }

  // --- global error handler
  async handleError(error: any) {
    const chunkFailedMessageFirefox = /.*Loading chunk [^\s]+ failed.*/gi;
    const chunkFailedMessageChrome = /.*Refused to execute script from 'https:\/\/landing\.high5\.id\/([^\s]*\/)*[^\s]*\.js'.*/gi;

    if (
      error &&
      (chunkFailedMessageFirefox.test(error.message) ||
        chunkFailedMessageChrome.test(error.message))
    ) {
      await this.onChunkLoadFailed();
    }

    console.error(error);
  }
}
