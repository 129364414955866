import { Injectable } from "@angular/core";
import { Role } from "../enums/user.enums";
import {
  InheritanceService,
  ReplacementTags
} from "../inheritance/inheritance.service";
import lodash from "lodash";
import firebase from "firebase/app";

@Injectable({
  providedIn: "root"
})
export class PreferencesService {
  readonly userDBNodes = {
    [Role.ORG]: "organizations",
    [Role.STUDIO]: "studios",
    [Role.SUPERADMIN]: null
  };

  readonly dbPath = `${ReplacementTags.USER_DB_NODE}/${ReplacementTags.USER_ID}/settings/org-preference`;

  constructor(private inheritanceProvider: InheritanceService) {}

  // --- get preference data by inheritance
  async getPreferenceByInheritance(
    role: string,
    userID: string,
    prefID: string,
    usersData?: any,
    useCache: boolean = false,
    propToReturn?: string
  ) {
    // --- params verification
    if (!role || (role != Role.SUPERADMIN && !userID) || !prefID)
      throw new Error(
        "Params missing in getPreferenceByInheritance method call"
      );

    // --- prepare query interceptor
    const queryInterceptor = {
      uniqueIdentifier: prefID,
      execute: (dbQuery: firebase.database.Query) => {
        return dbQuery.orderByChild("id").equalTo(prefID);
      }
    };

    // --- fetch preference data by inheritance
    let prefInheritedData = await this.inheritanceProvider.getByInheritance(
      role,
      userID,
      `${this.dbPath}`,
      "list",
      usersData,
      this.userDBNodes,
      null,
      useCache,
      queryInterceptor
    );

    // --- find highest priority non-null data from the inherited data
    let prefData: any = lodash.find(
      prefInheritedData,
      prefData => prefData.data != null
    );

    if (!prefData) return null;

    // --- process results
    let prefDataa = lodash
      .chain(prefData.data)
      .map((data, key) => {
        return { ...data, key };
      })
      .first()
      .value();

    if (
      lodash
        .chain(prefDataa)
        .get("Override")
        .toLower()
        .value() == "hide"
    )
      return null;

    if (propToReturn) return prefDataa[propToReturn];
    return { ...prefDataa, ownerID: prefData.ownerID };
  }
}
