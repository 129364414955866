<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" class="white-space-pre-wrap">
      {{ message }}
    </div>
    <div class="modal-footer">
      <button type="button" class="btn" (click)="accept()">{{ btnOkText }}</button>
     
    </div>