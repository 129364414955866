import { BrowserModule } from "@angular/platform-browser";
import { ErrorHandler, NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NgZorroAntdModule, NZ_I18N, en_US } from "ng-zorro-antd";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { registerLocaleData } from "@angular/common";
import en from "@angular/common/locales/en";
import { CoreModule } from "./core/core.module";
import { PageContainerComponent } from "./layout/page-container/page-container.component";
import { DragulaService } from "ng2-dragula";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NzToolTipModule } from "ng-zorro-antd/tooltip";
import { ConfirmationDialogService } from "./features/confirm-dialog/pages/confirmation-dialog/confirmation-dialog.service";
import { ConfirmationDialogComponent } from "./features/confirm-dialog/pages/confirmation-dialog/confirmation-dialog.component";
import { AlertDialogService } from "./features/alert-dialog/alert-dialog.service";
import { AlertDialogComponent } from "./features/alert-dialog/alert-dialog.component";
import { PhotoHomeConfirmDialogComponent } from "./features/confirm-dialog/pages/photo-home-confirm-dialog/photo-home-confirm-dialog.component";
import { PhotoHomeConfirmationDialogService } from "./features/confirm-dialog/pages/photo-home-confirm-dialog/photo-home-confirm-dialog.service";
import { ForgotPasswordDialogComponent } from "./features/confirm-dialog/pages/forgot-password-dialog/forgot-password-dialog.component";
import { ForgotPasswordDialogService } from "./features/confirm-dialog/pages/forgot-password-dialog/forgot-password-dialog.service";
import { AppCommonModule } from "./features/app-common/app-common.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { PwaPopupComponent } from "./features/confirm-dialog/pages/pwa-popup/pwa-popup.component";
import { PwaPopupDialogService } from "./features/confirm-dialog/pages/pwa-popup/pwa-popup-dialog.service";
import { ToastrModule } from "ngx-toastr";
import { ShowQrCodeComponent } from "./features/confirm-dialog/pages/show-qr-code/show-qr-code.component";
import { ShowQrCodeService } from "./features/confirm-dialog/pages/show-qr-code/show-qr-code.service";
import { CameraConfirmationDialogService } from "./features/confirm-dialog/pages/camera-confirmation-dialog/camera-confirmation-dialog.service";
import { ElasticService } from "./core/elastic/elastic";
import { GetIndPhotoUsingPrefSettings } from "./core/pipes/getIndPhotoUsingPrefSettings/get-ind-photo-using-pref-settings.pipe";
import { ViewAnonPipe } from "./core/pipes/viewAnon/view-anon.pipe";
import { Auth2FAModalComponent } from "./features/auth2-famodal/auth2-famodal.component";
import { GetIndPropPipe } from "./core/pipes/getIndProp/get-ind-prop.pipe";
import { CountAgeFromDOB } from "./core/pipes/countAgeFromDOB/count-age-from-dob";
import { GameListDialogComponent } from "./features/confirm-dialog/pages/game-list-dialog/game-list-dialog.component";
import { GameListDialogService } from "./features/confirm-dialog/pages/game-list-dialog/game-list-dialog.service";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { ChangePasswordDialogComponent } from "./features/confirm-dialog/pages/change-password-dialog/change-password-dialog.component";
import { ChangePasswordDialogService } from "./features/confirm-dialog/pages/change-password-dialog/change-password-dialog.service";
import { HeaderLayoutComponent } from "./layout/header-layout/header-layout.component";
import { CenterLayoutComponent } from "./layout/center-layout/center-layout.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { GlobalErrorHandler } from "./core/error-handlers/global-error-handler";
import { MultiMatchModalComponent } from './features/multi-match/multi-match-modal/multi-match-modal.component';
import { HttpInterceptorService } from "./core/http-interceptor/http-interceptor.service";
import { GetPassStatusPipe } from "./core/pipes/passStatus/get-pass-status.pipe";
import { OAuthModule } from "angular-oauth2-oidc";
registerLocaleData(en);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    PageContainerComponent,
    ConfirmationDialogComponent,
    AlertDialogComponent,
    PhotoHomeConfirmDialogComponent,
    ForgotPasswordDialogComponent,
    PwaPopupComponent,
    ShowQrCodeComponent,
    Auth2FAModalComponent,
    GameListDialogComponent,
    ChangePasswordDialogComponent,
    HeaderLayoutComponent,
    CenterLayoutComponent,
    MultiMatchModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgZorroAntdModule,
    NzToolTipModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    NgbModule,
    AppCommonModule,
    AngularFireAuthModule,
    OAuthModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: "toast-bottom-center"
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: "en"
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: true,
      registrationStrategy: "registerImmediately"
    }),
    ReactiveFormsModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    DragulaService,
    ConfirmationDialogService,
    AlertDialogService,
    PhotoHomeConfirmationDialogService,
    ForgotPasswordDialogService,
    GameListDialogService,
    PwaPopupDialogService,
    ShowQrCodeService,
    CameraConfirmationDialogService,
    ElasticService,
    GetIndPhotoUsingPrefSettings,
    ViewAnonPipe,
    GetIndPropPipe,
    GetPassStatusPipe,
    CountAgeFromDOB,
    ChangePasswordDialogService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [ConfirmationDialogComponent, AlertDialogComponent]
})
export class AppModule {}
