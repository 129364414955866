import { Location } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { AngularFireStorage } from "@angular/fire/storage";
import { Params } from "@angular/router";
import lodash from "lodash";
import moment from "moment";
import { map } from "rxjs/operators";
import { getDeviceInfo } from "../../../assets/js/deviceInfo.js";
import { environment } from "../../../environments/environment.js";
import { CacheKeys, CacheService } from "../cache/cache.service.js";
import { CommonServiceService } from "../common/common-service.service.js";
import { Role } from "../enums/user.enums.js";
import {
  InheritanceService,
  ReplacementTags
} from "../inheritance/inheritance.service.js";
import { getQueryParams } from "../utils/queryParams.utils.js";
import { OrganizationsService } from "../organizations/organizations.service.js";

@Injectable({
  providedIn: "root"
})
export class SettingsService {
  constructor(
    public http: HttpClient,
    public db: AngularFireDatabase,
    public location: Location,
    private fireStorage: AngularFireStorage,
    private commonService: CommonServiceService,
    private cacheService: CacheService,
    private inheritanceProvider: InheritanceService
  ) {}

  adminPasswordValidaion(
    pwMinLen: any,
    pwMaxLen: any,
    pwComplexity: string,
    password: string
  ) {
    try {
      let atLeastOneSpecialChar: RegExp = new RegExp(
        /^(?=.*[0-9])(?=.*[!@#$%^&*()_+,.\\\/;':"-]).{5,}$/
      );
      let atLeastOneNo: RegExp = new RegExp(/^(?=(.*[\d]){1,})/);
      let oneNoOneChar: RegExp = new RegExp(
        /^(?=.*[!@#$%^&*()_+,.\\\/;':"-]).{5,}$/
      );
      pwMinLen = pwMinLen === 1 ? 20 : parseInt(pwMinLen);
      pwMaxLen = pwMaxLen === 1 ? 20 : parseInt(pwMaxLen);
      if (password.length < pwMinLen || password.length > pwMaxLen) {
        return `Your password must be between ${pwMinLen} and ${pwMaxLen} characters long.`;
      } else if (password.length >= pwMinLen && password.length <= pwMaxLen) {
        if (pwComplexity == "atLeastOneSpecialChar") {
          if (!atLeastOneSpecialChar.test(password)) {
            return "Your password must include at least one special character.";
          } else {
            return null;
          }
        }
        if (pwComplexity == "atLeastOneNo") {
          if (!atLeastOneNo.test(password)) {
            return "Your password must include at least one number.";
          } else {
            return null;
          }
        }
        if (pwComplexity == "oneNoOneChar" || pwComplexity == "1") {
          if (!oneNoOneChar.test(password)) {
            return "Your password must include at least one number and one special character.";
          } else {
            return null;
          }
        }
      }
    } catch {
      return null;
    }
  }

  async getTaxExeptValue(orgService: OrganizationsService, orgID) {
    let taxExampt = await orgService.getOrgProp(orgID, "taxExempt")
    if(lodash.isNil(taxExampt)) taxExampt = true;
    return taxExampt;
  }

  deviceInfoDbPresent(indID, orgID, currentCycle) {
    return this.db
      .list(
        "/Transactions/" +
          orgID +
          "/" +
          currentCycle +
          "/devices/" +
          indID +
          "/"
      )
      .snapshotChanges()
      .pipe(
        map(changes =>
          changes.map((c: any) => ({ key: c.payload.key, ...c.payload.val() }))
        )
      );
  }

  compare(obj1, obj2) {
    let k1 = { ...obj1 };
    let k2 = { ...obj2 };
    if (
      k1.OS.model === k2.OS.model &&
      k1.OS.version === k2.OS.version &&
      k1.Browser.model === k2.Browser.model &&
      k1.Browser.version === k2.Browser.version
    ) {
      return true;
    } else {
      return false;
    }
  }

  async addDeviceInfo(userName, indID, orgID, updateObj?) {
    let deviceInfo = getDeviceInfo();
    let currentCycle = await this.commonService.getOrgCurrentCycle(orgID);
    deviceInfo.timeStamp = new Date().getTime();
    deviceInfo.name = userName;
    let sub = this.deviceInfoDbPresent(indID, orgID, currentCycle).subscribe(
      res => {
        sub.unsubscribe();
        // --- set browser model as chrome
        if (
          deviceInfo.device.OS.model == "iOS" &&
          deviceInfo &&
          deviceInfo.device &&
          deviceInfo.device.hardware &&
          deviceInfo.device.hardware.userAgent &&
          deviceInfo.device.hardware.userAgent.indexOf("CriOS/") != -1
        ) {
          deviceInfo.device.Browser = {
            model: "Chrome",
            version: deviceInfo.device.hardware.userAgent
              .split("/")[3]
              .split(" ")[0]
          };
        }
        // --- set browser model as firefox
        if (
          deviceInfo.device.OS.model == "iOS" &&
          deviceInfo &&
          deviceInfo.device &&
          deviceInfo.device.hardware &&
          deviceInfo.device.hardware.userAgent &&
          deviceInfo.device.hardware.userAgent.indexOf("FxiOS/") != -1
        ) {
          deviceInfo.device.Browser = {
            model: "Firefox",
            version: deviceInfo.device.hardware.userAgent
              .split("/")[3]
              .split(" ")[0]
          };
        }
        if (res.length > 0) {
          let found = res.filter(item =>
            this.compare(item.device, deviceInfo.device)
          );
          if (found.length > 0) {
            if (updateObj) {
              updateObj.timestamp = Number(moment().format("x"));
              // in Transactions node
              this.db.list(`/Transactions/${orgID}/${currentCycle}/devices/${indID}/${found[0].key}/updatedAt`).push(updateObj);
              // in Individual node
            } else {
              // in Transactions node
              this.db.object(`/Transactions/${orgID}/${currentCycle}/devices/${indID}/${found[0].key}/updatedTime`).set(Date.now());
              // in Individual node
            }
            return;
          }
        }
        deviceInfo.updatedAt = updateObj
          ? { [this.commonService.createFirebasePushId()]: updateObj }
          : null;
        this.addDeviceInfoDB(orgID, currentCycle, indID, deviceInfo);
      }
    );
  }
  addDeviceInfoDB(orgID, currentCycle, indID, deviceInfo) {
    deviceInfo.url = window.location.href;
    this.db
      .list(
        "/Transactions/" +
          orgID +
          "/" +
          currentCycle +
          "/devices/" +
          indID +
          "/"
      )
      .push(deviceInfo);
  }
  getVisualsLogo(callback: Function, orgID) {
    let orgLogoUrl;
    let orgLogoRef = "photos/organisations/" + orgID + "/orgLogo";
    const imageRef = this.fireStorage.ref(orgLogoRef);
    const subscription = imageRef
      .getDownloadURL()
      .toPromise()
      .then(url => {
        orgLogoUrl = url;
        callback(url);
      })
      .catch(e => {
        callback();
      });
  }

  getStudioLogo(studioID: string, callback: Function) {
    let studioLogoUrl = "";
    if (!studioID) {
      callback();
      return;
    }
    let studioLogoRef = "photos/studios/" + studioID + "/studioLogo";
    const imageRef = this.fireStorage.ref(studioLogoRef);
    imageRef
      .getDownloadURL()
      .toPromise()
      .then(url => {
        studioLogoUrl = url;
        callback(url);
      })
      .catch(() => {
        callback();
      });
  }

  fetchQueryParams = () => {
    return window.location.search.length === 0
      ? {}
      : window.location.search
          .substr(1)
          .split("&")
          .map(pairString => pairString.split("="))
          .reduce((out, pair) => {
            out[pair[0]] = pair[1];
            return out;
          }, {} as Params);
  };

  // --- check if url is valid or not
  isUrlValid(): boolean {
    const queryParams = this.fetchQueryParams();
    let isValidParams: boolean = true;
    Object.keys(queryParams).forEach(objectKey => {
      if (
        objectKey == "orgId" ||
        objectKey == "indID" ||
        objectKey == "designTypeId"
      ) {
        if (!queryParams[objectKey]) {
          isValidParams = false;
        }
      }
    });
    return isValidParams;
  }

  // --- check if url is valid or not
  visitorUrlValid(): boolean {
    const queryParams = this.fetchQueryParams();
    let isValidParams: boolean = true;
    if (!queryParams["orgID"]) {
      isValidParams = false;
    }
    return isValidParams;
  }

  post(endpoint: string, body: any, reqOpts?: any) {
    // --- assemble url
    let url = endpoint;
    if (endpoint.indexOf("http") === -1) {
      url = environment.newApiBaseUrl + "/" + endpoint;
    }

    return this.http.post(url, body, reqOpts);
  }

  // --- send error email to high5 admin
  sendErrorEmail(emailContent) {
    const emailPostData = {
      from: environment.mailFromAddress,
      to: [
        emailContent.toEmail
          ? emailContent.toEmail
          : environment.superAdminEmail
      ],
      text: `${
        emailContent.emailBody
          ? emailContent.emailBody
          : "The following URL was just used, but generated an error:"
      } ${window.location.href} \n Timestamp:- ${moment().format(
        "MMMM Do YYYY, h:mm:ss a"
      )}`,
      subject: `${
        emailContent.emailSubject ? emailContent.emailSubject : "ERROR DETECTED"
      }`,
      ["v:is_from_test"]: !environment.production
    };
    this.post(environment.mailgunObj.endPoint, {
      data: [emailPostData],
      type: "single",
      emailType: "email"
    }).subscribe(res => {});
  }

  // --- send error email to high5 admin
  invalidLicenseErrorEmail(emailContent, error) {
    let userAuth = getQueryParams();
    let emailBody = "";
    if (error == "Design Source not found!") {
      emailBody = `A URL was followed that produced an error.\n\nError: Does not have access to a design - this may be due to an expired license\n\nOrg ID: ${
        userAuth.orgID
      }\n\nOrg Display Name: ${emailContent.orgName}\n\nRep: ${
        emailContent.hasOwnProperty("studioName")
          ? emailContent.studioName
          : "-"
      }\n\nURL that caused the error: ${
        window.location.href
      }\n\nTimestamp: ${moment().format("MMMM Do YYYY, h:mm:ss a")}`;
    } else {
      let msg = this.commonService.prepareErrorMessage(error);
      emailBody = `A URL was followed that produced an error.\n\nError: ${
        msg ? msg : "no error message"
      } \n\nOrg ID: ${userAuth.orgID}\n\nOrg Display Name: ${
        emailContent.orgName
      }\n\nRep: ${
        emailContent.hasOwnProperty("studioName")
          ? emailContent.studioName
          : "-"
      }\n\nURL that caused the error: ${
        window.location.href
      }\n\nTimestamp: ${moment().format("MMMM Do YYYY, h:mm:ss a")}`;
    }
    const emailPostData = {
      from: environment.mailFromAddress,
      to: [
        emailContent.toEmail
          ? emailContent.toEmail
          : environment.superAdminEmail
      ],
      text: emailBody,
      subject: `${
        emailContent.emailSubject ? emailContent.emailSubject : "ERROR DETECTED"
      }`,
      ["v:is_from_test"]: !environment.production
    };
    this.post(environment.mailgunObj.endPoint, {
      data: [emailPostData],
      type: "single",
      emailType: "email"
    }).subscribe(res => {});
  }

  // --- if email send when invalid url deleted or not valid license
  async isEmailSend(key: string): Promise<any> {
    let snapshot = await this.db
      .object(`/settings/reportEmailSettings/${key}`)
      .query.once("value");
    let reportEmailSettings = snapshot.val();
    if (lodash.get(reportEmailSettings, "isSendEmail") == true) {
      return reportEmailSettings;
    } else return false;
  }

  // --- merge students reasons
  mergeStudentsReason(arr1, arr2) {
    arr1 = lodash.cloneDeep(arr1);
    arr2 = lodash.cloneDeep(arr2);
    let resultantArr = arr1;
    if (arr2.length > 0) {
      for (let i = 0; i < arr2.length; i++) {
        const ele = arr2[i];
        const eleId = arr2[i].id;

        let index = resultantArr.findIndex(arr => {
          return arr.id == eleId;
        });

        if (index == -1) {
          resultantArr.push(ele);
        }
        {
          resultantArr[index] = { ...ele, ...resultantArr[index] };
        }

        if (ele.reasons) {
          ele.reasons.forEach(element => {
            // --- find index
            let i = index == -1 ? resultantArr.length - 1 : index;

            if (resultantArr[i].hasOwnProperty("reasons")) {
              if (
                !resultantArr[i].reasons.find(o1 => {
                  return o1.key == element.key;
                })
              ) {
                if (resultantArr[i].hasOwnProperty("reasons")) {
                  resultantArr[i].reasons.push(element);
                } else {
                  resultantArr[i] = { ...resultantArr[i], reasons: [element] };
                }
              }
            } else {
              resultantArr[i] = { ...resultantArr[i], reasons: [element] };
            }
          });
        }
      }
    }
    return lodash.cloneDeep(resultantArr);
  }

  // --- remove override hide data
  removeOverrideHideReason(reasonsArr: any): Promise<any> {
    return new Promise((resolve, reject) => {
      reasonsArr = reasonsArr.filter((reason, reasonIndex) => {
        if (reason.hasOwnProperty("Override") && reason.Override == "hide") {
          return false;
        } else {
          if (reason.hasOwnProperty("reasons") && reason.reasons.length > 0) {
            reason.reasons = reason.reasons.filter(ele => {
              if (ele.hasOwnProperty("Override") && ele.Override == "hide") {
                return false;
              } else {
                return true;
              }
            });
          }
          return true;
        }
      });
      return resolve(lodash.cloneDeep(reasonsArr));
    });
  }

  getSAStudentReason(key: string) {
    return new Promise((resolve, reject) => {
      let orgSub = this.db
        .list(`${key}`)
        .snapshotChanges()
        .subscribe(SAStudentReasons => {
          orgSub.unsubscribe();
          let SAStudentReasonsArr = [];
          if (SAStudentReasons && SAStudentReasons.length > 0) {
            lodash.each(SAStudentReasons, entry => {
              const value: any = entry.payload.val();
              value["key"] = entry.payload.key;
              value["isFrom"] = "vip";
              if (!value["id"]) value.id = value.key;
              let tempArr = [];
              if (value.reasons) {
                Object.keys(value.reasons).forEach(key => {
                  let val = value.reasons[key];
                  val["key"] = key;
                  val["isFrom"] = "vip";
                  tempArr.push(val);
                });
                value["reasons"] = tempArr;
              }
              SAStudentReasonsArr.push(value);
            });
          }
          resolve(SAStudentReasonsArr);
        });
    });
  }

  // --- get studio students reasons
  getStudioStudentReason(studioId: string, path: string) {
    return new Promise(resolve => {
      if (!studioId) return resolve([]);
      let orgSub = this.db
        .list(`/studios/${studioId}${path}`)
        .snapshotChanges()
        .subscribe(studioStudentReasons => {
          orgSub.unsubscribe();
          let studioStudentReasonsArr = [];
          if (studioStudentReasons && studioStudentReasons.length > 0) {
            lodash.each(studioStudentReasons, entry => {
              const value: any = entry.payload.val();
              value["key"] = entry.payload.key;
              value["isFrom"] = "studio";
              let tempArr = [];
              if (!value["id"]) value.id = value.key;
              if (value.reasons) {
                Object.keys(value.reasons).forEach(key => {
                  let val = value.reasons[key];
                  val["key"] = key;
                  val["isFrom"] = "studio";
                  tempArr.push(val);
                });
                value["reasons"] = tempArr;
              }
              studioStudentReasonsArr.push(value);
            });
          }
          resolve(studioStudentReasonsArr);
        });
    });
  }

  // --- get org students reasons
  getOrgStudentReason(orgId: string, path: string) {
    return new Promise(resolve => {
      if (!orgId) return resolve([]);
      let orgSub = this.db
        .list(`/organizations/${orgId}${path}`)
        .snapshotChanges()
        .subscribe(orgStudentReasons => {
          orgSub.unsubscribe();
          let orgStudentReasonsArr = [];
          if (orgStudentReasons && orgStudentReasons.length > 0) {
            lodash.each(orgStudentReasons, entry => {
              const value: any = entry.payload.val();
              value["key"] = entry.payload.key;
              value["isFrom"] = "org";
              if (!value["id"]) value.id = value.key;
              let tempArr = [];
              if (value.reasons) {
                Object.keys(value.reasons).forEach(key => {
                  let val = value.reasons[key];
                  val["key"] = key;
                  val["isFrom"] = "org";
                  tempArr.push(val);
                });
                value["reasons"] = tempArr;
              }
              orgStudentReasonsArr.push(value);
            });
          }
          resolve(orgStudentReasonsArr);
        });
    });
  }

  dbPathReasons = `${ReplacementTags.USER_DB_NODE}/${ReplacementTags.USER_ID}/settings/reasons`;
  readonly userDBNodes = {
    [Role.ORG]: "organizations",
    [Role.STUDIO]: "studios",
    [Role.SUPERADMIN]: null
  };

  // --- get list of crossing types by inheritance
  async getCrossingTypes(
    type: "student" | "visitor",
    role: string,
    userID: string,
    usersData?: any,
    useCache: boolean = false
  ) {
    // --- params verification
    if (!role || (role != Role.SUPERADMIN && !userID))
      throw new Error("Params missing in getCrossingTypes method call");

    // --- fetch crossing data by inheritance
    let dbPath = `${this.dbPathReasons}/${
      type == "student" ? "school_Student" : "school_Visitor"
    }`;
    let crossingTypesInheritedData = await this.inheritanceProvider.getByInheritance(
      role,
      userID,
      dbPath,
      "list",
      usersData,
      this.userDBNodes,
      null,
      useCache
    );

    // --- merge reasons data based on inheritance
    let crossingTypes = {};
    lodash
      .chain(crossingTypesInheritedData)
      .reverse()
      .each(reasonsData => {
        let ownerID = reasonsData.ownerID;
        let userCrossingTypes = lodash.mapValues(
          reasonsData.data,
          (data: any) => {
            return { ...data, ownerID };
          }
        );

        crossingTypes = lodash.merge(crossingTypes, userCrossingTypes);
      })
      .value();

    // --- check if "Others" should be there in reasons list, add it there if not already
    lodash.each(crossingTypes, crossingType => {
      if (crossingType.isOthersAllowed) {
        if (!lodash.has(crossingType.reasons, "other")) {
          lodash.set(crossingType.reasons, "other", { reasonName: "Other..." });
        }
      }
    });

    // --- remove crossing types & reasons with override hide flag
    crossingTypes = lodash
      .chain(crossingTypes)
      .mapValues(crossingType => {
        // --- remove crossing type with override hide
        if (lodash.toLower(crossingType.Override) == "hide") return null;

        // --- remove reasons with override hide
        crossingType.reasons = lodash
          .chain(crossingType.reasons)
          .mapValues(reason => {
            if (lodash.toLower(reason.Override) == "hide") return null;

            return reason;
          })
          .omitBy(reason => lodash.isNil(reason))
          .value();

        return crossingType;
      })
      .omitBy(crossingType => lodash.isNil(crossingType))
      .value();

    return crossingTypes;
  }

  // --- get list of crossing types by inheritance
  async getCrossingType(
    type: "student" | "visitor",
    role: string,
    userID: string,
    crossingTypeKey: string,
    usersData?: any,
    useCache: boolean = false
  ) {
    // --- params verification
    if (!role || (role != Role.SUPERADMIN && !userID) || !crossingTypeKey)
      throw new Error("Params missing in getCrossingType method call");

    // --- fetch crossing data by inheritance
    let dbPath = `${this.dbPathReasons}/${
      type == "student" ? "school_Student" : "school_Visitor"
    }/${crossingTypeKey}`;
    let crossingTypeInheritedData = await this.inheritanceProvider.getByInheritance(
      role,
      userID,
      dbPath,
      "object",
      usersData,
      this.userDBNodes,
      null,
      useCache
    );

    // --- merge crossing type data based on inheritance
    let crossingType: any = {};
    lodash
      .chain(crossingTypeInheritedData)
      .reverse()
      .each(crossingTypeData => {
        let ownerID = crossingTypeData.ownerID;
        let userCrossingType = { ...crossingTypeData.data, ownerID };
        crossingType = lodash.merge(crossingType, userCrossingType);
      })
      .value();

    // --- check if "Others" should be there in reasons list, add it there if not already
    if (crossingType.isOthersAllowed) {
      if (!lodash.has(crossingType.reasons, "other")) {
        lodash.set(crossingType.reasons, "other", { reasonName: "Other..." });
      }
    }

    // --- remove crossing type with override hide
    if (lodash.toLower(crossingType.Override) == "hide") return null;

    // --- remove reasons with override hide
    crossingType.reasons = lodash
      .chain(crossingType.reasons)
      .mapValues(reason => {
        if (lodash.toLower(reason.Override) == "hide") return null;

        return reason;
      })
      .omitBy(reason => lodash.isNil(reason))
      .value();

    return crossingType;
  }

  studentReasonsPath = "/settings/reasons/school_Student";
  visitorReasonsPath = "/settings/reasons/school_Visitor";
  /**
   *
   * @param role
   * @param orgId
   * @param studioId
   */
  getVisitorReasons(orgService: OrganizationsService, orgId: string, studioId: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let studentReasonsList: any[] = [];
      let tempStudentReasonsArr = [];
      if (!orgId) return reject("Org Id Required");
      if (!studioId) studioId = await orgService.getOrgProp(orgId, "studioID", true);

      let readReasonsPromises = [];
      readReasonsPromises.push(
        this.getOrgStudentReason(orgId, this.visitorReasonsPath)
      );
      readReasonsPromises.push(
        this.getStudioStudentReason(studioId, this.visitorReasonsPath)
      );
      readReasonsPromises.push(
        this.getSAStudentReason(this.visitorReasonsPath)
      );

      let readReasonsPromisesResults = await Promise.all(readReasonsPromises);

      let orgStudentReasons: any = readReasonsPromisesResults[0];
      let studioStudentReasons: any = readReasonsPromisesResults[1];
      let SAStudentReasons: any = readReasonsPromisesResults[2];

      if (orgStudentReasons.length > 0 && studioStudentReasons.length > 0) {
        let tempArr = this.mergeStudentsReason(
          orgStudentReasons,
          studioStudentReasons
        );
        tempStudentReasonsArr = this.mergeStudentsReason(
          tempArr,
          SAStudentReasons
        );
        studentReasonsList = await this.removeOverrideHideReason(
          tempStudentReasonsArr
        );
      } else if (
        orgStudentReasons.length == 0 &&
        studioStudentReasons.length > 0
      ) {
        tempStudentReasonsArr = this.mergeStudentsReason(
          studioStudentReasons,
          SAStudentReasons
        );
        studentReasonsList = await this.removeOverrideHideReason(
          tempStudentReasonsArr
        );
      } else if (
        orgStudentReasons.length > 0 &&
        studioStudentReasons.length == 0
      ) {
        tempStudentReasonsArr = this.mergeStudentsReason(
          orgStudentReasons,
          SAStudentReasons
        );
        studentReasonsList = await this.removeOverrideHideReason(
          tempStudentReasonsArr
        );
      } else if (
        orgStudentReasons.length == 0 &&
        studioStudentReasons.length == 0
      ) {
        studentReasonsList = SAStudentReasons;
      }

      // --- check if "Others" should be there in reasons list, add it there if not already
      lodash.each(studentReasonsList, crossingType => {
        if (
          crossingType.hasOwnProperty("isOthersAllowed") &&
          crossingType.isOthersAllowed
        ) {
          if (!crossingType.reasons) crossingType.reasons = [];
          if (!crossingType.reasons.find(reason => reason.key == "other")) {
            crossingType.reasons.push({ key: "other", reasonName: "Other..." });
          }
        }
      });

      // --- remove crossing types which does not have any reasons
      // studentReasonsList = lodash.filter(studentReasonsList, crossingType => crossingType.reasons)

      return resolve(studentReasonsList);
    });
  }

  /**
   *
   * @param role
   * @param orgId
   * @param studioId
   */
  getStudentReasons(orgService: OrganizationsService, orgId: string, studioId: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let studentReasonsList: any[] = [];
      let tempStudentReasonsArr = [];
      if (!orgId) return reject("Org Id Required");
      if (!studioId) studioId = await orgService.getOrgProp(orgId, "studioID", true);

      let readReasonsPromises = [];
      readReasonsPromises.push(
        this.getOrgStudentReason(orgId, this.studentReasonsPath)
      );
      readReasonsPromises.push(
        this.getStudioStudentReason(studioId, this.studentReasonsPath)
      );
      readReasonsPromises.push(
        this.getSAStudentReason(this.studentReasonsPath)
      );

      let readReasonsPromisesResults = await Promise.all(readReasonsPromises);

      let orgStudentReasons: any = readReasonsPromisesResults[0];
      let studioStudentReasons: any = readReasonsPromisesResults[1];
      let SAStudentReasons: any = readReasonsPromisesResults[2];

      if (orgStudentReasons.length > 0 && studioStudentReasons.length > 0) {
        let tempArr = this.mergeStudentsReason(
          orgStudentReasons,
          studioStudentReasons
        );
        tempStudentReasonsArr = this.mergeStudentsReason(
          tempArr,
          SAStudentReasons
        );
        studentReasonsList = await this.removeOverrideHideReason(
          tempStudentReasonsArr
        );
      } else if (
        orgStudentReasons.length == 0 &&
        studioStudentReasons.length > 0
      ) {
        tempStudentReasonsArr = this.mergeStudentsReason(
          studioStudentReasons,
          SAStudentReasons
        );
        studentReasonsList = await this.removeOverrideHideReason(
          tempStudentReasonsArr
        );
      } else if (
        orgStudentReasons.length > 0 &&
        studioStudentReasons.length == 0
      ) {
        tempStudentReasonsArr = this.mergeStudentsReason(
          orgStudentReasons,
          SAStudentReasons
        );
        studentReasonsList = await this.removeOverrideHideReason(
          tempStudentReasonsArr
        );
      } else if (
        orgStudentReasons.length == 0 &&
        studioStudentReasons.length == 0
      ) {
        studentReasonsList = SAStudentReasons;
      }

      // --- update objects of list with default values
      lodash.each(studentReasonsList, crossingType => {
        // --- check if "Others" should be there in reasons list, add it there if not already
        if (
          crossingType.hasOwnProperty("isOthersAllowed") &&
          crossingType.isOthersAllowed
        ) {
          if (!crossingType.reasons) crossingType.reasons = [];
          if (!crossingType.reasons.find(reason => reason.key == "other")) {
            crossingType.reasons.push({ key: "other", reasonName: "Other..." });
          }
        }

        // --- add canStartOffCampus default value in crossing types if not present already
        // if(!crossingType.hasOwnProperty('canStartOffCampus')) crossingType.canStartOffCampus = true
      });

      // --- remove crossing types which does not have any reasons
      studentReasonsList = lodash.filter(
        studentReasonsList,
        crossingType => crossingType.reasons
      );

      return resolve(studentReasonsList);
    });
  }
  // monitor locations
  getPromocode(callback?: Function) {
    let subscription = this.db
      .list("/settings/eCommerce/PromoCodes")
      .snapshotChanges()
      .subscribe(obj => {
        let list = [];
        lodash.each(obj, entry => {
          let value = {
            key: entry.key,
            ...entry.payload.val()
          };

          list.push(value);
        });
        callback(list);
      });
  }

  getVideoLinks(callback?: Function) {
    let subscription = this.db
      .list("/settings/eCommerce/Videos")
      .snapshotChanges()
      .subscribe(obj => {
        let list = [];
        lodash.each(obj, entry => {
          let value = {
            key: entry.key,
            ...entry.payload.val()
          };

          list.push(value);
        });
        callback(list);
      });
  }

  // --- get list of locations
  async getLocations(orgID: string) {
    let snapshot = await this.db
      .list(`organizations/${orgID}/settings/locations`)
      .query.once("value");
    let locations = snapshot.val();
    locations = lodash.map(locations, (name, key) => {
      return { key, name };
    });
    return lodash.sortBy(locations ,[(location) => location.name]);;
  }

  // --- get location data
  async getLocation(orgID: string, locationKey: string) {
    let snapshot = await this.db
      .object(`organizations/${orgID}/settings/locations/${locationKey}`)
      .query.once("value");
    return { key: locationKey, name: snapshot.val() };
  }

  // --- get kiosk data
  async getKiosk(orgID: string, kioskKey: string) {
    let snapshot = await this.db
      .object(`organizations/${orgID}/settings/kiosks/${kioskKey}`)
      .query.once("value");
    return { key: kioskKey, name: snapshot.val() };
  }

  // monitor locations
  async monitorLocations(orgId: string) {
    let orgKey = orgId;
    let locationObj = (
      await this.db
        .list(`organizations/${orgKey}/settings/locations`)
        .query.once("value")
    ).val();
   let locations =lodash.map(locationObj, (location: any, key) => {
      return { value: locationObj[key], key };
    });
    return lodash.sortBy(locations ,[(location) => location.value]);
  }
}
