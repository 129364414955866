import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import moment from 'moment';

@Pipe({
  name: 'formatTimer'
})
export class FormatTimerPipe implements PipeTransform {

  transform(seconds: number, ...args: unknown[]): unknown {
    if (!seconds || seconds < 0) return "00:00"
    try {
      return this.secondsToHms(seconds)
    } catch (e) {
      return "00:00"
    }
  }

  secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = ("0" + h).slice(-2) + ":";
    var mDisplay = ("0" + m).slice(-2) + ":";
    var sDisplay = ("0" + s).slice(-2);
    return mDisplay + sDisplay;
  }
}


@Pipe({
  name: 'formatTimeInMin'
})

export class FormatTimeInMin implements PipeTransform {
  transform(seconds: number, ...args: unknown[]): unknown {
    if (!seconds || seconds < 0) return "00:00"
    try {
      return this.secondsToMinutes(seconds)
    } catch (e) {
      return "00:00"
    }
  }

  secondsToMinutes(d) {
    d = Number(d);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    var mDisplay = ("0" + m).slice(-2) + ":";
    var sDisplay = ("0" + s).slice(-2);
    return mDisplay + sDisplay;
  }
}
@Pipe({
  name: 'safeHtml'
})

export class SafeHtml implements PipeTransform {
  constructor(private sanitized: DomSanitizer){}
  transform(value:any) {
    if (!value) return ""
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}


@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: Date | moment.Moment | string | number): string {
    const now = moment();
    const past = moment(value);

    const diffMinutes = now.diff(past, 'minutes');
    const diffHours = now.diff(past, 'hours');
    const diffDays = now.diff(past, 'days');

    if (diffMinutes < 60) {
      return `${diffMinutes} mins ago`;
    } else if (diffHours < 24) {
      const hours = Math.floor(diffMinutes / 60);
      const minutes = diffMinutes % 60;
      return `${hours} hr ${minutes} min ago`;
    } else {
      return past.format('YYYY/MM/DD hh:mm A');
    }
  }
}