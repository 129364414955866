import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GameListDialogComponent } from './game-list-dialog.component';

@Injectable()
export class GameListDialogService {

  constructor(private modalService: NgbModal) { }

  public confirm(
    gameList: any[],
    selectedGame: any,
    orgName: string,
    dialogSize: 'sm'|'lg' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(GameListDialogComponent, { size: dialogSize, centered: true });
    modalRef.componentInstance.gameList = gameList;
    modalRef.componentInstance.selectedGame = selectedGame;
    modalRef.componentInstance.orgName = orgName;
    return modalRef.result;
  }

}
