import { Injectable } from "@angular/core";
import lodash from "lodash";

@Injectable({
  providedIn: "root"
})
export class QrCodeService {
  constructor() {}

  /**
   * get QR code of string content
   * @param data string data
   * @param pixelsSize Size of the QR code (pixels)
   * @returns QRiousStatic Object
   */
  toQRCode(data: string | object, pixelsSize?: number) {
    let stringData: any;
    if (lodash.isString(data)) stringData = data;
    if (lodash.isNumber(data)) stringData = `${data}`;
    if (lodash.isBoolean(data)) stringData = `${data}`;
    if (lodash.isObject(data))
      stringData = JSON.stringify(data)
        .split('"')
        .join("&apos;");

    return new QRious({
      value: stringData,
      size: pixelsSize | 500
    });
  }

  /**
   * get base64 of QR code
   * @param qr QRiousStatic Object
   * @returns base64 string of QR code
   */
  toBase64(qr: QRiousStatic) {
    return qr.toDataURL();
  }
}
