import lodash from "lodash";
import { Injectable } from "@angular/core";
import { IndStatusAtOrg, OrgType, TimeVaryingMethod } from "../enums/general.enums";
import { IndStatusAtOrgRulesService } from "../ind-status-at-org-rules/ind-status-at-org-rules.service";
@Injectable({
  providedIn: "root"
})
export class IndividualFull {
  public _new: boolean = false;
  public _key: string;
  public DBkey: string;
  public accessLevelKey: string;
  public disabled: boolean;
  ASBMember: string;
  studentID: string;
  public licenseID: string;
  public flag: any;
  public notes: any;
  public events: any;
  public isMainAdmin: boolean;

  public guardianEmailData: any;
  public guardianPhoneData: any;

  public plicID: string;
  public plicPhotoID: string;
  public firstName: string;
  public middleName: string;
  public nickName: string;
  public lastName: string;
  public suffix: string;
  public birth: string;
  public age: string;
  public license: string;
  public barcode: string;
  public photo: any;
  public photoUrl: string;
  public photoFromCsv: string;
  public lastPhotoFromCsv: string;
  public photoRetake: boolean;
  public hash: string;
  public govtID: string;
  public grade: string;

  // Bus Pass
  public accessBus_mode: any;
  public accessBus_endDateTime: number;
  public accessBus_startDateTime: number;
  public accessBus_validAfterDateTime: number;
  public accessBus_invalidAfterDateTime: number;

  // Campus Access
  public accessCampus_mode: any;
  public accessCampus_endDateTime: number;
  public accessCampus_startDateTime: number;
  public accessCampus_validAfterDateTime: number;
  public accessCampus_invalidAfterDateTime: number;

  // Cafeteria Access
  public accessCafeteria_mode: number;
  public accessCafeteria_endDateTime: number;
  public accessCafeteria_startDateTime: number;
  public accessCafeteria_validAfterDateTime: number;
  public accessCafeteria_invalidAfterDateTime: number;

  // Id Valid
  public idValid_mode: number;
  public idValid_endDateTime: number;
  public idValid_startDateTime: number;
  public idValid_validAfterDateTime: number;
  public idValid_invalidAfterDateTime: number;

  // Social Access
  public accessSocial_mode: any;
  public accessSocial_endDateTime: number;
  public accessSocial_startDateTime: number;
  public accessSocial_validAfterDateTime: number;
  public accessSocial_invalidAfterDateTime: number;

  // Athletics Access
  public accessAthletics_mode: any;
  public accessAthletics_endDateTime: number;
  public accessAthletics_startDateTime: number;
  public accessAthletics_validAfterDateTime: number;
  public accessAthletics_invalidAfterDateTime: number;

  // Permission To Leave
  public permissionToLeave_mode: any;
  public permissionToLeave_endDateTime: number;
  public permissionToLeave_startDateTime: number;
  public permissionToLeave_validAfterDateTime: number;
  public permissionToLeave_invalidAfterDateTime: number;

  // Parking Access
  public accessParking_mode: number;
  public accessParking_endDateTime: number;
  public accessParking_startDateTime: number;
  public accessParking_validAfterDateTime: number;
  public accessParking_invalidAfterDateTime: number;

  // Permission Flex Schedule
  public permissionFlexSchedule_mode: number;
  public permissionFlexSchedule_endDateTime: number;
  public permissionFlexSchedule_startDateTime: number;
  public permissionFlexSchedule_validAfterDateTime: number;
  public permissionFlexSchedule_invalidAfterDateTime: number;

  public title: string;

  public lastBadge: any;
  public lastVisitKey: string;
  public tokens: any;

  public uid: string;
  // public password: string;
  public isSubUserExist: boolean;
  public isFromResetPassword: boolean;
  public genNewPW: boolean;
  public pointBalance: string;
  public isPrimaryGua: boolean;
  public primaryGuaKey: string;

  // school type fields
  public class: string;
  public role: string;
  public teacher: string;

  // conference type fields
  public company: string;
  public groupID: string;
  public group: string;
  public phone: string;
  public mobilePhone: string;
  public shareMobilePhone: boolean;
  public email: string;
  public address: string;
  public addressPart1: string;
  public addressPart2: string;
  public postalCode: string;
  public city: string;
  public state: string;
  public country: string;
  public downloads: boolean;
  public specialEvents: boolean;

  // league type fields
  public team: string;
  public coach: string;
  public jerseyNumber: string;
  public position: string;
  public weight: string;
  public height1: string;
  public height2: string;
  public division: string;
  public ageClass: string;
  public weightClass: string;

  public firstTime: boolean;
  public canReSignIn: boolean;
  public lists: any = {};
  public userID: string;
  public timestampCreated: number;
  public timestampUpdated: number;
  public timestamp_PhotoLastUpdated: number;
  public photoUpdated: number;
  public phone_Mobile: string;
  public timestamp_indCreated: number;
  public timestamp_indLastUpdated: number;
  public cardType: string;
  public custom1: string;
  public custom2: string;
  public custom3: string;
  public auxID: string;
  public RFID: string;
  public guardianEmail: string;
  public guardianPhone: string;
  public admissibilityGroup: string;
  public idLastGenerated: number;
  public photoStatus: number;
  public isDetailsUpdated: boolean;
  public salutation: any;
  public homeRoom: any;
  public createdFrom: any;
  public permissionToLeave: string;
  public status: string;
  public buyPhoto: any;

  // safety cards fields
  public hairColor: string;
  public eyeColor: string;
  public indID_Client: string;

  // new sports fields
  public program: string;
  public programSection: string;
  public playStatus: string;
  public paymentStatus: string;
  public bookCode: string;
  public photoYear: string;
  public photos: any;
  public yearbookCollected: boolean;
  public yearbookPaid: boolean;
  public yearbookOrdered: boolean;
  public campus: string;
  public lunchPeriod: string;

  public teammatePURL: string;
  public optin: boolean;

  public isYearBookPurchased: boolean;
  public feesBalance: boolean;
  public parkingLot: string;
  public isLunchPrivilege: string;
  public canLeaveCampus: boolean;
  public printBadgeCount: boolean;
  public certificateStatus: number;

  // Field For Yes League (Sportsteam)
  public physicianName: null;
  public physicianStreet: null;
  public physicianCity: null;
  public physicianState: null;
  public physicianZip: null;
  public physicianPhone: null;
  public physicianFax: null;
  public physicianEmail: null;
  public medPrefHospital: null;

  // Medical Conditions
  public medAllergies: null;
  public medConditions: null;
  public medOtherNotes: null;

  // Medical Considerations
  public hasInsurance: null;

  // Medical Insurance
  public insuranceCarrierName: null;
  public insuranceGroup: null;
  public insurancePolicyNumber: null;
  public insuranceGroupNumber: null;
  public insurancePolicyHolderName: null;

  // School For Yes League
  public schoolName: null;
  public schoolDistrict: null;

  // Emergency Contacts
  // Contact 1
  public emergencyCon1Name: null;
  public emergencyCon1Phone: null;
  public emergencyCon1Relationship: null;

  // Contact 2
  public emergencyCon2Name: null;
  public emergencyCon2Phone: null;
  public emergencyCon2Relationship: null;

  public employer: null;

  public feesSeason: null;
  public feesRegistration: null;
  public section: string;

  public statusAtOrg: IndStatusAtOrg;
  
  copyInto(obj: any, key?: string, orgType?: string) {
    this.plicID = obj.plicID;
    this.plicPhotoID = obj.plicPhotoID;
    this.firstName = obj.firstName;
    this.middleName = obj.middleName;
    this.nickName = obj.nickName;
    this.lastName = obj.lastName;
    this.suffix = obj.suffix;
    this.birth = obj.birth;
    this.age = obj.age;
    this.license = obj.license;
    this.barcode = obj.barcode;
    this.photoUrl = obj.photoUrl;
    this.photoFromCsv = obj.photoFromCsv;
    this.lastPhotoFromCsv = obj.lastPhotoFromCsv;
    this.photoRetake = obj.photoRetake;
    this.hash = obj.hash;
    this.flag = obj.flag;
    this.notes = obj.notes;
    this.uid = obj.uid;
    // this.password = obj.password;
    this.isSubUserExist = obj.isSubUserExist;
    this.isFromResetPassword = obj.isFromResetPassword;
    this.genNewPW = obj.genNewPW || false;
    this.pointBalance = obj.pointBalance;
    this.isPrimaryGua =
      obj && obj.hasOwnProperty("isPrimaryGua") ? obj.isPrimaryGua : false;
    this.primaryGuaKey = obj.primaryGuaKey;
    this.events = obj.events;
    this.photoYear = obj.photoYear;
    this.photos = obj.photos;
    this.buyPhoto = obj.buyPhoto;
    this.status = obj.status;

    this.guardianEmailData = obj.guardianEmailData;
    this.guardianPhoneData = obj.guardianPhoneData;

    this.accessLevelKey = obj.accessLevelKey || null;

    // school type fields
    this.class = obj.class;
    this.role = obj.role;
    this.title = obj.title;
    this.teacher = obj.teacher;

    // conference type fields
    this.company = obj.company;
    this.groupID = obj.groupID;
    this.group = obj.group;
    this.phone = obj.phone;
    this.shareMobilePhone = obj.shareMobilePhone;
    this.mobilePhone = obj.mobilePhone;
    this.email = obj.email;
    this.address = obj.address;
    this.addressPart1 = obj.addressPart1;
    this.addressPart2 = obj.addressPart2;
    this.postalCode = obj.postalCode;
    this.city = obj.city;
    this.state = obj.state;
    this.country = obj.country;
    this.downloads = obj.downloads;
    this.specialEvents = obj.specialEvents;

    // league type fields
    this.team = obj.team;
    this.coach = obj.coach;
    this.jerseyNumber = obj.jerseyNumber;
    this.position = obj.position;
    this.weight = obj.weight;
    this.height1 = obj.height1;
    this.height2 = obj.height2;
    this.division = obj.division;
    this.ageClass = obj.ageClass;
    this.weightClass = obj.weightClass;

    this.lists = obj.lists;
    this.firstTime = obj.firstTime;
    this.canReSignIn = obj.canReSignIn;

    this.lastBadge = obj.lastBadge;
    this.lastVisitKey = obj.lastVisitKey;
    this.tokens = obj.tokens;

    this.timestampCreated = obj.timestampCreated;
    this.timestampUpdated = obj.timestampUpdated;
    this.photoUpdated = obj.timestampUpdated;

    this.userID = obj.uid;
    this.phone_Mobile = obj.mobilePhone;
    this.timestamp_indCreated = obj.timestampCreated;
    this.timestamp_indLastUpdated = obj.timestampUpdated;
    this.timestamp_PhotoLastUpdated = obj.timestampUpdated;
    this.DBkey = key;
    this.licenseID = obj.studentID;
    this.ASBMember =
      obj && obj.hasOwnProperty("ASBMember") ? obj.ASBMember : false;


   // Bus Access
   this.accessBus_mode = obj.accessBus_mode || TimeVaryingMethod.NO;
   this.accessBus_startDateTime = obj.accessBus_startDateTime || "";
   this.accessBus_endDateTime = obj.accessBus_endDateTime || "";
   this.accessBus_validAfterDateTime = obj.accessBus_validAfterDateTime || "";
   this.accessBus_invalidAfterDateTime = obj.accessBus_invalidAfterDateTime || "";
   
   // Campus Access
   this.accessCampus_mode = obj.accessCampus_mode || TimeVaryingMethod.YES;
   this.accessCampus_startDateTime = obj.accessCampus_startDateTime || "";
   this.accessCampus_endDateTime = obj.accessCampus_endDateTime || "";
   this.accessCampus_validAfterDateTime = obj.accessCampus_validAfterDateTime || "";
   this.accessCampus_invalidAfterDateTime = obj.accessCampus_invalidAfterDateTime || "";

   // Cafeteria Access
   this.accessCafeteria_mode = obj.accessCafeteria_mode || TimeVaryingMethod.YES,
   this.accessCafeteria_startDateTime = obj.accessCafeteria_startDateTime || "",
   this.accessCafeteria_endDateTime = obj.accessCafeteria_endDateTime || "",
   this.accessCafeteria_validAfterDateTime = obj.accessCafeteria_validAfterDateTime || "",
   this.accessCafeteria_invalidAfterDateTime = obj.accessCafeteria_invalidAfterDateTime || "",

   // Id Valid
   this.idValid_mode = obj.idValid_mode || TimeVaryingMethod.YES,
   this.idValid_startDateTime = obj.idValid_startDateTime || "",
   this.idValid_endDateTime = obj.idValid_endDateTime || "",
   this.idValid_validAfterDateTime = obj.idValid_validAfterDateTime || "",
   this.idValid_invalidAfterDateTime = obj.idValid_invalidAfterDateTime || "",

  // Social Access
   this.accessSocial_mode = obj.accessSocial_mode || TimeVaryingMethod.YES;
   this.accessSocial_endDateTime = obj.accessSocial_endDateTime || "";
   this.accessSocial_startDateTime = obj.accessSocial_startDateTime || "";
   this.accessSocial_validAfterDateTime = obj.accessSocial_validAfterDateTime || "";
   this.accessSocial_invalidAfterDateTime = obj.accessSocial_invalidAfterDateTime || "";

  // Athletics Access
   this.accessAthletics_mode = obj.accessAthletics_mode || TimeVaryingMethod.NO;
   this.accessAthletics_endDateTime = obj.accessAthletics_endDateTime || "";
   this.accessAthletics_startDateTime = obj.accessAthletics_startDateTime || "";
   this.accessAthletics_validAfterDateTime = obj.accessAthletics_validAfterDateTime || "";
   this.accessAthletics_invalidAfterDateTime = obj.accessAthletics_invalidAfterDateTime || "";
   
  // Permission To Leave
   this.permissionToLeave_mode = obj.permissionToLeave_mode || TimeVaryingMethod.NO;
   this.permissionToLeave_endDateTime = obj.permissionToLeave_endDateTime || "";
   this.permissionToLeave_startDateTime = obj.permissionToLeave_startDateTime || "";
   this.permissionToLeave_validAfterDateTime = obj.permissionToLeave_validAfterDateTime || "";
   this.permissionToLeave_invalidAfterDateTime = obj.permissionToLeave_invalidAfterDateTime || "";

  // Parking Access
   this.accessParking_mode = obj.accessParking_mode || TimeVaryingMethod.NO,
   this.accessParking_endDateTime = obj.accessParking_endDateTime || "",
   this.accessParking_startDateTime = obj.accessParking_startDateTime || "",
   this.accessParking_validAfterDateTime = obj.accessParking_validAfterDateTime || "",
   this.accessParking_invalidAfterDateTime = obj.accessParking_invalidAfterDateTime || "",

  // Permission Flex Schedule
   this.permissionFlexSchedule_mode = obj.permissionFlexSchedule_mode || TimeVaryingMethod.NO,
   this.permissionFlexSchedule_endDateTime = obj.permissionFlexSchedule_endDateTime || "",
   this.permissionFlexSchedule_startDateTime = obj.permissionFlexSchedule_startDateTime || "",
   this.permissionFlexSchedule_validAfterDateTime = obj.permissionFlexSchedule_validAfterDateTime || "",
   this.permissionFlexSchedule_invalidAfterDateTime = obj.permissionFlexSchedule_invalidAfterDateTime || "",

    this.studentID = obj && obj.studentID ? obj.studentID : "";
    this._new = false;
    this._key = key;
    this.disabled = obj.disabled;
    this.govtID = obj.license;
    this.grade = obj.class || "";
    this.cardType = obj.cardType;
    this.custom1 = obj.custom1;
    this.custom2 = obj.custom2;
    this.custom3 = obj.custom3;
    this.auxID = obj.auxID;
    this.RFID = obj.RFID;
    this.guardianEmail = obj.guardianEmail;
    this.guardianPhone = obj.guardianPhone;
    this.admissibilityGroup = obj.admissibilityGroup;
    this.idLastGenerated = obj.idLastGenerated;
    this.photoStatus = obj.photoStatus === "" ? null : obj.photoStatus;
    this.isDetailsUpdated = obj && obj.isDetailsUpdated;
    this.salutation = obj.salutation;
    this.homeRoom = obj.homeRoom;
    this.createdFrom = "touchless";
    this.eyeColor = obj.eyeColor;
    this.hairColor = obj.hairColor;
    this.indID_Client = obj.indID_Client;
    this.permissionToLeave =
      obj && obj.hasOwnProperty("permissionToLeave")
        ? obj.permissionToLeave
        : "";
    this.program = obj.program;
    this.programSection = obj.programSection;
    this.playStatus = obj.playStatus;
    this.paymentStatus = obj.paymentStatus;
    this.bookCode = obj.bookCode;
    this.teammatePURL = obj.teammatePURL;
    this.yearbookCollected = obj.yearbookCollected || false;
    this.yearbookPaid = obj.yearbookPaid || false;
    this.yearbookOrdered = obj.yearbookOrdered || false;
    this.campus = obj.campus || "";
    this.lunchPeriod = obj.lunchPeriod || "";
    this.optin = obj && obj.hasOwnProperty("optin") ? obj.optin : true;
    this.isYearBookPurchased = obj.isYearBookPurchased;
    this.feesBalance = obj.feesBalance;
    this.parkingLot = obj.parkingLot;
    this.isLunchPrivilege = obj.isLunchPrivilege;
    this.certificateStatus = obj.certificateStatus;
    this.canLeaveCampus = obj.canLeaveCampus || false;
    this.printBadgeCount = obj.printBadgeCount || 0;

    this.isMainAdmin = obj.isMainAdmin || false;

     // Field For Yes League (Sportsteam)
     this.physicianName =
     obj.physicianName || "";
   this.physicianStreet = obj.physicianStreet || "";
   this.physicianCity = obj.physicianCity || "";
   this.physicianState = obj.physicianState || "";
   this.physicianZip = obj.physicianZip || "";
   this.physicianPhone = obj.physicianPhone || "";
   this.physicianFax = obj.physicianFax || "";
   this.physicianEmail = obj.physicianEmail || "";
   this.medPrefHospital = obj.medPrefHospital || "";

   // Medical Conditions
   this.medAllergies = obj.medAllergies || "";
   this.medConditions =
     obj.medConditions || "";
   this.medOtherNotes =
     obj.medOtherNotes || "";

   // Medical Considerations
   this.hasInsurance = obj.hasOwnProperty('hasInsurance') ? obj.hasInsurance : false;

   // Medical Insurance
   this.insuranceCarrierName = obj.insuranceCarrierName || "";
   this.insuranceGroup = obj.insuranceGroup || "";
   this.insurancePolicyNumber = obj.insurancePolicyNumber || "";
   this.insuranceGroupNumber = obj.insuranceGroupNumber || "";
   this.insurancePolicyHolderName = obj.insurancePolicyHolderName || "";

   // School For Yes League
   this.schoolName = obj.schoolName || "";
   this.schoolDistrict = obj.schoolDistrict || "";

   // Emergency Contacts
   // Contact 1
   this.emergencyCon1Name = obj.emergencyCon1Name || '';
   this.emergencyCon1Phone = obj.emergencyCon1Phone || '';
   this.emergencyCon1Relationship = obj.emergencyCon1Relationship || '';

   // Contact 2
   this.emergencyCon2Name = obj.emergencyCon2Name || '';
   this.emergencyCon2Phone = obj.emergencyCon2Phone || '';
   this.emergencyCon2Relationship = obj.emergencyCon2Relationship || '';

   this.employer = obj.employer || '';

   this.feesRegistration = obj.hasOwnProperty('feesRegistration') ? obj.feesRegistration : '';
   this.feesSeason = obj.hasOwnProperty('feesSeason') ? obj.feesSeason : '';
   this.section = obj.section || '';

   this.statusAtOrg = obj.statusAtOrg;
    if(orgType && !this.statusAtOrg)
      this.statusAtOrg = IndStatusAtOrgRulesService.getDefaultValueForStatusAtOrg(orgType as OrgType)
  }
  
  static createHash(firstName: string, lastName: string, orgID: string) {
    if (!firstName) firstName = "";
    if (!lastName) lastName = "";

    function guid(len) {
      var buf = [],
        chars = "0123456789",
        charlen = chars.length,
        length = len || 32;
      for (var i = 0; i < length; i++) {
        buf[i] = chars.charAt(Math.floor(Math.random() * charlen));
      }
      return buf.join("");
    }
    function getHashFromString(str) {
      var hash = 0,
        i,
        chr;
      if (str.length === 0) return hash;
      for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash >>> 0;
    }

    let str: any =
      firstName
        .replace(/ /g, "")
        .trim()
        .toUpperCase() +
      "_" +
      lastName
        .replace(/ /g, "")
        .trim()
        .toUpperCase();

    if (str.length > 50) {
      str = str.substring(0, 50);
    }

    const toHash =
      str + "_" + orgID + "_" + new Date().getTime() + "_" + guid(3);
    return str + "_" + getHashFromString(toHash);
  }
  clearInternal() {
    this._new = null;
    this._key = null;

    lodash.forOwn(this, (value, key) => {
      if (this[key] === undefined) {
        this[key] = null;
      }
    });
  }
}
