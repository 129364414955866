import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { CommonServiceService } from "../common/common-service.service";
import { Role } from "../enums/user.enums";
import { OrganizationApiService } from "../organization/organization.api.service";
import * as lodash from "lodash";
import {
  InheritanceService,
  ReplacementTags
} from "../inheritance/inheritance.service";
import { CacheKeys, CacheService } from "../cache/cache.service";

@Injectable({
  providedIn: "root"
})
export class DesignService {
  private basePath: string = "designs";
  private designBasePath: string = `${this.basePath}/Design`;
  private designTypesBasePath: string = `${this.basePath}/DesignTypes`;
  private _CDBasePath: string = `${this.basePath}/customizeDesigns`;
  private _FFPath: string = `${this.basePath}/FormFactors`;

  readonly userDBNodes = {
    [Role.ORG]: "organizations",
    [Role.STUDIO]: "studios",
    [Role.SUPERADMIN]: null
  };
  readonly dbPathDesigns = `${ReplacementTags.USER_DB_NODE}/${ReplacementTags.USER_ID}/designs/Design`;
  readonly dbPathDefDesignTypeID = `${ReplacementTags.USER_DB_NODE}/${ReplacementTags.USER_ID}/designs/defaults/designType`;

  constructor(
    private db: AngularFireDatabase,
    private inheritanceProvider: InheritanceService,
    private cacheService: CacheService
  ) {}

  // --- get form factor data by inheritance
  async getFormFactorByInheritance(
    role: string,
    userID: string,
    formFactorID: string,
    usersData?: any,
    useCache: boolean = false
  ) {
    // --- params validation
    if (!role || (role != Role.SUPERADMIN && !userID) || !formFactorID)
      throw new Error(
        "Params missing in getFormFactorByInheritance method call"
      );

    // --- fetch formfactor data by inheritance
    let ffInheritedData = await this.inheritanceProvider.getByInheritance(
      role,
      userID,
      `${this._FFPath}/${formFactorID}`,
      "object",
      usersData,
      this.userDBNodes,
      null,
      useCache
    );

    // --- find highest priority non-null data from the inherited data
    let ffData: any = lodash.find(
      ffInheritedData,
      ffData => ffData.data != null
    );

    if (!ffData) return null;

    return { ...ffData.data, key: formFactorID, ownerID: ffData.ownerID };
  }

  /**
   * convert form factor measurements in pixels if not already
   * @param formFactor Form factor object
   * @returns a new object containing all measurements in pixels
   */
  getFormFactorsConvertPixel(formFactor) {
    if (formFactor.Units == "cm" || formFactor.Pixels == "cm") {
      if (
        formFactor.cHeight &&
        formFactor.cWidth &&
        formFactor.fHeight &&
        formFactor.fWidth
      ) {
        formFactor.cHeight = formFactor.cHeight * 37.7952755906;
        formFactor.cWidth = formFactor.cWidth * 37.7952755906;
        formFactor.fHeight = formFactor.fHeight * 37.7952755906;
        formFactor.fWidth = formFactor.fWidth * 37.7952755906;
      }
      // landscape
      formFactor.landscape.cHeight =
        formFactor.landscape.cHeight * 37.7952755906;
      formFactor.landscape.cWidth = formFactor.landscape.cWidth * 37.7952755906;
      formFactor.landscape.fHeight =
        formFactor.landscape.fHeight * 37.7952755906;
      formFactor.landscape.fWidth = formFactor.landscape.fWidth * 37.7952755906;
      // portrait
      formFactor.portrait.cHeight = formFactor.cHeight * 37.7952755906;
      formFactor.portrait.cWidth = formFactor.cWidth * 37.7952755906;
      formFactor.portrait.fHeight = formFactor.fHeight * 37.7952755906;
      formFactor.portrait.fWidth = formFactor.fWidth * 37.7952755906;
    } else if (formFactor.Units == "mm" || formFactor.Pixels == "mm") {
      if (
        formFactor.cHeight &&
        formFactor.cWidth &&
        formFactor.fHeight &&
        formFactor.fWidth
      ) {
        formFactor.cHeight = formFactor.cHeight * 3.7795275591;
        formFactor.cWidth = formFactor.cWidth * 3.7795275591;
        formFactor.fHeight = formFactor.fHeight * 3.7795275591;
        formFactor.fWidth = formFactor.fWidth * 3.7795275591;
      }
      //landscape
      formFactor.landscape.cHeight =
        formFactor.landscape.cHeight * 3.7795275591;
      formFactor.landscape.cWidth = formFactor.landscape.cWidth * 3.7795275591;
      formFactor.landscape.fHeight =
        formFactor.landscape.fHeight * 3.7795275591;
      formFactor.landscape.fWidth = formFactor.landscape.fWidth * 3.7795275591;
      //portrait
      formFactor.portrait.cHeight = formFactor.portrait.cHeight * 3.7795275591;
      formFactor.portrait.cWidth = formFactor.portrait.cWidth * 3.7795275591;
      formFactor.portrait.fHeight = formFactor.portrait.fHeight * 3.7795275591;
      formFactor.portrait.fWidth = formFactor.portrait.fWidth * 3.7795275591;
    }
    formFactor.Units = "pixel";
    formFactor.Pixels = "pixel";
    return formFactor;
  }

  /**
   * Fetch design object based on inheritance
   * @param id id of the design
   * @param role Role of the user
   * @param userId org or studio id
   * @returns design object of given id
   */
  async getDesignByInheritance(
    designID: string,
    role: string,
    userID?: string,
    usersData?: any,
    useCache: boolean = false
  ) {
    if (!designID) throw Error("Id required!");
    if (!role) throw Error("Role Required");
    if (role != Role.SUPERADMIN && !userID)
      throw Error("userId required when not requesting for superadmin");

    // --- fetch designs data by inheritance
    let designInheritedData = await this.inheritanceProvider.getByInheritance(
      role,
      userID,
      `${this.dbPathDesigns}/${designID}`,
      "object",
      usersData,
      this.userDBNodes,
      null,
      useCache
    );

    const isDesignObjValid = designObj => {
      return designObj && designObj.hasOwnProperty("Vecta_io_ID");
    };

    // --- find highest priority non-null data from the inherited data
    let designData: any = lodash
      .chain(designInheritedData)
      .compact()
      .find(item => isDesignObjValid(item.data))
      .get("data")
      .value();

    if (!designData || designData.Override == "hide") return null;

    return designData;
  }

  // --- Fetch design type data
  async getDesignType(designTypeID: string, useCache?: boolean) {
    if (!designTypeID) throw new Error("Params missing!");

    // --- read data from cache
    if (useCache) {
      let cachedData = this.cacheService.get(
        CacheKeys.DesignTypeData,
        designTypeID
      );
      if (cachedData) return cachedData.value;
    }

    // --- read data from db
    let snapshot = await this.db
      .object(`${this.designTypesBasePath}/${designTypeID}`)
      .query.once("value");
    let dTypeData = snapshot.val();

    // --- set cache
    this.cacheService.set(CacheKeys.DesignTypeData, designTypeID, dTypeData);

    return dTypeData;
  }

  /**
   * Fetch default design type ID
   */
  async getDefaultDesignTypeIDInheritance(
    role: string,
    userID?: string,
    usersData?: any,
    useCache: boolean = false
  ) {
    if (!role) throw Error("Role Required");
    if (role != Role.SUPERADMIN && !userID)
      throw Error("userId required when not requesting for superadmin");

    // --- fetch designs data by inheritance
    let defDTIDInheritedData = await this.inheritanceProvider.getByInheritance(
      role,
      userID,
      `${this.dbPathDefDesignTypeID}`,
      "object",
      usersData,
      this.userDBNodes,
      null,
      useCache
    );

    // --- loop through data and return highest priority valid design type ID
    let validDTID;
    for (const item of defDTIDInheritedData) {
      if (!item) continue;

      if (item.data) {
        let dTypeData = await this.getDesignType(item.data, useCache);
        if (dTypeData) {
          validDTID = item.data;
          break;
        }
      }
    }

    return validDTID;
  }
}
