import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getIndNameUsingHash'
})
export class GetIndNameUsingHashPipe implements PipeTransform {

  transform(indList: any[], hash: string): string {
    if(!hash) return '';
    let index = indList.findIndex(ind => {return ind.hash == hash})
    if(index > -1) {
      return `${indList[index].firstName} ${indList[index].lastName}`;
    } else {
      return '';
    }
    
  }

}
