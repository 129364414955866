import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CameraConfirmationDialogComponent } from "./camera-confirmation-dialog.component";

@Injectable()
export class CameraConfirmationDialogService {
  constructor(private modalService: NgbModal) {}

  public confirm(
    title: string,
    message: string,
    btnOkText: string,
    btnTryAgainText: string,
    dialogSize: "sm" | "lg" = "sm"
  ): Promise<any> {
    const modalRef = this.modalService.open(CameraConfirmationDialogComponent, {
      size: dialogSize,
      backdrop: "static",
      keyboard: false
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = btnOkText
      ? btnOkText
      : "Upload Manualy";
    modalRef.componentInstance.btnTryAgainText = btnTryAgainText
      ? btnTryAgainText
      : "Try Again";
    return modalRef.result;
  }
}
