<div ngbDropdown class="d-inline-block">
  <button class="btn btn-link" id="languageDropdown" [ngStyle]="styles.language_dropdown_btn" ngbDropdownToggle>
    <img src="assets/globe.png" class="icon" [ngStyle]="styles.language_icon" />&nbsp;&nbsp;{{
      selectedLang == "en" ? "English" : "Español"
    }}
  </button>

  <div ngbDropdownMenu aria-labelledby="languageDropdown">
    <button ngbDropdownItem (click)="onSelection(0)">English</button>
    <button ngbDropdownItem (click)="onSelection(1)">Español</button>
  </div>
</div>
