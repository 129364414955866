import lodash from "lodash";

function getAllEnums(enumObj: any) {
  return lodash
    .chain(enumObj)
    .keys()
    .map(key => {
      return { key, value: lodash.get(enumObj, key) };
    })
    .value();
}

export const PhotoWaitingForApprovalChoices = {
  RETAKE_SELFIE: "101",
  GO_TO_PERSONAL_HOME_PAGE: "102"
};

export const LocalStorageKeys = {
  lang: "lang",
  _2FAData: "_2FAData",
  H5Trace: "h5:tracePP",
  isPWAInstallationDetectedBefore: "installedPWA",
  chunkReloadAt: "chunkReloadAt",
  cacheCleanedAt: "cacheCleanedAt",
  claimsUpdatedAt: "claimsUpdatedAt",
};

export const IndexedDBKeys = {
  CachedID: "CachedID"
};

export enum Language {
  EN = "en",
  ES = "es"
}

export namespace Language {
  export function getAll() {
    return lodash.map(
      lodash.filter(Language, (value, key) => lodash.isString(value)),
      (value, key) => {
        return value;
      }
    );
  }
}

export enum PhotoStatus {
  APPROVED = 1
}

export enum ExpandSupportingChar {
  TimeFormat = "@@",
  StringMethod = "%%"
}
export const ExpandSupportingChars = getAllEnums(ExpandSupportingChar);

export enum ConsumablePaidBy {
  ORG = "org",
  TEAMMATE = "teammate"
}

export enum BundleType {
  SMALL = 1,
  MEDIUM = 2,
  LARGE = 3,
  BOUTIQUE = 4
}

export enum ZohoSubApiType {
  READ_CUSTOMER = "readCustomer",
  GET_CUSTOMER_CARDS = "getCustomerCards",
  CREATE_CUSTOMER = "createCustomer",
  REQUEST_PAYMENT_METHOD_LINK = "requestPaymentMethodLink",
  DELETE_CUSTOMER_CARD = "deleteCustomerCard"
}

export enum NoPhotoPresentSelfiePolicy {
  OFFER_SELFIE = 1,
  DO_NOT_OFFER_SELFIE = 2
}

export enum DIYCapturePolicy {
  NEW_PHOTO_EACH_YEAR = 2
}

export enum PhotoUsedInID {
  CURRENT_YEAR_ONLY = 1,
  MOST_RECENT_AVAILABLE_YEAR = 2
}

export const DiyCapturePolicyPhotoUsedInIDMap = {
  [DIYCapturePolicy.NEW_PHOTO_EACH_YEAR]: PhotoUsedInID.CURRENT_YEAR_ONLY
};

export enum GenericLoginChallenge {
  DOB = 1,
  SEC_WORD = 2,
  NONE = 3,
  STUDENT_ID = 4,
  EMAIL = 6,
  PHONE = 7,
  GUARDIAN_PHONE = 8,
  GUARDIAN_EMAIL = 9,
  NAME_WITH_HINTS = 10,
  NAME_4_LETTERS = 11,
  GRADUATION_YEAR = 12
}

export enum GenericIdentification {
  STUDENT_ID = 1,
  NAME = 2
}

export enum SendMsgOnPassSetOpts {
  NO = "no",
  EMAIL_OR_SMS = "emailOrSMS",
  SMS_OR_EMAIL = "smsOrEmail"
}

export enum ElasticEndPoint {
  IND_LASTNAME = 63258,
  IND_F_L_EXACT_NAME = 54874,
  IND_F_L_NAME = 65874,
  IND_STU_ID = 98745,
  IND_STU_ID_BIRTH = 25487,
  IND_VISITOR_F_L_NAME = 36541,
  IND_VISITOR_MOBILE_NUM = 85477,
  IND_ROLE = 26479,
  IND_LIST = 59874,
  CCD_ORG_NAME = 16548,
  TOTAL_IND_COUNT = 95648,
  IND_F_L_NAME_WITH_1_MATCH = 86412,
  IND_MOBILE_IND_ROLE_WITH_ORG_ID = 36959,
  IND_MOBILE_2_IND_ROLE_WITH_ORG_ID = 29375,
}

export enum GameOutcome {
  COMPLETED = "completed",
  FORFEIT = "forfeit",
  BYE = "bye",
  TIE = "tie"
}

export namespace GameOutcome {
  export function getAll(): any[] {
    // --- remove functions from object
    let filteredOpts = lodash.filter(GameOutcome, (value, key) =>
      lodash.isString(value)
    );

    let displayNames = {
      [GameOutcome.COMPLETED]: "Completed",
      [GameOutcome.FORFEIT]: "Forfeit",
      [GameOutcome.BYE]: "BYE",
      [GameOutcome.TIE]: "Tie"
    };

    return lodash.map(filteredOpts, (value: string, key) => {
      return { key, value, displayName: displayNames[value] };
    });
  }
}

export enum IndSearchType {
  NAME = "name",
  STUDENT_ID = "studentID",
  EMAILS = "emails",
  PHONE = "mobilePhone",
  IND_ID = "indId",
  CUSTOM3 = "custom3",
  ROLE = "role",
  HASH = "hash",
  LAST_NAME = "lastName",
  DIVISION = "division",
  BIRTH = "birth",
  JERSEY_NUMBER = "jerseyNumber"
}

export enum OrgSearchType {
  NAME = "name"
}

export enum StudioSearchType {
  NAME = "name"
}

export enum MsgMedium {
  SMS = "sms",
  EMAIL = "email",
  EMAIL_HTML = "emailHtml"
}

export enum SendMsgToReceiver {
  SELF = "self",
  GUARDIAN = "guardian"
}

export enum SendMsgToIndType {
  SET_PASS = "setPass",
  FORGOT_PASS = "forgotPass",
  _2FA = "2FA",
  CROSSING_NOTIFICATION = "crossingNotification"
}

export enum Module {
  SelfReg = "selfReg",
  Generic = "generic",
  TVT = "tvt",
  Demo = "demo",
  GameCheckIn = "gameCheckIn",
  Scanner = "scanner"
}

export enum BroadcastRecipient {
  GUARDIAN_ONLY = 1,
  GUARDIAN_OR_INDIVIDUAL = 2,
  INDIVIDUAL_OR_GUARDIAN = 3,
  GUARDIAN_AND_INDIVIDUAL = 4,
  INDIVIDUAL = 5,
  ORGANIZATION = 6
}

export enum StorageHost {
  S3 = "s3",
  FIREBASE = "firebase",
  UNKNOWN = "unknown"
}

export enum UpdateTypes {
  NORMAL = 100,
  FORCEFULL = 200
}

export enum FeesType {
  SPORTS_REGISTRATION = 1,
  SPORTS_SEASON = 2
}

export enum AccessRole {
  PERSON_AT_ORG = "PAO",
  TRUSTED_CHILD_ST = "ST",
  TRUSTED_ADULT_TE = "TE",
  TRUSTED_ADULT_GU = "GU",
  EVENT_TICKET_TRACKER = "ETT",
  ADMIN = "ADM",
  SBU = "SBU",
  TRUSTED_ADULT_VI = "VI"
}

export enum SportsFeesStatus {
  UNPAID = "Unpaid",
  PAID = "Paid",
  GIFTED = "Gifted",
  REFUNDED = "Refunded"
}

export enum OrgsListBy {
  LEAGUE_ID = "leagueID",
  PARTNER_ORG = "partnerOrg",
  PARENT_ID = "parentID",
  ALL = "all",
}

export enum TimeVaryingMethod {
  YES = 1,
  NO = 2,
  YESINTIME = 3,
  NOINTIME = 4,
  YESAFTERTIME = 5,
  NOAFTERTIME = 6,
  YESINDATETIME = 7,
  NOINDATETIME = 8,
  YESAFTERDATETIME = 9,
  NOAFTERDATETIME = 10,
}

export enum ScanStatus {
  ATTENDED = "Attended",
  PRESENT = "Currently Present",
  REFUSED= "Refused Access",
  NOTSCANNED = "Not Scanned"
}

export enum IndStatusAtOrg {
  APPLICANT = "applicant",
  CURRENT = "current",
  ALUMNI = "alumni",
  REJECTED_APPLICANT = "rejected_applicant",
}

export namespace IndStatusAtOrg {
  export function getAll(): any[] {
    // --- remove functions from object
    let filteredOpts = lodash.filter(IndStatusAtOrg, (value, key) =>
      lodash.isString(value)
    );

    let displayNames = {
      [IndStatusAtOrg.APPLICANT]: "Applicant",
      [IndStatusAtOrg.CURRENT]: "Current",
      [IndStatusAtOrg.ALUMNI]: "Alumni",
      [IndStatusAtOrg.REJECTED_APPLICANT]: "Rejected Applicant",
    };

    return lodash.map(filteredOpts, (value: string, key) => {
      return { key, value, displayName: displayNames[value] };
    });
  }
}

export enum OrgType {
  SCHOOL = "school",
  CONFERENCE = "conference",
  SPORTSTEAM = "sportsteam",
  COMPANY = "Company",
  PROPERTY = "property",
  PROGRAM = "program",
}

// --- sso platforms enum
export enum SSOProvider {
  GOOGLE = "google",
  MICROSOFT = "microsoft"
}