import { Pipe, PipeTransform } from "@angular/core";
import lodash from "lodash";

@Pipe({
  name: "safeAccessProp"
})
export class SafeAccessPropPipe implements PipeTransform {
  /**
   * get property from object
   * @param obj object
   * @param propKey property key (array because you might need to use variables and strings mixure in HTML)
   * @returns property value of object.
   */
  transform(obj: any, propKey: any[]) {
    return lodash.get(obj, lodash.join(propKey, "."), null);
  }
}
