import { Injectable } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PwaPopupComponent } from "./pwa-popup.component";

@Injectable()
export class PwaPopupDialogService {
  constructor(private modalService: NgbModal) {}

  public present(dialogSize: "sm" | "lg" = "sm"): Promise<boolean> {
    const modalRef = this.modalService.open(PwaPopupComponent, {
      size: dialogSize
    });
    return modalRef.result;
  }
}
