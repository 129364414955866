<div class="modal-header">
    <h4 class="modal-title">Please Confirm</h4>
</div>
<div class="modal-body">
    <p class="instructions">Your account at<br/>{{orgName}}<br/>is being used.<br/><br/><b>Is this you?</b></p>
</div>
<div class="modal-footer">
    <div class="btn-container">
        <button type="button" class="btn btn-danger btn-negative mr-8" (click)="noItsNotMe()">It's not me</button>
        <button type="button" class="btn btn-success w-100" (click)="yesItsMe()">Yes, it's me</button>
    </div>
</div>