<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form nz-form [formGroup]="signUpForm" class="login-form">
        <nz-form-item style="margin-bottom: 12px !important;">
            <nz-form-control class="password-form-control">
                <span>New Password</span>
                <nz-input-group nzPrefixIcon="lock">
                    <input type="password" class="pr-password" autocomplete="new-password" nz-input [ngClass]="isPwdEmpty ? 'err-ip' : ''"
                        formControlName="password" placeholder="{{ 'password.your_password' | translate }}"
                        (ngModelChange)="validateConfirmPassword(); checkPwdIsEmpty()" (keyup)="checkPassswordValidation()"
                        (focus)="showLabels()" (blur)="hideLabels()" autocapitalize="none" />
                    <div id="pr-box">
                        <i></i>
                        <div id="pr-box-inner">
                            <ul>
                                <li class="pr-minimumcharacters"><span></span>
                                    {{ 'password.length_between_X_to_Y' | translate : {minLength: passwordMinimumLength, maxLength: passwordMaximumLength} }}
                                </li>
                                <li class="pr-useSpecial"><span></span>{{ 'password.1_special_char' | translate }}</li>
                                <li class="pr-useNumbers"><span></span>{{ 'password.1_number' | translate }}</li>
                            </ul>
                        </div>
                    </div>
                </nz-input-group>
            </nz-form-control>
        </nz-form-item>

        <nz-form-item style="margin-bottom: 12px !important;">
            <nz-form-control [nzErrorTip]="passwordErrorTpl" class="password-form-control">
                <span>Confirm Password</span>
                <nz-input-group nzPrefixIcon="lock">
                    <input type="password" class="pr-password" nz-input formControlName="confirmPassword"  (ngModelChange)="checkPwdIsEmpty()"
                        placeholder="{{ 'password.confirm_your_password' | translate }}" autocomplete="new-password"
                        autocapitalize="none" />
                    <ng-template #passwordErrorTpl let-control>
                        <ng-container *ngIf="control.hasError('required')">
                            {{ 'password.please_confirm_your_password' | translate }}
                        </ng-container>
                        <ng-container *ngIf="control.hasError('confirm')">
                            {{ 'password.password_is_inconsistent' | translate }}
                        </ng-container>
                    </ng-template>
                </nz-input-group>
            </nz-form-control>
        </nz-form-item>
    </form>
</div>
<div class="modal-footer">

    <button type="button" class="btn btn-danger fullWidth" (click)="dismiss()">{{ btnCancelText }}</button>
    <button type="button" class="btn btn-primary" (click)="signUp()">{{ btnOkText }}</button>

</div>