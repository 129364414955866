import { forwardRef, Inject, Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import moment from "moment";
import { environment } from "../../../environments/environment";
import { ApiService } from "../api/api.service";
import { AutoRefillConsumablesService } from "../auto-refill-consumables/auto-refill-consumables.service";
import { CommonServiceService } from "../common/common-service.service";
import { UrlService } from "../digital-id/url.service";
import {
  BroadcastRecipient,
  ConsumablePaidBy,
  MsgMedium,
  SendMsgToIndType
} from "../enums/general.enums";
import { Role } from "../enums/user.enums";
import { IndividualApiService } from "../individual/individual.api.service";
import { LicenseService } from "../license/license.service";
import lodash from "lodash";
import {
  ToDoManagerClass,
  ToDoManagerr,
  ToDoService
} from "../to-do/to-do.service";
import { OrganizationsService } from "../organizations/organizations.service";
@Injectable({
  providedIn: "root"
})
export class CommunicationService {
  constructor(
    @Inject(forwardRef(() => ApiService)) public api,
    @Inject(forwardRef(() => UrlService)) public urlService,
    private individualApi: IndividualApiService,
    private commonService: CommonServiceService,
    public db: AngularFireDatabase,
    public autoRefillConsumablesService: AutoRefillConsumablesService,
    private licenseService: LicenseService
  ) {}

  // --- prepare send message
  prepareSendMessage(
    type: string,
    toAddress: string,
    message: string,
    emailSubject: string = "",
    indData: any
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let promises = [];
      let countryCodes = [];
      let numbers = [];
      let messages = [];
      let indIdList = [];
      let emails = [];
      let mailgunObj = {};
      promises.push(
        _ =>
          new Promise(async resolve => {
            if (type === "sms") {
              let number = toAddress;
              let wellFormattedNumber = this.commonService.getWellFormattedPhone(
                number
              );
              // --- get country code
              let phoneNumber = this.commonService.getCountryCode(
                wellFormattedNumber
              );
              countryCodes.push(phoneNumber.countryCode);
              numbers.push(phoneNumber.number);

              messages.push(message);
              indIdList.push(indData._key);
              resolve("");
            }
            if (type === "email") {
              let emailList = toAddress;
              emails.push(emailList);

              // --- create mailgun recipient variable obj
              mailgunObj[emails[0]] = { ind_key: indData._key };
              indIdList.push(indData._key);
              resolve("");
            }
          })
      );

      for (let promise of promises) {
        await promise();
      }
      // --- resolve obj
      const obj = {
        emailSubject: emailSubject,
        emails: emails,
        numbers: numbers,
        countryCodes: countryCodes,
        smsMessages: messages,
        indIdList: indIdList,
        emailMessage: message,
        recipient_var: mailgunObj
      };
      resolve(obj);
    });
  }

  async getTotalSmsCount(orgID) {
    return await this.licenseService.getTotalSmsCount(Role.ORG, orgID);
  }

  async checkIfCreditLessOrNot(
    totalSendCount: number,
    org: any,
    consumablePaidBy
  ) {
    let isOrg = consumablePaidBy == ConsumablePaidBy.ORG;
    let userRole = isOrg ? Role.ORG : Role.STUDIO;
    let userID = isOrg ? org.orgID : org.studioID;
    let remainingCredits = await this.licenseService.getTotalSmsCount(
      userRole,
      userID
    );
    if (remainingCredits < totalSendCount) {
      return true;
    } else {
      return false;
    }
  }

  tagPrefixStr = "%recipient.";
  async send(
    orgService: OrganizationsService,
    type: string,
    toAddress: string,
    org: any,
    indData: any,
    message: string,
    emailSubject?: string
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        let resObj = await this.prepareSendMessage(
          type,
          toAddress,
          message,
          emailSubject,
          indData
        );

        // check if auto refill is needed or not
        let autoFillRes = await this.autoRefillConsumablesService.checkAutoRefillNeeded(
          orgService,
          org.orgID
        );
        let totalSendCount =
          type == "sms"
            ? this.urlService.getTotalMsgCount(resObj.smsMessages)
            : resObj.emails.length;
        if (autoFillRes.success) {
          let promises = [];
          promises.push(this.commonService.getOrgCurrentCycle(org.orgID));
          promises.push(
            this.checkIfCreditLessOrNot(totalSendCount, org, autoFillRes.paidBy)
          );
          let promisesRes = await Promise.all(promises);
          let currentCycle = promisesRes[0];
          let creditRes = promisesRes[1];

          if (creditRes) {
            await this.broadcastReport(
              type,
              org.orgID,
              currentCycle,
              indData._key
            );
            let obj = {};
            if (type == "sms") {
              obj = {
                failureMessage: "No credit Balance available to send SMS",
                number: resObj.numbers[0],
                timestamp: Number(moment().format("x"))
              };
            } else {
              obj = {
                message: "No credit Balance available to send Email",
                email: resObj.emails[0],
                timestamp: Number(moment().format("x")),
                status: "failure",
                sentCount: 0
              };
            }
            this.db
              .list(
                `/Transactions/${org.orgID}/${currentCycle}/communications/broadcastReport/${this.broadcastKey}/sendReport`
              )
              .push(obj);
            return resolve(obj);
          } else {
            let response: any = await this.autoRefillConsumablesService.checkSmsConfiguration(
              totalSendCount,
              autoFillRes.paidBy,
              org.orgID,
              org.studioID
            );
            if (response.success) {
              // --- pass ajax request to the server
              window.setTimeout(() => {
                resolve(true);
              }, 2000);
              await this.broadcastReport(
                type,
                org.orgID,
                currentCycle,
                indData._key
              );
              if (type == "sms") {
                await this.sendSms(
                  type,
                  resObj,
                  response.licensesTobeUpdate,
                  response.messageCreditsToBeCharged,
                  org,
                  response.paidBy,
                  currentCycle
                );
              } else {
                await this.sendEmail(
                  resObj,
                  response.licensesTobeUpdate,
                  response.messageCreditsToBeCharged,
                  org,
                  response.paidBy,
                  currentCycle
                );
              }
            } else {
              resolve(true);
            }
          }
        }
      } catch (e) {
        resolve(true);
      }
    });
  }

  async sendSms(
    type,
    resObj,
    licensesTobeUpdate,
    messageCreditsToBeCharged,
    org,
    consumablePaidBy,
    currentCycle
  ) {
    const postData = {
      type: type,
      service: "twilio",
      emailSubject: [],
      emails: [],
      numbers: resObj.numbers,
      messages: resObj.smsMessages,
      indIdList: resObj.indIdList,
      countryCodes: resObj.countryCodes,
      orgName: org.orgName,
      timestamp: Number(moment().format("x"))
    };
    postData["broadcastKey"] = this.broadcastKey;
    postData["isFromTest"] = !environment.production;
    postData["orgId"] = org.orgID;
    postData["currentCycle"] = currentCycle;
    this.api
      .post(environment.smsObj.endPoint, postData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        },
        responseType: "text"
      })
      .subscribe(
        async (result: any) => {
          if (consumablePaidBy == ConsumablePaidBy.ORG) {
            await this.urlService.updateSMSCountInLicense(
              org.orgID,
              licensesTobeUpdate
            );
            await this.sendLowBalanceReminder(
              org.orgID,
              messageCreditsToBeCharged
            );
          } else {
            this.autoRefillConsumablesService.saveStudioConsumablesUsesCount(
              "email",
              messageCreditsToBeCharged,
              org.orgID,
              org.studioID
            );
            await this.autoRefillConsumablesService.updateStudioSMSCountInLicense(
              org.studioID,
              licensesTobeUpdate
            );
          }
        },
        error => {
          console.log(error.message);
        }
      );
  }

  async sendEmail(
    resObj,
    licensesTobeUpdate,
    messageCreditsToBeCharged,
    org,
    consumablePaidBy,
    currentCycle
  ) {
    // --- email post data
    const emailPostData = {
      from: environment.mailFromAddress,
      to: resObj.emails,
      subject: resObj.emailSubject,
      text: resObj.emailMessage,
      recipient_variables: resObj.recipient_var,
      ["v:ind_key"]: `${this.tagPrefixStr}ind_key%`,
      ["v:org_id"]: org.orgID,
      ["v:is_from_test"]: !environment.production,
      ["v:is_ind_email"]: 1
    };
    let obj = {
      data: [emailPostData],
      type: "single",
      emailType: "email",
      broadcastKey: this.broadcastKey,
      isFromTest: !environment.production,
      orgId: org.orgID,
      currentCycle: currentCycle
    };
    this.api.post(environment.mailgunObj.endPoint, obj).subscribe(
      async res => {
        console.log("res: ", res);
        if (res.status == "ok") {
          if (consumablePaidBy == ConsumablePaidBy.ORG) {
            await this.urlService.updateSMSCountInLicense(
              org.orgID,
              licensesTobeUpdate
            );
            await this.sendLowBalanceReminder(
              org.orgID,
              messageCreditsToBeCharged
            );
          } else {
            this.autoRefillConsumablesService.saveStudioConsumablesUsesCount(
              "email",
              messageCreditsToBeCharged,
              org.orgID,
              org.studioID
            );
            await this.autoRefillConsumablesService.updateStudioSMSCountInLicense(
              org.studioID,
              licensesTobeUpdate
            );
          }
        }
      },
      err => {
        console.log("err: ", err);
      }
    );
  }

  async sendLowBalanceReminder(orgID, messageCreditsToBeCharged) {
    // check remaining sms credit and show reminder if credit less then 10% of individuals
    let promises = [];
    promises.push(this.getTotalSmsCount(orgID));
    promises.push(this.urlService.getTotalIndCount(orgID));
    let [promisesResults, error] = await this.commonService.executePromise(
      Promise.all(promises)
    );
    if (error) return;
    let totalSmsCount = promisesResults[0];
    let smsCountObj = promisesResults[1];
    if (smsCountObj.success) {
      let minReqSmsCount = Math.ceil((smsCountObj.indCount * 10) / 100);

      // --- send warning only once when threshold gets crossed. Not every time when credit is lower than threshold. This is to avoid redundancy.
      let currentMessageCredits = totalSmsCount;
      let threshold = minReqSmsCount;
      let messageCreditsBeforeSend =
        currentMessageCredits + messageCreditsToBeCharged;
      if (
        messageCreditsBeforeSend > threshold &&
        currentMessageCredits <= threshold
      ) {
        this.urlService.sendLowBalanceReminderToOrg(orgID);
      }
    }
  }

  broadcastKey: string = "";
  sentCount: number = 0;
  async broadcastReport(
    sendType: string,
    orgID: string,
    currentCycle: string,
    indID?: string
  ) {
    return new Promise(async (resolve, reject) => {
      // --- for store broadcast history
      let obj = {
        sentTimestamp: Number(moment().format("x")),
        selectedIndCount: 1,
        creditUsed: sendType == "sms" ? this.sentCount : 1,
        recipients: 5,
        template: "custom",
        broadcastType: this.commonService.getBroadcastName(sendType, "number"),
        status: "pending",
        type: "Communication"
      };
      if (indID) obj["indID"] = indID;
      this.broadcastKey = this.commonService.createFirebasePushId();
      this.commonService
        .saveBroadcast(orgID, obj, currentCycle, this.broadcastKey)
        .then(
          res => {
            resolve("");
          },
          err => {
            console.log("err", err);
            resolve("");
          }
        );
    });
  }

  // --- Calculate required consumable credits to send given messages through given medium
  calculateRqrdCredits(messages: any[], medium: MsgMedium) {
    if (medium == MsgMedium.EMAIL || medium == MsgMedium.EMAIL_HTML)
      return lodash.size(messages);
    if (medium == MsgMedium.SMS)
      return lodash.reduce(
        messages,
        (ac: number, msg: string) => ac + Math.ceil(msg.length / 160),
        0
      );
    else throw Error(`Unhandled message medium ${medium}`);
  }

  // --- get broadcast type name using number
  getBroadcastName(num: any, type: string): any {
    if (type == "name") {
      switch (num) {
        case 1:
          return "SMS / Text message";
        case 2:
          return "Email - Plain Text";
        case 3:
          return "Email - HTML";
      }
    } else {
      switch (num) {
        case "sms":
          return 1;
        case "email":
          return 2;
        case "emailHtml":
          return 3;
      }
    }
  }

  // --- store broadcast report in DB
  saveBroadcastReport(
    orgID: string,
    messages: any[],
    requiredCredits: number,
    recipient: BroadcastRecipient,
    msgTemplateName: string,
    msgMedium: MsgMedium,
    msgType: SendMsgToIndType,
    cycle: string,
    indID?: string
  ) {
    let broadcastData: any = {
      sentTimestamp: moment().valueOf(),
      selectedIndCount: lodash.size(messages),
      creditUsed: requiredCredits,
      recipients: recipient,
      template: msgTemplateName,
      broadcastType: this.getBroadcastName(msgMedium, "number"),
      status: "pending",
      type: lodash.get(
        CommonServiceService.MsgTypeShortNames,
        msgType,
        "Unknown"
      )
    };
    if (indID) broadcastData.indID = indID;

    let broadcastKey = this.commonService.createFirebasePushId();

    let saveBroadcastReportPromise = this.db
      .object(
        `/Transactions/${orgID}/${cycle}/communications/broadcastReport/${broadcastKey}`
      )
      .set(broadcastData);

    return { broadcastKey, saveBroadcastReportPromise };
  }

  // --- send SMS
  async sendSMS(
    numbers: string[],
    countryCodes: string[],
    messages: string[],
    payloadOptions?: { [key: string]: any }
  ) {
    let url = `${environment.newApiBaseUrl}/${environment.smsObj.endPoint}`;
    let reqBody = {
      type: MsgMedium.SMS,
      service: "twilio",
      numbers: numbers,
      messages: messages,
      countryCodes: countryCodes,
      timestamp: moment().valueOf(),
      isFromTest: !environment.production
    };
    if (payloadOptions) reqBody = lodash.merge(reqBody, payloadOptions);

    let sendMsgRes = await this.api.post(url, reqBody).toPromise();
    if (lodash.get(sendMsgRes, "status") != "ok")
      throw new Error(lodash.get(sendMsgRes, "message"));

    return sendMsgRes;
  }

  // --- send single email
  async sendSingleEmail(
    toEmails: string[],
    subject: string,
    message: string,
    emailType: MsgMedium.EMAIL | MsgMedium.EMAIL_HTML,
    recipientVariables?: { [key: string]: any },
    mailgunOptions?: { [key: string]: any },
    payloadOptions?: { [key: string]: any }
  ) {
    let url = `${environment.newApiBaseUrl}/${environment.mailgunObj.endPoint}`;
    let mailgunObj: any = {
      from: environment.mailFromAddress,
      to: toEmails,
      subject: subject,
      text: message,
      ["v:is_from_test"]: !environment.production
    };
    if (recipientVariables) mailgunObj.recipient_variables = recipientVariables;
    if (mailgunOptions) mailgunObj = lodash.merge(mailgunObj, mailgunOptions);
    let reqBody = {
      data: [mailgunObj],
      type: "single",
      emailType: emailType,
      isFromTest: !environment.production
    };
    if (payloadOptions) reqBody = lodash.merge(reqBody, payloadOptions);

    let sendEmailRes = await this.api.post(url, reqBody).toPromise();
    if (lodash.get(sendEmailRes, "status") != "ok")
      throw new Error(lodash.get(sendEmailRes, "message"));

    return sendEmailRes;
  }

  // --- create low balance to-do task for organization
  async createLowBalanceToDoForOrg(
    orgID: string,
    cycle: string,
    todoService: ToDoService
  ) {
    // --- prepare to-do task object
    let lowCreditsToDoTask = {
      manager: ToDoManagerr.LICENSE,
      class: ToDoManagerClass.LICENSE.LOW_CREDIT_BALANCE,
      title: "Low Credit Balance",
      desc:
        "Your message balance is less than 10% of the individual count for your organization. Click the above button to refill.",
      severity: 15,
      privateDetails: {
        orgID: orgID,
        pageToOpen: "AddConsumablesPage"
      }
    };
    return await todoService.setToDoTask(
      orgID,
      Role.ORG,
      cycle,
      lowCreditsToDoTask
    );
  }

  // --- send low balance email/sms notification to org and its all parents/studios
  async sendLowBalanceEmailSMSToOrgAndParents(
    orgID: string,
    services: { orgService: OrganizationsService }
  ) {
    // TODO::complete method implementation
    // // #1 read org email, phone AND all parents email, phone
    // // # 1A - read org data
    // let readOrgDataPromises = [];
    // readOrgDataPromises.push(services.orgService.getOrgSecureData(orgID, true));
    // readOrgDataPromises.push(
    //   services.orgService.getOrgData(
    //     orgID,
    //     ["Phone_Main", "parentList", "orgName"],
    //     true
    //   )
    // );
    // let [
    //   orgDataPromisesResults,
    //   orgDataPromisesErr
    // ] = await this.commonService.executePromise(
    //   Promise.all(readOrgDataPromises)
    // );
    // if (orgDataPromisesErr) {
    //   console.log(
    //     `Error reading org data for sending low balance email/sms for orgID: ${orgID}`,
    //     orgDataPromisesErr
    //   );
    //   return false;
    // }
    // let orgSecData = lodash.nth(orgDataPromisesResults, 0);
    // let orgProps = lodash.nth(orgDataPromisesResults, 1);
    // let orgAdminEmail: any = lodash.get(orgSecData, "admin.email");
    // let orgAdminPhone: any = lodash.get(orgProps, "Phone_Main");
    // let orgParents: any = lodash.get(orgProps, "parentList");
    // let orgName: any = lodash.get(orgProps, "orgName");
    // let parsedAdminPhone;
    // if (orgAdminPhone) parsedAdminPhone = utils.parsePhone(orgAdminPhone);
    // // # 1B - read parents data
    // let orgParentsEmails: any[] = [];
    // let orgParentsPhones: any[] = [];
    // let orgParentsPhonesCountryCodes: any[] = [];
    // let parentIDs = lodash.keys(orgParents);
    // let fetchParentsDataPromises: Promise<any>[] = [];
    // lodash.each(parentIDs, (parentID: string) => {
    //   fetchParentsDataPromises.push(
    //     getStudioProps(parentID, ["email", "adminPhone"]).then(studioProps => {
    //       return { ...studioProps, key: parentID };
    //     })
    //   );
    // });
    // let [
    //   fetchParentsDataPromisesRes,
    //   fetchParentsDataPromisesErr
    // ] = await utils.executePromise(Promise.all(fetchParentsDataPromises));
    // if (fetchParentsDataPromisesErr) {
    //   utils.prettierLog(
    //     fetchParentsDataPromisesErr,
    //     `Error reading parents data for sending low balance email/sms for orgID: ${orgID}`
    //   );
    //   return false;
    // }
    // lodash.each(fetchParentsDataPromisesRes, (parentData: any) => {
    //   let email = lodash.get(parentData, "email");
    //   let phone = lodash.get(parentData, "adminPhone");
    //   if (utils.isEmailValid(email))
    //     orgParentsEmails.push(lodash.toLower(email));
    //   if (phone) {
    //     let parsedPhone = utils.parsePhone(phone);
    //     orgParentsPhonesCountryCodes.push(parsedPhone.countryCode);
    //     orgParentsPhones.push(parsedPhone.number);
    //   }
    // });
    // // #2 prepare & send messages to org & parents
    // let messagesForStudios = {
    //   sms: `IMPORTANT: The organization ${orgName} is about to run out of message credits. They will shortly be unable to send SMS/Email.`,
    //   email: `<h3>IMPORTANT</h3><br/><p>The organization ${orgName} is about to run out of message credits. They are sending email or SMS messages but will shortly be unable to continue.</p>`
    // };
    // let messagesForOrgAdmins = {
    //   sms:
    //     "IMPORTANT: Your message balance is less than 10% of the individual count for your org. Shortly you will be out of credits and won't able to send messages.",
    //   email:
    //     "<h3>IMPORTANT</h3><br/><p>Your message balance is less than 10% of the individual count for your org. Shortly you will be out of credits and won't able to send messages. You can buy more credits from admin panel.</p>"
    // };
    // let sendMsgPromises = [];
    // // --- send SMSes
    // let phoneNumbers: any[] = [];
    // let countryCodes: any = [];
    // let smsMessages: string[] = [];
    // if (parsedAdminPhone && parsedAdminPhone.number) {
    //   phoneNumbers.push(parsedAdminPhone.number);
    //   countryCodes.push(parsedAdminPhone.countryCode);
    //   smsMessages.push(messagesForOrgAdmins.sms);
    // }
    // if (orgParentsPhones && lodash.size(orgParentsPhones) > 0) {
    //   phoneNumbers = lodash.concat(phoneNumbers, orgParentsPhones);
    //   countryCodes = lodash.concat(countryCodes, orgParentsPhonesCountryCodes);
    //   lodash.each(orgParentsPhones, (_: any) => {
    //     smsMessages = lodash.concat(smsMessages, messagesForStudios.sms);
    //   });
    // }
    // if (lodash.size(phoneNumbers) > 0) {
    //   let payloadOptions = {
    //     orgName: orgName
    //   };
    //   sendMsgPromises.push(
    //     sendSMS(phoneNumbers, countryCodes, smsMessages, payloadOptions)
    //   );
    // }
    // // --- send emails
    // let emails: any[] = [];
    // let recipientVariables: any = {};
    // if (orgAdminEmail && utils.isEmailValid(orgAdminEmail)) {
    //   orgAdminEmail = lodash.toLower(orgAdminEmail);
    //   emails.push(orgAdminEmail);
    //   recipientVariables[orgAdminEmail] = {
    //     emailContent: messagesForOrgAdmins.email
    //   };
    // }
    // if (orgParentsEmails && lodash.size(orgParentsEmails) > 0) {
    //   emails = lodash.concat(emails, orgParentsEmails);
    //   lodash.each(orgParentsEmails, (e: string) => {
    //     recipientVariables[e] = { emailContent: messagesForStudios.email };
    //   });
    // }
    // if (lodash.size(emails) > 0) {
    //   let mailgunOptions = {
    //     ["v:org_id"]: orgID
    //   };
    //   sendMsgPromises.push(
    //     sendSingleEmail(
    //       emails,
    //       `Low message credits - ${orgName}`,
    //       `${Constants.mailgunTagPrefix}emailContent%`,
    //       MsgMedium.EMAIL_HTML,
    //       recipientVariables,
    //       mailgunOptions
    //     )
    //   );
    // }
    // if (lodash.size(sendMsgPromises) > 0) {
    //   let [, errSendingMsgs] = await utils.executePromise(
    //     Promise.all(sendMsgPromises)
    //   );
    //   if (errSendingMsgs) {
    //     utils.prettierLog(
    //       errSendingMsgs,
    //       `Error sending email/sms notifications of low balance for orgID: ${orgID}`
    //     );
    //     return false;
    //   }
    // }
    // return true;
  }

  // --- send low consumables balance reminder to org If it crossed threshold
  async sendLowBalanceReminderIfNeeded(
    orgID: string,
    creditsCharged: number,
    cycle: string,
    services: {
      licenseService: LicenseService;
      individualsService: IndividualApiService;
      todoService: ToDoService;
      orgService: OrganizationsService;
    }
  ) {
    // check remaining sms credit and show reminder if credit less then 10% of individuals
    let promises = [];
    promises.push(services.licenseService.getTotalSmsCount(Role.ORG, orgID));
    promises.push(services.individualsService.getTotalIndCount(orgID));
    let [promisesResults, error] = await this.commonService.executePromise(
      Promise.all(promises)
    );
    if (error) {
      console.log(
        `Error sending low balance reminder to orgID: ${orgID}`,
        error
      );
      return false;
    }

    let orgCredits: any = lodash.nth(promisesResults, 0);
    let indsCount: any = lodash.nth(promisesResults, 1);

    // --- send warning only once when threshold gets crossed. Not every time when credit is lower than threshold. This is to avoid redundancy.
    let threshold = Math.ceil((indsCount * 10) / 100); // 10% of inds count
    let orgCreditsBeforeSend = orgCredits + creditsCharged;
    if (orgCreditsBeforeSend > threshold && orgCredits <= threshold) {
      let sendRemindersPromises = [];

      // --- create to-do task for org
      sendRemindersPromises.push(
        this.createLowBalanceToDoForOrg(orgID, cycle, services.todoService)
      );

      // --- send email/sms notifications to org and its parents
      sendRemindersPromises.push(
        this.sendLowBalanceEmailSMSToOrgAndParents(orgID, {
          orgService: services.orgService
        })
      );

      let [
        results,
        errSendingReminders
      ]: any = await this.commonService.executePromise(
        Promise.all(sendRemindersPromises)
      );
      if (!errSendingReminders && lodash.nth(results, 1) == false) {
        errSendingReminders = "Error sending email/sms notifications";
      }
      if (errSendingReminders) {
        console.log(
          `Error sending low balance reminder to orgID: ${orgID}`,
          error
        );
        return false;
      }
    }

    return true;
  }
}
