import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { AngularFireDatabase } from "@angular/fire/database";
import { CommonServiceService } from "../common/common-service.service";
import { ApiService } from "../api/api.service";
import { UserApiService } from "../user/user.api.service";
import { Observable, from, of } from "rxjs";
import { EncryptionService } from "../encryption/encryption.service";
import { CacheKeys, CacheService } from "../cache/cache.service";
import { ApiHelperService, CloudFnNames } from "../api-helper.service";
import lodash from "lodash";
import { debounceTime, map, mergeMap } from "rxjs/operators";
import { OrgProps } from "../utils/orgs.utils";
import { OrgsListBy } from "../enums/general.enums";
import { AuthDependency } from "../interface/types";
import { Role } from "../enums/user.enums";
import { InheritanceService } from "../inheritance/inheritance.service";

@Injectable({
  providedIn: "root"
})
export class OrganizationsService {
  constructor(
    private httpClient: HttpClient,
    private db: AngularFireDatabase,
    private commonService: CommonServiceService,
    private api: ApiService,
    private usersService: UserApiService,
    private encryptionService: EncryptionService,
    private cacheService: CacheService,
    private apiHelperService: ApiHelperService,
    private inheritanceService: InheritanceService
  ) {}

  orgSecDataBaseURL = environment.firebaseFunctionsBaseUrl;
  readonly secKey = environment.edk;
  basePath = "organizations";
  tagPrefixStr = "%recipient.";

  // --- get organization data
  async getOrgData(
    orgID: string,
    propsToReturn: string[] = OrgProps.All,
    useCache: boolean = false
  ) {
    if (!orgID || !propsToReturn) throw new Error("Params missing!");

    // --- prepare cache path
    let cachePath = `${orgID}/`;
    cachePath += this.commonService.getCachePathForProps(propsToReturn);

    let orgData: any;

    // --- read from cache
    if (useCache) {
      let cachedData = this.cacheService.get(CacheKeys.OrgData, cachePath);
      if (cachedData) orgData = cachedData.value;
    }

    // --- read org data from database
    if (!orgData) {
      let reqBody = { orgID, propsToReturn };
      let orgDataRes = await this.apiHelperService.postToCloudFn(
        CloudFnNames.getOrgData,
        reqBody
      );
      orgData = lodash.get(orgDataRes, "result.data");

      // --- set cache
      this.cacheService.set(CacheKeys.OrgData, cachePath, orgData);
    }

    return orgData;
  }

  // --- get orgs list
  async getOrgsList(
    by: OrgsListBy,
    value: any,
    authDependencies?: AuthDependency[],
    propsToReturn?: string[],
    restrictions?: any
  ) {
    if (!by || !value) throw new Error("Params missing!");

    // --- read orgs list from database
    let reqBody = { by, value, authDependencies, propsToReturn, restrictions };
    let orgDataRes = await this.apiHelperService.postToCloudFn(
      CloudFnNames.getOrgs,
      reqBody
    );
    let orgsList = lodash.get(orgDataRes, "result.data");

    return orgsList;
  }

  // --- get org prop
  async getOrgProp(orgID: string, prop: string, useCache: boolean = false) {
    if (!orgID || !prop) throw new Error("Params missing!");

    let orgData = await this.getOrgData(orgID, [prop], useCache);
    return lodash.get(orgData, prop);
  }

  // --- read org sensitive info
  async getOrgSecureData(
    orgID: string,
    passRequired: boolean = false,
    returnRawData: boolean = false,
    useCache: boolean = true
  ) {
    if (!orgID) return null;

    let response: any;

    // --- read from cache
    let cachePath = `${orgID}/${passRequired}/${returnRawData}`;
    if (useCache) {
      let cachedData = this.cacheService.get(CacheKeys.OrgSecData, cachePath);
      if (cachedData) response = cachedData.value;
    }

    // --- make http request if cache data isn't available
    if (!response) {
      let requestBody = JSON.stringify({ orgID, passRequired });
      let encryptedBody = this.encryptionService.encrypt(
        requestBody,
        this.secKey
      );
      response = await this.httpClient
        .post(`${this.orgSecDataBaseURL}/getOrgSecData`, encryptedBody, {
          responseType: "text"
        })
        .toPromise();

      // --- set cache
      this.cacheService.set(CacheKeys.OrgSecData, cachePath, response);
    }

    response = JSON.parse(
      this.encryptionService.decrypt(response, this.secKey)
    );

    return returnRawData
      ? response.data
      : response.data && response.data.hasOwnProperty("admin")
      ? response.data["admin"]
      : response.data
      ? response.data
      : null;
  }

  // --- set org sensitive data
  async setOrgSecData(orgID: string, orgSecData: any) {
    if (!orgID || !orgSecData) return null;

    let requestBody = JSON.stringify({ orgID, orgSecData: orgSecData });
    let encryptedBody = this.encryptionService.encrypt(
      requestBody,
      this.secKey
    );

    return await this.httpClient
      .post(`${this.orgSecDataBaseURL}/setOrgSecData`, encryptedBody)
      .toPromise();
  }

  // --- update org sensitive data
  async updateOrgSecData(orgID: string, updateObj: any) {
    if (!orgID || !updateObj) return null;

    let requestBody = JSON.stringify({ orgID, updateObj: updateObj });
    let encryptedBody = this.encryptionService.encrypt(
      requestBody,
      this.secKey
    );

    return await this.httpClient
      .post(`${this.orgSecDataBaseURL}/updateOrgSecData`, encryptedBody)
      .toPromise();
  }

  // --- delete org sensitive info
  async deleteOrgSecData(orgID: string) {
    if (!orgID) return null;

    let requestBody = JSON.stringify({ orgID });
    let encryptedBody = this.encryptionService.encrypt(
      requestBody,
      this.secKey
    );

    return await this.httpClient
      .post(`${this.orgSecDataBaseURL}/removeOrgSecInfo`, encryptedBody)
      .toPromise();
  }

  /**
   * read organizations welcome email contents
   * @returns organizations welcome email contents
   */
  async getOrgWelcomeEmailContents() {
    let snapshot = await this.db
      .object(`settings/welcomeMailContent`)
      .query.once("value");
    return snapshot.val();
  }

  /**
   * update organization data
   * @param orgID organization id
   * @param updateObj update object
   * @param path extra path if you want to specify to update object in
   */
  async updateOrgData(orgID: string, updateObj: any, path?: string) {
    if (!orgID || !updateObj)
      throw Error("params missing in updateOrgData call");

    this.db.object(`${this.basePath}/${orgID}/${path || ""}`).update(updateObj);
  }

  // --- set org data
  async setOrgData(orgID: string, value: any, path?: string) {
    if (!orgID || !value) throw Error("params missing in value call");

    this.db.object(`${this.basePath}/${orgID}/${path || ""}`).set(value);
  }

  // --- monitor org data
  monitorOrgData(orgID, propsToReturn?: string[]) {
    return from(
      this.db
        .object(`metadata_1/organizations/${orgID}/updatedAt`)
        .valueChanges()
    )
      .pipe(debounceTime(500))
      .pipe(
        mergeMap(async metadata => {
          let orgData = await this.getOrgData(orgID, propsToReturn);
          if (orgData) orgData.key = orgID;

          return orgData;
        })
      );
  }

  // --- send single email
  async sendSingleEmail(mailgunObj, type?) {
    // --- send email
    let [res, error] = await this.commonService.executePromise(
      this.api
        .post(`${environment.newApiBaseUrl}/mailgun-send-mail.php`, {
          data: [mailgunObj],
          type: "single",
          emailType: type || "email"
        })
        .toPromise()
    );

    // --- error check
    if (error) {
      console.log(error, "Error while sending single email!");
      return null;
    }

    return true;
  }

  /**
   * send welcome email to organization
   * @param orgData organization data
   * @returns true if success, false otherwise
   */
  async sendRegCompleteEmailToOrg(orgData) {
    // --- prepare email message for org
    let orgEmailMessage = `Hello and welcome to High5.ID!

    For the record, here are your credentials:
    
    URL:        ${this.tagPrefixStr}actual_link%
    UserID:		  ${this.tagPrefixStr}user_email%
    Password:	  ${this.tagPrefixStr}password%
    
    When you log in, you'll find a quickstart guide that will get you rolling.

    Visit https://high5.id to chat with our support staff if you get stuck.

    ${orgData.mailContent ? orgData.mailContent : ""}

    Enjoy!

    --The High5.ID Team--`;

    // --- prepare mailgun object
    const orgEmailMailgunObj = {
      from: environment.mailFromAddress,
      to: [orgData.email],
      text: orgEmailMessage,
      subject: `Welcome to High5.ID!`,
      recipient_variables: {
        [orgData.email]: {
          actual_link: "https://high5.id/v",
          user_email: orgData.email,
          password: orgData.password
        }
      },
      ["v:is_from_test"]: !environment.production
    };

    // --- send email
    let res = await this.sendSingleEmail(orgEmailMailgunObj);

    // --- error check
    if (!res) {
      console.log(`Error while sending welcome email to org.`);
      return null;
    }

    return true;
  }

  /**
   * send org created email to high5 admin
   * @param {any} orgData object containing org data
   * @returns true if success
   */
  async sendOrgRegCompleteEmailToHigh5Admin(orgData) {
    // --- prepare email message for org
    let high5AdminEmailMessage = `The Org \n${this.tagPrefixStr}name%\njust created \n\n
    Contact details:\nadmin email ID: ${this.tagPrefixStr}email%\n
    admin Name: ${this.tagPrefixStr}adminName%\nadmin Phone: ${this.tagPrefixStr}adminPhone%`;

    // --- prepare mailgun object
    const high5AdminMailgunObj = {
      from: environment.mailFromAddress,
      to: [environment.onrampSetupAdminEmail],
      text: high5AdminEmailMessage,
      subject: `Org ${this.tagPrefixStr}name% created by PLIC`,
      recipient_variables: {
        [environment.onrampSetupAdminEmail]: {
          name: orgData.name,
          email: orgData.email,
          adminName: orgData.adminName || "-",
          adminPhone: orgData.adminPhone || "-"
        }
      },
      ["v:is_from_test"]: !environment.production
    };

    // --- send email
    let res = await this.sendSingleEmail(high5AdminMailgunObj);

    // --- error check
    if (!res) {
      console.log(
        `Error while sending org created email to high5 admin. org email: ${orgData.email}`
      );
      return null;
    }

    return true;
  }

  /**
   * remove individuals of organization
   * @param orgID organization id
   * @returns Promise of response returned by API
   */
  async removeIndividuals(orgID: string) {
    if (!orgID) throw new Error("orgID missing!");

    let requestBody = JSON.stringify({ orgID });
    let encryptedBody = this.encryptionService.encrypt(
      requestBody,
      this.secKey
    );

    let response: any = await this.httpClient
      .post(
        `${environment.firebaseFunctionsBaseUrl}/removeOrgInds`,
        encryptedBody
      )
      .toPromise();
    return response ? response.data : null;
  }

  // --- delete org
  async deleteOrg(id: string, firebaseUID: string) {
    if (!id) throw Error("id missing in params!");
    if (!firebaseUID) throw Error("firebaseUID missing in params!");

    let deleteOrgPromises = [];
    // --- removing org data
    deleteOrgPromises.push(this.db.object(`/organizations/${id}`).remove());

    // --- removing org sec data
    deleteOrgPromises.push(this.deleteOrgSecData(id));

    // --- removing org licenses
    deleteOrgPromises.push(this.deleteOrgLicenses(id));

    // --- removing org individuals
    deleteOrgPromises.push(this.removeIndividuals(id));

    // --- removing org user
    deleteOrgPromises.push(this.usersService.removeUser(firebaseUID));

    return Promise.all(deleteOrgPromises);
  }

  // --- remove org licenses
  deleteOrgLicenses(id: string) {
    return this.db.object(`/licenses/instances/${id}`).set(null);
  }

  async readOrgPrefByInheritance(orgData) {
    if (!orgData || !orgData?.key) return;

    let orgPrefByInheritance = await this.inheritanceService.getByInheritance(
      Role.ORG,
      orgData.key,
      'settings/org-preference',
      'list'
    );
    let arr2: any[] = [];
    lodash.forEach(orgPrefByInheritance, (inheritance: any) => {
      if (inheritance.ownerID == orgData.key && !lodash.isEmpty(inheritance.data)) {
        arr2 = this.commonService.convertObjToArr(inheritance.data);
      } else if (inheritance.ownerID == orgData?.studioID && !lodash.isEmpty(inheritance.data)) {
        arr2 = this.commonService.convertObjToArr(inheritance.data);
      } else if (inheritance.ownerID == Role.SUPERADMIN && !lodash.isEmpty(inheritance.data)) {
        arr2 = this.commonService.convertObjToArr(inheritance.data);
      }
      if (arr2.length > 0) return;
    })
    if(orgData?.settings['org-preference']) {
      let mergedPrefData = this.commonService.mergeTwoArrUsingKeyMatch(this.commonService.convertObjToArr(orgData?.settings['org-preference']), arr2);
      return mergedPrefData;
    }
    return arr2;
  }
}
