import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countAgeFromDOB'
})
export class CountAgeFromDOB implements PipeTransform {

  transform(birth: any) {
    // return Math.floor(moment(`31/07/${moment().format('YYYY')}`, 'DD/MM/YYYY').diff(birth, 'years',true))
    return this.getAge(birth)
  }

  getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = 7 - (birthDate.getMonth() + 1);
    if (m < 0 || (m === 0 && 31 < birthDate.getDate())) {
      age--;
    }
    return age;
  }

}
