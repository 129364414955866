import { Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { from } from "rxjs";
import { take, timeout } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class ServiceWorkerService {
  constructor(private swUpdate: SwUpdate) {}

  // --- update app if update is available
  updateIfAvailable() {
    return new Promise(async resolve => {
      try {
        // --- subscribe updates availability listener
        from(this.swUpdate.available)
          .pipe(take(1), timeout(10 * 1000))
          .subscribe(
            () => resolve(true),
            err => resolve(false)
          );

        await this.swUpdate.checkForUpdate();
      } catch (e) {
        return resolve(false);
      }
    });
  }

  // --- update update
  activateUpdate() {
    return this.swUpdate.activateUpdate();
  }
}
