import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import lodash from "lodash";
import { fromEvent, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CommonServiceService } from "src/app/core/common/common-service.service";
import { RendererService } from "src/app/core/renderer/renderer.service";
import { ShowQrCodeService } from "../../confirm-dialog/pages/show-qr-code/show-qr-code.service";

@Component({
  selector: "app-cached-id",
  templateUrl: "./cached-id.component.html",
  styleUrls: ["./cached-id.component.scss"]
})
export class CachedIDComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("canvasTarget") canvasTarget;

  constructor(
    private commonService: CommonServiceService,
    private showQrCodeService: ShowQrCodeService,
    private cd: ChangeDetectorRef,
    private rendererService: RendererService
  ) {}

  // --- show QR code in big view
  async showQRCode() {
    let cachedID = await this.commonService.getCachedID();
    let indHash = lodash.get(cachedID, "indHash");
    let studentID = lodash.get(cachedID, "studentID");
    if (!indHash && !studentID) return;

    this.showQrCodeService.confirm("Qr Code", { indhash: indHash }, studentID);
  }

  // --- clear canvas to render new SVG
  clearCanvas() {
    if (this.canvasTarget && this.canvasTarget.nativeElement) {
      this.canvasTarget.nativeElement.innerHTML = "";
      this.canvasTarget.nativeElement.style.height = "0px";
    }
  }

  // --- init views
  async initView() {
    // --- set ID in the view
    let cachedID = await this.commonService.getCachedID();
    let idB64 = lodash.get(cachedID, "b64");
    let timestampFormat = lodash.get(cachedID, "timestampFormat");
    if (!idB64) return;

    // --- clear canvas
    this.clearCanvas();

    // --- show cached digital ID in container
    if (this.canvasTarget && this.canvasTarget.nativeElement)
      this.canvasTarget.nativeElement.innerHTML = idB64;

    // --- start timestamp ticking
    try {
      this.rendererService.startTimestampRefresh(timestampFormat);
    } catch (e) {
      console.log("Error while timestamp refreshing!", e);
    }

    // --- trigger resize event to adjust SVG sizing
    this.commonService.svgSizing(this.canvasTarget, 0);

    this.isLoading = false;
    this.cd.detectChanges();
  }

  // --- prevent browser back action
  private pageDestroyObserver: Subject<void> = new Subject<void>();
  preventBackAction() {
    history.pushState(null, "");

    fromEvent(window, "popstate")
      .pipe(takeUntil(this.pageDestroyObserver))
      .subscribe(() => {
        history.pushState(null, "");
      });
  }

  // --- page initialization
  isLoading = false;
  displayMsg: any = {};
  async init() {
    this.isLoading = true;

    this.preventBackAction();

    let cachedID: any = await this.commonService.getCachedID();
    let idB64 = lodash.get(cachedID, "b64");
    if (cachedID.isExpired) {
      this.displayMsg = {
        msg: "We are unable to connect to http://High5.iD to refresh your digital ID. Please ensure that you have a working internet connection.",
        type: "info"
      };
      this.isLoading = false;
      return;
    } else if (!cachedID.isCachePresent && !idB64) {
      this.displayMsg = {
        msg: "We are unable to connect to http://High5.iD to collect your digital ID. Please ensure that you have a working internet connection.",
        type: "info"
      };
      this.isLoading = false;
      return;
    }
  }

  ngOnInit(): void {
    this.init();
  }

  ngAfterViewInit(): void {
    this.initView();
  }

  ngOnDestroy(): void {
    this.rendererService.stopTimestampRefresh();

    this.pageDestroyObserver.next();
    this.pageDestroyObserver.complete();
  }
}
