import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FliperPagesComponent } from "./fliper-pages/fliper-pages.component";
import { DigitalIDComponent } from "./digital-id/digital-id.component";
import { NgZorroAntdModule } from "ng-zorro-antd";
import { GeneralCommonContentsComponent } from "./general-common-contents/general-common-contents.component";
import { TranslateModule } from "@ngx-translate/core";
import { LanguageChooserComponent } from "./language-chooser/language-chooser/language-chooser.component";
import { NgbModule, NgbAccordionModule } from "@ng-bootstrap/ng-bootstrap";
import { PipesModule } from "src/app/core/pipes/pipes.module";
import { CachedIDComponent } from "./cached-id/cached-id.component";
import { GoogleSignInButtonComponent } from './google-sign-in-button/google-sign-in-button.component';
import { MicrosoftSignInButtonComponent } from './microsoft-sign-in-button/microsoft-sign-in-button.component';

@NgModule({
  declarations: [
    FliperPagesComponent,
    DigitalIDComponent,
    GeneralCommonContentsComponent,
    LanguageChooserComponent,
    CachedIDComponent,
    GoogleSignInButtonComponent,
    MicrosoftSignInButtonComponent
  ],
  imports: [
    CommonModule,
    NgZorroAntdModule,
    TranslateModule,
    NgbModule,
    NgbAccordionModule,
    PipesModule
  ],
  exports: [
    FliperPagesComponent,
    DigitalIDComponent,
    GeneralCommonContentsComponent,
    TranslateModule,
    LanguageChooserComponent,
    CachedIDComponent,
    GoogleSignInButtonComponent,
    MicrosoftSignInButtonComponent
  ]
})
export class AppCommonModule {}
