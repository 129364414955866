import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import lodash from "lodash";
import { AuthApiService } from "src/app/core/auth/auth.api.service";
import { CommonServiceService } from "src/app/core/common/common-service.service";
import { IndSearchType, SSOProvider } from "src/app/core/enums/general.enums";
import { IndividualApiService } from "src/app/core/individual/individual.api.service";
import { SSOEvent } from "src/app/core/interface/types";
import { SSOService } from "src/app/core/sso/sso.service";
import { getQueryParams } from "src/app/core/utils/queryParams.utils";

@Component({
  selector: "app-google-sign-in-button",
  templateUrl: "./google-sign-in-button.component.html",
  styleUrls: ["./google-sign-in-button.component.scss"]
})
export class GoogleSignInButtonComponent implements OnInit {
  shouldBeVisible: boolean = false;
  userAuth;
  @Output() events: EventEmitter<SSOEvent> = new EventEmitter();

  constructor(
    private ssoService: SSOService,
    private router: Router,
    private commonService: CommonServiceService,
    private individualApiService: IndividualApiService,
    private authApi: AuthApiService
  ) {}

  // --- sign in with google
  async googleSignIn() {
    this.events.next({ name: "showLoader" });
    this.ssoService.provider = SSOProvider.GOOGLE;
    await this.ssoService.configureSSO();
    await this.ssoService.initiateSSOSignin(); // this redirects user to out of app, so no need to dismiss loader
  }

  // --- handle google SSO redirect
  async handleGoogleSignInRedirect() {
    // --- check for sso provider
    if (this.ssoService.provider !== SSOProvider.GOOGLE) return;

    // --- check for sso flag
    if (this.userAuth.sso !== "1") return;

    // --- show loader
    this.events.next({ name: "showLoader" });

    // --- remove sso flag from query params
    delete this.userAuth.sso;
    await this.router.navigate([], {
      queryParams: this.userAuth,
      replaceUrl: true
    });

    const handleError = (error?: any) => {
      this.events.next({ name: "hideLoader" });
      if (error) this.commonService.handleError(error);
    };

    let ssoIdToken = this.ssoService.idToken;
    let ssoUserEmail = this.ssoService.ssoUserEmail;
    if (!ssoIdToken || !ssoUserEmail) return handleError();

    // --- find matching ind
    let indID = this.userAuth.indID;
    if (!indID) {
      // --- find matching ind
      let [
        matchingInds,
        errInSearchInds
      ] = await this.commonService.executePromise(
        this.individualApiService.searchInds(
          this.userAuth.orgID,
          IndSearchType.EMAILS,
          ssoUserEmail,
          ["key"]
        )
      );
      if (errInSearchInds) return handleError(errInSearchInds);

      if (matchingInds.length == 0)
        return handleError(
          `Sorry, We couldn't find account with ${ssoUserEmail} email`
        );

      if (matchingInds.length > 1)
        return handleError(
          `More than one account found with ${ssoUserEmail} email.`
        );

      indID = matchingInds[0].key;
    }

    // --- get custom token based on SSO user id token for sign in
    let [
      customTokenRes,
      customTokenErr
    ] = await this.commonService.executePromise(
      this.authApi.getUserCustomToken(
        this.userAuth.orgID,
        indID,
        undefined,
        ssoIdToken
      )
    );

    let customToken = lodash.get(customTokenRes, "token");
    if (customTokenErr || !customToken)
      return handleError(
        "Login failed! Please try again! Make sure you are using correct account to sign in."
      );

    // --- sign in with token
    let [, authErr] = await this.commonService.executePromise(
      this.authApi.signIn(
        "customToken",
        { individualApi: this.individualApiService },
        { orgID: this.userAuth.orgID, pwOrToken: customToken }
      )
    );
    if (authErr) return handleError(authErr);

    // --- trigger signed in event
    this.events.next({
      name: "signedIn",
      data: { userAuthUpdate: { indID, sso: null } },
      onHandled: () => {
        this.events.next({ name: "hideLoader" });
      }
    });
  }

  // --- check if necessary params are present
  async checkNecessaryParams() {
    const handleError = async msg => {
      console.warn(
        "Google Sign In won't be visible & won't function! Reason ::",
        msg
      );
      return false;
    };

    // --- check for org ID
    if (!this.userAuth.orgID) {
      return await handleError("Organization Id Missing in URL");
    }

    return true;
  }

  // --- initialize component
  async init() {
    this.userAuth = getQueryParams();

    // --- validate necessary query params
    let isParamsValid = await this.checkNecessaryParams();
    if (!isParamsValid) return;

    // --- wait for authorization
    let authorized = await this.commonService.waitForAuthorization(
      this.authApi
    );
    if (!authorized) return;

    this.shouldBeVisible = true;

    this.handleGoogleSignInRedirect();
  }

  ngOnInit(): void {
    this.init();
  }
}
