import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { CloudFnV2Error } from "../models/general.models";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class HttpInterceptorService implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        let isCloudFnV2Req = req.url.startsWith(
          environment.firebaseCallableFunctionUrl
        );
        if (!isCloudFnV2Req) return throwError(httpErrorResponse);

        // --- throw custom error in case of cloud function v2 error
        let cloudFnV2Error = new CloudFnV2Error(req, httpErrorResponse);
        return throwError(cloudFnV2Error);
      })
    );
  }
}
