import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-auth2-famodal",
  templateUrl: "./auth2-famodal.component.html",
  styleUrls: ["./auth2-famodal.component.scss"]
})
export class Auth2FAModalComponent implements OnInit {
  @Input() orgName: any;

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  yesItsMe() {
    this.activeModal.close(true);
  }

  noItsNotMe() {
    this.activeModal.close(false);
  }
}
