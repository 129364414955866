<div *ngIf="orgAndInd$ | async as orgAndInd else loading;" class="root-container">
    <!-- header -->
    <ng-container *ngIf="showHeader">
        <ng-container *ngTemplateOutlet="indDetailsHeader; context:{orgAndInd:orgAndInd, showSidebar:showSidebar}"></ng-container>
    </ng-container>
    
    <!-- Page content -->
    <div class="page-content-container">
        <div *ngIf="pageContent" class="page-content">
            <ng-container *ngTemplateOutlet="pageContent; context:{orgAndInd:orgAndInd, loading:loading}">
            </ng-container>
        </div>

        <!-- footer -->
        <ng-container *ngIf="showFooter">
            <ng-container *ngTemplateOutlet="footer"></ng-container>
        </ng-container>
    </div>
</div>

<!-- sidebar -->
<ng-template let-orgAndInd="orgAndInd" #sidebar>
    <input type="checkbox" class="openSidebarMenu" id="openSidebarMenu">

    <label for="openSidebarMenu" class="sidebarIconToggle">
        <div class="spinner diagonal part-1"></div>
        <div class="spinner horizontal"></div>
        <div class="spinner diagonal part-2"></div>
    </label>

    <div id="sidebarMenu">
        <ul class="sidebarMenuInner">
            <li class="mobile_none" *ngIf="orgAndInd && orgAndInd.individual && orgAndInd.individual.mobilePhone != ''">
                <a href="javascript:void(0);" style="text-transform: capitalize;"
                    (click)="sendDigitalIdConfirmation(orgAndInd.individual.mobilePhone);" class="no-select">
                    Send ID to my mobile phone
                </a>
            </li>
            
            <!-- Account Accordion -->
            <li style="padding: 0px">
                <ngb-accordion [closeOthers]="true">
                    <ngb-panel id="static-1" title="Account" cardClass="accordion-card">
                        <ng-template ngbPanelContent>
                            <p (click)="openAccountSettings()" class="no-select" style="color: #fff !important;">Account
                                Settings</p>
                            <p (click)="changePassword()" *ngIf="indData && !indData.isSubUserExist" class="no-select" style="color: #fff !important;">Change
                                Password</p>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </li>

            <li *ngIf="shouldOfferTakeSelfie && orgAndInd && orgAndInd.individual && diyCapturePolicyPredValue && currentCycle &&
            (orgAndInd.individual | getIndPhotoUsingPrefSettings : diyCapturePolicyPhotoUsedInIDMap[diyCapturePolicyPredValue] : currentCycle : null : null : null : null : false : true).photoStatus != 1"
                (click)="takeASelfie()" class="no-select">
                Take a selfie for my ID
            </li>
            <li (click)="installPWA('default')" *ngIf="!!commonService.shareData.deferredPWAPrompt"
                class="no-select">
                Save shortcut to High5.ID
            </li>

            <!-- Others -->
            <li style="padding: 0px">
                <ngb-accordion [closeOthers]="true">
                    <ngb-panel title="Settings" id="static-1" cardClass="accordion-card">
                        <ng-template ngbPanelContent>
                            <p (click)="clearIDCache()" class="no-select" style="color: #fff !important;">Refresh</p>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </li>

            <li><a href="https://high5.id/wp/" target="_blank" class="no-select">About High5.ID</a></li>
            
            <li (click)="logout()" class="no-select">Logout</li>
        </ul>
    </div>
</ng-template>

<!-- Ind details header -->
<ng-template let-orgAndInd="orgAndInd" let-showSidebar="showSidebar" #indDetailsHeader>
    <div style="height: 165px;background: #dcefff;">
        <nz-layout class="layout main-header" style="margin-bottom: 15px;">
            <div style="height: 65px;position: relative;">
                <!-- Left Content -->
                <div class="share-btn-div">
                    <img [src]="orgLogoUrl" style="height: 50px; max-width: 100px;object-fit: contain;">
                </div>

                <!-- Center Content -->
                <div class="logo">
                    <a href="https://high5.id/wp/" target="_blank" class="high5-logo">
                        <img src="assets/high5-golder-logo.png" />
                    </a>
                </div>

                <!-- Right Content (sidebar) -->
                <div class="sidebarToggleContainer" *ngIf="showSidebar">
                    <ng-container *ngTemplateOutlet="sidebar; context:{orgAndInd:orgAndInd}"></ng-container>
                </div>
            </div>
            <div class="div-colors-of-the-day">
                <div></div>
            </div>
            <div style="height: 84px;min-height: 84px;">
                <div class="row d-flex-center" style="position: relative;height: 84px;width: 100%;">
                    <div class="col-12 vertical_centr" style="width: fit-content;position: absolute;top: 50%;transform: translateY(-50%)">
                        <img [src]="indPhoto" style="height: 60px;width: 60px;border-radius: 50%;display: none; object-fit: cover;" onload="this.style.display = 'block'">
                        <div style="text-align: center;padding-left: 20px;">
                            <span style="color: #fff;font-weight: bold;font-size: 18px;">{{ orgAndInd.individual.firstName }}
                                {{ orgAndInd.individual.lastName }}</span><br/>
                            <span style="color: #fff;font-size: 14px;">{{orgAndInd.individual.role}}</span><br/>
                            <span style="color: #fff;font-size: 14px;">{{orgAndInd.organization.orgName}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </nz-layout>
    </div>
</ng-template>

<!-- Footer -->
<ng-template #footer>
    <div style="text-align: center;">
        <a href="https://high5.id/wp/" class="high5-stamp regular-font-with-family"
            style="text-decoration: none;color: rgba(0, 0, 0, 0.85);" target="_blank">Powered by High5.ID</a>
    </div>
    <div style="text-align: center;" class="high5-stamp regular-font-with-family">Version {{app.version}}</div>
</ng-template>

<!-- Loader -->
<ng-template #loading>
    <div class="align centered">
        <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
        <span>Loading...</span>
    </div>
</ng-template>