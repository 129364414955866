<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
      <button type="button" *ngIf="!hideCloseButton" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" [class]="btnCancelText == 'Not Now' ? '' : 'white-space-pre-wrap'" [innerHTML]="message"></div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="onBtnClicked(false)"
        *ngIf="btnCancelText">{{ btnCancelText }}</button>
      <button type="button" class="btn btn-primary" (click)="onBtnClicked(true)">{{ btnOkText }}</button>
     
    </div>