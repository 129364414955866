<div class="row PwaModal" (click)="installApp()">
    <div class="col-3 vertical_centrC" style="padding: 0px;">
        <img src="../../../../../assets/high5-logo-small-ios-pwa.png" alt="" class="high5Img">
    </div>
    <div class="col-7" style="padding: 0px;">
        <div class="modal-body vertical_centrC">
            <div>
                <strong style="font-size: 15px;">Tap here to save a shortcut to High5.ID</strong><br>
                <span style="font-size: 13px;">This will make it easier to return to High5 in the future.</span>
            </div>
        </div>
    </div>
    <div class="col-2" style="padding: 0px;">
        <button type="button" class="closeBtn" aria-label="Close" (click)="$event.stopPropagation();dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>