import { Injectable } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";
import { forkJoin } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class CommonFirebaseServices {
  constructor(private storage: AngularFireStorage) {}

  getDownloadURL(urls: string[]) {
    const urlsMap = {};
    urls.forEach(
      url => (urlsMap[url] = this.storage.ref(url).getDownloadURL())
    );
    return forkJoin(urlsMap);
  }
}
