import { Injectable } from "@angular/core";
import lodash from "lodash";
import moment from "moment";

@Injectable({
  providedIn: "root"
})
export class RendererService {
  constructor() {}

  // === START
  // === Helper functions copied from renderer to this code to make it work in cached/offline mode

  // --- find timestamp elements
  getTimestampElms() {
    const elementAuto = document.querySelector("#timestamp");
    if (!elementAuto) return null;
    const textAuto = elementAuto.querySelectorAll("text")[0];
    if (!textAuto) return null;
    const tspansAuto = textAuto.querySelectorAll("tspan");
    if (!tspansAuto) return null;

    return tspansAuto;
  }

  // --- parse custom supported date format and convert it to moment format
  parseCustomDateFormat(mm, dd, yy) {
    // --- convert custom format to moment supported format
    let converstionsMap = {
      month: {
        Month: "MMMM",
        Mon: "MMM",
        MM: "MM",
        M: "M",
        def: "MMM"
      },
      date: {
        def: "DD"
      },
      year: {
        Y: "YY",
        YY: "YYYY",
        def: "YYYY"
      }
    };

    let momentMnth = lodash.get(
      converstionsMap.month,
      mm,
      converstionsMap.month.def
    );
    let momentDate = lodash.get(
      converstionsMap.date,
      dd,
      converstionsMap.date.def
    );
    let momentYr = lodash.get(
      converstionsMap.year,
      yy,
      converstionsMap.year.def
    );

    return `${momentMnth} ${momentDate}, ${momentYr}`;
  }

  // --- parse custom supported time format and convert it to moment format
  parseCustomTimeFormat(hh, mm, ss) {
    // --- convert custom format to moment supported format
    let converstionsMap = {
      hour: {
        HH24: "HH[h]",
        H24: "H[h]",
        HH12: "hh[h]",
        H12: "h[h]",
        def: "HH[h]"
      },
      minute: {
        MM: "mm",
        M: "m",
        def: "m"
      },
      second: {
        SS: "ss",
        S: "s",
        def: "s"
      }
    };

    let momentHr = lodash.get(
      converstionsMap.hour,
      hh,
      converstionsMap.hour.def
    );
    let momentMnt = lodash.get(
      converstionsMap.minute,
      mm,
      converstionsMap.minute.def
    );
    let momentSec = lodash.get(
      converstionsMap.second,
      ss,
      converstionsMap.second.def
    );

    return `${momentHr}${momentMnt}:${momentSec} A`;
  }

  // --- get current timestamp in given format
  getCurrentTimestamp(dateTimeFormat: any) {
    let momentFormat;
    if (lodash.isObject(dateTimeFormat)) {
      let momentDateFormat;
      let momentTimeFormat;
      let dateForm;
      let timeForm;
      if (dateTimeFormat.DateFormat) {
        dateForm = dateTimeFormat.DateFormat.split(/,| /g);
        momentDateFormat = this.parseCustomDateFormat(
          dateForm[0],
          dateForm[1],
          dateForm[3]
        );
      }
      if (dateTimeFormat.TimeFormat) {
        timeForm = dateTimeFormat.TimeFormat.split(/h|:| /g);
        momentTimeFormat = this.parseCustomTimeFormat(
          timeForm[0],
          timeForm[1],
          timeForm[2]
        );
      }

      switch (dateTimeFormat.Display) {
        case "DateTime":
          momentFormat = momentDateFormat + " " + momentTimeFormat;
          break;
        case "DateNLTime":
          momentFormat = momentDateFormat + " " + momentTimeFormat;
          break;
        case "Date":
          momentFormat = momentDateFormat;
          break;
        case "Time":
          momentFormat = momentTimeFormat;
          break;
        default:
          momentFormat = "MMM DD, YYYY";
          break;
      }
    } else {
      momentFormat = dateTimeFormat;
    }

    try {
      return moment().format(momentFormat);
    } catch (e) {
      console.log("Error while formatting timestamp", dateTimeFormat, e);
      return "Unsupported Display Format";
    }
  }

  // --- start refreshing/ticking timestamp
  timestampRefreshInterval;
  startTimestampRefresh(dateTimeFormat: any) {
    if (this.timestampRefreshInterval)
      clearInterval(this.timestampRefreshInterval);
      
    this.timestampRefreshInterval = setInterval(() => {
      let timestampElements = this.getTimestampElms(); // --- get timestamp elements
      let dateTimeToDisplay = this.getCurrentTimestamp(dateTimeFormat); // --- get current timestamps

      lodash.each(timestampElements, elem => {
        elem.innerHTML = dateTimeToDisplay.substring(
          0,
          elem.innerHTML.replace(/&nbsp;/g, " ").length
        );
        dateTimeToDisplay = dateTimeToDisplay.slice(
          elem.innerHTML.replace(/&nbsp;/g, " ").length + 1
        );
      });
    }, 1000);
  }

  // --- stop refreshing timestamp
  stopTimestampRefresh() {
    if (this.timestampRefreshInterval)
      clearInterval(this.timestampRefreshInterval);
  }

  // === Helper functions copied from renderer to this code to make it work in cached/offline mode
  // === END
}
