export const GetIndFields = {
  FirstName: ["firstName", "key"],
  nameOnly: ["firstName", "lastName", "key"],
  IdValid_mode: [
  "idValid_mode",
  "idValid_startDateTime",
  "idValid_endDateTime",
  "idValid_validAfterDateTime",
  "idValid_invalidAfterDateTime", "key"],
  IndAuthPageFields: [
    "firstName",
    "lastName",
    "suffix",
    "idValid_mode",
    "idValid_startDateTime",
    "idValid_endDateTime",
    "idValid_validAfterDateTime",
    "idValid_invalidAfterDateTime",
    "email",
    "isSubUserExist",
    "optin",
    "mobilePhone",
    "forgotPassTimestamp",
    "genNewPW",
    "key"
  ],
  PhotoFirstPageFields: ["firstName", "lastName", "suffix", "photo", "key"],
  SelfRegistrationHome: [
    "key",
    "firstName",
    "lastName",
    "genNewPW",
    "mobilePhone",
    "idValid_mode",
    "idValid_startDateTime",
    "idValid_endDateTime",
    "idValid_validAfterDateTime",
    "idValid_invalidAfterDateTime",
    "email",
    "optin",
    "key"
  ], //"photos", "photoUrl"
  All: ["all"],
  ForgotPassDialog: [
    "key",
    "email",
    "mobilePhone",
    "guardianPhoneData.all",
    "guardianEmailData.all"
  ],
  PP: ["key", "firstName", "lastName"],
  DID_GRDN_PROPS: [
    "key",
    "firstName",
    "lastName",
    "email",
    "mobilePhone",
    "employer"
  ],
  TVTHome: [
    "firstName",
    "lastName",
    "genNewPW",
    "key",
    "forgotPassTimestamp",
    "email"
  ],
  TVTPhotoPage: [
    "firstName",
    "lastName",
    "genNewPW",
    "role",
    "photos.all",
    "flag",
    "key"
  ],
  TVTStudentTracking: [
    "firstName",
    "lastName",
    "role",
    "idValid_mode",
    "idValid_startDateTime",
    "idValid_endDateTime",
    "idValid_validAfterDateTime",
    "idValid_invalidAfterDateTime",
    "flag",
    "disabled",
    "key"
  ],
  TVTVisitorBadge: [
    "role",
    "genNewPW",
    "disabled",
    "idValid_mode",
    "idValid_startDateTime",
    "idValid_endDateTime",
    "idValid_validAfterDateTime",
    "idValid_invalidAfterDateTime",
    "firstName",
    "lastName",
    "key"
  ],
  GuardianProps: [
    "key",
    "firstName",
    "middleName",
    "suffix",
    "lastName",
    "role",
    "mobilePhone",
    "email"
  ],
  GuardianOnBoardingPage: [
    "firstName",
    "lastName",
    "genNewPW",
    "role",
    "photos.all",
    "flag",
    "key",
    "feesSeason",
    "feesRegistration",
    "statusAtOrg"
  ],
  PassAssignerProps: ["firstName", "lastName"]
};
