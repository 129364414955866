<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" [class]="btnCancelText == 'Not Now' ? '' : 'white-space-pre-wrap'">{{message}}</div>
    <div class="modal-footer">
      <button *ngIf="btnCancelText != 'Not Now'" type="button" class="btn btn-danger" (click)="decline()">{{ btnCancelText }}</button>
      <button *ngIf="btnCancelText != 'Not Now'" type="button" class="btn btn-primary" (click)="btnOkText == 'Copy Link' ? copyToClipboard(copyUrl) : accept()">{{ btnOkText }}</button>
      
      <button *ngIf="btnCancelText == 'Not Now'" type="button" class="btn btn-primary fullWidth" (click)="accept()">{{ btnOkText }}</button>
      <button *ngIf="btnCancelText == 'Not Now'" type="button" class="btn btn-danger fullWidth" (click)="decline()">{{ btnCancelText }}</button>
      <button *ngIf="btnCancelText == 'Send Now'" type="button" class="btn btn-danger fullWidth" (click)="accept()">{{ btnOkText }}</button>
     
    </div>