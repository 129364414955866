import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { EncryptionService } from "../encryption/encryption.service";
@Injectable({
  providedIn: "root"
})
export class UserApiService {
  constructor(
    private httpClient: HttpClient,
    private encryptionService: EncryptionService,
  ) {}

  /**
   * update email/password of user in authentication section
   * @param uid firebase uid
   * @param newEmail new email which needs to be set
   * @param newPassword new password which needs to be set
   * @returns response object returned from API
   */
  async updateEmailPassword(uid, newEmail?, newPassword?) {
    if (!uid || (!newEmail && !newPassword)) throw new Error("params missing!");

    return await this.httpClient
      .post(`${environment.firebaseFunctionsBaseUrl}/updateEmailPassword`, {
        uid,
        newEmail,
        newPassword
      })
      .toPromise();
  }

  /**
   * delete user from firebase authentication section
   * @param uid firebase uid of the user
   * @returns promise of response object from API
   */
  async removeUser(uid) {
    if (!uid) throw new Error("uid missing in params!");
    let encryptedBody = this.encryptionService.encrypt(
      { uid },
      environment.edk
    );
    return await this.httpClient
      .post(`${environment.firebaseFunctionsBaseUrl}/removeUser`, encryptedBody)
      .toPromise();
  }
}
