import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";

@Injectable({
  providedIn: "root"
})
export class LeagueDivisionsService {
  private basePath = `league`;
  private divisionsPath = `divisions/`;

  constructor(public http: HttpClient, public db: AngularFireDatabase) {}

  /**
   * Read division details by its id (key)
   * @param leagueId League Id
   * @param divId Division Key
   * @returns Promise of Division Object
   */
  async getDivision(leagueID: string, divID: String) {
    if (!leagueID) throw Error("League Id Required!");
    if (!divID) throw Error("Division Id Required!");

    let dbPath = `${this.basePath}/${leagueID}/${this.divisionsPath}/${divID}`;
    let snapshot = await this.db.object(dbPath).query.once("value");

    return snapshot.val();
  }
}
