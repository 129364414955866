import { HttpClient } from "@angular/common/http";
import { Component, OnInit, Input } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import UserAuth from "src/app/core/auth/userAuth.class";
import { UrlService } from "src/app/core/digital-id/url.service";
import { IndividualApiService } from "src/app/core/individual/individual.api.service";
import { MessagesService } from "src/app/core/messages/messages.service";
import { OrganizationsService } from "src/app/core/organizations/organizations.service";
import { getQueryParams } from "src/app/core/utils/queryParams.utils";
import { ViewAnonPipe } from "src/app/core/pipes/viewAnon/view-anon.pipe";
import { GetIndFields } from "src/app/core/utils/inds.utils";
import { MsgMedium, SendMsgToReceiver } from "src/app/core/enums/general.enums";
import { CommonServiceService } from "src/app/core/common/common-service.service";

@Component({
  selector: "app-forgot-password-dialog",
  templateUrl: "./forgot-password-dialog.component.html",
  styleUrls: ["./forgot-password-dialog.component.scss"]
})
export class ForgotPasswordDialogComponent {
  @Input() title: string;
  userAuth: UserAuth;
  indData: any;
  isShow: boolean = false;

  readonly MsgMedium = MsgMedium;
  readonly SendMsgToReceiver = SendMsgToReceiver;

  constructor(
    private activeModal: NgbActiveModal,
    public db: AngularFireDatabase,
    public urlService: UrlService,
    public http: HttpClient,
    public individualApi: IndividualApiService,
    public orgService: OrganizationsService,
    public messagesService: MessagesService,
    public viewAnon: ViewAnonPipe,
    private commonService: CommonServiceService
  ) {
    this.userAuth = getQueryParams();
    this.init();
  }

  async init() {
    // --- read individual data
    let [indData, readIndDataErr] = await this.commonService.executePromise(
      this.individualApi.getIndData(
        this.userAuth.orgID,
        this.userAuth.indID,
        GetIndFields.ForgotPassDialog,
        false,
        true
      )
    );
    if(readIndDataErr) {
      await this.commonService.handleError(readIndDataErr);
      this.dismiss()
      return;
    }

    this.indData = indData;

    this.isShow = true;
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

  forgotPassword(medium, receiver, targetAddress, receiverKey?) {
    this.activeModal.close({ medium, receiver, targetAddress, receiverKey });
  }
}
