import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { QrCodeService } from 'src/app/core/qr-code.service';

declare var JsBarcode: any;

@Component({
  selector: 'app-show-qr-code',
  templateUrl: './show-qr-code.component.html',
  styleUrls: ['./show-qr-code.component.scss']
})
export class ShowQrCodeComponent implements OnInit {

  @Input() indhash: any;
  @Input() studentID: any;
  qrCode: string = '';
  barcode: string = '';
  constructor(public activeModal: NgbActiveModal, private qrCodeService: QrCodeService) { }

  ngOnInit(): void {
    if(this.indhash) {
      let qr = this.qrCodeService.toQRCode(this.indhash, 500)
      this.qrCode = this.qrCodeService.toBase64(qr)
    }
    
    if(this.studentID) {
      this.barcode = this.textToBase64Barcode(this.studentID);
    }
  }

  onBack() {
    this.activeModal.dismiss();
  }

  textToBase64Barcode(text){
    var canvas = document.createElement("canvas");
    JsBarcode(canvas, text, {format: "CODE39"});
    return canvas.toDataURL("image/png");
  }
}
