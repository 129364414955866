import { Pipe, PipeTransform } from "@angular/core";
import { environment } from "../../../../../src/environments/environment";
import lodash from "lodash";
import { CommonServiceService } from "../../common/common-service.service";
import { PhotoService } from "../../photo.service";
import { CacheKeys, CacheService } from "../../cache/cache.service";
import { MediaService } from "../../media/media.service";
import { Role } from "../../enums/user.enums";
import { PreferencesService } from "../../preferences/preferences.service";
import { PhotoUsedInID } from "../../enums/general.enums";

@Pipe({
  name: "getIndPhotoUsingPrefSettings"
})
export class GetIndPhotoUsingPrefSettings implements PipeTransform {
  constructor(
    private commonService: CommonServiceService,
    private photoService: PhotoService,
    private cacheService: CacheService,
    private mediaService: MediaService,
    private preferencesService: PreferencesService
  ) {}

  convertObjToArr(obj) {
    let returnArr = [];
    Object.keys(obj).forEach(objKey => {
      let value = obj[objKey];
      if (value && lodash.isObject(value)) {
        value["key"] = objKey;
        returnArr.push(value);
      }
    });
    return returnArr;
  }

  transform(
    ind: any,
    prefValue: any,
    currentCycle: any,
    isFrom?,
    orgID?,
    studioID?,
    photoDefBGPref?,
    includeWithBgImg = false,
    useCache = false,
    outputFormat:
      | "base64"
      | "objectURL"
      | "sanitizedObjectURL"
      | "url" = "base64"
  ): any /* PhotoResult | Promise<PhotoResult> */ {
    let photoResult: PhotoResult = {};

    let photos: any[] = [];
    if (prefValue == PhotoUsedInID.CURRENT_YEAR_ONLY && !isFrom) {
      photos = ind.photos ? this.convertObjToArr(ind.photos) : [];
      photos = photos.sort((a, b) => {
          return b.key - a.key;
        });
      let currentYearIndex = photos.findIndex((photo) => {
        return photo.photoUrl && photo.key == currentCycle;
      });
      if (currentYearIndex > -1) {
        photoResult = photos[currentYearIndex];
        photoResult.showYearLable = false;
        photoResult.isPhotoPresent =
          photoResult &&
          photoResult.photoUrl &&
          photoResult.photoUrl.indexOf("image-not-found") > -1
            ? false
            : true;
        photoResult.photoYear = currentCycle;
        photoResult.photoStatus = !photoResult.isPhotoPresent
          ? ""
          : photos[currentYearIndex] &&
            photos[currentYearIndex].hasOwnProperty("photoStatus")
          ? photos[currentYearIndex].photoStatus
          : "";
      } else {
        photoResult.photoYear = currentCycle;
        photoResult.photoUrl =
          ind.photoUrl && ind.photoUrl != environment.placeholderImageUrl
            ? ind.photoUrl
            : environment.placeholderImageUrl;
        photoResult.photoStatus = this.getPhotoStatus(photoResult, ind);
        photoResult.showYearLable = false;
        photoResult.isPhotoPresent =
          photoResult.photoUrl.indexOf("image-not-found") > -1 ? false : true;
        photoResult.isRootLevelUrl =
          photoResult.isPhotoPresent && photoResult.photoUrl == ind.photoUrl
            ? true
            : false;
      }
    } else {
      photos = ind.photos ? this.convertObjToArr(ind.photos) : [];
      photos = photos
      .filter((photo) => {
        return photo.photoUrl;
      }).sort((a, b) => {
        return b.key - a.key;
      });
      if (photos.length > 0) {
        if (photos[0].photoUrl) {
          photoResult = photos[0];
          photoResult.showYearLable =
            photos[0].key == currentCycle ||
            photoResult.photoUrl == environment.placeholderImageUrl
              ? false
              : true;
          photoResult.isPhotoPresent =
            photoResult.photoUrl.indexOf("image-not-found") > -1 ? false : true;
          photoResult.photoYear = photos[0].key;
          photoResult.photoStatus = !photoResult.isPhotoPresent
            ? ""
            : photos[0].hasOwnProperty("photoStatus")
            ? photos[0].photoStatus
            : "";
        } else {
          photoResult.photoYear = currentCycle;
          photoResult.photoUrl = environment.placeholderImageUrl;
          photoResult.photoStatus = "";
          photoResult.showYearLable = false;
          photoResult.isPhotoPresent = false;
        }
      } else if (
        ind.photoUrl &&
        ind.photoUrl != environment.placeholderImageUrl
      ) {
        photoResult.photoYear = currentCycle;
        photoResult.photoUrl = ind.photoUrl;
        photoResult.photoStatus = this.getPhotoStatus(photoResult, ind);
        photoResult.showYearLable = false;
        photoResult.isPhotoPresent =
          photoResult.photoUrl.indexOf("image-not-found") > -1 ? false : true;
        photoResult.isRootLevelUrl = true;
      } else {
        photoResult.photoYear = currentCycle;
        photoResult.photoUrl = environment.placeholderImageUrl;
        photoResult.photoStatus = "";
        photoResult.showYearLable = false;
        photoResult.isPhotoPresent = false;
        photoResult.isRootLevelUrl =
          photoResult.photoUrl == ind.photoUrl ? true : false;
      }
    }

    return this.getFinalResult({
      ...photoResult,
      orgID,
      studioID,
      photoDefBGPref,
      includeWithBgImg,
      useCache,
      outputFormat
    });
  }

  // --- helper method to get photo status
  getPhotoStatus(photoResult: PhotoResult, ind) {
    return photoResult.photoUrl == environment.placeholderImageUrl
      ? ""
      : !ind.photos
      ? ind.photoStatus == 0 || ind.photoStatus == 1
        ? ind.photoStatus
        : ""
      : lodash.get(ind, `photos[${photoResult.photoYear}].photoStatus`, "");
  }

  /**
   * prepare final return result
   * @param data object containing PhotoResult properties and information about photo background requirements
   * @returns PhotoResult object (if image with bg not required) or promise of it ((if image with bg required))
   */
  getFinalResult(data: any): PhotoResult | Promise<PhotoResult> {
    let photoResult: PhotoResult = {};
    if (!data) return photoResult;

    let propsToCopy = [
      "showYearLable",
      "isPhotoPresent",
      "photoYear",
      "photoStatus",
      "photoUrl",
      "finalImgSrc",
      "photoFromCsv",
      "isRootLevelUrl"
    ];

    // --- copy props from input data
    lodash.each(propsToCopy, prop => {
      let value = lodash.get(data, prop);
      if (!lodash.isNil(value)) lodash.set(photoResult, prop, value);
    });

    // --- copy photoUrl to finalImgSrc
    if (photoResult.photoUrl && !photoResult.finalImgSrc)
      photoResult.finalImgSrc = photoResult.photoUrl;

    // --- if NO need to attach background with image, return results
    if (!data.includeWithBgImg) return photoResult;

    // --- if need to attach background with image, do it and return promise of it
    if (data.includeWithBgImg) {
      return new Promise(async resolve => {
        const convertImg = async () => {
          let [imgConverted, err] = await this.commonService.executePromise(
            this.photoService.convertImg(
              photoResult.finalImgSrc,
              "url",
              data.outputFormat
            )
          );
          if (imgConverted) photoResult.finalImgSrc = imgConverted;
        };

        // --- prepare source image url
        let srcImageURL = photoResult.photoUrl;
        if (
          srcImageURL == environment.placeholderImageUrl ||
          !this.photoService.doesPhotoContainsTransparentPixels(srcImageURL)
        ) {
          await convertImg();
          return resolve(photoResult);
        }

        // --- prepare bg image url
        if (!data.orgID) {
          await convertImg();
          return resolve(photoResult);
        }
        let bgImgURL = data.photoDefBGPref;
        if (!bgImgURL) {
          let mediaID = await this.preferencesService.getPreferenceByInheritance(
            Role.ORG,
            data.orgID,
            "PhotoDefBG",
            null,
            data.useCache,
            "value"
          );
          if (mediaID == "none") bgImgURL = mediaID;
          else if (mediaID) {
            let mediaData = await this.mediaService.getMediaByInheritance(
              Role.ORG,
              data.orgID,
              mediaID,
              null,
              data.useCache
            );
            bgImgURL = lodash.get(mediaData, "source.url");
          }
        }

        // --- if background preference is none or invalid URL, return results
        if (bgImgURL == "none" || !this.commonService.isURLValid(bgImgURL)) {
          await convertImg();
          return resolve(photoResult);
        }

        // --- fetch from cache
        if (
          data.useCache &&
          (data.outputFormat == "objectURL" ||
            data.outputFormat == "sanitizedObjectURL" ||
            data.outputFormat == "url")
        ) {
          let cachedData = this.cacheService.get(
            CacheKeys.ImgWithBGData,
            `${data.outputFormat}.${data.orgID}.${srcImageURL}.${bgImgURL}`
          );
          if (cachedData) {
            photoResult.finalImgSrc = cachedData.value;
            return resolve(photoResult);
          }
        }

        // --- create image with background attached
        let [imgWithBG, err] = await this.commonService.executePromise(
          this.photoService.attachBG(
            srcImageURL,
            bgImgURL,
            data.outputFormat == "url" ? "base64" : data.outputFormat,
            data.useCache
          )
        );
        if (err) {
          return resolve(photoResult);
        }
        if (imgWithBG) {
          if (data.outputFormat == "url") {
            // --- upload backgrounded image to s3
            let signUrlReqObj = {
              extensions: [],
              path: ""
            };
            signUrlReqObj.extensions.push("jpeg");
            signUrlReqObj.path = "photos/photosWithBG/" + data.orgID + "/";
            // convert base64 into file
            let file = this.commonService.dataURLtoFile(
              imgWithBG,
              `${this.commonService.generateId(14)}.jpeg`
            );
            let fileName = await this.commonService.imageUpload(
              signUrlReqObj,
              file
            );

            // --- set url as image with BG value
            imgWithBG =
              environment.awsImageUpload.bucketUrl +
              "/" +
              signUrlReqObj.path +
              fileName;
          }

          // --- set cache
          if (
            data.outputFormat == "objectURL" ||
            data.outputFormat == "sanitizedObjectURL" ||
            data.outputFormat == "url"
          ) {
            this.cacheService.set(
              CacheKeys.ImgWithBGData,
              `${data.outputFormat}.${data.orgID}.${srcImageURL}.${bgImgURL}`,
              imgWithBG
            );
          }

          photoResult.finalImgSrc = imgWithBG;
        }
        return resolve(photoResult);
      });
    }
  }
}

export class PhotoResult {
  showYearLable?: boolean;
  isPhotoPresent?: boolean;
  photoYear?: string;
  photoStatus?: string;
  photoUrl?: string;
  finalImgSrc?: string;
  photoFromCsv?: string;
  isRootLevelUrl?: boolean;
}
