import { Injectable } from "@angular/core";
import { IndStatusAtOrg, OrgType } from "../enums/general.enums";

@Injectable({
  providedIn: "root"
})
export class IndStatusAtOrgRulesService {
  constructor() {}

  // --- get default value for "statusAtOrg"
  static getDefaultValueForStatusAtOrg(orgType: OrgType) {
    // sportsteam: applicant
    // others (eg. school): current
    if (orgType == OrgType.SPORTSTEAM) return IndStatusAtOrg.APPLICANT;
    return IndStatusAtOrg.CURRENT;
  }

  getDefaultValueForStatusAtOrg(orgType: OrgType) {
    return IndStatusAtOrgRulesService.getDefaultValueForStatusAtOrg(orgType);
  }
}
