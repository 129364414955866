import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-pwa-popup",
  templateUrl: "./pwa-popup.component.html",
  styleUrls: ["./pwa-popup.component.scss"]
})
export class PwaPopupComponent implements OnInit {
  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
  public dismiss() {
    this.activeModal.close(false);
  }

  installApp() {
    this.activeModal.close(true);
  }
}
