<div class="container">
  <!-- Cached digital ID container -->
  <div #canvasTarget (click)="showQRCode()" id="canvasTarget"></div>

  <!-- Display Message -->
  <div *ngIf="displayMsg && displayMsg.msg" class="displayMsg"
    [ngClass]="{ error: displayMsg?.type == 'error', info: displayMsg?.type == 'info' }">
    <p>{{ displayMsg?.msg }}</p>
  </div>

  <!-- Loader -->
  <div class="align centered" *ngIf="isLoading">
    <nz-spin nzSimple nzSize="large"></nz-spin>
    <span>Loading...</span>
  </div>
</div>
