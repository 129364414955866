export const OrgProps = {
  // all allowed props to read for signed in user
  All: [
    "key",
    "orgName",
    "type",
    "cycle",
    "consumablePaidBy",
    "settings.visuals.all",
    "settings.org-preference.all",
    "designs.all",
    "certificationStamp",
    "certificationSignature",
    "COD1",
    "COD2",
    "taxExempt",
    "orgSmsShortCode",
    "shippingAddress",
    "shippingAddress2",
    "shippingCity",
    "shippingState",
    "shippingCountry",
    "shippingPostCode",
    "billingAddress",
    "billingAddress2",
    "billingCity",
    "billingState",
    "billingCountry",
    "billingPostCode",
    "studioID",
    "teammateClientID",
    "type",
    "primaryColor",
    "secondaryColor",
    "leagueID",
    "conferenceID",
    "regionID",
    "orgLat",
    "orgLong",
    "onCampusRadius",
    "parentList",
    "isMarkAsDelete",
    "markAsDeleteTimestamp"
  ],
  DemoPage: ["key", "orgName", "type", "settings.visuals.all", "studioID"],
  PhotoPage: ["key", "orgName", "settings.visuals.all", "studioID"],
  // --- props for personal page
  PP: ["key", "orgName"],
  // --- props for digital ID
  DID: [
    "key",
    "orgName",
    "settings.visuals.all",
    "certificationStamp",
    "certificationSignature",
    "COD1",
    "COD2",
    "shippingAddress",
    "shippingAddress2",
    "shippingCity",
    "shippingState",
    "shippingCountry",
    "shippingPostCode",
    "billingAddress",
    "billingAddress2",
    "billingCity",
    "billingState",
    "billingCountry",
    "billingPostCode",
    "studioID",
    "teammateClientID",
    "type",
    "primaryColor",
    "secondaryColor",
    "leagueID",
    "conferenceID",
    "regionID"
  ],
  TVTHomePage: ["orgName", "settings.visuals.logo", "studioID"],
  TVTVisitorBadge: ["orgName", "studioID"]
};
