import { forwardRef, Inject, Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { CacheKeys, CacheService } from "./cache/cache.service";
import { CommonServiceService } from "./common/common-service.service";
import lodash from "lodash";

@Injectable({
  providedIn: "root"
})
export class PhotoService {
  constructor(
    @Inject(forwardRef(() => CommonServiceService)) public commonService,
    private sanitizer: DomSanitizer,
    private cacheService: CacheService
  ) {}

  /**
   * load image into image element and get it back
   * @param imgSrc image source
   * @returns Promise of HTML Image Element
   */
  async loadImage(imgSrc: any): Promise<HTMLImageElement> {
    if (imgSrc instanceof HTMLImageElement) return imgSrc;

    // --- create image element
    let imgElem = new Image();
    imgElem.setAttribute("crossorigin", "anonymous");

    // --- create promise function of src image load
    let onImgLoad = new Promise((resolve, reject) => {
      imgElem.addEventListener("load", resolve);
      imgElem.addEventListener("error", reject);
    });

    // --- set source of image
    imgElem.src = imgSrc;

    // --- set listener for image load completion
    await onImgLoad;

    return imgElem;
  }

  /**
   * convert canvas to other format
   * @param canvas canvas element
   * @param toFormat format in which you want to convert canvas
   * @param mimeType mime type in which image is (eg. image/jpeg)
   * @returns Promise of converted format result
   */
  private async convertCanvas(
    canvas: HTMLCanvasElement,
    toFormat: "base64" | "objectURL" | "sanitizedObjectURL" = "base64",
    mimeType: MimeType
  ): Promise<any> {
    let result;
    if (toFormat == "objectURL" || toFormat == "sanitizedObjectURL") {
      let blob: any = await this.commonService.promisify(
        canvas.toBlob,
        0,
        canvas
      )(mimeType);
      result = URL.createObjectURL(blob);
      if (toFormat == "sanitizedObjectURL")
        result = this.sanitizer.bypassSecurityTrustUrl(result);
    } else if (toFormat == "base64") {
      result = canvas.toDataURL(mimeType, 1);
    }
    return result;
  }

  // --- convert given image to target format
  async convertImg(
    img: any,
    sourceFormat: "base64" | "objectURL" | "sanitizedObjectURL" | "url",
    targetFormat: "base64" | "objectURL" | "sanitizedObjectURL",
    mimeType?: MimeType
  ) {
    if (!img || sourceFormat == targetFormat) return img;

    // --- handle direct possible conversations
    if (sourceFormat == "objectURL" && targetFormat == "sanitizedObjectURL")
      return this.sanitizer.bypassSecurityTrustUrl(img);

    // --- load image
    let imgElem = await this.loadImage(img);

    // --- render image on canvas
    let canvas = document.createElement("canvas");
    let canvasCtx = canvas.getContext("2d");

    // --- set canvas width & height same as source image
    canvas.width = imgElem.width;
    canvas.height = imgElem.height;

    // --- draw source image on canvas
    canvasCtx.drawImage(imgElem, 0, 0);

    return await this.convertCanvas(canvas, targetFormat, mimeType);
  }

  /**
   * check if photo contains transparent pixels or not
   * @param imgURL image URL
   * @returns true if image contains transparent pixels
   */
  doesPhotoContainsTransparentPixels = imgURL => {
    if (
      !imgURL ||
      !this.commonService.isURLValid(imgURL) ||
      imgURL.match(/\.(jpg|jpeg|webp|svg|avif|gif|apng)$/gi) != null
    )
      return false;

    return true;
  };

  /**
   * Attach background with source image
   * @param srcImgURL source image URL
   * @param bgImgURL background image URL
   * @returns local URL of background
   */
  async attachBG(
    srcImgURL,
    bgImgURL,
    outputFormat: "base64" | "objectURL" | "sanitizedObjectURL" = "base64",
    useCache = false
  ) {
    // --- use cache
    let srcImgB64, bgImgB64;
    if (useCache) {
      let srcCachedData = this.cacheService.get(
        CacheKeys.URLData,
        `${srcImgURL}`
      );
      if (srcCachedData) srcImgB64 = srcCachedData.value;

      let bgCachedData = this.cacheService.get(
        CacheKeys.URLData,
        `${bgImgURL}`
      );
      if (bgCachedData) bgImgB64 = bgCachedData.value;
    }

    // --- load images
    let loadImagesPromises = [];
    loadImagesPromises.push(this.loadImage(srcImgB64 || srcImgURL));
    loadImagesPromises.push(this.loadImage(bgImgB64 || bgImgURL));

    let [loadImagesPromisesRes, err] = await this.commonService.executePromise(
      Promise.all(loadImagesPromises)
    );
    if (err) {
      console.log("Error while loading image for attaching bg", err);
      throw err;
    }

    let srcImgElem: HTMLImageElement = lodash.nth(loadImagesPromisesRes, 0);
    let bgImgElem: HTMLImageElement = lodash.nth(loadImagesPromisesRes, 1);

    // --- set cache
    if (!srcImgB64 || !bgImgB64) {
      let cachingCanvas = document.createElement("canvas");
      let cachingCanvasCtx = cachingCanvas.getContext("2d");

      if (!bgImgB64) {
        cachingCanvasCtx.clearRect(
          0,
          0,
          cachingCanvas.width,
          cachingCanvas.height
        );
        cachingCanvas.width = bgImgElem.width;
        cachingCanvas.height = bgImgElem.height;
        cachingCanvasCtx.drawImage(bgImgElem, 0, 0);
        let bgB64 = cachingCanvas.toDataURL();
        this.cacheService.set(CacheKeys.URLData, `${bgImgURL}`, bgB64);
      }

      if (!srcImgB64) {
        cachingCanvasCtx.clearRect(
          0,
          0,
          cachingCanvas.width,
          cachingCanvas.height
        );
        cachingCanvas.width = srcImgElem.width;
        cachingCanvas.height = srcImgElem.height;
        cachingCanvasCtx.drawImage(srcImgElem, 0, 0);
        let srcB64 = cachingCanvas.toDataURL();
        this.cacheService.set(CacheKeys.URLData, `${srcImgURL}`, srcB64);
      }
    }

    // --- create canvas for drawing bg and src over it
    let resultCanvas = document.createElement("canvas");
    let resultCanvasCtx = resultCanvas.getContext("2d");

    // --- set canvas width & height same as source image
    resultCanvas.width = srcImgElem.width;
    resultCanvas.height = srcImgElem.height;

    // --- draw bg layer (of size of source image) on canvas
    resultCanvasCtx.drawImage(
      bgImgElem,
      0,
      0,
      bgImgElem.width,
      bgImgElem.height,
      0,
      0,
      srcImgElem.width,
      srcImgElem.height
    );
    // --- draw src layer on canvas
    resultCanvasCtx.drawImage(srcImgElem, 0, 0);

    // --- prepare result
    let [result, convertCanvasErr] = await this.commonService.executePromise(
      this.convertCanvas(resultCanvas, outputFormat, MimeType.JPEG)
    );
    if (convertCanvasErr) {
      console.log("Error while preparing attach bg result", convertCanvasErr);
      throw convertCanvasErr;
    }

    // --- return result
    return result;
  }

  /**
   * resize image
   * @param imgSrc image source (URL/base64)
   * @param targetW target width
   * @param targetH target height
   * @param outputFormat output format in which you want result back
   * @param mimeType source image mime type
   */
  async resizeImage(
    imgSrc: string | HTMLImageElement,
    targetW: number,
    targetH: number,
    outputFormat: "base64" | "objectURL" | "sanitizedObjectURL" = "base64",
    mimeType: MimeType
  ) {
    if (!imgSrc || !targetW || !targetH || !outputFormat)
      throw new Error("Params missing while resizing image");

    // --- load Image in image element
    let [imgElem, err] = await this.commonService.executePromise(
      this.loadImage(imgSrc)
    );
    if (err) {
      console.log("Error while loading image for resizing", err);
      throw err;
    }

    // --- create canvas for drawing image
    let resultCanvas = document.createElement("canvas");
    let resultCanvasCtx = resultCanvas.getContext("2d");

    // --- set canvas width & height as target width/height
    resultCanvas.width = targetW;
    resultCanvas.height = targetH;

    // --- draw src image with new size on canvas
    resultCanvasCtx.drawImage(
      imgElem,
      0,
      0,
      imgElem.width,
      imgElem.height,
      0,
      0,
      targetW,
      targetH
    );

    // --- prepare result
    let [result, convertCanvasErr] = await this.commonService.executePromise(
      this.convertCanvas(resultCanvas, outputFormat, mimeType)
    );
    if (convertCanvasErr) {
      console.log("Error while preparing resize result", convertCanvasErr);
      throw convertCanvasErr;
    }

    // --- return result
    return result;
  }

  /**
   * calculate width/height capped at max cap
   * @param srcW source width
   * @param srcH source height
   * @param maxCap maximim cap to apply
   * @returns width and height capped at maximum cap (maintaining aspect ratio)
   */
  calcualteWHWithMaxCap(srcW: number, srcH: number, maxCap: number) {
    if (!srcW || !srcH || !maxCap) {
      throw new Error(
        "Params missing while calculating width height with max cap"
      );
    }

    let isLandscape = srcW > srcH ? true : false;
    let srcAR = srcW / srcH;

    if (isLandscape && srcW > maxCap) {
      srcW = maxCap;
      srcH = srcW / srcAR;
    } else if (!isLandscape && srcH > maxCap) {
      srcH = maxCap;
      srcW = srcH * srcAR;
    }

    return { width: srcW, height: srcH };
  }

  /**
   * resize image with maximum cap
   * @param imgSrc image source
   * @param maxCap maximum cap(limit)
   * @param outputFormat output format
   * @param mimeType source image mime type
   * @returns resized image in outputFormat
   */
  async resizeImageWithMaxCap(
    imgSrc: any,
    maxCap: number,
    outputFormat: "base64" | "objectURL" | "sanitizedObjectURL" = "base64",
    mimeType: MimeType
  ) {
    if (!imgSrc || !maxCap)
      throw new Error("Params missing while resizing image");

    let [imgElem, err] = await this.commonService.executePromise(
      this.loadImage(imgSrc)
    );
    if (err) {
      console.log(
        "Error while loading image for resizing with max cap",
        err,
        imgSrc
      );
      throw err;
    }

    let imageW = imgElem.width;
    let imageH = imgElem.height;

    let _WHCapped = this.calcualteWHWithMaxCap(imageW, imageH, maxCap);
    let targetW = _WHCapped.width;
    let targetH = _WHCapped.height;

    // --- if resized w/h is same as source w/h, and output format is same as input image src format,
    // return image source
    if (
      imageW == targetW &&
      imageH == targetH &&
      outputFormat == "base64" &&
      this.commonService.isBase64Img(imgSrc)
    ) {
      return imgSrc;
    }

    return await this.resizeImage(
      imgElem,
      targetW,
      targetH,
      outputFormat,
      mimeType
    );
  }
}

export enum MimeType {
  PNG = "image/png",
  JPEG = "image/jpeg"
}
