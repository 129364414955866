import { Injectable } from "@angular/core";
import { Role } from "./enums/user.enums";
import {
  InheritanceService,
  ReplacementTags
} from "./inheritance/inheritance.service";
import lodash from "lodash";

@Injectable({
  providedIn: "root"
})
export class PatchesService {
  readonly userDBNodes = {
    [Role.ORG]: "organizations",
    [Role.STUDIO]: "studios",
    [Role.SUPERADMIN]: "superadmin"
  };
  readonly dbPath = `patches/${ReplacementTags.USER_DB_NODE}/${ReplacementTags.USER_ID}/`;
  readonly superadminUserID = null;

  constructor(private inheritanceProvider: InheritanceService) {}

  // --- get patches list by inheritance
  async getPatches(
    role: string,
    userID: string,
    usersData?: any,
    useCache: boolean = false
  ) {
    // --- params verification
    if (!role || (role != Role.SUPERADMIN && !userID))
      throw new Error("Params missing in getPatches method call");

    // --- fetch preference data by inheritance
    let patchesInheritedData = await this.inheritanceProvider.getByInheritance(
      role,
      userID,
      `${this.dbPath}`,
      "list",
      usersData,
      this.userDBNodes,
      this.superadminUserID,
      useCache
    );

    // --- merge patches data
    return lodash
      .chain(patchesInheritedData)
      .reduce((ac, currentValue) => {
        if (!currentValue || !currentValue.data) return ac;

        lodash.each(currentValue.data, (patchData: any, key) => {
          if (!lodash.has(ac, key))
            lodash.set(ac, key, {
              ...patchData,
              key,
              ownerID: currentValue.ownerID
            });
        });

        return ac;
      }, {})
      .values()
      .sortBy("key")
      .value();
  }
}

export enum PatchID {
  YEARBOOK = "isYearBookPurchased",
  FEES_BALANCE = "feesBalance",
  PARKING_LOT = "parkingLot",
  LUNCH_PRIVILAGE = "isLunchPrivilege",
  ASB = "ASBMember",
  CAN_LEAVE_CAMPUS = "canLeaveCampus"
}

export namespace Patches {
  let displayNames = {
    [PatchID.YEARBOOK]: "YB",
    [PatchID.FEES_BALANCE]: "FEE",
    [PatchID.PARKING_LOT]: "Parking Lot",
    [PatchID.LUNCH_PRIVILAGE]: "PRI",
    [PatchID.ASB]: "ASB",
    [PatchID.CAN_LEAVE_CAMPUS]: "Leave Campus"
  };

  export function getAll(): any[] {
    // --- remove functions from object
    let filteredPatchIDs = lodash.filter(PatchID, (value, key) =>
      lodash.isString(value)
    );

    return lodash.map(filteredPatchIDs, (value: string) => {
      return { value, displayName: displayNames[value] };
    });
  }

  export function getDisplayName(patchID: PatchID) {
    return displayNames[patchID];
  }
}
