import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';
import { CommonServiceService } from '../common/common-service.service';
import { ZohoSubApiType } from '../enums/general.enums';

@Injectable({
  providedIn: "root"
})

export class ZohoService {

  constructor(public commonService: CommonServiceService, public http: HttpClient) { }

  async getZohoResponse(body: any) {
    // --- make api call
    let [response, error] = await this.commonService.executePromise(
      this.http.post(`${environment.zohoBooksV2BaseUrl}/api.php`, body).toPromise()
    );
    if (error) {
      console.log("Zoho api error", error.error);
      return error.error;
    }

    return response;
  }

  // create subscription
  createSubscription(customerID: string, subscriptionData: any) {
    let requestBody = {
      type: "createSubscription",
      customerID: customerID,
      subscriptionData: subscriptionData,
    };

    return this.getZohoResponse(requestBody);
  }

  // get customer cards
  readCustomerCards(customerId: string) {
    let requestBody = {
      type: ZohoSubApiType.GET_CUSTOMER_CARDS,
      customerID: customerId,
    };

    // --- make api call
    return this.getZohoResponse(requestBody);
  }
}
