import { Injectable } from "@angular/core";
import { CacheKeys, CacheService } from "../cache/cache.service";

@Injectable({
  providedIn: "root"
})
export class LocalStorageService {
  constructor(private cacheService: CacheService) {}

  // --- check if localstorage available
  isAvailable() {
    try {
      localStorage.getItem("anything");
      return true;
    } catch (e) {
      return false;
    }
  }

  // --- set localstorage item
  setItem(key: string, value: string) {
    if (!key) return;

    try {
      localStorage.setItem(key, value);
    } catch (e) {
      console.log("Error storing data in localstorage", e);
      this.cacheService.set(CacheKeys.LocalStorage, key, value);
    }
  }

  // --- remove localstorage item
  removeItem(key: string) {
    if (!key) return;

    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.log("Error removing localstorage item", e);
      this.cacheService.remove(CacheKeys.LocalStorage, key);
    }
  }

  // --- get localstorage item
  getItem(key: string) {
    if (!key) return null;

    try {
      return localStorage.getItem(key);
    } catch (e) {
      console.log("Error reading item from localstorage", e);
      let cachedData = this.cacheService.get(CacheKeys.LocalStorage, key);
      if (cachedData) return cachedData.value;
    }
    return null;
  }

  // --- clear localstorage
  clear() {
    try {
      localStorage.clear();
    } catch (e) {
      console.log("Error clearing localstorage", e);
      this.cacheService.remove(CacheKeys.LocalStorage, null);
    }
  }
}
