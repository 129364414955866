<div class="flip-container" [ngStyle]="styles.flip_container">
	<div class="flipper" [ngStyle]="styles.flipper">
		<div class="front" [ngStyle]="styles.f_elem_style">
			<!-- front content -->
            <ng-container *ngTemplateOutlet="f_elem"></ng-container>
            <p *ngIf="!f_elem">Front</p>
		</div>
		<div class="back" [ngStyle]="styles.b_elem_style">
			<!-- back content -->
            <ng-container *ngTemplateOutlet="b_elem"></ng-container>
            <p *ngIf="!b_elem">Back</p>
		</div>
	</div>
</div>