import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-header-layout",
  templateUrl: "./header-layout.component.html",
  styleUrls: ["./header-layout.component.scss"]
})
export class HeaderLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
