export default class UserAuth {
  id?: string;
  orgID: string;
  indID: string;
  email?: string;
  password?: string;
  designTypeId?: string;
  workflow?:string;
  referrer?: string;
  scanType?:string;
  scannedField?:string;
  surveyId?:string;
  targetImageWidth?: string;
  callFrom?: string;
  factorV2?: string;
  isNewTab?: string;
  forgotPassword?: string;
  task?: string;
  maxCameraResolution?:string;
  firstName?: string;
  lastName?: string;
  visitKey?:string;
  isVisitor?: boolean;
  isVisitorPhotoCaptured?;
  isFromStartPage?:boolean;
  phone?: string;
  currentState?: string;
  gameId?: string;
  isFrom?: string;
  temperature?: string;
  maxAge?: number;
  eventId?: string
  studioID?: string;
  superAdmin?:string;
  designOrVariationId?:string;
  isDesignOrVariation?:string;
  isRecordEntryExit?: boolean;
  repeatScanDelay?: string;
  scannedUserKey?: string;
  landingPageKey?: string;
  ticketId?: string;
  seriesId?: string;
  printDesignTypeID?: string;
  printDesignID?: string;
  isPrintDesignOrVariation?: string;
  printFF?: string;
  isSelfRegistrant?: boolean;
  isPhotoCaptured?: boolean
  previewDesignTypeID?: string;
  passTypeID?: string;
  visitorIndID?: string;
  from?: string;
  designTypeId_Review?: string;
  designOrVariationId_Review?: string;
  isDesignOrVariation_Review?: string;
  contactDetailsPref?: boolean;
  digitalIDPref?: boolean;
  auxIDPref?: string;
  _RFIDPref?: string;
  sourceField?: string;
  otherIDFormat?: string;
  minNumOfImages?: number;
  watchMeGrowEffect?: number;
  _2FAVerification?: number;
  passId?: string;
  attendedEventsKey?: string;
  courseKey?: string;
  kioskDataKey?: string;
  payload?: string;
  log?: number;
  url?: string;
  redir?: string;
  timestamp?: string;
  sso?: string; // will be set to 1 when sso redirect is done with successful login
}
