import { decodeKeys } from "src/app/core/utils/queryParams.utils";

export const environment = {
  production: false,
  siteUrl: "https://high5.id",
  baseUrl: "https://landingstage.high5.id/",
  apiBaseUrl: "https://high5.id/demo/cms/api",
  newApiBaseUrl: "https://high5.id/api",
  rendererBaseUrl: "https://high5.id/stage/newRenderer",
  localStoragePrefix: "high5:dev:",
  ImageSelfieCamera: "Image_SelfieCamera",
  firebaseImgUrl: "https://firebasestorage.googleapis.com/v0/b/",
  firebaseFunctionsBaseUrl:
  "https://function-stage.high5.id/httpRequests",
  firebaseCallableFunctionUrl: "https://function-stage.high5.id/",
  zohoBooksV2BaseUrl: "https://high5.id/stage/ZohoBooks/v2",
  demoConfig: {
    orgID: "central_secondary_school_-M5bZLmx2VqsSmHglZHR",
    studioID: "mike_plic_studio__staging__-NAOk1QsvNoEUxmWASXh",
    defaultDesignTypeID: "-M4svx1Ssr7jk7r4dJLN"
  },
  firebaseConfig: {
    apiKey: decodeKeys(
      "010000010100100101111010011000010101001101111001010001000111010001010001011001100101011101010000011110010101010101110010001110010011001001001100010100110011001101110000011100010111000001001101011101110011001001010010011010000111000001011000011100110011001101101000001101010100111000110010011001010111011001000001000==="
    ),
    authDomain: "high5-test.firebaseapp.com",
    databaseURL: "https://high5-test.firebaseio.com",
    projectId: "high5-test",
    storageBucket: "high5-test.appspot.com",
    messagingSenderId: "155372021514",
    appId: "1:155372021514:web:f8f8499af2de5bf5973cf3",
    measurementId: "G-2R8WXTHKQZ"
  },
  awsImageUpload: {
    signUrlApi:
      "https://u56iadd5gb.execute-api.us-east-1.amazonaws.com/dev/upload-urls/fetch",
    getImageUrlApi:
      "https://u56iadd5gb.execute-api.us-east-1.amazonaws.com/dev/url/fetch",
    xApiKey: decodeKeys(
      "0110001101000010011000110101010101100101011011000101001101100001010011100100100000110110010110000011100001000010001100000011010001101011011011000011001001101111010011000011010101110001011100110101000001100101010010100110001001000010011011010110100001111010001100100111000001100111010101100110011101000100010001010100011100=="
    ),
    bucketUrl: "https://test-high5-image.s3.amazonaws.com",
    endPoint: "https://u56iadd5gb.execute-api.us-east-1.amazonaws.com/dev"
  },
  superAdminEmail: "admin@high5.id",
  onrampSetupAdminEmail: "sales@high5.id",
  supportAdminEmail: "support@high5.id",
  orgManagerType: "-MKcuuD1prov6sLCgjn_", // Teammate id
  redirectToHigh5: window.location.href.includes("localhost")
    ? "http://localhost:8100/"
    : "https://high5.id/stage/",
  placeholderImageUrl:
    "https://firebasestorage.googleapis.com/v0/b/vip-pass.appspot.com/o/photos%2Findividuals%2Fimage-not-found.jpg?alt=media&token=db93ec98-fcf5-4791-ba54-b7913d105188",
  mailFromAddress: "noreply",
  urlCrashMsgContent:
    "There was a problem with the formatting of the link you used. \n\n Sorry! \n A notification has automatically been sent to the right people, so they know there is a problem. \n Please try again later.",
  elasticSearch: {
    endPoint: "/stage-elastic.php"
  },
  stripePaymentKey: {
    publicKey: "pk_test_HWEqPT2u3dfQ4TGymcOO7wr3009Q61zwkT"
  },
  mailgunObj: {
    endPoint: "stage-mailgun-send-mail.php"
  },
  smsObj: {
    endPoint: "stage-message-sms-bulk.php"
  },
  edk: decodeKeys(
    "01110001011001110110010101100011010000010100111001011000011010010110001101010010010101100010000101100010010110100110101100100001010000000110100001001000011010010110010100111001010000010010010001000110000==="
  ),
  sampleImage:
    "https://firebasestorage.googleapis.com/v0/b/high5-test.appspot.com/o/test2%2Ftransparent-img.png?alt=media&token=ea644b49-4668-4a5a-a0f8-0c04ecf16de0",
  stampNotConfiguredImage:
    "https://firebasestorage.googleapis.com/v0/b/high5-test.appspot.com/o/test2%2FApprovalStampNotConfigured.png?alt=media&token=d1be3af1-8fd0-4cdc-a501-41f9033c1559"
};
