import { Injectable } from "@angular/core";
import { Role } from "./enums/user.enums";
import {
  InheritanceService,
  ReplacementTags
} from "./inheritance/inheritance.service";
import lodash from "lodash";
import { CommonServiceService } from "./common/common-service.service";
import { AngularFireDatabase } from "@angular/fire/database";

@Injectable({
  providedIn: "root"
})
export class CertificateService {
  organizations = "organizations";
  studios = "studios";

  readonly userDBNodes = {
    [Role.ORG]: "organization",
    [Role.STUDIO]: "studios",
    [Role.SUPERADMIN]: "superadmin"
  };

  readonly dbPath = `certificates/${ReplacementTags.USER_DB_NODE}/${ReplacementTags.USER_ID}/`;

  constructor(
    public inheritanceProvider: InheritanceService,
    public commonFun: CommonServiceService,
    public db: AngularFireDatabase
  ) {}

  // read certificate
  readCertificate(role: string, orgId: string, userData: any): Promise<any[]> {
    return new Promise(async (resolve, reject) => {
      // --- fetch certificate list by inheritance
      let certificateInheritedData = await this.inheritanceProvider.getByInheritance(
        role,
        orgId,
        this.dbPath,
        "list",
        userData,
        this.userDBNodes,
        null,
        false
      );

      let certificateList = [];
      let allCerticates = {};

      // --- merge certificate data based on inheritance
      lodash.each(certificateInheritedData, certificateData => {
        let ownerID = certificateData.ownerID;
        let usersCerts = lodash
          .chain(certificateData.data)
          .map((data: any, key) => {
            return { ...data, id: key, key, ownerID };
          })
          .keyBy("key")
          .value();

        allCerticates = lodash.merge(usersCerts, allCerticates);
      });

      certificateList = lodash.map(allCerticates, value => value);

      // --- remove certificate with Override hide
      lodash.remove(
        certificateList,
        certificateData => lodash.toLower(certificateData.override) == "hide"
      );

      // --- sort certificate list by name
      certificateList = lodash.orderBy(certificateList, certificateData =>
        lodash.toLower(certificateData.displayName)
      );

      resolve(certificateList);
    });
  }

  claimCertificate(orgId: string, data: any) {
    return this.db.list(`/certificateValidation/${orgId}`).push(data);
  }

  readIndCertificate(orgId: string, indId: string): Promise<any[]> {
    return new Promise((resolve, reject) => {
      let listSub = this.db
        .list(`/certificateValidation/${orgId}`, ref =>
          ref.orderByChild("indId").equalTo(indId)
        )
        .snapshotChanges()
        .subscribe(list => {
          listSub.unsubscribe();
          let certificateList: any[] = [];
          lodash.each(list, entry => {
            let value: any = entry.payload.val();
            value["key"] = entry.payload.key;
            certificateList.push(value);
          });
          resolve(certificateList);
        });
    });
  }

  async readCertificationApprovalData(orgId: string, indID?: string) {
    let snapshot = await this.db
      .list(`/certificationApproval/${orgId}/${indID || ""}`)
      .query.once("value");
    let data = snapshot.val();

    if (indID) {
      return lodash.map(data, (certData, certKey) => {
        return { ...certData, certKey };
      });
    } else {
      return lodash
        .chain(data)
        .map((indCerts, indID) => {
          return lodash.map(indCerts, (certData, certKey) => {
            return { ...certData, certKey, indID };
          });
        })
        .flatten()
        .value();
    }
  }

  // --- update individual certificates
  async updateIndCerts(orgID: string, indID: string, updateObj: any) {
    if (!orgID || !indID) throw new Error("Params missing!");

    return await this.db
      .object(`certificationApproval/${orgID}/${indID}`)
      .update(updateObj);
  }

  // --- get certification signature from user id
  async getCertSign(userID: string) {
    let snapshot = await this.db
      .object(`/userManagement/${userID}/certificationSignature`)
      .query.once("value");
    return snapshot.val();
  }
}
