import { Injectable } from "@angular/core";
import { Role } from "./enums/user.enums";
import {
  InheritanceService,
  ReplacementTags
} from "./inheritance/inheritance.service";
import lodash from "lodash";

@Injectable({
  providedIn: "root"
})
export class TransactionsService {
  constructor(private inheritanceProvider: InheritanceService) {}

  readonly dbPathCycleToDisplay = `${ReplacementTags.USER_DB_NODE}/${ReplacementTags.USER_ID}/cycle`;
  readonly userDBNodesCycleToDisplay = {
    [Role.ORG]: "organizations",
    [Role.STUDIO]: "studios",
    [Role.SUPERADMIN]: "settings"
  };

  // --- fetch cycle to display on ID by inheritance
  async getCycleToDisplayOnID(
    role: any,
    userID: string,
    usersData?: any,
    useCache: boolean = false
  ) {
    // --- params validation
    if (!role || (role != Role.SUPERADMIN && !userID))
      throw Error("Invalid params passed to getDateToShowOnID call");

    // --- fetch cycle to display by inheritance
    let cycleInheritedData = await this.inheritanceProvider.getByInheritance(
      role,
      userID,
      this.dbPathCycleToDisplay,
      "object",
      usersData,
      this.userDBNodesCycleToDisplay,
      null,
      useCache
    );

    // --- find highest priority non-null data from the inherited data
    let cycleData: any = lodash.find(
      cycleInheritedData,
      cycleData => cycleData.data != null
    );

    const defCycleToDisplay = "Please configure year";
    if (!cycleData) return defCycleToDisplay;

    return cycleData.data;
  }
}
