import { Pipe, PipeTransform } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import lodash from "lodash";
import { CacheKeys, CacheService } from "../../cache/cache.service";
import { CommonServiceService } from "../../common/common-service.service";

@Pipe({
  name: "getIndProp"
})
export class GetIndPropPipe implements PipeTransform {
  constructor(
    private cacheService: CacheService,
    private db: AngularFireDatabase,
    private commonService: CommonServiceService
  ) {}

  transform(
    indData: any,
    orgID: string,
    propPath: string,
    useCache: boolean = false,
    postProcess: Function = value => value
  ): Promise<any> {
    return this.getIndProp(indData, orgID, propPath, useCache, postProcess);
  }

  private async getIndProp(
    indData,
    orgID,
    propPath,
    useCache: boolean,
    postProcess
  ): Promise<any> {
    if (!indData) return postProcess(null);

    // --- read from ind object itself if available
    if (lodash.has(indData, propPath)) return lodash.get(indData, propPath);

    // --- read individual ID
    let indID = lodash.get(indData, "key", lodash.get(indData, "_key"));
    if (!indID || !orgID) return postProcess(null);

    // --- read from cache if allowed
    if (useCache) {
      let cachedData = this.cacheService.get(
        CacheKeys.IndProp,
        `${orgID}.${indID}.${propPath}`
      );
      if (cachedData) return postProcess(cachedData.value);
    }

    // --- read from DB
    let [propValueSnapshot, err] = await this.commonService.executePromise(
      this.db
        .object(`individuals/${orgID}/${indID}/${propPath}`)
        .query.once("value")
    );
    if (err) return postProcess(null);
    let propValue = propValueSnapshot.val();

    // --- set cache
    this.cacheService.set(
      CacheKeys.IndProp,
      `${orgID}.${indID}.${propPath}`,
      propValue
    );

    return postProcess(propValue);
  }
}
