export enum Role {
    SUPERADMIN = "superadmin",
    STUDIO = 'studioadmin',
    ORG = "admin"
}
export enum CommanName {
    organizations = 'organizations',
    studios = 'studios',
    schoolStudent = 'school_Student',
    schoolVisitor = 'school_Visitor'
}

export enum FlagBehaviorStatus {
    NONE = 0,
    ALWAYS_REQUIRE_APPROVAL = 1,
    AUTOMATIC_APPROVAL = 2,
    AUTOMATIC_REFUSAL = 3
}

export enum GuestPolicy {
    NOTALLOWED = 'notAllowed',
    ANONYMOUS= 'anonymous',
    ORGIND = 'existingIndsOnly',
    ORGNONORGIND = 'existingOrNewInds'
}