<div *ngIf="isShow && indData && (indData.mobilePhone || indData.email || indData.guardianPhone || indData.guardianEmail)">
    <div class="modal-header" *ngIf="isShow">
        <h4 class="modal-title">{{ title }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" *ngIf="isShow">We will send instructions for resetting your password.
        Where would you like us to send this message?</div>
    <div class="modal-footer" *ngIf="isShow">
        <button type="button" class="btn btn-primary fullWidth" 
            *ngIf="indData.mobilePhone"
            (click)="forgotPassword(MsgMedium.SMS, SendMsgToReceiver.SELF, indData.mobilePhone)"
            >{{indData.mobilePhone}} <br/> (Your Mobile Phone)</button>
        <button type="button" class="btn btn-primary fullWidth" 
            *ngIf="indData.email" 
            (click)="forgotPassword(MsgMedium.EMAIL, SendMsgToReceiver.SELF, indData.email)"
            >{{indData.email}} <br/> (Your Email Address)</button>
        <button type="button" class="btn btn-primary fullWidth" 
            *ngIf="indData.guardianPhoneData" 
            (click)="forgotPassword(MsgMedium.SMS, SendMsgToReceiver.GUARDIAN, indData.guardianPhoneData.mobilePhone, indData.guardianPhoneData.key)"
            >{{indData.guardianPhoneData.mobilePhone}} <br/> (Your Guardian's Phone)</button>
        <button type="button" class="btn btn-primary fullWidth" 
            *ngIf="indData.guardianEmailData"
            (click)="forgotPassword(MsgMedium.EMAIL, SendMsgToReceiver.GUARDIAN, indData.guardianEmailData.email, indData.guardianEmailData.key)"
            >{{indData.guardianEmailData.email}} <br/> (Your Guardian's Email Address)</button>
        <button type="button" class="btn btn-danger fullWidth" 
            (click)="dismiss()">Cancel</button>
    </div>
</div>

<div *ngIf="isShow && !indData.mobilePhone && !indData.email && !indData.guardianPhone && !indData.guardianEmail">
    <div class="modal-header" *ngIf="isShow">
        <h4 class="modal-title">{{ title }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" *ngIf="isShow">We do not have any contact information on file for you.
        Please visit the person in charge of High5.ID for your organization to reset your password.</div>
    <div class="modal-footer" *ngIf="isShow">
        <button type="button" class="btn btn-danger fullWidth" (click)="dismiss()">OK</button>
    </div>
</div>