<!-- header -->
<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
</div>

<!-- Content -->
<div class="modal-body">
    <p>{{ message }}</p>
    
    <div class="inds-list">
        <div class="item-ind" *ngFor="let ind of matchingInds">
            <button type="button" class="btn btn-primary" (click)="onBtnClicked(ind)"
                >{{ ind.label }}</button>
        </div>
    </div>
</div>

<!-- Footer -->
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="onBtnClicked(null)" *ngIf="btnCancelText"
        >{{ btnCancelText }}</button>
</div>