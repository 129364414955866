import { forwardRef, Inject, Injectable } from "@angular/core";
import { AngularFireDatabase } from '@angular/fire/database';
import { SettingsService } from '../settings/settings.service';
import lodash from "lodash";
import { environment } from '../../../../src/environments/environment';
import { ZohoService } from '../zoho/zoho.service';
import moment from 'moment';
import { BundleType, ConsumablePaidBy, MsgMedium } from "../enums/general.enums";
import { CommonServiceService } from "../common/common-service.service";
import { Message, MessageArea, MessagesService, MessageTypes } from "../messages/messages.service";
import { Role } from "../enums/user.enums";
import { HttpClient } from "@angular/common/http";
import { LicenseService } from "../license/license.service";
import { OrganizationsService } from "../organizations/organizations.service";

@Injectable({
  providedIn: 'root'
})

export class AutoRefillConsumablesService {

  constructor(public db: AngularFireDatabase,
    public settingsService: SettingsService,
    public http: HttpClient,
    @Inject(forwardRef(() => CommonServiceService)) public commonService,
    public messagesService: MessagesService,
    public zohoService: ZohoService,
    private licenseService: LicenseService) { }

  getTemmateLicense(studioId: string) {
    return this.db.list(`/licenses/studios/instances/${studioId}`);
  }

  consumablePaidBy: string = '';
  // checkAutoRefillNeeded
  checkAutoRefillNeeded(orgService: OrganizationsService, orgID: string) {
    return new Promise<any>(async (resolve, reject) => {
      // check send sms send preference
      let orgData = await orgService.getOrgData(orgID, ["consumablePaidBy"], true);
      this.consumablePaidBy = lodash.get(orgData, "consumablePaidBy")
      if (!this.consumablePaidBy || this.consumablePaidBy == ConsumablePaidBy.ORG) {
        let obj = {
          paidBy: ConsumablePaidBy.ORG,
          success: true
        }
        resolve(obj);
      } else {
        let studioId: string = await orgService.getOrgProp(orgID, 'studioID', true)
        if (!studioId) return;
        let remainingCredits = await this.licenseService.getTotalSmsCount(
          Role.STUDIO,
          studioId
        )
        let orgCredits = await this.licenseService.getTotalSmsCount(Role.ORG, orgID)
        if (
          this.consumablePaidBy == ConsumablePaidBy.TEAMMATE &&
          orgCredits > 0
          ){
          // --- if preference is paid by studio, still use org credits if enough credits are available
          let obj = { paidBy: ConsumablePaidBy.ORG, success: true }
          resolve(obj);
        }else if(remainingCredits){
           // if valid balance available then further processing
          let obj = { paidBy: ConsumablePaidBy.TEAMMATE, success: true }
          resolve(obj);
        } else {
          let [res, error] = await this.commonService.executePromise(this.autoRefillLicense(orgService, studioId, orgID));
          if(error) {
            reject(error)
          } else {
            resolve(res);
          }
        }
      }
    })
  }

  async checkSmsConfiguration(sendMsgsCreditsUsageCount: number, paidBy, orgId: string, studioId: string) {
    return await this.checkIfCreditAvailable(
      paidBy,
      orgId,
      studioId,
      sendMsgsCreditsUsageCount
    )
  }

  getStudioConsumablesPref(studioID: string) {
    return this.db.object(`licenses/studios/consumablesPref/${studioID}/`).query.once('value')
  }

  getSingleLicenseData(licenseId: string) {
    return this.db.object(`/licenses/definitions/${licenseId}`).query.once('value')
  }

  getTeammate(key) {
    return new Promise((resolve, reject) => {
      let sub = this.db.object(`/studios/${key}`).valueChanges().subscribe(res => {
        sub.unsubscribe();
        if (res) {
          resolve(res);
        } else {
          resolve('')
        }
      })
    })
  }

  cardDetails: any;
  async readCustomerCards(customerId: string) {
    let readCardRes = await this.zohoService.readCustomerCards(customerId);
    if (readCardRes.status != 200) return;

    // --- sort in descending order by created time
    readCardRes.data = lodash.orderBy(readCardRes.data, cardData => {
      return moment(cardData.created_time, 'YYYY-MM-DDTHH:mm:ssZZ').valueOf()
    }, 'desc')

    this.cardDetails = lodash.first(readCardRes.data);
    if (!this.cardDetails) return;
  }

  post(endpoint: string, body: any, reqOpts?: any) {
    // --- assemble url
    let url = endpoint;
    if (endpoint.indexOf("http") === -1) {
      url = environment.newApiBaseUrl + "/" + endpoint;
    }

    return this.http.post(url, body, reqOpts);
  }

  // --- send email on setups@high5.id when create new new org by teammate or high5
  sendEmailToHigh5(amount, studioName: string, orgName: string, productName, failureMessage: string) {
    // --- mailgun post data
    const mailgunObj = {
      from: environment.mailFromAddress,
      to: [environment.supportAdminEmail],
      text: `Auto Renew \nCredit card payment for ${amount} failed for  teammate ${studioName}\nUsed by Org: ${orgName}\nTry too purchase: ${productName}\n
        Type: Auto renew\nFailure Reason: ${failureMessage}`,
      ['v:is_from_test']: !environment.production
    }
    this.post(environment.mailgunObj.endPoint, { data: [mailgunObj], type: "single", emailType: "email" }).subscribe((res: any) => {
    }, (err) => {
      console.log('err: ', err);
    })
  }

  async setSAMessage(amount, studioName: string, orgName: string, productName, failureMessage: string) {
    let message: Message = {
      timestamp: new Date().getTime(),
      type: 'error',
      details: `Auto Renew \nCredit card payment for ${amount} failed for  teammate ${studioName}\nUsed by Org: ${orgName}\nTry too purchase: ${productName}\n
      Type: Auto renew\nFailure Reason: ${failureMessage}`,
      action: MessageTypes.CUSTOM_ALERT,
      area: MessageArea.REGISTRATION
    }
    this.sendEmailToHigh5(amount, studioName, orgName, productName, failureMessage)
    await this.messagesService.setMessage(Role.SUPERADMIN, message);
  }

  async processPayment(licenseData: any, studioName: string, orgName: string) {
    return new Promise(async (resolve, reject) => {
      let subscriptionData = {
        cardID: this.cardDetails.card_id,
        itemsData: [{
          productName: licenseData.name,
          subProductName: `${licenseData.name} - ${(licenseData.type == BundleType.SMALL) ? 'Small' : (licenseData.type == BundleType.MEDIUM) ? 'Medium' :
            (licenseData.type == BundleType.LARGE) ? 'Large' : (licenseData.type == BundleType.BOUTIQUE) ? 'Boutique' : ''}`,
          price: Number(licenseData.price),
          quantity: 1
        }],
        billingCycles: 0,
        trialDays: 0,
        interval: 1,
        applyCreditNotesIfPossible: true,
        addToCredits: false
      }

      let createSubRes =
        await this.zohoService.createSubscription(this.cardDetails.customer_id, subscriptionData)

      // --- error handling
      if (createSubRes.status != 200) {
        alert(createSubRes.message);
        this.setSAMessage(subscriptionData.itemsData[0].price, studioName, orgName, subscriptionData.itemsData[0].productName, createSubRes.message);
        resolve(false);
      }
      resolve(true);
    })
  }

  // --- check if license is exist in org or not 
  checkIfTeammateLicenseIsExist(licenseKey: string, orgId: string, callback: Function) {
    let licenseSub = this.db.list(`/licenses/studios/instances/${orgId}`, ref => ref.orderByKey().equalTo(licenseKey)).snapshotChanges().subscribe(res => {
      if (licenseSub) {
        licenseSub.unsubscribe();
      };
      if (res.length > 0) {
        lodash.each(res, entry => {
          const value: any = entry.payload.val();
          value['key'] = entry.payload.key;
          callback(value);
        })
      } else {
        callback(null)
      }
    })
  }

  createDefaultObj(licenseData: any) {
    return {
      id: licenseData.id,
      price: licenseData.price,
      assigned_by: 'Autofill',
      assigned_on: moment().format("x") + '_' + this.commonService.guid(4),
      type: licenseData.type,
      licenseCount: 1,
    }
  }

  addConsumablePurchaseHistory(orgID: string, data: any) {
    return this.db.list(`/licenses/purchaseHistory/${orgID}`).push(data);
  }

  createConsumerAutoFillHistory(licenseData, studioId: string, orgID: string) {
    let obj = {
      timestamp: moment().format('x'),
      type: licenseData.name,
      size: licenseData.defaultCount,
      price: licenseData.price,
      paymentStatus: 'Paid'
    }
    this.addConsumablePurchaseHistory(orgID, obj);
    this.db.list(`/licenses/studios/autoFillHistory/${studioId}/`).push(obj);
  }


  // purchase teammate consumables license auto refill
  autoRefillStudioLicense(orgService: OrganizationsService, licenseKey: string, studioId: string, orgId: string, licenseData: any) {
    return new Promise<any>(async (resolve, reject) => {
      let studioData: any = await this.getTeammate(studioId);
      let orgData: any = await orgService.getOrgData(orgId, ["orgName"]);
      if (!studioData || !studioData.zohoCustomerID) {
        return reject('Something went wrong. please try again later!')
      }
      await this.readCustomerCards(studioData.zohoCustomerID);
      if (!this.cardDetails) return;

      // 
      let paymentRes = await this.processPayment(licenseData, studioData.studioName, orgData.orgName);
      if (!paymentRes) return;

      this.checkIfTeammateLicenseIsExist(licenseKey, studioId, (res) => {
        if (res == null) {
          let studioLicenseInstance: any = {
            ...this.createDefaultObj(licenseData),
            defaultCount: licenseData.defaultCount,
          }
          delete studioLicenseInstance.remainingCount;
          this.db.object(`/licenses/studios/instances/${studioId}/${licenseKey}/${studioLicenseInstance.assigned_on}`).set(studioLicenseInstance);
          this.createConsumerAutoFillHistory(licenseData, studioId, orgId);
          resolve('')
        } else {
          // --- call when license is already exist.
          let oldLicense;
          let oldLicenseKey;
          delete res.key;
          let studioLicenses = Object.keys(res).map(li => {
            oldLicenseKey = li;
            return res[li];
          })
          studioLicenses = studioLicenses.sort((a, b) => (a && this.commonService.checkAssingedOnKey(a.assigned_on) && b &&
            this.commonService.checkAssingedOnKey(b.assigned_on) && this.commonService.checkAssingedOnKey(a.assigned_on) > this.commonService.checkAssingedOnKey(b.assigned_on)) ? -1 : 1);
          oldLicense = studioLicenses[0];
          let orgLicense = {};
          let newLicense: any = {
            ...this.createDefaultObj(licenseData),
            defaultCount: licenseData.defaultCount,
          }
          delete newLicense.remainingCount;

          oldLicense = {
            ...oldLicense,
            isReplaced: true,
            replaced_on: moment().format("x")
          }
          orgLicense[oldLicenseKey] = oldLicense;
          orgLicense[newLicense.assigned_on] = newLicense;
          this.db.object(`/licenses/studios/instances/${studioId}/${licenseKey}`).update(orgLicense);
          this.createConsumerAutoFillHistory(licenseData, studioId, orgId);
          resolve('')
        }
      })
    })
  }

  autoRefillLicense(orgService: OrganizationsService, studioId: string, orgId: string) {
    return new Promise(async (resolve, reject) => {
      let studioPref: any = (await this.getStudioConsumablesPref(studioId)).val();
      if (!studioPref) studioPref = {}
      if (!studioPref.messageId) studioPref.messageId = environment.production ? '-MgIKpk4dVyRbul2hJm4' : '-M2O3QO14-yoXLLU2C5-';
      if (!studioPref.offenderId) studioPref.offenderId = '-M3c-VxcilS7uhAWij_Z';
      let singleLicenseData: any = (await this.getSingleLicenseData(studioPref.messageId)).val();
      let [res, error] = await this.commonService.executePromise(this.autoRefillStudioLicense(orgService, studioPref.messageId, studioId, orgId, singleLicenseData))
      if(error) {
        reject(error)
      } else {
        resolve({ success: true, paidBy: ConsumablePaidBy.TEAMMATE });
      }
    })
  }

  async saveStudioConsumablesUsesCount(type, count, orgID: string, studioID: string) {
    let previousData: any = (await this.getReportOfStudioConsumableUses(studioID, orgID)).val();
    let body = {
      count: previousData && previousData.count ? `${Number(previousData.count) + count}` : count,
      timestamp: Number(moment().format('x')),
      type: type
    }
    this.saveReportOfStudioConsumableUses(studioID, orgID, body);
  }

  // ===========================================================================
  // ================== ORGANIZATION LICENSE CHECK =======
  // ===========================================================================

  /**
   * check credits availability conditionally (if preference of paid by is org, check for org only and if pref is paid by studio, check org first and then if not sufficient then check studio)
   * @param paidBy paid by preference
   * @param orgID organization id
   * @param studioID studio id
   * @param sendMsgsCreditsUsageCount required credits to send messages
   * @returns object containing the key success (indicates success or error), message (contains error message in case of error), licensesTobeUpdate and messageCreditsToBeCharged
   */
  async checkIfCreditAvailable(
    paidBy: string,
    orgID: string,
    studioID: string,
    sendMsgsCreditsUsageCount: number
  ) {
    // --- helper function to check if truedialog have sufficient credits or not
    const checkTruedialogCredits = async (requiredCredits, orgName) => {
      let [response, error] = await this.commonService.executePromise(
        this.messagesService.getTruedialogCredit()
      );

      // --- error checking
      if (error || !response.success) {
        throw new Error(
          `Error while checking credits: ` + this.commonService.prepareErrorMessage(error)
        )
      }

      // --- if credits are not sufficient, send message to superadmin and throw error
      if(requiredCredits > response.creditsLeft) {
        await this.messagesService.sendEmailToHigh5Admin(
          orgName,
          sendMsgsCreditsUsageCount,
          response.creditsLeft
        );
        throw new Error(
          `Please advise High5.ID:<br/>
          Additional license credits need to be added system-wide.<br/>
          Until this is addressed, messages cannot be sent.`
        );
      }

      return true;
    }

    // --- read org remaining credits and org data
    let readDataPromises = []
    readDataPromises.push(this.commonService.getOrgData(orgID, undefined, true))
    readDataPromises.push(
      this.licenseService.getTotalSmsCount(
        Role.ORG,
        orgID
      )
    )
    let [readDataPromisesRes, readDataPromisesErr] = await this.commonService.executePromise(
      Promise.all(readDataPromises)
    )
    if(readDataPromisesErr) {
      return {
        success: false,
        message: this.commonService.prepareErrorMessage(readDataPromisesErr)
      };
    }
    let orgData = lodash.nth(readDataPromisesRes, 0)
    let orgRemainingCredits = lodash.nth(readDataPromisesRes, 1)

    let isPaidByOrg = paidBy == ConsumablePaidBy.ORG

    // --- if preference is to paid by org
    // and org remaining credits are less than required credit, return error
    if(isPaidByOrg && sendMsgsCreditsUsageCount > orgRemainingCredits) {
      return {
        success: false,
        message: `Not enough consumable credits to send messages. Please ask your organization to refill consumables and then try again!`
      };
    }

    // --- if org remaining credits is sufficient,
    // prepare license update object after send happens and return response back
    if(sendMsgsCreditsUsageCount <= orgRemainingCredits) {
      let orgLicensesToUpdate = await this.licenseService.getLicensesToUpdateAfterMsgsSent(
        Role.ORG,
        orgID,
        sendMsgsCreditsUsageCount
      )

      // --- check error while preparing orgLicensesToUpdate
      if(lodash.size(orgLicensesToUpdate) == 0) {
        return {
          success: false,
          message: `Something went wrong while figuring out licenses to deduct!`
        }
      }

      // --- Check truedialog remaining sms credit before sending sms
      let [, error] = await this.commonService.executePromise(
        checkTruedialogCredits(sendMsgsCreditsUsageCount, orgData.orgName)
      );
      if (error) {
        return {
          success: false,
          message: this.commonService.prepareErrorMessage(error)
        };
      }
     
      return {
        success: true,
        licensesTobeUpdate: orgLicensesToUpdate,
        messageCreditsToBeCharged: sendMsgsCreditsUsageCount,
        paidBy: ConsumablePaidBy.ORG
      };
    }

    // --- if org remaining credits is not sufficient,
    // check studio's credits
    let studioRemainingCredits = await this.licenseService.getTotalSmsCount(
      Role.STUDIO,
      studioID
    )

    // --- if not sufficient credits, return error
    if(sendMsgsCreditsUsageCount > studioRemainingCredits) {
      return {
        success: false,
        message: `Studio does not have enough consumable credits to send messages. Please ask your studio to refill consumables and try again!`
      };
    }

    // --- prepare license update object after send happens
    let studioLicensesToUpdate = await this.licenseService.getLicensesToUpdateAfterMsgsSent(
      Role.STUDIO,
      studioID,
      sendMsgsCreditsUsageCount
    )

    // --- check error while preparing orgLicensesToUpdate
    if(lodash.size(studioLicensesToUpdate) == 0) {
      return {
        success: false,
        message: `Something went wrong while figuring out licenses to deduct!`
      }
    }

    // --- Check truedialog remaining sms credit before sending sms
    let [, error] = await this.commonService.executePromise(
      checkTruedialogCredits(sendMsgsCreditsUsageCount, orgData.orgName)
    );
    if (error) {
      return {
        success: false,
        message: this.commonService.prepareErrorMessage(error)
      };
    }

    return {
      success: true,
      licensesTobeUpdate: studioLicensesToUpdate,
      messageCreditsToBeCharged: sendMsgsCreditsUsageCount,
      paidBy: ConsumablePaidBy.TEAMMATE
    };
  }

  updateStudioSMSCountInLicense(studioID, licenseList): Promise<any> {
    return new Promise(resolve => {
      let sub = this.db.object(`/licenses/studios/instances/${studioID}`).valueChanges().subscribe((res: {}) => {
        sub.unsubscribe();
        lodash.each(licenseList, (ele, index) => {
          let currentLicenseKey;
          lodash.each(res, (e, index) => {
            if (Object.keys(e).length == 1) {
              if (Object.keys(ele)[0] == Object.keys(res[index])[0]) currentLicenseKey = index;
            } else {
              let innerObj = res[index];
              lodash.each(innerObj, (e1, index1) => {
                if (Object.keys(ele)[0] == index1) currentLicenseKey = index;
              })
            }
          })
          this.db.object(`/licenses/studios/instances/${studioID}/${currentLicenseKey}`).update(ele);
        })
        resolve('');
      })
    })
  }

  // --- read report of studio consumables usage by given org
  getReportOfStudioConsumableUses(studioID: string, orgID: string) {
    return this.db
      .object(`/teammateConsumablesUseCount/${studioID}/${orgID}`)
      .query
      .once("value");
  }

  // --- save report of studio consumables use count by given org
  saveReportOfStudioConsumableUses(
    studioID: string,
    orgID: string,
    data: any
  ) {
    return this.db
      .object(`/teammateConsumablesUseCount/${studioID}/${orgID}/`)
      .update(data);
  }

  // --- save studio consumables usage by org report
  async saveStudioConsumablesUsageByOrgReport(
    type: MsgMedium,
    creditsUsed: number,
    orgID: string,
    studioID: string
  ) {
    let previousData: any = (
      await this.getReportOfStudioConsumableUses(studioID, orgID)
    ).val();
    let currentCount = lodash.get(previousData, "count", 0);

    let body = {
      count: currentCount + creditsUsed,
      timestamp: moment().valueOf(),
      type: type,
    };
    return await this.saveReportOfStudioConsumableUses(studioID, orgID, body);
  }

}
