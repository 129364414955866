import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import lodash from "lodash";
import { environment } from "../../../environments/environment";
import * as $ from "jquery";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  url: string = `${environment.newApiBaseUrl}`
  static zeroUrl: string = "https://high5.id/zero/";
  public debug: boolean = true;
  public log: string[];
  public t0: number = 0;
  public t1: number = 0;
  public platforms: string[];

  constructor(
    public http: HttpClient,
  ) {
  }

  isPlatform(platformName: string) {
    return lodash.includes(this.platforms, platformName);
  }

  performanceStart() {
    if (this.debug === false) {
      return false;
    }

    this.t0 = performance.now();
  }

  performanceEnd(nameOfMethod: string) {
    if (this.debug === false) {
      return false;
    }

    this.t1 = performance.now();

    let duration = (this.t1 - this.t0).toFixed(4);

    if (!this.log) this.log = [];
    this.log.push(nameOfMethod + ": " + duration + " ms");

    return duration;
  }

  performancePrintLog(toConsole: boolean, download: boolean) {
    if (this.debug === false) {
      return false;
    }

    let text = this.log.join("\n");
    if (toConsole === true) {
      this.log = undefined;
    }

    if (download === true) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", "log_" + Date.now() + ".txt");

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  }

  get(endpoint: string, params?: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams()
      };
    }

    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      for (let k in params) {
        reqOpts.params.set(k, params[k]);
      }
    }

    return this.http.get(endpoint, reqOpts);
  }

  post(endpoint: string, body: any, reqOpts?: any) {
    // --- assemble url
    let url = endpoint;
    if (endpoint.indexOf("http") === -1) {
      url = this.url + "/" + endpoint;
    }

    return this.http.post(url, body, reqOpts);
  }

  put(endpoint: string, body: any, reqOpts?: any) {
    return this.http.put(this.url + "/" + endpoint, body, reqOpts);
  }

  delete(endpoint: string, reqOpts?: any) {
    return this.http.delete(this.url + "/" + endpoint, reqOpts);
  }

  patch(endpoint: string, body: any, reqOpts?: any) {
    return this.http.put(this.url + "/" + endpoint, body, reqOpts);
  }
}
