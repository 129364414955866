import { Injectable } from "@angular/core";
import moment from "moment";
import { Role } from "../enums/user.enums";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { EncryptionService } from "../encryption/encryption.service";

@Injectable({
  providedIn: "root"
})
export class ToDoService {
  constructor(
    private httpClient: HttpClient,
    private encryptionService: EncryptionService
  ) {}

  verifyToDoTask(todoTask: any) {
    if (!todoTask.manager) throw Error("todoTask must contain manager");
    if (!todoTask.class) throw Error("todoTask must contain class");
    if (!todoTask) throw Error("todoTask must be a valid object");
    if (!todoTask.title) throw Error("todoTask must contain title");
    if (!todoTask.desc) throw Error("todoTask must contain desc");
  }

  /**
   * create to-do task for any user in High5
   * @param {string} userId userID to create to-do task for (org/studio ID, 'superadmin' for super admin)
   * @param {string} role user role
   * @param {object} cycle reporting year in which the task should get created
   * @param {object} todoTask to-do task object
   */
  async setToDoTask(
    userID: string,
    role: string,
    cycle: string,
    todoTask: any,
    avoidDuplication = false
  ) {
    // --- function argument validations
    if (!userID) throw Error("userId Required");
    if (!cycle) throw Error("cycle Required");
    if (!todoTask || !todoTask.title || !todoTask.desc)
      throw Error("valid todoTask Required");
    if (!role || (role != Role.SUPERADMIN && !userID) || !cycle)
      throw new Error("Params missing in setToDoTask");

    // --- add missing data
    if (!todoTask.status) todoTask.status = "active";
    if (!todoTask.createdAt) todoTask.createdAt = moment().valueOf();

    // --- verify to-do task object
    this.verifyToDoTask(todoTask);

    console.log(`Creating to-do task for userID: ${userID}`, todoTask);

    // --- prepare request body to push task to DB
    let requestBody = JSON.stringify({
      userID,
      role,
      cycle,
      todoTask,
      avoidDuplication
    });
    let encryptedBody = this.encryptionService.encrypt(
      requestBody,
      environment.edk
    );

    // --- push task to DB
    let response: any = await this.httpClient
      .post(
        `${environment.firebaseFunctionsBaseUrl}/createToDoTask`,
        encryptedBody
      )
      .toPromise();

    return response.data && response.data.success;
  }
}

export enum ToDoStatus {
  ACTIVE = "active",
  COMPLETED = "completed",
  UNKNOWN = "unknown"
}

export const ToDoManagerClass = {
  PLIC: {
    REG_CONFLICT: 1001
  },
  ORG_MANAGER: {
    SETUP: 2001,
    AGREEMENT: 2002
  },
  ORG: {
    SETUP: 3001,
    AGREEMENT: 3002
  },
  LICENSE: {
    LOW_CREDIT_BALANCE: 4001
  }
};

export enum ToDoManagerr {
  PLIC = "plic",
  ORG_MANAGER = "org_manager",
  ORG = "org",
  LICENSE = "LICENSE"
}
