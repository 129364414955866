import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { Observable, Subscription } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class Auth2FAService {
  constructor(private db: AngularFireDatabase) {}

  _2FARequiredFlagSubscription: Subscription;
  monitor2FARequiredFlag(orgID: string, indID: string) {
    return new Observable(observer => {
      if (!orgID || !indID) observer.complete();

      // --- remove previous subscription
      if (
        this._2FARequiredFlagSubscription &&
        !this._2FARequiredFlagSubscription.closed
      )
        this._2FARequiredFlagSubscription.unsubscribe();

      // --- monitor 2FA required flag
      this._2FARequiredFlagSubscription = this.db
        .object(`organizations/${orgID}/2FARequired/${indID}`)
        .valueChanges()
        .subscribe(
          value => {
            observer.next(value);
          },
          error => observer.error(error)
        );
    });
  }
}
